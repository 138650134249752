import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { StorageService } from '../core/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HabitacionService } from '../core/services/habitacion.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatPaginator,MatSort,MatTableDataSource} from '@angular/material';
import { Empleado } from '../core/models/empleado';
import { EdicionUsuariosComponent } from '../mantenimientos/editar/edicion-usuarios/edicion-usuarios.component';
import { InsertarUsuariosComponent } from '../mantenimientos/insertar/insertar-usuarios/insertar-usuarios.component';
import { User } from '../core/models/user.model';
import { HttpClient } from '@angular/common/http';
import { map, first } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { ChangePasswordComponent } from './change-password/change-password.component';


@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  //data:Empleado
  empleado:Empleado=new Empleado();
  //public loginUserData: User;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['strUserName','strNombre', 'strApellidos','intId','strUserId'];
  dataSource ;
  data:Empleado[]=[];

  listEmpleados:Empleado[]=[];
  dataExcel:datosExcel[]=[];
  filtro:string;
  listFiltrada:Empleado[]=[];
  dataTodo:Empleado[]=[];
  teclaAlt:any;
  otraTecla:any;

  constructor(private storageService: StorageService,private miservicio:HabitacionService,
    private router: Router,private route: ActivatedRoute,public dialog: MatDialog,private http: HttpClient) { }

  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = 'Elementos por pagina';          
    this.listar();
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===78 && this.otraTecla===18){
      this.addUser();
    }else if(this.teclaAlt===88 && this.otraTecla===18){
      this.exportAsXLSX();
    }else if(this.teclaAlt===66 && this.otraTecla===18){
      var myEl = document.getElementById('miFiltro');
      myEl.focus();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }

  listar(){
    this.dataSource = new MyTableDataSource(this.paginator, this.sort);
    let intId=0;
    this.miservicio.getEmpleados(intId).pipe(first()).subscribe(datos=>{
      this.data=datos;
      this.listFiltrada=datos;
      //console.log(this.data);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    })
  }

  getColor(strActivo) { 
    switch (strActivo) {
      case 'N':
        return '#ff3333';
      case 'S':
        return '#00b300';
    }
  }

  editHab(empleado:Empleado){
    let dialogRef = this.dialog.open(EdicionUsuariosComponent, {
      width: '600px',
      //height:'1100px',
      data: {
        'intId':empleado.intId,
      }
    });
    dialogRef.afterClosed().subscribe((datos) => {
      if(datos.miRespuesta!=undefined){
        let myObject:any;
        myObject=datos.miListTrabajador[0];
        //console.log(myObject);
  
        let miTicket=datos.miListTrabajador[0].intId;
  
        var index = this.data.map(x => {
          return x.intId;
        }).indexOf(miTicket);
  
        if(datos.length!=0){      
          this.data.splice(index, 1, myObject);
          //console.log(index);
          //console.log(this.data);
          this.dataSource=new MatTableDataSource(this.data);
        }
        this.getColor(myObject.strActivo);
        if(datos.length===0){
          this.data.splice(index, 1);
        }
      }      
    });
  }

  addUser(){
    let dialogRef = this.dialog.open(InsertarUsuariosComponent, {
      width: '600px',
      //height:'630px',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      //this.listar();
      //console.log(result)
      if(result!=null){
        if(result.length>0){
          this.data.splice(0, this.data.length);
          this.data=result;  
          this.dataSource=new MatTableDataSource(result); 
        }
      }      
    });
  }

  deleteHab(){
    
  }

  changePassword(empleado:Empleado){
    let dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '400px',
      data: {
        'strUserId':empleado.strUserId,
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      //this.listar();
    });
  }

  buscar(event: any){
    this.filtro = event.target.value;
  
    this.dataTodo=this.data;
    if(this.filtro===''){
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
      this.listFiltrada=this.data;
    }else{
      this.data=this.data.filter(e=>e.strNombre.toLowerCase().startsWith(this.filtro.toLowerCase()) || 
      e.strApellidos.toLowerCase().startsWith(this.filtro.toLowerCase()) || e.strDni.toLowerCase().startsWith(this.filtro.toLowerCase()) || 
      e.strUserName.toLowerCase().startsWith(this.filtro.toLowerCase()));
    //console.log(this.data);
      this.listFiltrada=this.data;
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    }
    this.data=this.dataTodo;
  }

  exportAsXLSX():void {
    this.listFiltrada.forEach(e => {
      //console.log(e)
      // this.dataExcel.push({num_reserva:element.intId, num_habitacion:element.strHabitacionNombre, entrada:this.datepipe.transform(element.datEntrada, 'dd/MM/yyyy'), salida:this.datepipe.transform(element.datSalida, 'dd/MM/yyyy'), cliente:element.strClienteNombre+" "+element.strClienteApellidos, precio:0});
      this.dataExcel.push({
        Usuario:e.strUserName,
        Nombre:e.strNombre,
        Apellidos:e.strApellidos,
        Dni:e.strDni,
        Direccion:e.strDireccion,
        CP:e.strCP,
        Poblacion:e.strPoblacion,
        Provincia:e.strProvincia,
        Pais:e.strPais,
        Telefono1:e.strTelefono1,
        Telefono2:e.strTelefono2,
        Activo:e.strActivo
      })
    });
    this.miservicio.exportAsExcelFile(this.dataExcel, 'Listado Empleados');

    this.dataExcel.splice(0, this.dataExcel.length)
  }

}

export interface datosExcel{
  Usuario:any;
  Nombre:any;
  Apellidos:any;
  Dni:any;
  Direccion:any;
  CP:any;
  Poblacion:any;
  Provincia:any;
  Pais:any;
  Telefono1:any;
  Telefono2:any;
  Activo:any;
}


export class MyTableDataSource extends DataSource<Empleado> {
  data:Empleado[]=[];
 
   constructor(private paginator: MatPaginator, private sort: MatSort) {
     super();

   }
 
   connect(): Observable<Empleado[]> {
     const dataMutations = [
       observableOf(this.data),
       this.paginator.page,
       this.sort.sortChange
     ];
 
     this.paginator.length = this.data.length;
 
     return merge(...dataMutations).pipe(map(() => {
       return this.getPagedData(this.getSortedData([...this.data]));
     }));
   }
 
   disconnect() {}
 
   private getPagedData(data: Empleado[]) {
     const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
     return data.splice(startIndex, this.paginator.pageSize);
   }
 
 
   private getSortedData(data: Empleado[]) {
     if (!this.sort.active || this.sort.direction === '') {
       return data;
     }
 
     return data.sort((a, b) => {
       const isAsc = this.sort.direction === 'asc';
       switch (this.sort.active) {
         case 'strUserName': return compare(a.strUserName, b.strUserName, isAsc);
         case 'strNombre': return compare(+a.strNombre, +b.strNombre, isAsc);
         default: return 0;
       }
     });
   }
 }
 
 function compare(a, b, isAsc) {
   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
 }