import { Component, OnInit, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReservasService } from 'src/app/core/services/reservas.service';
import { DataService } from '../../service/data.service';
import { HabitacionService } from 'src/app/core/services/habitacion.service';
import { Reserva } from 'src/app/core/models/reserva';
import { Regimenes } from 'src/app/core/models/regimenes';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AgregaHuespedNuevaReservaComponent } from '../../componentes-auxiliares/agrega-huesped-nueva-reserva/agrega-huesped-nueva-reserva.component';
import { Cliente } from 'src/app/core/models/cliente';

@Component({
  selector: 'app-nueva-reserva',
  templateUrl: './nueva-reserva.component.html',
  styleUrls: ['./nueva-reserva.component.scss']
})
export class NuevaReservaComponent implements OnInit {

  form: FormGroup;
  miReserva:Reserva=new Reserva();
  listHabitaciones: any[];
  Habitacion:any;
  listRegimenes:Regimenes[]=[];
  selectRegimen:any;
  isChecked:boolean=false;
  Estado:string;
  cliente:Cliente=new Cliente();

  constructor(private fb: FormBuilder,private miservicioReservas:ReservasService,public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
  public datepipe: DatePipe,public dialog: MatDialog,private ds: DataService, private miservicioHabitacion:HabitacionService) {
    /*this.form = this.fb.group({
      id: [""],
      nombre: [""],
      apellidos: [""],
      email: [""],
      telefono:[""]
    });*/
  }

  ngOnInit() {
    this.listarHabitaciones();
    this.listarRegimenes();

    if(this.isChecked){
      this.Estado='Si'
    }else{
      this.Estado='No'
    }
  }

  onChangeEntrada(event){
    this.data.start=event;    
  }

  onChangeSalida(event){
    this.data.end=event;
  }

  listarHabitaciones(){
    this.ds.getRooms().subscribe(result =>{
      let lista:any[]=[];
       result.forEach(element => {
        lista.push({id:element.intHabitacionId.toString(), name:element.strHabitacionNombre, capacity:element.strTipoHabitacionNombre, intTipoHabitacion:element.intTipoHabitacionId, status:element.intEstadoLimpiezaId});
      })
      this.listHabitaciones=lista;
    });
  }

  listarRegimenes(){
    this.miservicioReservas.getRegimenes().subscribe(datos=>{
      this.listRegimenes=datos.filter(e=>e.strActivo==="S");
      this.selectRegimen=this.listRegimenes[0].intId
    })
  }

  cancelar(){
    this.thisDialogRef.close();
  }

  pulsa(){
    if(this.isChecked){
      this.Estado='No';
    }else{
      this.Estado="Si";
    }
  }

  guardarCambiosReserva(){
    /*this.miservicioReservas.editaReserva(this.miReserva).subscribe(datos=>{
      this.thisDialogRef.close(datos);
    })*/
    //console.log(this.data)
    //console.log(this.selectRegimen)
    //console.log(this.cliente)
    //console.log(this.isChecked)  

    //RELLENO EL OBJETO DE RESERVA
    this.miReserva.datEntrada = this.data.start
    this.miReserva.datSalida = this.data.end
    this.miReserva.intHabitacionId = this.data.data.resource
    this.miReserva.intRegimenId = this.selectRegimen
    //datos del cliente
    //----------------------------
    this.miReserva.intClienteId = this.cliente.intId
    this.miReserva.strClienteNombre = this.cliente.strNombre
    this.miReserva.strClienteApellidos = this.cliente.strApellidos
    this.miReserva.strClienteTelefono = this.cliente.strTelefono1
    this.miReserva.strClienteEmail = this.cliente.strEmail
    this.miReserva.strOrigen="L"

    this.miservicioReservas.insertaReserva(this.miReserva,this.isChecked).subscribe((datos:any)=>{
      //console.log(datos)
      this.thisDialogRef.close(datos)
    })
  }

  openClientes(){
    let dialogRef = this.dialog.open(AgregaHuespedNuevaReservaComponent, {
      width: '600px',
      data:this.data
    });
    dialogRef.afterClosed().subscribe((data:any)=> {
      //console.log(data)
      if(data!=undefined){
        this.cliente=data
      }      
      //console.log(this.cliente)
      //this.form.controls.nombre.setValue(data.strNombre)
    });
  }

}


export class reservaNueva{

  public start: any;
  public end: any;
  public resource: any;  

}
