import {Component, ViewChild, OnInit} from "@angular/core";
import {DayPilot, DayPilotModalComponent} from "daypilot-pro-angular";
import {Validators, FormBuilder, FormGroup, FormControl} from "@angular/forms";
import {Observable, AsyncSubject} from "rxjs";
import { CreateReservationParams, DataService } from "../service/data.service";
import { Cliente } from "../../core/models/cliente";
import { first } from "rxjs/operators";
import { ReservasService } from "../../core/services/reservas.service";
import { MantenimientosService } from "../../core/services/mantenimientos.service";
import { TipoHabitacion } from "../../core/models/tipo-habitacion";
import { Habitacion } from "../../core/models/habitacion";
import { Regimenes } from "../../core/models/regimenes";
import { MatDialog } from "@angular/material";
import { BuscaClienteComponent } from "./busca-cliente/busca-cliente.component";

@Component({
  selector: 'reservation-create-dialog',
  template: `
    <daypilot-modal>
    <div class="center">            
      
      <h1>Nueva reserva</h1>
      <br>
      <div class="myheaders">
        <div class="form-item">
        <!--<label for="nuevo">Nuevo cliente </label>
        <input type="radio" id="nuevo" name="cliente" value="nuevo" (click)="addClienteNuevo()" />
        &nbsp;&nbsp;&nbsp;
        <label for="buscar">Buscar cliente </label>
        <input type="radio" id="buscar" name="cliente" value="buscar" (click)="buscarCliente()" />-->
            
        <!--<button (click)="addClienteNuevo()" > Cliente Nuevo </button>
        <button (click)="buscarCliente()" > Buscar Cliente </button>-->
        </div>
        <!--<div class="form-item" *ngIf="nuevoCliente">
        *Nombre: <input type="text" name="nombre" [(ngModel)]="nombre"/>        
        </div>
        <div class="form-item" *ngIf="nuevoCliente">
        *Apellidos: <input type="text" name="apellidos" [(ngModel)]="apellidos"/>         
        </div>-->
        </div>
        <form [formGroup]="form">
        <div class="form-item">
        *Nº cliente: <input type="text" class="mini" formControlName="name" name="intId"  [(ngModel)]="intId" disabled/>
        &nbsp;&nbsp;
        <button (click)="findCliente()">&nbsp; * &nbsp;</button>
        </div>
        <div class="form-item">
        *Nombre: <input type="text" formControlName="formNombre" name="nombre"  [(ngModel)]="nombre"/>        
        </div>
        <div class="form-item">
        *Apellidos: <input type="text" formControlName="formApellidos" name="apellidos"  [(ngModel)]="apellidos"/>         
        </div> 
        <div class="form-item" *ngIf="miCliente">
        *Cliente
        <select formControlName="name" [(ngModel)]="selectCliente">
            <option *ngFor="let r of listaClientes" [ngValue]="r.intId">{{r.strNombre}} {{r.strApellidos}}</option>
          </select>
        </div>
        <!--<div class="form-item">
          *Tipo Habitación
          <select formControlName="name" [(ngModel)]="selectTipoHab" (ngModelChange)="cambia($event)">
            <option *ngFor="let h of listaTiposHabitacion" [ngValue]="h.intId">{{h.strNombre}}</option>
          </select>
        </div>-->
        <div class="form-item">
        *Habitación 
          <select formControlName="resource">
            <option *ngFor="let r of resources" [ngValue]="r.id">{{r.name}} - {{r.capacity}}</option>
          </select>
        </div>        
        <div class="form-item">
        *Régimen 
          <select formControlName="midato" [(ngModel)]="selectRegimen">
            <option *ngFor="let r of listRegimenes" [ngValue]="r.intId">{{r.strNombre}}</option>
          </select>
        </div>
        <div class="form-item">
        *Entrada <input formControlName="start" type="text" placeholder="Entrada"> <span *ngIf="!form.controls.start.valid"> *Fecha no válida</span>
        </div>
        <div class="form-item">
        *Salida <input formControlName="end" type="text" placeholder="Salida"> <span *ngIf="!form.controls.end.valid"> *Fecha no válida</span>
        </div>
        <br>
        <div class="form-item">
          <ul>
            <li>
              <button (click)="submit()" [disabled]="!form.valid">Guardar</button>
            </li>
            <li>
              <button (click)="cancel()">Cancelar</button>
            </li>
          </ul>                    
        </div>
    </form>
    </div>
    </daypilot-modal>
  `,
  styles: [`
  .center {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top:80px;
  }
  .center form{
    margin:0 auto;
    max-width:400px;
  }
  .form-item {
    margin: 4px 0px;
  }
  .form-item .mini{
    max-width:50px;
  }
  .myheaders, h1{
    margin:0 auto;
    max-width:400px;
  }

  .myheaders label:hover{
    cursor:pointer;
  }

  .form-item ul{
    width:100%;
    list-style:none;
  }

  li{
    width:50%;
    display: inline-block;
  }
  li button{
    width:40%;
    margin:0 auto;
  }
  `]
})
export class ReservationCreateComponent implements OnInit{
  @ViewChild(DayPilotModalComponent) modal : DayPilotModalComponent;

  form: FormGroup;
  dateFormat = "dd/MM/yyyy";

  resources: any[];
  selectCliente:any;
  selectTipoHab:any;
  selectRegimen:any;
  seleccionMetodo:boolean=true;
  nuevoCliente:boolean=false;
  miCliente:boolean=false;
  //listClientes:any[];
  //clientes:AsyncSubject<Cliente>;

  listaClientes:Cliente[]=[];
  listaTiposHabitacion:TipoHabitacion[]=[];
  listHabitaciones:Habitacion[]=[];
  listRegimenes:Regimenes[]=[];
  //cliente:Cliente=new Cliente();
  nombre:any;
  apellidos:any;
  intId:any;

  subject: AsyncSubject<CreateReservationParams>;

  constructor(private fb: FormBuilder, private ds: DataService,private miservicioReservas:ReservasService,
    private miservicio:MantenimientosService,public dialog: MatDialog) {
    this.form = this.fb.group({
      name: [""],
      start: ["", this.dateTimeValidator(this.dateFormat)],
      end: ["", [Validators.required, this.dateTimeValidator(this.dateFormat)]],
      resource: ["", Validators.required],
      midato:["",Validators.required],
      formNombre:[""],
      formApellidos:[""]  
    });
  }

  ngOnInit(){
    this.listarClientes();
    this.listarTiposHab();
    this.listarRegimenes();
  }

  show(args: any): Observable<CreateReservationParams> {
    this.ds.getRooms().subscribe(result =>{
      //this.resources = result
      let lista:any[]=[];
       //this.config.resources = result          
       result.forEach(element => {
        lista.push({id:element.intHabitacionId.toString(), name:element.strHabitacionNombre, capacity:element.strTipoHabitacionNombre, intTipoHabitacion:element.intTipoHabitacionId, status:element.intEstadoLimpiezaId});
        //console.log(element)
      })
      this.resources=lista;
      //console.log(this.resources);
    });

    this.listarRegimenes();

    //this.listHabitaciones=this.resources.filter(r=>r.intTipoHabitacion=this.selectTipoHab)

    args.name = "";
    this.form.setValue({
      start: args.start.toString(this.dateFormat),
      end: args.end.toString(this.dateFormat),
      name: "",
      resource: args.resource,
      midato:this.listRegimenes[0].intId,
      formNombre:"",
      formApellidos:""  
    });
    //this.form.controls.start.setValue(this.form.controls.start.value)
    this.modal.show();

    this.subject = new AsyncSubject();
    return this.subject.asObservable();
  }

  listarClientes(){
    this.miservicioReservas.getClientes().pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.listaClientes=datos;
      //console.log(this.listaClientes)
    })
  }

  listarTiposHab(){
    this.miservicioReservas.getTiposHab().pipe(first()).subscribe(datos=>{
      //this.listaTiposHabitacion=datos;
      this.listaTiposHabitacion=datos.filter(e=>e.strActivo==="S");
      //console.log(this.listaTiposHabitacion);
      
    })
  }

  buscarCliente(){
    this.seleccionMetodo=false;
    this.nuevoCliente=false;
    this.miCliente=true;
  }

  addClienteNuevo(){
    this.seleccionMetodo=false;
    this.nuevoCliente=true;
    this.miCliente=false;
  }
  /*cambia(event:any){
    console.log(event);
    //console.log(this.resources)
    
    let resourcesFiltado=this.resources;
    let resourcesTodo=this.resources;
    if(event!=undefined){
      
      resourcesFiltado=this.resources.filter(r=>r.intTipoHabitacion===event);
      this.resources=resourcesFiltado;
      
    }    
    console.log(this.resources);
    this.resources=resourcesTodo;
  }*/

  listarRegimenes(){
    this.miservicioReservas.getRegimenes().pipe(first()).subscribe(datos=>{
      //console.log(datos);
      //this.listRegimenes=datos;
      this.listRegimenes=datos.filter(e=>e.strActivo==="S");
    })
  }

  findCliente(){
    let dialogRef = this.dialog.open(BuscaClienteComponent, {
      width: '280px',
    });
    dialogRef.afterClosed().subscribe(result => {
      //this.listarRservas();
      //console.log(result)
      this.form.controls.name.setValue(result[0].intId)
      this.form.controls.formNombre.setValue(result[0].strNombre)
      this.form.controls.formApellidos.setValue(result[0].strApellidos)
      //console.log(this.form.value.formApellidos.setValue(result[0].strApellidos))
      /*this.form.setValue({
        //start: ev.start().toString(this.dateFormat),
        //end: ev.end().toString(this.dateFormat),
        name: result[0].intId,
        //resource: ev.resource(),
        //strEstadoReserva: result.strEstadoReserva,
        //midato1:result.intRegimenId,
        formNombre:result[0].strNombre,
        formApellidos:result[0].strApellidos
      });*/
      
    });
  }

  submit() {
    let data = this.form.getRawValue();
    //console.log(this.nombre)
    //console.log(this.apellidos)
    //console.log(this.selectCliente);
    //console.log(this.selectTipoHab)
    //console.log(this.selectRegimen)

    //let miCliente=this.listaClientes.find(c=>c.intId.toString().startsWith(this.selectCliente.toString()))

    // let params: CreateReservationParams = {
    //   start: DayPilot.Date.parse(data.start, this.dateFormat).toString(),
    //   end: DayPilot.Date.parse(data.end, this.dateFormat).toString(),
    //   strClienteNombre: this.nombre,
    //   strClienteApellidos:this.apellidos,  
    //   intClienteId:this.form.controls.name.value,    
    //   room: data.resource,
    //   intRegimenId:this.selectRegimen
    // };

    this.modal.hide();

    //this.subject.next(params);
    this.subject.complete();
    //AQUI LLAMO A LA FUNCION DE INSERTAR RESERVA

    this.seleccionMetodo=true;
    this.nuevoCliente=false;
    this.miCliente=false;
    this.nombre="";
    this.apellidos="";
    this.selectCliente=undefined;
  }

  cancel() {
    this.modal.hide();
    this.subject.next(null);
    this.subject.complete();
    this.seleccionMetodo=true;
    this.nuevoCliente=false;
    this.miCliente=false;
  }

  dateTimeValidator(format: string) {
    return function(c:FormControl) {
      let valid = !!DayPilot.Date.parse(c.value, format);
      return valid ? null : {badDateTimeFormat: true};
    };
  }
}
