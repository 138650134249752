import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Articulo } from 'src/app/core/models/articulo';
import { MantenimientosService } from 'src/app/core/services/mantenimientos.service';
import { Iva } from 'src/app/core/models/iva';

@Component({
  selector: 'app-nuevo-articulo',
  templateUrl: './nuevo-articulo.component.html',
  styleUrls: ['./nuevo-articulo.component.scss']
})
export class NuevoArticuloComponent implements OnInit {

  formNew: FormGroup;
  nuevoArticulo:Articulo=new Articulo();
  submitted:boolean=false;
  listIva:Iva[]=[];
  selectIva:Iva=new Iva();
  isChecked:boolean;
  existError:boolean=false;
  mensajeError:string;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>,private _formBuilder: FormBuilder,private miServicio:MantenimientosService) { }

  ngOnInit() {
    this.formNew = this._formBuilder.group({
      nombre: ['', Validators.required],
      descripcion: [''],
      pv: [''],
      iva: [''],
      pvp: ['', Validators.required],
      dto: [''],
      activo: ['']
    })

    this.formNew.get('pv').disable();

    this.listarDatos();
  }

  listarDatos(){
    this.miServicio.getIva(0).subscribe(datos=>{
      //console.log(datos)
      this.listIva=datos.filter(e=>e.strActivo==="S"); 

      datos.forEach(e => {
        if(e.strDefecto==='S' ){
          this.selectIva=e;
        }
      });
    })
  }

  salvar(){
    if(this.isChecked){
      this.nuevoArticulo.strActivo="S"
    }else{
      this.nuevoArticulo.strActivo="N"
    }
    this.submitted=true;

    if(this.formNew.valid){      
      //iva por defecto
      if(this.nuevoArticulo.decIva===undefined){
        this.nuevoArticulo.decIva=this.selectIva.decValor;
      }
      //console.log(this.nuevoArticulo)
      this.miServicio.insertaArticulo(this.nuevoArticulo).subscribe((datos:any)=>{
        //console.log(datos)
        if(datos.miRespuesta.booOk){
          this.thisDialogRef.close(datos.miListArticulo)
        }else{
          this.existError=true;
          this.mensajeError=datos.miRespuesta.strMensaje
        }
      })

    }    
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
