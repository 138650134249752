import { Component, OnInit, Inject, ViewChild, HostListener } from '@angular/core';
import { HabitacionService } from '../core/services/habitacion.service';
import { Habitacion } from '../core/models/habitacion';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { EdicionHabitacionesComponent } from '../mantenimientos/editar/edicion-habitaciones/edicion-habitaciones.component';
import { InsertarHabitacionesComponent } from '../mantenimientos/insertar/insertar-habitaciones/insertar-habitaciones.component';
import { User } from '../core/models/user.model';
import { DataSource } from '@angular/cdk/collections';
import { map, first } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import {MatTableDataSource, MatSort, DateAdapter,MatPaginator} from '@angular/material';


@Component({
  selector: 'app-habitaciones',
  templateUrl: './habitaciones.component.html',
  styleUrls: ['./habitaciones.component.scss']
})
export class HabitacionesComponent implements OnInit {

  //listaHabitaciones:Habitacion[]=[];
  data:Habitacion[]=[];
  //data:Habitacion=new Habitacion();
  dataExcel:datosExcel[]=[];
  filtro:string;
  dataTodo:Habitacion[]=[];
  listFiltrada:Habitacion[]=[];
  teclaAlt:any;
  otraTecla:any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['strNombre','strTipoHabitacionNombre','strDescripcion'];
  dataSource ;

  constructor(private miservicio:HabitacionService,public dialog: MatDialog) { }

  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = 'Elementos por pagina';
    this.listar();
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===78 && this.otraTecla===18){
      this.addHab();
    }else if(this.teclaAlt===88 && this.otraTecla===18){
      this.exportAsXLSX();
    }else if(this.teclaAlt===66 && this.otraTecla===18){
      var myEl = document.getElementById('miFiltro');
      myEl.focus();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }

  public listar(){
    this.dataSource = new MyTableDataSource(this.paginator, this.sort);

    this.miservicio.getHabitaciones().pipe(first()).subscribe(datos=>{
      this.data=datos;
      this.listFiltrada=datos;
      //console.log(this.data);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    })
  }

getColor(strActivo) { 
  switch (strActivo) {
    case 'N':
      return '#ff3333';
    case 'S':
      return '#00b300';
  }
}

  
    editHab(habitacion:Habitacion){
      let dialogRef = this.dialog.open(EdicionHabitacionesComponent, {
        width: '600px',
        data: {
          'intId':habitacion.intId,
        }
      });
      dialogRef.afterClosed().subscribe((datos) => {
        //console.log(datos)
        if(datos!=undefined){
          let myObject:any;
          myObject=datos.miListHabitacion[0];
          //console.log(myObject);
    
          let miTicket=datos.miListHabitacion[0].intId;
    
          var index = this.data.map(x => {
            return x.intId;
          }).indexOf(miTicket);
    
          if(datos.length!=0){      
            this.data.splice(index, 1, myObject);
            //console.log(index);
            //console.log(this.data);
            this.dataSource=new MatTableDataSource(this.data);
          }
          this.getColor(myObject.strActivo);
          if(datos.length===0){
            this.data.splice(index, 1);
          }
        }        
      });
    }

    addHab(){
      let dialogRef = this.dialog.open(InsertarHabitacionesComponent, {
        width: '600px',
      });
      dialogRef.afterClosed().subscribe((result:any) => {
        //this.listar();
        //this.data=result;
        //console.log(result);
        if(result!=null){
          if(result.length>0){
            this.data.splice(0, this.data.length);
            this.data=result;  
            this.dataSource=new MatTableDataSource(result); 
          }
        }        
      });
    }

    buscar(event: any){
      this.filtro = event.target.value;
    
      this.dataTodo=this.data;
      if(this.filtro===''){
        this.dataSource = new MyTableDataSource(this.paginator, this.sort);
        this.dataSource=new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
        this.listFiltrada=this.data;
      }else{
        this.data=this.data.filter(e=>e.strNombre.toLowerCase().startsWith(this.filtro.toLowerCase()) || 
        e.strTipoHabitacionNombre.toLowerCase().startsWith(this.filtro.toLowerCase()) || e.strDescripcion.toLowerCase().startsWith(this.filtro.toLowerCase()));
      //console.log(this.data);
        this.listFiltrada=this.data;
        this.dataSource = new MyTableDataSource(this.paginator, this.sort);
        this.dataSource=new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
      }
      this.data=this.dataTodo;
    }

    exportAsXLSX():void {
      this.listFiltrada.forEach(e => {
        // this.dataExcel.push({num_reserva:element.intId, num_habitacion:element.strHabitacionNombre, entrada:this.datepipe.transform(element.datEntrada, 'dd/MM/yyyy'), salida:this.datepipe.transform(element.datSalida, 'dd/MM/yyyy'), cliente:element.strClienteNombre+" "+element.strClienteApellidos, precio:0});
        this.dataExcel.push({
          Habitacion:e.strNombre, 
          Tipo:e.strTipoHabitacionNombre, 
          Descripcion:e.strDescripcion, 
          Activo:e.strActivo
        })
      });
      this.miservicio.exportAsExcelFile(this.dataExcel, 'Listado habitaciones');
  
      this.dataExcel.splice(0, this.dataExcel.length)
    }

    
}

export interface datosExcel {
  Habitacion:any;
  Tipo:any;
  Descripcion:any;
  Activo:any;
}


export class MyTableDataSource extends DataSource<Habitacion> {
  data:Habitacion[]=[];
 
   constructor(private paginator: MatPaginator, private sort: MatSort) {
     super();
   }
 
   connect(): Observable<Habitacion[]> {
     const dataMutations = [
       observableOf(this.data),
       this.paginator.page,
       this.sort.sortChange
     ];
 
     this.paginator.length = this.data.length;
 
     return merge(...dataMutations).pipe(map(() => {
       return this.getPagedData(this.getSortedData([...this.data]));
     }));
   }
 
   disconnect() {}
 
   private getPagedData(data: Habitacion[]) {
     const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
     return data.splice(startIndex, this.paginator.pageSize);
   }
 
 
   private getSortedData(data: Habitacion[]) {
     if (!this.sort.active || this.sort.direction === '') {
       return data;
     }
 
     return data.sort((a, b) => {
       const isAsc = this.sort.direction === 'asc';
       switch (this.sort.active) {
         case 'strTipoHabitacionNombre': return compare(a.strTipoHabitacionNombre, b.strTipoHabitacionNombre, isAsc);
         case 'strDescripcion': return compare(+a.strDescripcion, +b.strDescripcion, isAsc);
         default: return 0;
       }
     });
   }
 }
 
 function compare(a, b, isAsc) {
   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
 }
