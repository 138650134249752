import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MantenimientosService } from '../core/services/mantenimientos.service';
import { first, map } from 'rxjs/operators';
import { TipoHabitacion } from '../core/models/tipo-habitacion';
import { Habitacion } from '../core/models/habitacion';
import { DateAdapter, MAT_DATE_LOCALE, MatDatepickerInputEvent, MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { ReservasService } from '../core/services/reservas.service';
import { Cliente } from '../core/models/cliente';
import { DataSource } from '@angular/cdk/collections';
import { Observable, of as observableOf, merge } from 'rxjs';
import { Reserva } from '../core/models/reserva';
import { InsertaReservaComponent } from './inserta-reserva/inserta-reserva.component';
import { DatePipe } from '@angular/common';
import { ClaveValor } from '../listado/clave-valor';

@Component({
  selector: 'app-reservas',
  templateUrl: './reservas.component.html',
  styleUrls: ['./reservas.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es' }]
})
export class ReservasComponent implements OnInit {

  /*isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;*/
  formNewCliente: FormGroup;
  muestraReserva:boolean=false;
  datosInicio:boolean=true;

  habitacion:Habitacion=new Habitacion();
  public listaTiposHabitacion:TipoHabitacion[]=[];
  date = new FormControl(new Date());
  date2 = new FormControl(new Date());
  cliente:Cliente=new Cliente();
  listaClientes:Cliente[]=[];
  nuevoCliente:Cliente=new Cliente();
  decImporteTotal:string;

  isNewCliente:boolean=false;
  guardar:boolean=true;
  submitted:boolean=false;
  dniError:boolean=false;
  dniValido:boolean=false;

  today = new FormControl(new Date());
  fechaInicio:any;
  fechaFin:any;
  fechaInicioValida:boolean=false;
  fechaFinValida:boolean=false;
  fechasError:boolean=false;

  showAdd:boolean=true;
  showRemove:boolean=false;
  isClick:boolean=false;

  faltanDatos:boolean=false;

  data:Reserva[]=[];
  from:string;
  fechaFiltroEntrada:string;

  dataExcel:datosExcelReserva[]=[];
  filtro:string;
  listFiltrada:Reserva[]=[];
  dataTodo:Reserva[]=[];
  listFiltros:ClaveValor[]=[];
  existFilter:boolean=false;
  teclaAlt:any;
  otraTecla:any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['strHabitacionNombre','datEntrada','datSalida','strClienteNombre','decImporteTotal'];
  dataSource ;
  hide = false;

  constructor(private _formBuilder: FormBuilder,private miservicio:MantenimientosService,private dateAdapter: DateAdapter<Date>,
    private miservicioReservas:ReservasService,public dialog: MatDialog,public datepipe: DatePipe) {
    this.dateAdapter.setLocale('es');   
  }

  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = 'Elementos por pagina';
    //console.log(this.today.value.toLocaleDateString())
    /*this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });*/

    this.formNewCliente = this._formBuilder.group({
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      dni: ['', Validators.required],
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),
      telefono: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$'),
      ])),
      direccion: ['', Validators.required],
      cp: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{5}$'),
      ])),
      localidad: ['', Validators.required],
      poblacion: ['', Validators.required],
      pais: ['', Validators.required],
    })


    this.listarTiposHab();
    this.listarClientes();
    this.listarRservas();
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===88 && this.otraTecla===18){
      this.exportAsXLSX();
    }else if(this.teclaAlt===66 && this.otraTecla===18){
      var myEl = document.getElementById('miFiltro');
      myEl.focus();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }  

  newReserva2(){
    let dialogRef = this.dialog.open(InsertaReservaComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.listarRservas();
    });
  }

  listarTiposHab(){
    this.miservicio.getTiposHab(0).pipe(first()).subscribe(datos=>{
      this.listaTiposHabitacion=datos;
      //console.log(this.listaTiposHabitacion);
    })
  }

  listarClientes(){
    this.miservicioReservas.getClientes().pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.listaClientes=datos;
      //console.log(this.listaClientes)
    })
  }

  buscarFechaInicio( event: MatDatepickerInputEvent<Date>) {
    /*this.miFecha=event.value.toLocaleDateString();
    this.navegar=false;
    let fechaCambia=event.value.toJSON()

    if(this.fechaHoy.value.toJSON()<=fechaCambia){
      //alert('No existe limpieza para la fecha seleccionada')
      setTimeout(() => {
        this.openWindow();
      }, 100); 
    }else{
      this.miservicio.getHabLimpiezas(this.miFecha,'','').pipe(first()).subscribe(datos=>{
        this.data=datos;
        this.dataSource=new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
      })
    }*/
    //console.log(this.datepipe.transform(event.value, 'yyyy-MM-dd'))

    this.from=this.datepipe.transform(event.value, 'yyyy-MM-dd');  
    //this.from=event.value.toJSON();

    let myFilter:ClaveValor=new ClaveValor();
      myFilter.id=1;
      myFilter.valor="Desde: " + this.datepipe.transform(event.value, 'dd/MM/yy');

    if(this.listFiltros.length>0){
      if(this.listFiltros.some(e => e.id === 1)){
        let miTicket=myFilter.id;
        var index = this.listFiltros.map(x => {
          return x.id;
        }).indexOf(miTicket);    
        this.listFiltros.splice(index, 1, myFilter);
      }else{
        this.listFiltros.push(myFilter);
      }      
    }else{
      this.listFiltros.push(myFilter);
    }
    
    this.existFilter=true;

    this.buscarReservas();
    
  }

  buscarFechaFin( event: MatDatepickerInputEvent<Date>) {
    /*this.miFecha=event.value.toLocaleDateString();
    this.navegar=false;
    let fechaCambia=event.value.toJSON()

    if(this.fechaHoy.value.toJSON()<=fechaCambia){
      //alert('No existe limpieza para la fecha seleccionada')
      setTimeout(() => {
        this.openWindow();
      }, 100); 
    }else{
      this.miservicio.getHabLimpiezas(this.miFecha,'','').pipe(first()).subscribe(datos=>{
        this.data=datos;
        this.dataSource=new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
      })
    }*/
    //console.log(this.datepipe.transform(event.value, 'yyyy-MM-dd'))

    this.fechaFiltroEntrada=this.datepipe.transform(event.value, 'yyyy-MM-dd');  
    //this.fechaFiltroEntrada=event.value.toJSON();

    let myFilter:ClaveValor=new ClaveValor();
      myFilter.id=2;
      myFilter.valor="Hasta: " + this.datepipe.transform(event.value, 'dd/MM/yy');

    if(this.listFiltros.length>0){
      if(this.listFiltros.some(e => e.id === 2)){
        let miTicket=myFilter.id;
        var index = this.listFiltros.map(x => {
          return x.id;
        }).indexOf(miTicket);    
        this.listFiltros.splice(index, 1, myFilter);
      }else{
        this.listFiltros.push(myFilter);
      }      
    }else{
      this.listFiltros.push(myFilter);
    }
    
    this.existFilter=true;

    this.buscarReservas();
  }

  buscarReservas(){
    let  today =  new FormControl(new Date());
    

    if(this.from === undefined){
      this.from=today.value.toJSON();
    }

    if(this.fechaFiltroEntrada === undefined){
      //this.fechaFiltroEntrada=today.value.toJSON();
      this.fechaFiltroEntrada=this.datepipe.transform(today.value.toJSON(), 'yyyy-MM-dd'); 
    }

    //console.log(this.from+" "+ this.fechaFiltroEntrada)

    this.dataSource = new MyTableDataSource(this.paginator, this.sort);

    this.miservicioReservas.getReserva(this.from,"",this.fechaFiltroEntrada).pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.data=datos;
      this.listFiltrada=datos;
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    })
  }

  exportAsXLSX():void {
    //console.log(this.data);
    this.listFiltrada.forEach(element => {
      this.dataExcel.push({Reserva:element.intId, Habitacion:element.strHabitacionNombre, Entrada:this.datepipe.transform(element.datEntrada, 'dd/MM/yyyy'), Salida:this.datepipe.transform(element.datSalida, 'dd/MM/yyyy'), Cliente:element.strClienteNombre+" "+element.strClienteApellidos, Precio:0});
      //this.data.pop();
    });
    //console.log(this.dataExcel)
    this.miservicioReservas.exportAsExcelFile(this.dataExcel, 'listado-reservas');

    this.dataExcel.splice(0, this.dataExcel.length)
    /*this.dataExcel.forEach(element => {
      this.dataExcel.pop();
    });*/
  }

  newCliente(){
    this.isNewCliente=true;
    this.guardar=false;
    this.showAdd=false;
    this.showRemove=true;
  }

  removeIcon(){
    this.isNewCliente=false;
    this.guardar=true;
    this.showAdd=true;
    this.showRemove=false;
  }

  addCliente(){
    
    this.submitted = true;
    //console.log(this.nuevoCliente);

    if(this.nif()===true){
      this.dniError=false;

      if(this.formNewCliente.valid){
        this.miservicioReservas.postCliente(this.nuevoCliente).subscribe((datos:any)=>{
          //console.log(datos)
          if(datos.booOk===true){
            this.listarClientes();
          }
        })
        this.isNewCliente=false;
        this.guardar=true;
      }
    }else{
      this.dniError=true;
    }      
  }

  addReserva(){
    //console.log(this.cliente);
    //console.log(this.habitacion);
    if(this.fechaInicio===undefined){
      this.fechaInicioValida=true;
      this.fechaInicio=this.today.value.toLocaleDateString();
    }

    if(this.fechaFin===undefined || this.fechaFin===this.fechaInicio){
      this.fechaFinValida=false;
      this.fechaFin=this.today.value.toLocaleDateString();
    }
    //console.log("Inicio"+this.fechaInicio);
    //console.log("fin"+this.fechaFin);
    if(this.fechaInicioValida===true && this.fechaFinValida===true){
      //console.log('ok')
      this.fechasError=false;
     /* this.miservicioReservas.postReserva(this.cliente,this.habitacion,this.fechaInicio,this.fechaFin,this.decImporteTotal).subscribe((datos:any)=>{
        if(datos.booOk===false){
          this.faltanDatos=true;
        }else{
        this.listarRservas();
        this.muestraReserva=false;
        this.datosInicio=true;
        }
      })*/
    }else{
      this.fechasError=true;
    }
  }

  addEvent( event: MatDatepickerInputEvent<Date>) {
    this.fechaInicio=event.value.toJSON();
    //console.log("Hoy: "+this.today.value.toJSON());
    //console.log("Reserva: "+this.fechaInicio);

    if(this.today.value.toJSON()<=this.fechaInicio){
      this.fechaInicioValida=true;
    }else{
      alert('No puede seleccionar una fecha inferior a la actual');
    }
    
  }

  addEvent2( event: MatDatepickerInputEvent<Date>) {
    this.fechaFin=event.value.toJSON();

    //console.log("Hoy: "+this.today.value.toJSON());
    //console.log("Reserva: "+this.fechaInicio);

    if(this.today.value.toJSON()>this.fechaFin){
      alert('No puede seleccionar una fecha inferior a la actual');
    }else{
      this.fechaFinValida=true;
    }
  }

  newReserva(){
    this.muestraReserva=true;
    this.datosInicio=false;
  }

  deleteMuestraReserva(){
    this.muestraReserva=false;
    this.datosInicio=true;
  }

  public listarRservas(){
    this.dataSource = new MyTableDataSource(this.paginator, this.sort);

    this.miservicioReservas.getReserva("", "","").pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.data=datos;
      this.listFiltrada=datos;
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    })
  }

  public  nif() {
    var numero
    var letr
    var letra
    var expresion_regular_dni
   
    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;
   
    if(expresion_regular_dni.test (this.formNewCliente.controls.dni.value) == true){
       numero = this.formNewCliente.controls.dni.value.substr(0,this.formNewCliente.controls.dni.value.length-1);
       letr = this.formNewCliente.controls.dni.value.substr(this.formNewCliente.controls.dni.value.length-1,1);
       numero = numero % 23;
       letra='TRWAGMYFPDXBNJZSQVHLCKET';
       letra=letra.substring(numero,numero+1);
      if (letra!=letr.toUpperCase()) {
        return this.dniValido=false;
         //alert('Dni erroneo, la letra del NIF no se corresponde');
       }else{
         return this.dniValido=true;
       }
    }else{
      return this.dniValido=false;
       //alert('Dni erroneo, formato no válido');
     }
  }

  buscar(event: any){
    this.filtro = event.target.value;
  
    this.dataTodo=this.data;
    if(this.filtro===''){
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
      this.listFiltrada=this.data;
    }else{
      this.data=this.data.filter(e=>e.strHabitacionNombre.toLowerCase().startsWith(this.filtro.toLowerCase()) || 
      e.strClienteNombre.toLowerCase().startsWith(this.filtro.toLowerCase()) || e.strClienteApellidos.toLowerCase().startsWith(this.filtro.toLowerCase()));
      //console.log(this.data);
      this.listFiltrada=this.data;
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    }
    this.data=this.dataTodo;
  }

  deleteFilter(){
    //Refrescar la pagina
    window.location.reload();
  }

}


export interface datosExcelReserva {
  Reserva: number;
  Habitacion: string;
  Entrada:string;
  Salida: string;
  Cliente: string;
  Precio:number;
}


export class MyTableDataSource extends DataSource<Reserva> {
  data:Reserva[]=[];
 
   constructor(private paginator: MatPaginator, private sort: MatSort) {
     super();
   }
 
   connect(): Observable<Reserva[]> {
     const dataMutations = [
       observableOf(this.data),
       this.paginator.page,
       this.sort.sortChange
     ];
 
     this.paginator.length = this.data.length;
 
     return merge(...dataMutations).pipe(map(() => {
       return this.getPagedData(this.getSortedData([...this.data]));
     }));
   }
 
   disconnect() {}
 
   private getPagedData(data: Reserva[]) {
     const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
     return data.splice(startIndex, this.paginator.pageSize);
   }
 
 
   private getSortedData(data: Reserva[]) {
     if (!this.sort.active || this.sort.direction === '') {
       return data;
     }
 
     return data.sort((a, b) => {
       const isAsc = this.sort.direction === 'asc';
       switch (this.sort.active) {
         case 'strHabitacionNombre': return compare(a.strHabitacionNombre, b.strHabitacionNombre, isAsc);
         case 'strClienteNombre': return compare(+a.strClienteNombre, +b.strClienteNombre, isAsc);
         default: return 0;
       }
     });
   }
 }
 
 function compare(a, b, isAsc) {
   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
 }
