import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ColorPickerService, Cmyk } from 'ngx-color-picker';
import { EstadosLimpieza } from '../core/models/estados-limpieza';
import { first } from 'rxjs/operators';
import { HabitacionService } from '../core/services/habitacion.service';

@Component({
  selector: 'app-mantenimiento-estados-limpieza',
  templateUrl: './mantenimiento-estados-limpieza.component.html',
  styleUrls: ['./mantenimiento-estados-limpieza.component.scss']
})
export class MantenimientoEstadosLimpiezaComponent implements OnInit {

  color1: string = '#2889e9';
  listaEstados:EstadosLimpieza[]=[];
  strDefecto:string;

  miEstadoNuevo:EstadosLimpieza=new EstadosLimpieza();

  constructor(public vcRef: ViewContainerRef, private cpService: ColorPickerService,private miservicio:HabitacionService) { }

  ngOnInit() {
    this.getMisEstadosLimpieza();
  }

  public onChangeColor(color): Cmyk {
    const hsva = this.cpService.stringToHsva(color);
    const rgba = this.cpService.hsvaToRgba(hsva);
    return this.cpService.rgbaToCmyk(rgba);
  }

  public onChangeColor2(color): Cmyk {
    const hsva = this.cpService.stringToHsva(color);
    const rgba = this.cpService.hsvaToRgba(hsva);
    return this.cpService.rgbaToCmyk(rgba);
  }

  getMisEstadosLimpieza(){
    this.miservicio.getEstadosLimpieza().pipe(first()).subscribe(datos=>{
      this.listaEstados=datos;
      //console.log(this.listaEstados);
      this.listaEstados.forEach(e => {
          if(e.strDefecto==='S' ){
            return e.strChecked=true;
          }else{
            return e.strChecked=false;
          }
      });
    })
  }


  pulsa(e){
    //console.log(e);
    this.listaEstados.forEach(i => {
      i.strChecked=false;
      i.strDefecto="N"
    });

    let myObject:any;
      myObject=e;
      myObject.strChecked=true;
      myObject.strDefecto="S";

      var index = this.listaEstados.map(x => {
        return x.intId;
      }).indexOf(e.intId);
    
        this.listaEstados.splice(index, 1, myObject);
        //console.log(this.listaEstados)
      
        this.miservicio.putEstadosLimpieza(this.listaEstados).subscribe((datos:any)=>{
          if(datos.booOk===true){
            this.getMisEstadosLimpieza();
          }
        })
   }


   /*cambia(event:any){
     console.log(event)   
   }*/

  salvar(e){
    this.miservicio.putEstadosLimpieza(e).subscribe(datos=>{
      if(datos.booOk===true){
        this.getMisEstadosLimpieza();
      }
    })
  }

  insertar(miEstadoNuevo){
    //console.log(miEstadoNuevo)
    this.miservicio.postEstadosLimpieza(miEstadoNuevo).subscribe(datos=>{
      console.log(datos);
    })
  }

  reset(){
    let array:EstadosLimpieza[]=[];

    let misEstadoDefecto1:EstadosLimpieza=new EstadosLimpieza();
    let misEstadoDefecto2:EstadosLimpieza=new EstadosLimpieza();
    let misEstadoDefecto3:EstadosLimpieza=new EstadosLimpieza();

    misEstadoDefecto1.intId=1;
    misEstadoDefecto1.strAbreviatura="S";
    misEstadoDefecto1.strNombre="Sucia";
    misEstadoDefecto1.strActivo="S";
    misEstadoDefecto1.strDefecto="S";
    misEstadoDefecto1.strColor="#ff3333";
    misEstadoDefecto1.intPosicion=1;
    array.push(misEstadoDefecto1);

    misEstadoDefecto2.intId=2;
    misEstadoDefecto2.strAbreviatura="P";
    misEstadoDefecto2.strNombre="En proceso";
    misEstadoDefecto2.strActivo="S";
    misEstadoDefecto2.strDefecto="N";
    misEstadoDefecto2.strColor="#ffc14d";
    misEstadoDefecto2.intPosicion=2;
    array.push(misEstadoDefecto2);

    misEstadoDefecto3.intId=3;
    misEstadoDefecto3.strAbreviatura="L";
    misEstadoDefecto3.strNombre="Limpia";
    misEstadoDefecto3.strActivo="S";
    misEstadoDefecto3.strDefecto="N";
    misEstadoDefecto3.strColor="#00b300";
    misEstadoDefecto3.intPosicion=3;
    array.push(misEstadoDefecto3);


    this.miservicio.putEstadosLimpieza(array).subscribe(datos=>{
      this.getMisEstadosLimpieza();
    })
  }

}
