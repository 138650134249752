import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { MantenimientosService } from 'src/app/core/services/mantenimientos.service';
import { FormaPago } from 'src/app/core/models/forma-pago';

@Component({
  selector: 'app-lista-forma-pago',
  templateUrl: './lista-forma-pago.component.html',
  styleUrls: ['./lista-forma-pago.component.scss']
})
export class ListaFormaPagoComponent implements OnInit {

  listFormaPagos:FormaPago[]=[];  
  formaPago:FormaPago=new FormaPago();

  filtro:string;
  dataTodo:FormaPago[]=[];
  checktodos:any;
  dataTodoObsoleto:FormaPago[]=[];

  selectFormaPago:any;
  listAux:any[]=[];

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, private miservicioReservas:MantenimientosService) { }

  ngOnInit() {
    this.listarFormaPagos();
  }

  listarFormaPagos(){
    this.miservicioReservas.getFormasPago(0).subscribe(datos=>{
      //console.log(datos);
      this.dataTodoObsoleto=datos;
      this.dataTodo=datos.filter(e=>e.strActivo==="S");
      this.listFormaPagos=datos.filter(e=>e.strActivo==="S");
    })
  }

  todos(event){
    if(event.checked){
      this.dataTodo.splice(0,this.dataTodo.length)
      this.dataTodo=this.dataTodoObsoleto;
      if(this.filtro===undefined){
        this.filtro='';
      }   
      this.funcionBusqueda(this.filtro.toLocaleLowerCase(),this.dataTodo);                    
    }else{
      this.dataTodo=this.dataTodoObsoleto.filter(e=>e.strActivo==="S");
      if(this.filtro===undefined){
        this.filtro='';
      }
      this.funcionBusqueda(this.filtro.toLocaleLowerCase(),this.dataTodo);
    }
  }

  buscar(event: any){
    this.filtro = event.target.value;
    this.funcionBusqueda(this.filtro,this.dataTodo);
  }

  funcionBusqueda(filtro,dataFilter){
    if(filtro===''){
      this.listFormaPagos=dataFilter
    }else{
      this.listFormaPagos=dataFilter.filter(e=>e.strNombre.toLowerCase().startsWith(filtro.toLowerCase()) );
    }
  }

  seleccionaFormaPago(e){
    this.selectFormaPago=e.intId;
    this.listAux.push(e.intId)
    let ultimo=this.listAux[this.listAux.length - 1];

    this.listAux.forEach(o => {
      if(o===ultimo){
        document.getElementById(o).style.borderStyle="solid";
        document.getElementById(o).style.borderWidth="2px";
        document.getElementById(o).style.borderColor="#ffe066";        
      }else{
        document.getElementById(o).style.borderStyle="none";
      }
    });
  }

  guardar(){
    this.thisDialogRef.close(this.dataTodoObsoleto.filter(e=>e.intId===this.selectFormaPago)[0]);
  }

  cancelar(){
    this.thisDialogRef.close();
  }


}
