import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MantenimientosService } from 'src/app/core/services/mantenimientos.service';
import { FormaPago } from 'src/app/core/models/forma-pago';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-nuevo-formapago',
  templateUrl: './nuevo-formapago.component.html',
  styleUrls: ['./nuevo-formapago.component.scss']
})
export class NuevoFormapagoComponent implements OnInit {

  nuevo:FormaPago=new FormaPago();
  formNew: FormGroup;
  submitted:boolean=false;
  isChecked:boolean;
  existError:boolean=false;
  mensajeError:string;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>,private miServicio:MantenimientosService,private _formBuilder: FormBuilder) { }

  ngOnInit() {

    this.formNew = this._formBuilder.group({
      nombre: ['', Validators.required],
      activo: ['']
    })
  }  

  salvar(){
    if(this.isChecked){
      this.nuevo.strActivo="S"
    }else{
      this.nuevo.strActivo="N"
    }
    this.submitted=true;

    if(this.formNew.valid){      

      //console.log(this.nuevo)
      this.miServicio.insertaFormaPago(this.nuevo).subscribe((datos:any)=>{
        //console.log(datos)
        if(datos.miRespuesta.booOk){
          this.thisDialogRef.close(datos.miListForma_Pago)
        }else{
          this.existError=true;
          this.mensajeError=datos.miRespuesta.strMensaje
        }
      })

    }    
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
