export class Habitacion {
     
    /* public intId?:number;
	 public strNombre?:string;
	 public strDescripcion?:string;
	 public intTipoHabitacionId?:number;
	 public intEstadoLimpiezaId?:number;
	 public strActivo?:string;
	 public strTipoHabitacionNombre?:string;
	 public strEstadoLimpiezaNombre?:string;
	 //public strUserName?:string;
	 
	 */

	public intId?:number;
	public strNombre?:string;
	public strDescripcion?:string;
	public intTipoHabitacionId?:number;
	public intEstadoLimpiezaId?:number;
	public strActivo?:string;
	public strTipoHabitacionNombre?:string;
	public strEstadoLimpiezaNombre?:string;
	public datFechaRegistro?:Date;
	public datFechaActualizacion?:Date;

}
