import { Component, OnInit, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MantenimientosService } from '../core/services/mantenimientos.service';
import { TipoHabitacion } from '../core/models/tipo-habitacion';
import { Regimenes } from '../core/models/regimenes';
import { DatePipe } from '@angular/common';
import { DateAdapter, MatDialog } from '@angular/material';
import { ReultTarifaThR } from './models/reult-tarifa-th-r';
import { DetalleTarifaComponent } from './detalle-tarifa/detalle-tarifa.component';
import { InsertaTarifaComponent } from './inserta-tarifa/inserta-tarifa.component';

const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

const listDias=['Lunes','Martes','Miercoles','Jueves','Viernes','Sabado','Domingo'];

@Component({
  selector: 'app-tarifas',
  templateUrl: './tarifas.component.html',
  styleUrls: ['./tarifas.component.scss']
})
export class TarifasComponent implements OnInit {

  //today = new Date();
  date = new FormControl(new Date());
  dateDesde = new FormControl(new Date());
  dateHasta = new FormControl(new Date());
  // multiples_3=[];
  // multiples_5=[];
  dias_mes:any;
  //listMes:any[]=[];
  listMes:CuadriculaMes[]=[];
  listTiposHabitacion:TipoHabitacion[]=[];
  listRegimenes:Regimenes[]=[];
  // anchoColumnaHabitacion:any;
  //fechaSeleccionada:any;
  mesEnCurso:string;
  yearCurso:any;
  mes:any;
  firstDay:any;
  lastDay:any;
  listDatosTarifas:ReultTarifaThR[]=[];
  listFiltoDia:diasSemana[]=[];
  //listFiltoDia:diasSemana[]=[{num:1,dia:'Lunes'}, {num:2,dia:'Martes'}, {num:3,dia:'Miercoles'}, {num:4,dia:'Jueves'}, {num:5,dia:'Viernes'}, {num:6,dia:'Sabado'},{num:7,dia:'Domingo'}];
  miobjetoTarifa:objetoTarifa=new objetoTarifa();
  listDiaSeleccion:diasSemana[]=[];
  hide:false;
  teclaAlt:any;
  otraTecla:any;

  constructor(private miservicio:MantenimientosService,public datepipe: DatePipe,private dateAdapter: DateAdapter<Date>,public dialog: MatDialog) {
    this.dateAdapter.setLocale('es');    
    // this.listFiltoDia=['Lunes','Martes','Miercoles','Jueves','Viernes','Sabado','Domingo'];   
  }

  ngOnInit() {
     for(var i=0; i<listDias.length; i++){
       let mivalornum;
       mivalornum=i+1;
       if(i===6){
        mivalornum=0;
       }
       this.listFiltoDia.push({num:mivalornum,dia:listDias[i],check:true})
     }
    //console.log(this.listFiltoDia)
    this.mes=this.date.value.getMonth()+1;
    //console.log(this.mes)
    this.yearCurso=this.date.value.getFullYear();
    //console.log(this.days_of_a_year(2015));

    this.dias_mes=this.function(this.mes, this.yearCurso);

    this.firstDay = new FormControl(new Date(this.date.value.getFullYear(), this.date.value.getMonth(), 1));
    this.lastDay = new FormControl(new Date(this.date.value.getFullYear(), this.date.value.getMonth() + 1, 0));
    //this.firstDay = new Date(this.date.value.getFullYear(), this.date.value.getMonth(), 1);
    //this.lastDay = new Date(this.date.value.getFullYear(), this.date.value.getMonth() + 1, 0);

    //this.listarDatos(this.datepipe.transform(this.firstDay, 'yyyy-MM-dd'),this.datepipe.transform(this.lastDay, 'yyyy-MM-dd'));
    this.listarDatos(this.firstDay.value.toLocaleDateString(),this.lastDay.value.toLocaleDateString());

    //console.log(year);

    for(var i=1;i<=this.dias_mes;i++){
      //let primero=this.datepipe.transform(this.firstDay, 'd');
      //this.listMes.push(i);
      let miColor:string;
      if(this.firstDay.value.getDay()===6 || this.firstDay.value.getDay()===0){
        miColor="#d9d9d9";
      }else{
        miColor="white"
      }
      this.listMes.push({clave:this.firstDay.value.getDay(), valor:i, color:miColor});
      this.firstDay.value.setDate(this.firstDay.value.getDate()+1);
    }
    //console.log(this.listMes)

    //let fechaNueva=new FormControl(new Date(this.date.value.getFullYear(), this.date.value.getMonth(), 1));
    //console.log(fechaNueva.value.toLocaleDateString())

    //this.date.value.addDay()

    //console.log(this.listMes)
    //console.log(this.datepipe.transform(this.firstDay, 'd'))

    //console.log(this.date)
    //console.log(this.firstDay.value.getDate())
    //this.firstDay.value.setDate(this.firstDay.value.getDate()+1);
    //console.log(this.firstDay.value.getDate())
    //console.log(this.firstDay.value.getDay())

    //console.log(this.datepipe.transform(this.date.value, 'd'))

    this.mesEnCurso=monthNames[this.date.value.getMonth()];

    //console.log("The current month is " + monthNames[this.date.value.getMonth()])

    this.listarRegimenes();
    this.listarTiposHabitacion();      

    // for(var i=1;i<=this.function(mes, year);i++)
    // {
    //     if(this.multiple(i,6))
    //         this.multiples_3.push(i);

    //     if(this.multiple(i,7))
    //         this.multiples_5.push(i);
    // }

    //     console.log("Los multiplos de 6 son: "+this.multiples_3);
    //     console.log("Los multiplos de 7 son: "+this.multiples_5);

  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===78 && this.otraTecla===18){
      this.newTarifa();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }

  // days_of_a_year(year) 
  // {
    
  //   return this.isLeapYear(year) ? 366 : 365;
  // }

  //  isLeapYear(year) {
  //   return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  // }

  function(month,year) {
   return new Date(year, month, 0).getDate();
  }

  // multiple(valor, multiple)
  // {
  //     let resto = valor % multiple;
  //     if(resto==0)
  //         return true;
  //     else
  //         return false;
  // }

  listarRegimenes(){
    this.miservicio.getRegimenes(0).subscribe(datos=>{
      //console.log(datos);
      //this.listRegimenes=datos;
      this.listRegimenes=datos.filter(e=>e.strActivo==="S");
      // this.anchoColumnaHabitacion=this.listRegimenes.length;
    })
  }

  listarTiposHabitacion(){
    this.miservicio.getTiposHab(0).subscribe(datos=>{
      //console.log(datos);
      //this.listTiposHabitacion=datos; 
      this.listTiposHabitacion=datos.filter(e=>e.strActivo==="S");
    })
  }

  tarifasDia(a,b,c){
    //console.log(a.miTipoHabitacion);
    //console.log(b.miRegimen);
    //console.log(c);

    let dialogRef = this.dialog.open(DetalleTarifaComponent, {
      width: '600px',
      data: {
        'intTipoHabitacionId':a.miTipoHabitacion.intId,
        'strTipoHabitacionNombre':a.miTipoHabitacion.strNombre,
        'intTipoRegimenId':b.miRegimen.intId,
        'strTipoRegimenNombre':b.miRegimen.strNombre,
        'datFecha':c.datFecha,
        'decPrecio':c.decPrecio,
        'decIva':c.decIva,
        'decPvp':c.decPvp
      }
    });
    dialogRef.afterClosed().subscribe((datos) => {
      //console.log(datos)
      if(datos!=undefined){
        /*let myObject:any;
        myObject=datos.miListHabitacion[0];
        //console.log(myObject);
  
        let miTicket=datos.miListHabitacion[0].intId;
  
        var index = this.data.map(x => {
          return x.intId;
        }).indexOf(miTicket);
  
        if(datos.length!=0){      
          this.data.splice(index, 1, myObject);
          //console.log(index);
          //console.log(this.data);
          this.dataSource=new MatTableDataSource(this.data);
        }
        this.getColor(myObject.strActivo);
        if(datos.length===0){
          this.data.splice(index, 1);
        }*/
        this.listDatosTarifas.splice(0, this.listDatosTarifas.length);      
        this.listDatosTarifas=datos;
      }     
    });
  }

  listarDatos(inicio,fin){
    //this.listDatosTarifas.splice(0, this.listDatosTarifas.length);   
    this.miservicio.getTarifasRegimenHabitacion(inicio,fin).subscribe(datos=>{
      //console.log(datos);   
      //this.listDatosTarifas.splice(0, this.listDatosTarifas.length);      
      this.listDatosTarifas=datos;
      //console.log(this.listDatosTarifas)
    })
  }

  mesAnterior(){
    this.date.value.setMonth(this.date.value.getMonth()-1)
    //console.log(this.date.value.toLocaleDateString());
    this.mesEnCurso=monthNames[this.date.value.getMonth()];
    this.yearCurso=this.date.value.getFullYear();
    this.mes=this.date.value.getMonth()+1;
    this.dias_mes=this.function(this.mes, this.yearCurso);
    this.listMes.splice(0,this.listMes.length)
    this.firstDay = new FormControl(new Date(this.date.value.getFullYear(), this.date.value.getMonth(), 1));
    this.lastDay = new FormControl(new Date(this.date.value.getFullYear(), this.date.value.getMonth() + 1, 0));
    this.listarDatos(this.firstDay.value.toLocaleDateString(),this.lastDay.value.toLocaleDateString());
    for(var i=1;i<=this.dias_mes;i++){
      //this.listMes.push(i);
      let miColor:string;
      if(this.firstDay.value.getDay()===6 || this.firstDay.value.getDay()===0){
        miColor="#d9d9d9";
      }else{
        miColor="white"
      }
      this.listMes.push({clave:this.firstDay.value.getDay(), valor:i, color:miColor});
      this.firstDay.value.setDate(this.firstDay.value.getDate()+1);
    }
    //this.firstDay = new Date(this.date.value.getFullYear(), this.date.value.getMonth(), 1);
    //this.lastDay = new Date(this.date.value.getFullYear(), this.date.value.getMonth() + 1, 0);
    //this.listarDatos(this.datepipe.transform(this.firstDay, 'yyyy-MM-dd'),this.datepipe.transform(this.lastDay, 'yyyy-MM-dd'));
  }

  mesSiguiente(){
    //console.log(this.date)
    this.date.value.setMonth(this.date.value.getMonth()+1)
    //console.log(this.date.value.toLocaleDateString());
    this.mesEnCurso=monthNames[this.date.value.getMonth()];
    this.yearCurso=this.date.value.getFullYear();
    this.mes=this.date.value.getMonth()+1;
    this.dias_mes=this.function(this.mes, this.yearCurso);
    this.listMes.splice(0,this.listMes.length);
    this.firstDay = new FormControl(new Date(this.date.value.getFullYear(), this.date.value.getMonth(), 1));
    this.lastDay = new FormControl(new Date(this.date.value.getFullYear(), this.date.value.getMonth() + 1, 0));
    this.listarDatos(this.firstDay.value.toLocaleDateString(),this.lastDay.value.toLocaleDateString());
    for(var i=1;i<=this.dias_mes;i++){
      //this.listMes.push(i);
      let miColor:string;
      if(this.firstDay.value.getDay()===6 || this.firstDay.value.getDay()===0){
        miColor="#d9d9d9";
      }else{
        miColor="white"
      }
      this.listMes.push({clave:this.firstDay.value.getDay(), valor:i, color:miColor});
      this.firstDay.value.setDate(this.firstDay.value.getDate()+1);
    }
    //this.firstDay = new Date(this.date.value.getFullYear(), this.date.value.getMonth(), 1);
    //this.lastDay = new Date(this.date.value.getFullYear(), this.date.value.getMonth() + 1, 0);
    //this.listarDatos(this.datepipe.transform(this.firstDay, 'yyyy-MM-dd'),this.datepipe.transform(this.lastDay, 'yyyy-MM-dd'));
  }

  // selectDia(d){
  //   console.log(d)
  // }

  marcarItem2(event: any,d)
  {
    //console.log(event);
    //console.log(d);
    //this.listDiaSeleccion=this.listFiltoDia;
     /*if(event.checked===true){
      this.listDiaSeleccion.push(d.num);
     }else{
      const index = this.listDiaSeleccion.indexOf(d.num, 0);
        if (index > -1) {
          this.listDiaSeleccion.splice(index, 1);
        }
     }*/
     this.listDiaSeleccion.splice(0, this.listDiaSeleccion.length)

     this.listFiltoDia.forEach((e:any) => {
       //console.log(e)
       if(e.check===true){
        //console.log(e)
        this.listDiaSeleccion.push(e.num)
       }
     });

     //console.log(this.listDiaSeleccion);
  }

  guardar(){
    
    //console.log(this.miobjetoTarifa)
    //console.log(this.miobjetoTarifa.datFechaDesde.toLocaleDateString())
    //console.log(this.listDiaSeleccion);
    //let date = new FormControl(new Date());
    //console.log(this.date.value)
    let firstDay = new FormControl(new Date(this.date.value.getFullYear(), this.date.value.getMonth(), 1));
    //let lastDay = new FormControl(new Date(date.value.getFullYear(), date.value.getMonth() + 1, 0));
    //console.log(this.firstDay.value.toLocaleDateString());
    //console.log(this.lastDay.value.toLocaleDateString());

    this.listDiaSeleccion.splice(0, this.listDiaSeleccion.length)

     this.listFiltoDia.forEach((e:any) => {
       //console.log(e)
      if(e.check===true){
        //console.log(e)
        this.listDiaSeleccion.push(e.num)
      }
     });

    this.miservicio.insertaTarifaHabitacion(this.miobjetoTarifa,this.listDiaSeleccion,null,null).subscribe(datos=>{
       //console.log(datos)
      if(datos.length>0){
        //this.listDatosTarifas.splice(0, this.listDatosTarifas.length);
        //this.listDatosTarifas=datos; 
        //window.location.reload();  
        this.listDatosTarifas.splice(0, this.listDatosTarifas.length);
        this.listarDatos(firstDay.value.toLocaleDateString(),this.lastDay.value.toLocaleDateString());      
      }
    })

    //console.log(this.listDiaSeleccion)

    
    this.hide=false;
    this.miobjetoTarifa=new objetoTarifa();

    this.listFiltoDia.splice(0, this.listFiltoDia.length);
    for(var i=0; i<listDias.length; i++){
      let mivalornum;
      mivalornum=i+1;
      if(i===6){
       mivalornum=0;
      }
      this.listFiltoDia.push({num:mivalornum,dia:listDias[i],check:true})
    }
    
  }

  newTarifa(){
    this.firstDay = new FormControl(new Date(this.date.value.getFullYear(), this.date.value.getMonth(), 1));
    this.lastDay = new FormControl(new Date(this.date.value.getFullYear(), this.date.value.getMonth() + 1, 0));

    let dialogRef = this.dialog.open(InsertaTarifaComponent, {
      width: '600px',
      data:{
        'inicio':this.firstDay.value,
        'fin':this.lastDay.value,
      }
    });
    dialogRef.afterClosed().subscribe((datos) => {
      //console.log(datos)
      if(datos!=undefined){
        /*let myObject:any;
        myObject=datos.miListHabitacion[0];
  
        let miTicket=datos.miListHabitacion[0].intId;
  
        var index = this.data.map(x => {
          return x.intId;
        }).indexOf(miTicket);
  
        if(datos.length!=0){      
          this.data.splice(index, 1, myObject);
          this.dataSource=new MatTableDataSource(this.data);
        }
        this.getColor(myObject.strActivo);
        if(datos.length===0){
          this.data.splice(index, 1);
        }*/
        this.listDatosTarifas.splice(0, this.listDatosTarifas.length);
        this.listDatosTarifas=datos;
      }        
    });
  }

}

export interface diasSemana {
  num: number;
  dia: string;
  check:boolean;
}

export class objetoTarifa{
  intTipoHabitacion:number;
  intTipoRegimen:number;
  datFechaDesde:any;
  datFechaHasta:any;
  decPrecio:number;
  //listDias:diasSemana[];
}

export class CuadriculaMes{
  clave:number;
  valor:any;
  color:string;
}