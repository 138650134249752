import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Cliente } from '../models/cliente';
import { Habitacion } from '../models/habitacion';
import { Reserva } from '../models/reserva';
import { Regimenes } from '../models/regimenes';
import { TipoHabitacion } from '../models/tipo-habitacion';
import { CreateReservationParams } from '../../hotel/service/data.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Huesped } from '../models/huesped';
import { ReservaGasto } from '../models/reserva-gasto';
import { ReservaPago } from '../models/reserva-pago';
import { Facturado } from '../models/facturado';
import { ReservaDia } from '../models/reserva-dia';
import { Facturas } from '../models/facturas';
import { EstadoReserva } from '../models/estado-reserva';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ReservasService {

  constructor(private http: HttpClient, private storageService: StorageService,public datepipe: DatePipe) { }
  
  public url: string = environment.baseUrl;
  private token=this.storageService.getCurrentToken();
  private cookie=this.storageService.getCurrentSession().strCookie;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + this.token  })
  };

  getClientes():Observable<Cliente[]>{
    let dato='strCookie='+this.cookie;
    let urlCompleta=this.url+'/api/cliente?'+dato;
 
    return this.http.get<Cliente[]>(urlCompleta,this.httpOptions);
  }

  /*
  getUnicoCliente(intId):Observable<Cliente[]>{
    let token=this.storageService.getCurrentToken();
    let dato='strToken='+token+'&intId='+intId;
    let urlCompleta=this.url+'cliente?'+dato;
 
    return this.http.get<Cliente[]>(urlCompleta);
  }

  postCliente(nuevoCliente:Cliente):Observable<Cliente>{
    let token=this.storageService.getCurrentToken();
    let urlCompleta=this.url+'cliente?'+"strToken="+token ;
    let dato = '{"strNombre": "'+ nuevoCliente.strNombre +'", "strApellidos":"'+ nuevoCliente.strApellidos +'", "strDni":"'+ nuevoCliente.strDni +'", "strEmail":"'+ nuevoCliente.strEmail +'", "strTelefono1":"'+ nuevoCliente.strTelefono1 +'", "strTelefono2":"'+ nuevoCliente.strTelefono2 +'", "strDireccion":"'+ nuevoCliente.strDireccion +'", "strCP":"'+ nuevoCliente.strCP +'", "strLocalidad":"'+ nuevoCliente.strLocalidad +'", "strPoblacion":"'+ nuevoCliente.strPoblacion +'", "strPais":"'+ nuevoCliente.strPais +'", "strActivo":"S"}';
    //let json=dato;
    //let params = json; 
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this.http.post<Cliente>(urlCompleta, dato, {headers: headers});             
  }
  */

  getUnicoCliente(intId):Observable<Cliente[]>{
    let dato='strCookie='+this.cookie+'&intId='+intId;
    let urlCompleta=this.url+'/api/cliente?'+dato;
 
    return this.http.get<Cliente[]>(urlCompleta,this.httpOptions);
  }

  postCliente(nuevoCliente:Cliente):Observable<Cliente>{
    let urlCompleta=this.url+'/api/cliente?'+"strCookie="+this.cookie ;
    let dato:any = {};
    dato.strNombre = nuevoCliente.strNombre;
    dato.strApellidos=nuevoCliente.strApellidos;
    dato.strDni=nuevoCliente.strDni;
    dato.strEmail=nuevoCliente.strEmail;
    dato.strTelefono1=nuevoCliente.strTelefono1;
    dato.strTelefono2=nuevoCliente.strTelefono2;
    dato.strDireccion=nuevoCliente.strDireccion;
    dato.strCP=nuevoCliente.strCP;
    dato.strProvincia=nuevoCliente.strProvincia;
    dato.strPoblacion=nuevoCliente.strPoblacion;
    dato.strPais=nuevoCliente.strPais;
    dato.strActivo="S";
    dato.datFechaNacimiento=nuevoCliente.datFechaNacimiento;
    dato.datDniExpedido=nuevoCliente.datDniExpedido;
    dato.strClienteSexo=nuevoCliente.strClienteSexo;

    var jsonString = JSON.stringify(dato);

    //let dato = '{"strNombre": "'+ nuevoCliente.strNombre +'", "strApellidos":"'+ nuevoCliente.strApellidos +'", "strDni":"'+ nuevoCliente.strDni +'", "strEmail":"'+ nuevoCliente.strEmail +'", "strTelefono1":"'+ nuevoCliente.strTelefono1 +'", "strTelefono2":"'+ nuevoCliente.strTelefono2 +'", "strDireccion":"'+ nuevoCliente.strDireccion +'", "strCP":"'+ nuevoCliente.strCP +'", "strLocalidad":"'+ nuevoCliente.strLocalidad +'", "strPoblacion":"'+ nuevoCliente.strPoblacion +'", "strPais":"'+ nuevoCliente.strPais +'", "strActivo":"S"}';
    return this.http.post<Cliente>(urlCompleta, jsonString, this.httpOptions);             
  }

  putCliente(cliente:Cliente,miEstado):Observable<Cliente>{
    let urlCompleta=this.url+'/api/cliente?'+"strCookie="+this.cookie ;

    let dato:any = {};
    dato.intId=cliente.intId;
    dato.strNombre = cliente.strNombre;
    dato.strApellidos=cliente.strApellidos;
    dato.strDni=cliente.strDni;
    dato.strEmail=cliente.strEmail;
    dato.strTelefono1=cliente.strTelefono1;
    dato.strTelefono2=cliente.strTelefono2;
    dato.strDireccion=cliente.strDireccion;
    dato.strCP=cliente.strCP;
    dato.strProvincia=cliente.strProvincia;
    dato.strPoblacion=cliente.strPoblacion;
    dato.strPais=cliente.strPais;
    dato.strActivo=miEstado;
    dato.datFechaNacimiento=cliente.datFechaNacimiento;
    dato.datDniExpedido=cliente.datDniExpedido;
    dato.strClienteSexo=cliente.strClienteSexo;

    var jsonString = JSON.stringify(dato);
    //let dato = '{"intId": "'+ cliente.intId +'", "strNombre": "'+ cliente.strNombre +'", "strApellidos":"'+ cliente.strApellidos +'", "strDni":"'+ cliente.strDni +'", "strEmail":"'+ cliente.strEmail +'", "strTelefono1":"'+ cliente.strTelefono1 +'", "strTelefono2":"'+ cliente.strTelefono2 +'", "strDireccion":"'+ cliente.strDireccion +'", "strCP":"'+ cliente.strCP +'", "strLocalidad":"'+ cliente.strLocalidad +'", "strPoblacion":"'+ cliente.strPoblacion +'", "strPais":"'+ cliente.strPais +'", "strActivo":"'+ miEstado +'"}';
     
    return this.http.put<Habitacion>(urlCompleta, jsonString,this.httpOptions);
  }

  getReserva(from,to,fechaFiltroEntrada):Observable<Reserva[]>{
    let dato='strCookie='+this.cookie+'&strEntrada='+from+'&strSalida='+to+'&strFechaFiltroEntrada='+fechaFiltroEntrada;
    let urlCompleta=this.url+'/api/reserva?'+dato;
 
    return this.http.get<Reserva[]>(urlCompleta,this.httpOptions);
  }

  getReservaById(reservaId,grupoId):Observable<any[]>{
    let dato='strCookie='+this.cookie+'&intId='+ reservaId +'&strEntrada=&strSalida=&strFechaFiltroEntrada=&intGrupoId='+grupoId;
    let urlCompleta=this.url+'/api/reserva?'+dato;
 
    return this.http.get<any[]>(urlCompleta,this.httpOptions);
  }

  getReservaPlaning():Observable<Reserva[]>{
    let dato='strCookie='+this.cookie;
    let urlCompleta=this.url+'/api/reserva?'+dato;
 
    return this.http.get<Reserva[]>(urlCompleta,this.httpOptions);
  }

  postReserva(data: CreateReservationParams):Observable<any>{
    let urlCompleta=this.url+'/api/reserva?'+"strCookie="+this.cookie;
    //let dato = '{"intIdHabitacion":"'+ habitacion.intIdHabitacion +'", "decImporteTotal":"'+ decImporteTotal +'", "intIdCliente":"'+ cliente.intId +'", "datEntrada":"'+ fechaInicio +'", "datSalida":"'+ fechaFin +'"}';
    
    let entrada=this.datepipe.transform(data.start, 'yyyy-MM-dd');
    let salida=this.datepipe.transform(data.end, 'yyyy-MM-dd');

    let dato:any = {};
    dato.intHabitacionId=data.room;
    //dato.strHabitacionNombre=data.strNombreHabitacion;    
    dato.datEntrada=entrada;
    dato.datSalida=salida;
    if(data.intClienteId===undefined || data.intClienteId===""){
      dato.strClienteNombre=data.strClienteNombre;
      dato.strClienteApellidos=data.strClienteApellidos;      
    }else{
      dato.intClienteId = data.intClienteId;
    }
    dato.intRegimenId=data.intRegimenId;
    dato.strOrigen="L";
    //dato.strRegimenNombre=data.strNombre;
    //dato.intTipoHabitacionId=data.intId;
    //dato.strTipoHabitacionNombre=data.strNombre;
    //dato.strOrigen="L";

    var jsonString = JSON.stringify(dato);

    return this.http.post<any>(urlCompleta, jsonString, this.httpOptions);    
  }

  insertaReserva(data,isChecked):Observable<any>{
    let urlCompleta=this.url+'/api/reserva?'+"strCookie="+this.cookie+"&booSendEmail="+isChecked;
    
    // let entrada=this.datepipe.transform(data.start, 'yyyy-MM-dd');
    // let salida=this.datepipe.transform(data.end, 'yyyy-MM-dd');

    // let dato:any = {};
    // dato.intHabitacionId=data.room;
    // dato.datEntrada=entrada;
    // dato.datSalida=salida;
    // if(data.intClienteId===undefined || data.intClienteId===""){
    //   dato.strClienteNombre=data.strClienteNombre;
    //   dato.strClienteApellidos=data.strClienteApellidos;      
    // }else{
    //   dato.intClienteId = data.intClienteId;
    // }
    // dato.intRegimenId=data.intRegimenId;
    // dato.strOrigen="L";

    var jsonString = JSON.stringify(data);

    return this.http.post<any>(urlCompleta, jsonString, this.httpOptions);    
  }

  putReserva(params: any):Observable<any>{
    let urlCompleta=this.url+'/api/reserva?'+"strCookie="+this.cookie+"&strUserName="+this.storageService.getCurrentUser().strUserName;

    let entrada=this.datepipe.transform(params.start.value, 'yyyy-MM-dd');
    let salida=this.datepipe.transform(params.end.value, 'yyyy-MM-dd');

    let dato:any = {};
    dato.intId=params.id;
    dato.intHabitacionId=params.room;
    dato.datEntrada=entrada;
    dato.datSalida=salida;
    if(params.intClienteId===undefined|| params.intClienteId===""){
      dato.strClienteNombre=params.strClienteNombre;
      dato.strClienteApellidos=params.strClienteApellidos;      
    }else{
      dato.intClienteId = params.intClienteId;
    }
    dato.intRegimenId=params.intRegimenId;
    dato.strEstado=params.strEstadoReserva;
    dato.strOrigen="L";

    var jsonString = JSON.stringify(dato);

    return this.http.put<any>(urlCompleta, jsonString, this.httpOptions);    
  }

  editaReserva(params: any):Observable<any>{
    let urlCompleta=this.url+'/api/reserva?'+"strCookie="+this.cookie+"&strUserName="+this.storageService.getCurrentUser().strUserName;

    let entrada=this.datepipe.transform(params.datEntrada, 'yyyy-MM-dd');
    let salida=this.datepipe.transform(params.datSalida, 'yyyy-MM-dd');

    let dato:any = {};
    dato.intId=params.intId;
    dato.intHabitacionId=params.intHabitacionId;
    dato.datEntrada=entrada;
    dato.datSalida=salida;
    if(params.intClienteId===undefined|| params.intClienteId===""){
      dato.strClienteNombre=params.strClienteNombre;
      dato.strClienteApellidos=params.strClienteApellidos;      
    }else{
      dato.intClienteId = params.intClienteId;
    }
    dato.intRegimenId=params.intRegimenId;
    dato.intEstadoId=params.intEstadoId;
    //dato.strEstado=params.strEstado;
    dato.strOrigen="L";

    var jsonString = JSON.stringify(dato);

    return this.http.put<any>(urlCompleta, jsonString, this.httpOptions);    
  }

  moveReserva(params: any):Observable<any>{
    let urlCompleta=this.url+'/api/reserva?'+"strCookie="+this.cookie+"&strUserName="+this.storageService.getCurrentUser().strUserName;
    //let dato = '{"intIdHabitacion":"'+ habitacion.intIdHabitacion +'", "decImporteTotal":"'+ decImporteTotal +'", "intIdCliente":"'+ cliente.intId +'", "datEntrada":"'+ fechaInicio +'", "datSalida":"'+ fechaFin +'"}';

    let entrada=this.datepipe.transform(params.start.value, 'yyyy-MM-dd');
    let salida=this.datepipe.transform(params.end.value, 'yyyy-MM-dd');

    let dato:any = {};
    dato.intId=params.id;
    dato.intHabitacionId=params.room;
    //dato.strHabitacionNombre=params.strNombreHabitacion;    
    dato.datEntrada=entrada;
    dato.datSalida=salida;
    if(params.idCliente===undefined || params.idCliente===0){
      dato.strClienteNombre=params.strClienteNombre;
      dato.strClienteApellidos=params.strClienteApellidos;      
    }else{
      dato.intClienteId = params.idCliente;
    }
    //dato.strClienteNombre=params.strNombre;
    //dato.strClienteApellidos=params.strApellidos;
    dato.intRegimenId=params.intRegimenId;
    dato.strEstado=params.strEstadoReserva;
    //dato.strRegimenNombre=params.strNombre;
    //dato.intTipoHabitacionId=params.intId;
    //dato.strTipoHabitacionNombre=params.strNombre;
    dato.strOrigen="L";
    //dato.strUserName=this.storageService.getCurrentUser().strUserName;

    var jsonString = JSON.stringify(dato);

    return this.http.put<any>(urlCompleta, jsonString, this.httpOptions);    
  }

  getRegimenes():Observable<Regimenes[]>{
    let dato='strCookie='+this.cookie+'&intId=0';;
    let urlCompleta=this.url+'/api/regimenes?'+dato;
 
    return this.http.get<Regimenes[]>(urlCompleta,this.httpOptions);
  }

  getTiposHab():Observable<TipoHabitacion[]>{
    let dato='strCookie='+this.cookie+'&intId=0';
    let urlCompleta=this.url+'/api/tipohabitacion?'+dato;
 
    return this.http.get<TipoHabitacion[]>(urlCompleta,this.httpOptions);
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    //console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  
  getHuespedReserva(grupo):Observable<Huesped[]>{
    let dato='strCookie='+this.cookie+'&intIdGrupo='+grupo;
    let urlCompleta=this.url+'/api/Ocupacion?'+dato;
 
    return this.http.get<Huesped[]>(urlCompleta,this.httpOptions);
  }

  postHuespedReserva(idGrupo,IdReserva,IdCliente):Observable<any>{
    let urlCompleta=this.url+'/api/Ocupacion?'+"strCookie="+this.cookie+"&intIdGrupo="+idGrupo;

    let Ocupacion:any = {};
    Ocupacion.intReservaId=IdReserva;
    Ocupacion.intClienteId=IdCliente;
    var jsonString = JSON.stringify(Ocupacion);

    // let Cliente:any = {};
    // Cliente.strNombre=cliente.;
    // Cliente.strApellidos=cliente.;
    // Cliente.strDni=cliente.;
    // Cliente.strPais=cliente.;
    // Cliente.datFechaNacimiento=cliente.;
    // Cliente.datDniExpedido=cliente.;
    // Cliente.strClienteSexo=cliente.;
    //var jsonString2 = JSON.stringify(cliente);

    //var jsonString = JSON.stringify({Ocupacion, cliente});

    return this.http.post<any>(urlCompleta, jsonString, this.httpOptions);    
  }

  postAgregaHuespedReserva(idGrupo,idReserva,cliente:Cliente):Observable<any>{
    let urlCompleta=this.url+'/api/Ocupacion/nuevocliente?'+"strCookie="+this.cookie+"&intReservaId="+idReserva+"&intIdGrupo="+idGrupo;

    // let Ocupacion:any = {};
    // Ocupacion.intReservaId=IdReserva;
    // Ocupacion.intClienteId=IdCliente;

    var jsonString = JSON.stringify(cliente);

    return this.http.post<any>(urlCompleta, jsonString, this.httpOptions);    
  }

  putHuespedReserva(huesped:Huesped):Observable<any>{
    let urlCompleta=this.url+'/api/Ocupacion?'+"strCookie="+this.cookie;

    var jsonString = JSON.stringify(huesped);

    return this.http.put<any>(urlCompleta, jsonString, this.httpOptions);    
  }

  deleteHuespedReserva(intId,intReservaId):Observable<any>{
    let urlCompleta=this.url+'/api/Ocupacion?'+"strCookie="+this.cookie+"&intId="+intId+"&intReservaId="+intReservaId;

    // var jsonString = JSON.stringify(intId);

    return this.http.put<any>(urlCompleta, this.httpOptions);    
  }

  getGastoReserva(intId,intReservaId,intArticuloId,strFecha,strFechaFiltro,intReservaGrupoId,intFacturaId):Observable<ReservaGasto[]>{
    let dato='strCookie='+this.cookie+'&intId='+intId+'&intReservaId='+intReservaId+'&intArticuloId='+intArticuloId+'&strFecha='+strFecha;
    dato += '&strFechaFiltro='+strFechaFiltro+'&intReservaGrupoId='+intReservaGrupoId+'&intFacturaId='+intFacturaId;
    
    let urlCompleta=this.url+'/api/Reserva_Gasto?'+dato;
 
    return this.http.get<ReservaGasto[]>(urlCompleta,this.httpOptions);
  }

  postGastoReserva(nuevoGasto:ReservaGasto,booIva):Observable<any>{
    let urlCompleta=this.url+'/api/Reserva_Gasto?'+"strCookie="+this.cookie+'&booIva='+booIva;

    var jsonString = JSON.stringify(nuevoGasto);

    return this.http.post<any>(urlCompleta, jsonString, this.httpOptions);    
  }

  putGastoReserva(nuevoGasto:ReservaGasto,decPrecio,booIva):Observable<any>{
    let urlCompleta=this.url+'/api/Reserva_Gasto?'+"strCookie="+this.cookie+'&decPrecio='+decPrecio+'&booIva='+booIva;

    var jsonString = JSON.stringify(nuevoGasto);

    return this.http.put<any>(urlCompleta, jsonString, this.httpOptions);    
  }

  deleteGastoReserva(intId,intReservaId):Observable<any>{
    let urlCompleta=this.url+'/api/Reserva_Gasto?'+"strCookie="+this.cookie+"&intId="+intId+"&intReservaId="+intReservaId;

    // var jsonString = JSON.stringify(intId);

    return this.http.put<any>(urlCompleta, this.httpOptions);    
  }

  getPagoReserva(intId,strFecha,strFechaFiltro,intReservaGrupoId,intFormaPago,intFacturaId):Observable<ReservaPago[]>{
    let dato='strCookie='+this.cookie+'&intId='+intId+'&strFecha='+strFecha;
    dato += '&strFechaFiltro='+strFechaFiltro+'&intReservaGrupoId='+intReservaGrupoId+"&intFormaPago="+intFormaPago+'&intFacturaId='+intFacturaId;
    
    let urlCompleta=this.url+'/api/Reserva_Pago?'+dato;
 
    return this.http.get<ReservaPago[]>(urlCompleta,this.httpOptions);
  }

  postPagoReserva(nuevoPago:ReservaPago):Observable<any>{
    let urlCompleta=this.url+'/api/Reserva_Pago?'+"strCookie="+this.cookie;

    var jsonString = JSON.stringify(nuevoPago);

    return this.http.post<any>(urlCompleta, jsonString, this.httpOptions);    
  }

  putPagoReserva(nuevoPago:ReservaPago):Observable<any>{
    let urlCompleta=this.url+'/api/Reserva_Pago?'+"strCookie="+this.cookie;

    var jsonString = JSON.stringify(nuevoPago);

    return this.http.put<any>(urlCompleta, jsonString, this.httpOptions);    
  }

  deletePagoReserva(intId,intReservaGrupoId):Observable<any>{
    let urlCompleta=this.url+'/api/Reserva_Pago?'+"strCookie="+this.cookie+"&intId="+intId+"&intReservaGrupoId="+intReservaGrupoId;

    // var jsonString = JSON.stringify(intId);

    return this.http.put<any>(urlCompleta, this.httpOptions);    
  }

  getFacturado(intId,strFecha,strFechaFiltro,intGrupoId,intReservaId,intFacturaId,strTipo):Observable<Facturado[]>{
    let dato='strCookie='+this.cookie+'&intId='+intId+'&strFecha='+strFecha;
    dato += '&strFechaFiltro='+strFechaFiltro+'&intGrupoId='+intGrupoId+"&intReservaId="+intReservaId+'&intFacturaId='+intFacturaId+'&strTipo='+strTipo;
    
    let urlCompleta=this.url+'/api/Facturas?'+dato;
 
    return this.http.get<Facturado[]>(urlCompleta,this.httpOptions);
  }

  getCabeceraFactura(intId, intGrupoId, strFecha, strFechaFiltro, intEjercicio, numFactura):Observable<Facturas[]>{
    let dato='strCookie='+this.cookie+'&intId='+intId+'&intGrupoId='+intGrupoId+'&strFecha='+strFecha;
    dato += '&strFechaFiltro='+strFechaFiltro+'&intEjercicio='+intEjercicio+"&numFactura="+numFactura;
    
    let urlCompleta=this.url+'/api/Facturas?'+dato;
 
    return this.http.get<Facturas[]>(urlCompleta,this.httpOptions);
  }

  postInsertaCabeceraFactura(miFactura):Observable<any>{
    let urlCompleta=this.url+'/api/Facturas/nuevaFactura?'+"strCookie="+this.cookie;

    let Factura:any = {};
    Factura.intId=miFactura.intId;
    Factura.intClienteId=miFactura.intClienteId;
    Factura.intGrupoId=miFactura.intGrupoId;
    Factura.strCliente=miFactura.strCliente;
    Factura.intEjercicio=miFactura.intEjercicio;
    Factura.intNumFactura=miFactura.intNumFactura;
    Factura.datFecha=miFactura.datFecha;
    Factura.strDescripcion=miFactura.strDescripcion;
    Factura.decDto=miFactura.decDto;
    Factura.decBase=miFactura.decBase;
    Factura.decCuotaDto=miFactura.decCuotaDto;
    Factura.decCuotaIva=miFactura.decCuotaIva;
    Factura.decTotal=miFactura.decTotal;

    var jsonString = JSON.stringify(Factura);

    // var jsonString = JSON.stringify(miFactura);

    return this.http.post<any>(urlCompleta, jsonString, this.httpOptions);    
  }

  putCambiaCabeceraFactura(miFactura):Observable<any>{
    let urlCompleta=this.url+'/api/Facturas/cambiaFactura?'+"strCookie="+this.cookie;

    var jsonString = JSON.stringify(miFactura);

    return this.http.put<any>(urlCompleta, jsonString, this.httpOptions);    
  }

  putEliminaCabeceraFactura(intId,intGrupoId,intReservaId):Observable<any>{
    let urlCompleta=this.url+'/api/Facturas/eliminaFactura?'+"strCookie="+this.cookie+'&intId='+intId+'&intGrupoId='+intGrupoId+'&intReservaId='+intReservaId;

    return this.http.put<any>(urlCompleta, this.httpOptions);    
  }

  postInsertaLineasFactura(misLineasFactura):Observable<any>{
    let urlCompleta=this.url+'/api/Facturas?'+"strCookie="+this.cookie;

    var jsonString = JSON.stringify(misLineasFactura);

    return this.http.post<any>(urlCompleta, jsonString, this.httpOptions);    
  }

  getReservaDias(intReservaId):Observable<ReservaDia[]>{
    let dato='strCookie='+this.cookie+'&intReservaId='+intReservaId;
    
    let urlCompleta=this.url+'/api/Reserva?'+dato;
 
    return this.http.get<ReservaDia[]>(urlCompleta,this.httpOptions);
  }

  putPrecioDiaReserva(intReservaId,miListReservaDias):Observable<any>{
    let urlCompleta=this.url+'/api/Reserva?'+"strCookie="+this.cookie+"&intReservaId="+intReservaId;

    var jsonString = JSON.stringify(miListReservaDias);

    return this.http.put<any>(urlCompleta, jsonString, this.httpOptions);    
  }
 
  getEstadosReserva(intId):Observable<EstadoReserva[]>{
    let dato='strCookie='+this.cookie+'&intId='+ intId ;
    let urlCompleta=this.url+'/api/EstadoReserva?'+dato;
 
    return this.http.get<EstadoReserva[]>(urlCompleta,this.httpOptions);
  }
}
