export class ReservaGasto {

    public intId?:number;
    public intReservaId?:number;
    public intArticuloId?:number;
    public intFacturaId?:number;
    public strArticuloNombre?:string;
    public decArticuloPv?:number;
    public decArticuloPvp?:number;
    public decArticuloIva?:number;
    public decArticuloDto?:number;
    public intCantidad?:number;
    public datFecha?:Date;
    public decImporte?:number;
    public strHabitacionNombre?:string;

}
