import { Component, OnInit } from '@angular/core';
import { User } from '../core/models/user.model';
import { StorageService } from '../core/services/storage.service';
import { CRespuestaService } from '../login/shared/CRespuesta.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  
  public loginUserData: User;
  showSpinner: boolean = false;
  miTrabajo:boolean=false;
  soyAdmin:boolean=false;
  
  constructor(
    private storageService: StorageService,
    private CRespuestaService:CRespuestaService
  ) { }

  ngOnInit() {

    //console.log(this.storageService.getCurrentSession())
    //this.storageService.getCurrentSession().expires= new FormControl(new Date()).value.toUTCString();

    //console.log(new FormControl(new Date()).value.toUTCString())
    var event = new Date(this.storageService.getCurrentSession().expires);
    var jsonDate = event.toJSON();
    //console.log(jsonDate)

    var today = new FormControl(new Date()).value.toJSON();
    //console.log(today);

    if(jsonDate>today){
      //console.log('uno');
    }else if(jsonDate<=today){
      //console.log('dos');
      //alert("Sesion caducada");
    }

    this.loginUserData = this.storageService.getCurrentUser();
    /** Me dice si es administrador o trabajador **/
    //console.log(this.loginUserData.strRolName);
    this.definirUser();
  }

  public logout(): void{
    this.showSpinner = true;

          setTimeout(() => {
            this.showSpinner = false;
            this.storageService.logout();
          }, /*2000*/);  
  }

  public definirUser(){
  this.loginUserData = this.storageService.getCurrentUser();
    /** Me dice si es administrador o trabajador **/
    //console.log(this.loginUserData.strRolName);
    if(this.loginUserData.strRolName === 'Trabajador'){
      return this.miTrabajo=true;
    }else{
      return this.soyAdmin=true;
    }
  }

}
