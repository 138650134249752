export class NuevoTrabajador {

    public strNombre?:string;
    public strApellidos?:string;
    public strDni?:string;
    public strEmail?:string;
    public strTelefono1?:string;
    public strTelefono2?:string;
    public strDireccion?:string;
    public strPoblacion?:string;
    public strProvincia?:string;
    public strCP?:string;
    public strPais?:string;
    public strActivo?:string;
    public strUserName?:string;
    public strPassword?:string;
    public strConfirmPassword?:string;
}
