import { Component, OnInit, Inject } from '@angular/core';
import { Cliente } from 'src/app/core/models/cliente';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ReservasService } from 'src/app/core/services/reservas.service';
import { Huesped } from 'src/app/core/models/huesped';

@Component({
  selector: 'app-edita-huesped',
  templateUrl: './edita-huesped.component.html',
  styleUrls: ['./edita-huesped.component.scss']
})
export class EditaHuespedComponent implements OnInit {

  //cliente:Cliente=new Cliente();
  formNewCliente: FormGroup;
  dniValido:boolean=false;
  submitted:boolean=false;
  avanza:boolean=false;
  dniError:boolean=false;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: Huesped,private miservicioReservas:ReservasService,
  private _formBuilder: FormBuilder) { }

  ngOnInit() {
    //console.log(this.data)

    this.formNewCliente = this._formBuilder.group({
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      nacimiento: ['', Validators.required],
      sexo: ['', Validators.required],
      dni: ['', Validators.required],
      expedido: ['', Validators.required],
      pais: ['', Validators.required]
    })
  }

  onChangeNacimiento(event){
    //console.log(event.srcElement.value)
    if(event){
      this.data.datClienteNacimiento=event.srcElement.value;
    }
  }

  onChangeExpedido(event){
    //console.log(event)
    if(event){
      this.data.datDniExpedido=event.srcElement.value;
    }
  }

  agregarHuesped(){
    //this.thisDialogRef.close();
    this.dniError=false;
    this.submitted = true;

    if(this.nif()===true){
      this.avanza=true;
    }else{
      this.dniError=true;
      this.avanza=false;
    }

    if(this.formNewCliente.valid && this.dniValido===true){
      //console.log(this.cliente)
      //this.data.strActivo="S";

      //console.log(this.data)

      this.miservicioReservas.putHuespedReserva(this.data).subscribe((datos:any)=>{
        //console.log(datos)
        if(datos.miRespuesta.booOk){
          this.thisDialogRef.close(datos.miListHuesped);
        }
      })

    }
  }

  public  nif() {
    var numero
    var letr
    var letra
    var expresion_regular_dni
   
    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;
   
    if(expresion_regular_dni.test (this.formNewCliente.controls.dni.value) == true){
       numero = this.formNewCliente.controls.dni.value.substr(0,this.formNewCliente.controls.dni.value.length-1);
       letr = this.formNewCliente.controls.dni.value.substr(this.formNewCliente.controls.dni.value.length-1,1);
       numero = numero % 23;
       letra='TRWAGMYFPDXBNJZSQVHLCKET';
       letra=letra.substring(numero,numero+1);
      if (letra!=letr.toUpperCase()) {
        return this.dniValido=false;
         //alert('Dni erroneo, la letra del NIF no se corresponde');
       }else{
         return this.dniValido=true;
       }
    }else if(this.formNewCliente.controls.dni.value===undefined){
      return this.dniValido=true;
    }else{
      return this.dniValido=false;
       //alert('Dni erroneo, formato no válido');
     }
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
