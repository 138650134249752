import { Component, OnInit, Inject } from '@angular/core';
import { Reserva } from 'src/app/core/models/reserva';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MantenimientosService } from 'src/app/core/services/mantenimientos.service';
import { ReservasService } from 'src/app/core/services/reservas.service';

@Component({
  selector: 'app-habitaciones-reserva',
  templateUrl: './habitaciones-reserva.component.html',
  styleUrls: ['./habitaciones-reserva.component.scss']
})
export class HabitacionesReservaComponent implements OnInit {

  listHabitacionesReserva:Reserva[]=[];  

  filtro:string;
  checktodos:any;
  dataTodo:Reserva[]=[];

  selectHabitacion:any;
  listAux:any[]=[];

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, private miservicioReservas:ReservasService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.listarHabitaciones();    
  }

  listarHabitaciones(){
    this.miservicioReservas.getReservaById(0,this.data).subscribe(datos=>{
      //console.log(datos);
      // this.dataTodoObsoleto=datos;
      this.dataTodo=datos;
      this.listHabitacionesReserva=datos;
    })
  }

  // todos(event){
  //   if(event.checked){
  //     this.dataTodo.splice(0,this.dataTodo.length)
  //     this.dataTodo=this.dataTodoObsoleto;
  //     if(this.filtro===undefined){
  //       this.filtro='';
  //     }   
  //     this.funcionBusqueda(this.filtro.toLocaleLowerCase(),this.dataTodo);                    
  //   }else{
  //     this.dataTodo=this.dataTodoObsoleto.filter(e=>e.strActivo==="S");
  //     if(this.filtro===undefined){
  //       this.filtro='';
  //     }
  //     this.funcionBusqueda(this.filtro.toLocaleLowerCase(),this.dataTodo);
  //   }
  // }

  buscar(event: any){
    this.filtro = event.target.value;
    this.funcionBusqueda(this.filtro,this.dataTodo);
  }

  funcionBusqueda(filtro,dataFilter){
    if(filtro===''){
      this.listHabitacionesReserva=dataFilter
    }else{
      this.listHabitacionesReserva=dataFilter.filter(e=>e.strHabitacionNombre.toLowerCase().startsWith(filtro.toLowerCase()) || 
      e.strClienteNombre.toLowerCase().startsWith(filtro.toLowerCase()) || e.strClienteApellidos.toLowerCase().startsWith(filtro.toLowerCase()) );
    }
  }

  seleccionaHabitacion(e){
    this.selectHabitacion=e.intId;
    this.listAux.push(e.intId)
    let ultimo=this.listAux[this.listAux.length - 1];

    this.listAux.forEach(o => {
      if(o===ultimo){
        document.getElementById(o).style.borderStyle="solid";
        document.getElementById(o).style.borderWidth="2px";
        document.getElementById(o).style.borderColor="#ffe066";        
      }else{
        document.getElementById(o).style.borderStyle="none";
      }
    });
  }

  guardar(){
    this.thisDialogRef.close(this.listHabitacionesReserva.filter(e=>e.intId===this.selectHabitacion)[0]);
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
