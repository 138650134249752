import { Component, OnInit, Inject } from '@angular/core';
import { ReservasService } from 'src/app/core/services/reservas.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from '../../service/data.service';
import { DatePipe } from '@angular/common';
import { HabitacionService } from 'src/app/core/services/habitacion.service';
import { ReservaGasto } from 'src/app/core/models/reserva-gasto';
import { ListaArticuloComponent } from '../lista-articulo/lista-articulo.component';
import { HabitacionesReservaComponent } from '../habitaciones-reserva/habitaciones-reserva.component';

@Component({
  selector: 'app-nuevo-gasto',
  templateUrl: './nuevo-gasto.component.html',
  styleUrls: ['./nuevo-gasto.component.scss']
})
export class NuevoGastoComponent implements OnInit {

  miReservaGasto:ReservaGasto=new ReservaGasto();
  isChecked:boolean=false;
  textoIva:string="No";

  constructor(private miservicioReservas:ReservasService,public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
  public datepipe: DatePipe,public dialog: MatDialog,private ds: DataService, private miservicioHabitacion:HabitacionService) {}

  ngOnInit() {
    //console.log(this.data)
    this.miReservaGasto.intReservaId=this.data.id;

    //console.log(this.miReservaGasto.decArticuloIva);
    if(this.miReservaGasto.decArticuloIva===undefined){
      this.miReservaGasto.decArticuloIva=10      
    }

    if(this.miReservaGasto.intCantidad===undefined){
      this.miReservaGasto.intCantidad=1;
    }

    // console.log(this.miReservaGasto.strHabitacionNombre)
    if(this.miReservaGasto.strHabitacionNombre===undefined){
      this.miReservaGasto.strHabitacionNombre=this.data.habitacion
    }
    
    if(this.miReservaGasto.datFecha===undefined){
      this.miReservaGasto.datFecha=this.data.fecha
    }
  }

  pulsa(){
    if(this.isChecked===true){
      this.textoIva='No';
    }else{
      this.textoIva="Sí";
    }
  }

  onChangeFecha(event){
    this.miReservaGasto.datFecha=event;
  }
  

  cancelar(){
    this.thisDialogRef.close();
  }

  openHabitacion(){
    let dialogRef = this.dialog.open(HabitacionesReservaComponent, {
      width: '600px',
      data:this.data.grupoId
    });
    dialogRef.afterClosed().subscribe((data:any)=> {
      //console.log(data)
      if(data!=undefined){
        this.miReservaGasto.strHabitacionNombre=data.strHabitacionNombre;
        this.miReservaGasto.intReservaId=data.intId;
        // this.miReservaGasto.decArticuloPv=data.decPv;
        // this.miReservaGasto.decArticuloPvp=data.decPvp;
        // this.miReservaGasto.decArticuloIva=data.decIva;
        // this.miReservaGasto.decArticuloDto=data.decDto;        
      }      
    });
  }

  openArticulos(){
    let dialogRef = this.dialog.open(ListaArticuloComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe((data:any)=> {
      //console.log(data)
      if(data!=undefined){
        this.miReservaGasto.intArticuloId=data.intId;
        this.miReservaGasto.strArticuloNombre=data.strNombre;
        // this.miReservaGasto.decArticuloPv=data.decPv;
        this.miReservaGasto.decArticuloPvp=data.decPvp;
        this.miReservaGasto.decArticuloIva=data.decIva;
        this.miReservaGasto.decArticuloDto=data.decDto;        
      }      
      //console.log(this.cliente)
      //this.form.controls.nombre.setValue(data.strNombre)
    });
  }

  guardar(){
    //console.log(this.miReservaGasto);
    //console.log(this.isChecked);
    // let strIva:any;
    // if(this.isChecked){
    //   strIva='S'
    // }else{
    //   strIva='N';
    // }
    this.miservicioReservas.postGastoReserva(this.miReservaGasto,this.isChecked).subscribe((datos:any)=>{
      if(datos.miRespuesta.booOk){
        this.thisDialogRef.close(datos.miListReservaGastos);        
      }
    })
  }

}