import { Component, OnInit, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReservasService } from 'src/app/core/services/reservas.service';
import { ReservaPago } from 'src/app/core/models/reserva-pago';
import { Reserva } from 'src/app/core/models/reserva';
import { ReservaDia } from 'src/app/core/models/reserva-dia';
import { Regimenes } from 'src/app/core/models/regimenes';

@Component({
  selector: 'app-edita-habitacion-reserva',
  templateUrl: './edita-habitacion-reserva.component.html',
  styleUrls: ['./edita-habitacion-reserva.component.scss']
})
export class EditaHabitacionReservaComponent implements OnInit {

  miListReservaDias:ReservaDia[]=[];
  miListRegimen:Regimenes[]=[];

  constructor(private miservicioReservas:ReservasService,public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: Reserva,
  public datepipe: DatePipe,public dialog: MatDialog) {}

  ngOnInit() {
    //console.log(this.data)
    this.listarDiasReserva();
    this.listarRegimenes();
  }

  marcarItem(event: any,e)
  {
    //console.log(e)
  }

  listarDiasReserva(){
    this.miservicioReservas.getReservaDias(this.data.intId).subscribe(datos=>{
      this.miListReservaDias=datos;
      //console.log(datos)
    })
  }

  listarRegimenes(){
    this.miservicioReservas.getRegimenes().subscribe(datos=>{
      //console.log(datos);
      this.miListRegimen=datos.filter(r=>r.strActivo==="S")
    })
  }

  guardar(){
    //console.log(this.miListReservaDias);
    // if(this.data.intId!=undefined){
    //   this.miservicioReservas.putPagoReserva(this.data).subscribe((datos:any)=>{
    //     //console.log(datos)
    //     if(datos.miRespuesta.booOk){
    //       this.thisDialogRef.close(datos.miListReservaPagos);        
    //     }
    //   })
    // }else{
    //   this.miservicioReservas.postPagoReserva(this.data).subscribe((datos:any)=>{
    //     //console.log(datos)
    //     if(datos.miRespuesta.booOk){
    //       this.thisDialogRef.close(datos.miListReservaPagos);        
    //     }
    //   })
    // }
    
    this.miservicioReservas.putPrecioDiaReserva(this.data.intId,this.miListReservaDias).subscribe(datos=>{
      //console.log(datos)
      if(datos.miRespuesta.booOk){
        this.thisDialogRef.close(datos.miReserva);   
      }
    })
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
