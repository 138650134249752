import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthTrabajadorGuard implements CanActivate {
  constructor(private router: Router,
    private storageService: StorageService) { }

canActivate() {

let miComponente=window.location.pathname;
let loginUserComponent = this.storageService.getCurrentSession();
let strAcceso:boolean;

strAcceso=loginUserComponent.miListComponente.some(e => e.strPath === miComponente);
let loginUserData = this.storageService.getCurrentUser();
//if (this.storageService.isAuthenticated() &&  loginUserData.strRolName.includes("Trabajador")) {
if (this.storageService.isAuthenticated()  && strAcceso===true) {
// logged in so return true
return true;
}

this.storageService.logout();
return false;
}
}
