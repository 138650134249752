export class Articulo {

    public intId?:number;
    public strNombre?:string;
    public strDescripcion?:string;
    public decPv?:number;
    public decIva?:number;
    public decPvp?:number;
    public decDto?:number;
    public strActivo?:string;

}
