import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {APP_BASE_HREF, DatePipe} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ColorPickerModule } from 'ngx-color-picker';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
//import {CalendarModule} from "angular-calendar";
import { CRespuestaService } from './login/shared/CRespuesta.service';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { MatNativeDateModule } from '@angular/material';
import { ListadoComponent } from './listado/listado.component';
import { LoginRecuperacionComponent } from './login-recuperacion/login-recuperacion.component';
import { HabitacionService } from './core/services/habitacion.service';
import { EditarComponent } from './editar/editar.component';
import { InformesComponent } from './informes/informes.component';
import { HabitacionAsignadaComponent } from './habitacion-asignada/habitacion-asignada.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { HabitacionesComponent } from './habitaciones/habitaciones.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EdicionUsuariosComponent } from './mantenimientos/editar/edicion-usuarios/edicion-usuarios.component';
import { EdicionHabitacionesComponent } from './mantenimientos/editar/edicion-habitaciones/edicion-habitaciones.component';
import { InsertarHabitacionesComponent } from './mantenimientos/insertar/insertar-habitaciones/insertar-habitaciones.component';
import { InsertarUsuariosComponent } from './mantenimientos/insertar/insertar-usuarios/insertar-usuarios.component';
import { AsignacionesComponent } from './asignaciones/asignaciones.component';
import { EmpleadoComponent } from './asignaciones/empleado/empleado.component';
import { ReservasComponent } from './reservas/reservas.component';
import { ReservasService } from './core/services/reservas.service';
import { ClientesComponent } from './clientes/clientes.component';
import { EdicionClientesComponent } from './clientes/edicion-clientes/edicion-clientes.component';
import { LayoutComponent } from './layout/layout.component';
import { InicioComponent } from './inicio/inicio.component';
import { InsertaReservaComponent } from './reservas/inserta-reserva/inserta-reserva.component';
import { InsertaClientesComponent } from './clientes/inserta-clientes/inserta-clientes.component';
import { ChangePasswordComponent } from './usuarios/change-password/change-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HotelComponent } from './hotel/hotel.component';
import { RoomEditComponent } from './hotel/componentes/room-edit.component';
import { DayPilotModule } from 'daypilot-pro-angular';
import { RoomCreateComponent } from './hotel/componentes/room-create.component';
import { ReservationEditComponent } from './hotel/componentes/reservation-edit.component';
import { ReservationCreateComponent } from './hotel/componentes/reservation-create.component';
import { MisReservasComponent } from './hotel/mis-reservas/mis-reservas.component';
import { SidebarCollapsedComponent } from './hotel/sidebar/sidebar-collapsed.component';
import { SidebarContainerComponent } from './hotel/sidebar/sidebar-container.component';
import { SidebarExpandedComponent } from './hotel/sidebar/sidebar-expanded.component';
import { SidebarMainComponent } from './hotel/sidebar/sidebar-main.component';
import { ConfiguracionesComponent } from './configuraciones/configuraciones.component';
import { BuscaClienteComponent } from './hotel/componentes/busca-cliente/busca-cliente.component';
import { VentanaErrorComponent } from './ventana-error/ventana-error.component';
import { MantenimientoEstadosLimpiezaComponent } from './mantenimiento-estados-limpieza/mantenimiento-estados-limpieza.component';
import { MantenimientoTiposHabitacionComponent } from './mantenimiento-tipos-habitacion/mantenimiento-tipos-habitacion.component';
import { MantenimientoRegimenComponent } from './mantenimiento-regimen/mantenimiento-regimen.component';
import { InsertarTipoHabitacionComponent } from './mantenimientos/insertar/insertar-tipo-habitacion/insertar-tipo-habitacion.component';
import { EdicionTipoHabitacionComponent } from './mantenimientos/editar/edicion-tipo-habitacion/edicion-tipo-habitacion.component';
import { EdicionRegimenComponent } from './mantenimientos/editar/edicion-regimen/edicion-regimen.component';
import { InsertarRegimenComponent } from './mantenimientos/insertar/insertar-regimen/insertar-regimen.component';
import { TarifasComponent } from './tarifas/tarifas.component';
import { DetalleTarifaComponent } from './tarifas/detalle-tarifa/detalle-tarifa.component';
import { ModifyReservationEditComponent } from './hotel/componentes/modify-reservation-edit/modify-reservation-edit.component';
import { AgregaHuespedComponent } from './hotel/componentes-auxiliares/agrega-huesped/agrega-huesped.component';
import { Demo4Component } from './demo4/demo4.component';
import { FacturasComponent } from './facturas/facturas.component';
import { MantenimientoArticulosComponent } from './mantenimiento-articulos/mantenimiento-articulos.component';
import { MantenimientoIvaComponent } from './mantenimiento-iva/mantenimiento-iva.component';
import { MantenimientoFormaPagoComponent } from './mantenimiento-forma-pago/mantenimiento-forma-pago.component';
import { EditaArticuloComponent } from './mantenimiento-articulos/edita-articulo/edita-articulo.component';
import { NuevoArticuloComponent } from './mantenimiento-articulos/nuevo-articulo/nuevo-articulo.component';
import { EditaIvaComponent } from './mantenimiento-iva/edita-iva/edita-iva.component';
import { NuevoIvaComponent } from './mantenimiento-iva/nuevo-iva/nuevo-iva.component';
import { NuevoFormapagoComponent } from './mantenimiento-forma-pago/nuevo-formapago/nuevo-formapago.component';
import { EditaFormapagoComponent } from './mantenimiento-forma-pago/edita-formapago/edita-formapago.component';
import { InsertaTarifaComponent } from './tarifas/inserta-tarifa/inserta-tarifa.component';
import { NuevaReservaComponent } from './hotel/componentes/nueva-reserva/nueva-reserva.component';
import { AgregaHuespedNuevaReservaComponent } from './hotel/componentes-auxiliares/agrega-huesped-nueva-reserva/agrega-huesped-nueva-reserva.component';
import { AgruparReservasComponent } from './hotel/componentes-auxiliares/agrupar-reservas/agrupar-reservas.component';
import { NuevoGastoComponent } from './hotel/componentes-auxiliares/nuevo-gasto/nuevo-gasto.component';
import { ListaArticuloComponent } from './hotel/componentes-auxiliares/lista-articulo/lista-articulo.component';
import { HabitacionesReservaComponent } from './hotel/componentes-auxiliares/habitaciones-reserva/habitaciones-reserva.component';
import { EditaGastoComponent } from './hotel/componentes-auxiliares/edita-gasto/edita-gasto.component';
import { ReservaPagoComponent } from './hotel/componentes-auxiliares/reserva-pago/reserva-pago.component';
import { ListaFormaPagoComponent } from './hotel/componentes-auxiliares/lista-forma-pago/lista-forma-pago.component';
import { EditaHuespedComponent } from './hotel/componentes-auxiliares/edita-huesped/edita-huesped.component';
import { TitularFacturaComponent } from './hotel/componentes-auxiliares/titular-factura/titular-factura.component';
import { EditaHabitacionReservaComponent } from './hotel/componentes-auxiliares/edita-habitacion-reserva/edita-habitacion-reserva.component';
//import { EditaClientesComponent } from './clientes/edita-clientes/edita-clientes.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    ToolbarComponent,
    ListadoComponent,
    LoginRecuperacionComponent,
    EditarComponent,
    InformesComponent,
    HabitacionAsignadaComponent,
    SidemenuComponent,
    HabitacionesComponent,
    UsuariosComponent,
    EdicionUsuariosComponent,
    EdicionHabitacionesComponent,
    InsertarHabitacionesComponent,
    InsertarUsuariosComponent,
    AsignacionesComponent,
    EmpleadoComponent,
    ReservasComponent,
    ClientesComponent,
    EdicionClientesComponent,
    LayoutComponent,
    InicioComponent,
    InsertaReservaComponent,
    InsertaClientesComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    HotelComponent,
    RoomEditComponent,
    RoomCreateComponent,
    ReservationEditComponent,
    ReservationCreateComponent,
    MisReservasComponent,
    SidebarCollapsedComponent,
    SidebarContainerComponent,
    SidebarExpandedComponent,
    SidebarMainComponent,
    ConfiguracionesComponent,
    BuscaClienteComponent,
    VentanaErrorComponent,
    MantenimientoEstadosLimpiezaComponent,
    MantenimientoTiposHabitacionComponent,
    MantenimientoRegimenComponent,
    InsertarTipoHabitacionComponent,
    EdicionTipoHabitacionComponent,
    EdicionRegimenComponent,
    InsertarRegimenComponent,
    TarifasComponent,
    DetalleTarifaComponent,
    ModifyReservationEditComponent,
    AgregaHuespedComponent,
    Demo4Component,
    FacturasComponent,
    MantenimientoArticulosComponent,
    MantenimientoIvaComponent,
    MantenimientoFormaPagoComponent,
    NuevoArticuloComponent,
    EditaArticuloComponent,
    EditaIvaComponent,
    NuevoIvaComponent,
    NuevoFormapagoComponent,
    EditaFormapagoComponent,
    InsertaTarifaComponent,
    NuevaReservaComponent,
    AgregaHuespedNuevaReservaComponent,
    AgruparReservasComponent,
    NuevoGastoComponent,
    ListaArticuloComponent,
    HabitacionesReservaComponent,
    EditaGastoComponent,
    ReservaPagoComponent,
    ListaFormaPagoComponent,
    EditaHuespedComponent,
    TitularFacturaComponent,
    EditaHabitacionReservaComponent,
    //EditaClientesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    DayPilotModule,
    ColorPickerModule
    //CalendarModule.forRoot(),
  ],
  providers: [CRespuestaService, HabitacionService, ReservasService,DatePipe],
  //providers: [CRespuestaService, HabitacionService,  {provide:APP_BASE_HREF, useValue: '/http://gobernanta.infitec.es/'}],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
