export class Limpieza {

    public intId?:number;
    public datFechaRegistro?:Date;
    public intHabitacionId?:number;
    public strHabitacionNombre?:string;
    public strUserId?:string;
    public strUserName?:string;
    public strNotas?:string;
    public intEstadoLimpiezaId?:number;
    public strEstadoLimpiezaNombre?:string;
    public strHoraInicio?:string;
    public strHoraFin?:string;

}
