export class Huesped {

    public intOcupacionId?:number;
    public intReservaId?:number;
    public intReservaGrupo?:number;
    public strHabitacionNombre?:string;
    public intClienteId?:number;
    public strClienteNombre?:string;
    public strClienteApellidos?:string;
    public strClienteSexo?:string;
    public datClienteNacimiento?:Date;
    public strClienteDni?:string;
    public datDniExpedido?:Date;
    public strPais?:string;

}
