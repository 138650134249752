import { Component, OnInit,ViewChild, HostListener, ElementRef } from '@angular/core';
import { StorageService } from '../core/services/storage.service';
import { User } from '../core/models/user.model';
import { HabitacionService } from '../core/services/habitacion.service';
import {MatTableDataSource, MatSort, DateAdapter,MatPaginator, MatDialog} from '@angular/material';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { Estados } from '../core/models/estados';
import {FormControl} from '@angular/forms';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { HabitacionLimpieza } from '../core/models/habitacion-limpieza';
import {MAT_DATE_LOCALE} from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import { map, first } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { Limpieza } from '../core/models/limpieza';
import { EditarComponent } from '../editar/editar.component';
import { VentanaErrorComponent } from '../ventana-error/ventana-error.component';
import { CRespuestaService } from '../login/shared/CRespuesta.service';
import { EstadosLimpieza } from '../core/models/estados-limpieza';
import { DatePipe} from '@angular/common';
import { ClaveValor } from './clave-valor';
import { t } from '@angular/core/src/render3';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es' }]
})
export class ListadoComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public loginUserData: User;
  showSpinner: boolean = false;
  data:HabitacionLimpieza[]=[];
  dataTodo:HabitacionLimpieza[]=[];
  displayedColumns = ['strHabitacionNombre','strEstHabitacion','strUserNameMixto','strUltimaNotaLimpieza'];
  dataSource ;
  today = new Date();
  date = new FormControl(new Date());
  fechaHoy = new FormControl(new Date());
  listaEstados:EstadosLimpieza[]=[];
  listaEstadosHabitacion:Estados[]=[];
  cambioFecha:boolean=false;
  fechaFija:boolean=false;
  miFecha:string;
  navegar:boolean=true;
  filtro:string;
  miEstado:string;
  miEstado2:string;
  default:any;
  datosLimpiezas:Limpieza[]=[];
  puntoPartida:string="listado";

  listHabitacionLimpieza2: Observable<HabitacionLimpieza[]>;
  listFiltrada:HabitacionLimpieza[]=[];
  hide = false;
  listFiltros:ClaveValor[]=[];
  existFilter:boolean=false;

  dataExcel:datosExcelLimpieza[]=[];
  teclaAlt:any;
  otraTecla:any;

  constructor(private storageService: StorageService,private miservicio:HabitacionService,private router: Router,public datepipe: DatePipe,
    private dateAdapter: DateAdapter<Date>,public dialog: MatDialog, private pueba2:CRespuestaService,private route: ActivatedRoute) {
    this.dateAdapter.setLocale('es');   
   }

  ngOnInit() {    
    //console.log(this.route.snapshot.paramMap.get('listado'));
    //console.log(window.location.pathname);
    this.paginator._intl.itemsPerPageLabel = 'Elementos por pagina';
    this.loginUserData = this.storageService.getCurrentUser();
    /*if(this.loginUserData.strRolName==="Administrador"){
      this.cambioFecha=true;
      this.fechaFija=false;
    }else{
      this.cambioFecha=false;
      this.fechaFija=true;
    }*/

    //console.log(this.loginUserData.strRolId)
    if(this.loginUserData.strRolId==="1" || "3" || "4" || "5"){
      this.cambioFecha=true;
       this.fechaFija=false;
    }
    if(this.loginUserData.strRolId==="2"){
      this.cambioFecha=false;
       this.fechaFija=true;
    }
/*
     if(this.loginUserData.strRolName.includes("Administrador")){
       this.cambioFecha=true;
       this.fechaFija=false;
     }else{
       this.cambioFecha=false;
       this.fechaFija=true;
     }*/

    // if(this.loginUserData.strRolId==="3" || "1" || "4" || "5"){
    //   this.cambioFecha=true;
    //   this.fechaFija=false;
    // }else{
    //   this.cambioFecha=false;
    //   this.fechaFija=true;
    // }

    this.listar();
    this.getMisEstadosLimpieza();
    this.getEstadosHabitacion();
    //this.pruebas();
    //this.listHabitacionLimpieza2 = this.miservicio.getClients(this.date.value.toLocaleDateString(),'','')
    //this.listarPrueba();

    //console.log(this.date);
    //console.log(this.date.value.toJSON());
    //let miFecha=this.datepipe.transform(this.date.value, 'yyyy-MM-dd');
    //console.log(miFecha);
  }  

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===88 && this.otraTecla===18){
      this.exportAsXLSX();
    }else if(this.teclaAlt===66 && this.otraTecla===18){
      var myEl = document.getElementById('miFiltro');
      myEl.focus();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }

  pruebas(){
    this.pueba2.postPrueba().pipe(first()).subscribe(datos=>{
      //console.log(datos);
    })
  }

  listarPrueba(){
    let miFecha=this.datepipe.transform(this.date.value, 'yyyy-MM-dd');
    this.miservicio.getClients(miFecha,'','').pipe(first()).subscribe(datos=>{
      //console.log(datos)
    })
  }

  public listar(){
    let miFecha=this.datepipe.transform(this.date.value, 'yyyy-MM-dd');
    this.dataSource = new MyTableDataSource(this.paginator, this.sort);
    this.miservicio.getHabLimpiezas(miFecha,'','').pipe(first()).subscribe(datos=>{
      this.data=datos;  
      this.listFiltrada=datos; 
      //console.log(this.data)   
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    })
  }

  /*getColor(intEstadoLimpiezaId) { 
    switch (intEstadoLimpiezaId) {
      case 1:
        return '#ff3333';
      case 2:
        return '#ffc14d';
      case 3:
        return '#00b300';
    }
  }*/

 /* cambia(h:HabitacionLimpieza){
      let extra: NavigationExtras = {
        queryParams: {
          'strHabitacionNombre':h.strHabitacionNombre,
          'intHabitacionId':h.intHabitacionId,
          'datFecha':h.datFecha,
          'intLimpiezaId':h.intLimpiezaId,
          'intEstadoLimpiezaId':h.intEstadoLimpiezaId,
          'puntoPartida':this.puntoPartida
        }
    }
      this.router.navigate(["editar"],extra);    
  }*/

  cambia(h:HabitacionLimpieza){
    let dialogRef = this.dialog.open(EditarComponent, {
      width: '600px',
      data: {
        'strHabitacionNombre':h.strHabitacionNombre,
          'intHabitacionId':h.intHabitacionId,
          'datFechaRegistro':h.datFecha,
          'intLimpiezaId':h.intLimpiezaId,
          'intEstadoLimpiezaId':h.intEstadoLimpiezaId,
          'strUltimaNotaLimpieza':h.strUltimaNotaLimpieza,
          'puntoPartida':this.puntoPartida
      }
    });
    dialogRef.afterClosed().subscribe((datos) => {
      //console.log(datos)

      if(datos!=undefined){
        let myObject:any;
        myObject=datos.miListHabitacionLimpieza[0];

        let miTicket=datos.miListHabitacionLimpieza[0].intHabitacionId;

        var index = this.data.map(x => {
          return x.intHabitacionId;
        }).indexOf(miTicket);

        if(datos.length!=0){      
          this.data.splice(index, 1, myObject);
          this.dataSource=new MatTableDataSource(this.data);
        }
        this.dataSource=new MatTableDataSource(this.data);
        if(datos.length===0){
          this.data.splice(index, 1);
        }
      }

    });
  }

  getMisEstadosLimpieza(){
    this.miservicio.getEstadosLimpieza().pipe(first()).subscribe(datos=>{
      this.listaEstados=datos;
      //console.log(this.listaEstados);
    })
  }

  getEstadosHabitacion(){
    this.miservicio.getEstadosHabitacion().pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.listaEstadosHabitacion=datos;
    })
  }
/*
  getFiltrado(estado){
   if(this.miFecha===undefined){
     this.miFecha=this.date.value.toJSON();
   }

    this.miservicio.recHabLimpiezas(this.storageService.getCurrentToken(),this.miFecha,'',estado.intId)
    .subscribe(datos=>{
      this.data=datos as HabitacionLimpieza[];
      this.dataSource=new MatTableDataSource(this.data);
    })

    
  }
*/

//cambio la funcion para no consumir mas rest
  getFiltrado(estado){
    if(this.miFecha===undefined){
      let miFechaOtra=this.datepipe.transform(this.date.value, 'yyyy-MM-dd');
      this.miFecha=miFechaOtra;
    }
    //console.log(estado.strNombre)

    let myFilter:ClaveValor=new ClaveValor();
      myFilter.id=1;
      myFilter.valor=estado.strNombre;

    if(this.listFiltros.length>0){
      if(this.listFiltros.some(e => e.id === 1)){
        let miTicket=myFilter.id;
        var index = this.listFiltros.map(x => {
          return x.id;
        }).indexOf(miTicket);    
        this.listFiltros.splice(index, 1, myFilter);
      }else{
        this.listFiltros.push(myFilter);
      }      
    }else{
      this.listFiltros.push(myFilter);
    }
    
    this.existFilter=true;
 
    this.dataTodo=this.data;
    this.data=this.data.filter(habitacion=>habitacion.intEstadoLimpiezaId===estado.intId);
    this.listFiltrada=this.data;
    this.dataSource = new MyTableDataSource(this.paginator, this.sort);
    this.dataSource=new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator=this.paginator;
    this.data=this.dataTodo;
   }

   getFiltrado2(estado){
    if(this.miFecha===undefined){
      let miFechaNueva=this.datepipe.transform(this.date.value, 'yyyy-MM-dd');
      this.miFecha=miFechaNueva;
    }
 
    let myFilter:ClaveValor=new ClaveValor();
      myFilter.id=2;
      myFilter.valor=estado.strNombre;
      
    if(this.listFiltros.length>0){
      if(this.listFiltros.some(e => e.id === 2)){
        let miTicket=myFilter.id;
        var index = this.listFiltros.map(x => {
          return x.id;
        }).indexOf(miTicket);    
        this.listFiltros.splice(index, 1, myFilter);
      }else{
        this.listFiltros.push(myFilter);
      }      
    }else{
      this.listFiltros.push(myFilter);
    }
    
      this.existFilter=true;

    this.dataTodo=this.data;
    //console.log(this.data)
    this.data=this.data.filter(habitacion=>habitacion.strEstHabitacion===estado.strNombre);
    //console.log(this.data)
    this.dataSource = new MyTableDataSource(this.paginator, this.sort);
    this.dataSource=new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator=this.paginator;
    this.data=this.dataTodo;
   }

  getTodos(){
    this.listar();
  }  

  openWindow(){
    let dialogRef = this.dialog.open(VentanaErrorComponent, {
      width: '600px',
      // data:"Parece que ha ocurrido un error con las fechas seleccionadas..."
      data:{
        texto:"Parece que ha ocurrido un error con las fechas seleccionadas...",
        boton:false
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //this.logout();
    });
  }

  addEvent( event: MatDatepickerInputEvent<Date>) {
    let miFechaNueva=this.datepipe.transform(event.value, 'yyyy-MM-dd');
    //console.log(miFechaNueva);
    this.miFecha=miFechaNueva;
    this.navegar=false;
    let fechaCambia=miFechaNueva
    let miFechaNuevaOtra=this.datepipe.transform(this.fechaHoy.value, 'yyyy-MM-dd');
    if(miFechaNuevaOtra<fechaCambia){
      //alert('No existe limpieza para la fecha seleccionada')
      setTimeout(() => {
        this.openWindow();
      }, 100); 
    }else{
      //console.log(this.datepipe.transform(event.value, 'dd/MM/yy'));
      let myFilter:ClaveValor=new ClaveValor();
      myFilter.id=3;
      myFilter.valor=this.datepipe.transform(event.value, 'dd/MM/yy');

      
    if(this.listFiltros.length>0){
      if(this.listFiltros.some(e => e.id === 3)){
        let miTicket=myFilter.id;
        var index = this.listFiltros.map(x => {
          return x.id;
        }).indexOf(miTicket);    
        this.listFiltros.splice(index, 1, myFilter);
      }else{
        this.listFiltros.push(myFilter);
      }      
    }else{
      this.listFiltros.push(myFilter);
    }
    
      this.existFilter=true;

      this.miservicio.getHabLimpiezas(this.miFecha,'','').pipe(first()).subscribe(datos=>{
        this.data=datos;
        this.listFiltrada=datos;
        this.dataSource=new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
      })
    }    
  }


  buscar(event: any){
    this.filtro = event.target.value;
  
    this.dataTodo=this.data;
    if(this.filtro===''){
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
      this.listFiltrada=this.data;
    }else{
      this.data=this.data.filter(habitacion=>habitacion.strUserNameAsignado.toLowerCase().startsWith(this.filtro.toLowerCase()) || habitacion.strUserNameMixto.toLowerCase().startsWith(this.filtro.toLowerCase()) || habitacion.strUserNameRealizado.toLowerCase().startsWith(this.filtro.toLowerCase()) || habitacion.strHabitacionNombre.toLowerCase().startsWith(this.filtro.toLowerCase()) || habitacion.strTipoHabitacionNombre.toLowerCase().startsWith(this.filtro.toLowerCase()));
    //console.log(this.data);
      this.listFiltrada=this.data;
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    }
    this.data=this.dataTodo;
  }

  exportAsXLSX():void {
    this.listFiltrada.forEach(e => {
      // this.dataExcel.push({num_reserva:element.intId, num_habitacion:element.strHabitacionNombre, entrada:this.datepipe.transform(element.datEntrada, 'dd/MM/yyyy'), salida:this.datepipe.transform(element.datSalida, 'dd/MM/yyyy'), cliente:element.strClienteNombre+" "+element.strClienteApellidos, precio:0});
      this.dataExcel.push({
        Fecha:this.datepipe.transform(e.datFecha, 'dd/MM/yyyy'), 
        Habitacion:e.strHabitacionNombre, 
        Tipo:e.strTipoHabitacionNombre, 
        Estado_reserva:e.strEstHabitacion, 
        Estado_limpieza:e.strEstadoLimpiezaNombre, 
        Asignado:e.strUserNameAsignado, 
        Realizado:e.strUserNameRealizado,
        Nota:e.strUltimaNotaLimpieza
      })
    });
    this.miservicio.exportAsExcelFile(this.dataExcel, 'Listado limpiezas-habitaciones');

    this.dataExcel.splice(0, this.dataExcel.length)
  }

  deleteFilter(){
    //Refrescar la pagina
    window.location.reload();
  }

}

export interface datosExcelLimpieza {
  Fecha:any;
  Habitacion:any;
  Tipo:any;
  Estado_reserva:any;
  Estado_limpieza:any;
  Asignado:any;
  Realizado:any;
  Nota:any;
}


export class MyTableDataSource extends DataSource<HabitacionLimpieza> {
  data:HabitacionLimpieza[]=[];
 
   constructor(private paginator: MatPaginator, private sort: MatSort) {
     super();
   }
 
   connect(): Observable<HabitacionLimpieza[]> {
     const dataMutations = [
       observableOf(this.data),
       this.paginator.page,
       this.sort.sortChange
     ];
     
     this.paginator.length = this.data.length;
 
     return merge(...dataMutations).pipe(map(() => {
       return this.getPagedData(this.getSortedData([...this.data]));
     }));
   }
 
   disconnect() {}
 
   private getPagedData(data: HabitacionLimpieza[]) {
     const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
     return data.splice(startIndex, this.paginator.pageSize);
   }
 
 
   private getSortedData(data: HabitacionLimpieza[]) {
     if (!this.sort.active || this.sort.direction === '') {
       return data;
     }
 
     return data.sort((a, b) => {
       const isAsc = this.sort.direction === 'asc';
       switch (this.sort.active) {
         case 'strTipoHabitacionNombre': return compare(a.strTipoHabitacionNombre, b.strTipoHabitacionNombre, isAsc);
         case 'strHabitacionDescripcion': return compare(+a.strHabitacionDescripcion, +b.strHabitacionDescripcion, isAsc);
         default: return 0;
       }
     });
   }
 }
 
 function compare(a, b, isAsc) {
   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
 }