import { Component, OnInit, Inject, ViewChild, ElementRef, Directive, Input, Renderer } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSlideToggleChange} from '@angular/material';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Cliente } from '../../core/models/cliente';
import { StorageService } from '../../core/services/storage.service';
import { ReservasService } from '../../core/services/reservas.service';

//@Directive({ selector: '[myFocus]' })

@Component({
  selector: 'app-edicion-clientes',
  templateUrl: './edicion-clientes.component.html',
  styleUrls: ['./edicion-clientes.component.scss']
})
export class EdicionClientesComponent implements OnInit {

  listaClientes:Cliente[]=[];
  //cliente:Cliente= new Cliente();
  miCliente:Cliente= new Cliente();
  isChecked:boolean=false;
  Estado:string;
  formNewCliente: FormGroup;
  submitted:boolean=false;
  dniError:boolean=false;
  dniValido:boolean=false;
  avanza:boolean=false;
  existError:boolean=false;
  mensajeError:string;

  @ViewChild("falloDni") dniField:ElementRef;  
  @ViewChild("needFocus") needFocus:ElementRef;
  //@Input('myFocus') isFocused: boolean;

  constructor(private storageService: StorageService,private miservicioReservas:ReservasService,private _formBuilder: FormBuilder,
    private router: Router,
    public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: Cliente, private route: ActivatedRoute,
    private hostElement: ElementRef, private renderer: Renderer) {
      /*this.route.queryParams.subscribe(params => {
        this.miCliente.intId = params['intId'];  
      });*/
   }

  ngOnInit() {
    this.listarCliente();

    this.formNewCliente = this._formBuilder.group({
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      nacimiento: ['', Validators.required],
      sexo: ['', Validators.required],
      dni: ['', Validators.required],
      expedido: ['', Validators.required],
      email: new FormControl('', Validators.compose([
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),
      telefono: new FormControl('', Validators.compose([
        Validators.pattern('^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$'),
      ])),
      direccion: [''],
      cp: new FormControl('', Validators.compose([
        Validators.pattern('^[0-9]{5}$'),
      ])),
      provincia: [''],
      poblacion: [''],
      pais: ['', Validators.required],
      estado: ['']
    })
  }

  listarCliente(){
    //let intId=this.miCliente.intId;
    let intId=this.data.intId;
    this.miservicioReservas.getUnicoCliente(intId).pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.listaClientes=datos;

      if(this.listaClientes[0].strActivo==='S'){
        this.isChecked=true;
        this.Estado='Activo'
      }else{
        this.isChecked=false;
        this.Estado='Inactivo'
      }
    })
  }

  public  nif() {
    var numero
    var letr
    var letra
    var expresion_regular_dni
   
    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;
   
    if(expresion_regular_dni.test (this.formNewCliente.controls.dni.value) == true){
       numero = this.formNewCliente.controls.dni.value.substr(0,this.formNewCliente.controls.dni.value.length-1);
       letr = this.formNewCliente.controls.dni.value.substr(this.formNewCliente.controls.dni.value.length-1,1);
       numero = numero % 23;
       letra='TRWAGMYFPDXBNJZSQVHLCKET';
       letra=letra.substring(numero,numero+1);
      if (letra!=letr.toUpperCase()) {
        return this.dniValido=false;
         //alert('Dni erroneo, la letra del NIF no se corresponde');
       }else{
         return this.dniValido=true;
       }
    }else if(this.formNewCliente.controls.dni.value===undefined){
      return this.dniValido=true;
    }else{
      return this.dniValido=false;
       //alert('Dni erroneo, formato no válido');
     }
  }

  onChangeExpedido(event){

  }

  onChangeNacimiento(event){

  }

  salvar(cliente:Cliente){

   /* let listPropiedades:any[]= Object.keys(this.formNewCliente.controls)
    console.log(listPropiedades);

    listPropiedades.forEach(function (mivalor) {
      console.log(mivalor);
      
    });*/

    //console.log(cliente)

    let miEstado:string;
    this.dniError=false;

    if(this.isChecked===true){
      miEstado='S';
    }else{
      miEstado="N";
    }

    this.submitted = true;

      if(this.nif()===true){
        this.avanza=true;
      }else{
        this.dniError=true;
        this.dniField.nativeElement.focus();
      }
    

    if(this.formNewCliente.valid && this.avanza===true){
      
      this.miservicioReservas.putCliente(cliente, miEstado).pipe(first()).subscribe((datos:any)=>{        
        //console.log(datos);
        if(datos.miRespuesta.booOk===true){
          this.thisDialogRef.close(datos);
        }else{
          this.mensajeError=datos.miRespuesta.strMensaje;
          this.existError=true;
        }
      })   
    }

    if(this.formNewCliente.invalid){
      //let listPropiedades:any[]= Object.keys(cliente);
      let listPropiedades=['strNombre','strApellidos','strDni','strEmail','strTelefono1','strDireccion','strCP','strLocalidad','strPoblacion','strPais']
      //console.log(listPropiedades)

      listPropiedades.forEach(function (value) {
        //console.log(value);
        document.getElementById(value).focus();
      });

    }
  }

  cancelar(){
    this.thisDialogRef.close();
  }

  pulsa(){
    if(this.isChecked===true){
      this.Estado='Inactivo';
    }else{
      this.Estado="Activo";
    }
  }

}
