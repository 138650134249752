import { Component, OnInit, Inject } from '@angular/core';
import { ReservasService } from 'src/app/core/services/reservas.service';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReservaPago } from 'src/app/core/models/reserva-pago';
import { ListaFormaPagoComponent } from '../lista-forma-pago/lista-forma-pago.component';

@Component({
  selector: 'app-reserva-pago',
  templateUrl: './reserva-pago.component.html',
  styleUrls: ['./reserva-pago.component.scss']
})
export class ReservaPagoComponent implements OnInit {

  miReservaPago:ReservaPago=new ReservaPago();

  constructor(private miservicioReservas:ReservasService,public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: ReservaPago,
  public datepipe: DatePipe,public dialog: MatDialog) {}

  ngOnInit() {
    //console.log(this.data.intId)

    // if(this.miReservaPago.datFecha===undefined){
    //   this.miReservaPago.datFecha=this.data.fecha;
    // }

    // this.miReservaPago.intReservaGrupoId=this.data.grupoId;
  }

  onChangeFecha(event){
    this.data.datFecha=event;
  }

  openVentana(){
    let dialogRef = this.dialog.open(ListaFormaPagoComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe((data:any)=> {
      //console.log(data)
      if(data!=undefined){
        this.data.intFormaPagoId=data.intId;        
      }      
    });
  }

  guardar(){
    //console.log(this.miReservaGasto);
    if(this.data.intId!=undefined){
      this.miservicioReservas.putPagoReserva(this.data).subscribe((datos:any)=>{
        //console.log(datos)
        if(datos.miRespuesta.booOk){
          this.thisDialogRef.close(datos.miListReservaPagos);        
        }
      })
    }else{
      this.miservicioReservas.postPagoReserva(this.data).subscribe((datos:any)=>{
        //console.log(datos)
        if(datos.miRespuesta.booOk){
          this.thisDialogRef.close(datos.miListReservaPagos);        
        }
      })
    }
   
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
