import { Component, OnInit, Inject } from '@angular/core';
import { ReservasService } from 'src/app/core/services/reservas.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ReservaGasto } from 'src/app/core/models/reserva-gasto';
import { HabitacionesReservaComponent } from '../habitaciones-reserva/habitaciones-reserva.component';
import { ListaArticuloComponent } from '../lista-articulo/lista-articulo.component';

@Component({
  selector: 'app-edita-gasto',
  templateUrl: './edita-gasto.component.html',
  styleUrls: ['./edita-gasto.component.scss']
})
export class EditaGastoComponent implements OnInit {

  isChecked:boolean=false;
  textoIva:string="No";
  decPrecio:number;

  constructor(private miservicioReservas:ReservasService,public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: miObjeto,
  public dialog: MatDialog) {}

  ngOnInit() {
    //console.log(this.data)
  }

  pulsa(){
    if(this.isChecked===true){
      this.textoIva='No';
    }else{
      this.textoIva="Sí";
    }
  }

  onChangeFecha(event){
    this.data.data.datFecha=event;
  }

  cancelar(){
    this.thisDialogRef.close();
  }

  openHabitacion(){
    let dialogRef = this.dialog.open(HabitacionesReservaComponent, {
      width: '600px',
      data:this.data.grupoId
    });
    dialogRef.afterClosed().subscribe((data:any)=> {
      //console.log(data)
      if(data!=undefined){
        this.data.data.strHabitacionNombre=data.strHabitacionNombre;
        this.data.data.intReservaId=data.intId;
        // this.miReservaGasto.decArticuloPv=data.decPv;
        // this.miReservaGasto.decArticuloPvp=data.decPvp;
        // this.miReservaGasto.decArticuloIva=data.decIva;
        // this.miReservaGasto.decArticuloDto=data.decDto;        
      }      
    });
  }

  openArticulos(){
    let dialogRef = this.dialog.open(ListaArticuloComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe((data:any)=> {
      //console.log(data)
      if(data!=undefined){
        this.data.data.intArticuloId=data.intId;
        this.data.data.strArticuloNombre=data.strNombre;
        this.data.data.decArticuloPv=data.decPv;
        this.data.data.decArticuloPvp=data.decPvp;
        this.data.data.decArticuloIva=data.decIva;
        this.data.data.decArticuloDto=data.decDto;        
      }      
      //console.log(this.cliente)
      //this.form.controls.nombre.setValue(data.strNombre)
    });
  }

  guardar(){
    // console.log(this.data.data)
    // console.log(this.decPrecio)

    this.miservicioReservas.putGastoReserva(this.data.data,this.decPrecio,this.isChecked).subscribe((datos:any)=>{
      //console.log(datos)
      if(datos.miRespuesta.booOk){
        this.thisDialogRef.close(datos.miListReservaGastos);        
      }
    })
  }

}

export class miObjeto{

  public data: ReservaGasto;
  public grupoId: number;

}
