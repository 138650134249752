import { Component, OnInit, AfterViewInit, ViewChild, OnChanges, Input,HostListener  } from '@angular/core';
import { RoomCreateComponent } from '../componentes/room-create.component';
import { ReservationEditComponent } from '../componentes/reservation-edit.component';
import { ReservationCreateComponent } from '../componentes/reservation-create.component';
import { RoomEditComponent } from '../componentes/room-edit.component';
import { DayPilotNavigatorComponent, DayPilotSchedulerComponent, DayPilot } from 'daypilot-pro-angular';
import { MoveReservationParams, ReservationData, UpdateReservationParams, DataService, CreateRoomParams, UpdateRoomParams } from '../service/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EdicionHabitacionesComponent } from '../../mantenimientos/editar/edicion-habitaciones/edicion-habitaciones.component';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { VentanaErrorComponent } from '../../ventana-error/ventana-error.component';
import { ReservasService } from '../../core/services/reservas.service';
import { first } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ModifyReservationEditComponent } from '../componentes/modify-reservation-edit/modify-reservation-edit.component';
import { MantenimientosService } from 'src/app/core/services/mantenimientos.service';
import { NuevaReservaComponent } from '../componentes/nueva-reserva/nueva-reserva.component';
import { AgruparReservasComponent } from '../componentes-auxiliares/agrupar-reservas/agrupar-reservas.component';

@Component({
  selector: 'mis-reservas',
  templateUrl: './mis-reservas.component.html',
  styleUrls: ['./mis-reservas.component.scss']
})
export class MisReservasComponent {

  @ViewChild("scheduler") scheduler: DayPilotSchedulerComponent;
  @ViewChild("navigator") navigator: DayPilotNavigatorComponent;
  @ViewChild(RoomEditComponent) roomEdit: RoomEditComponent;
  @ViewChild(EdicionHabitacionesComponent) editHabitacion: EdicionHabitacionesComponent;  
  @ViewChild(RoomCreateComponent) roomCreate: RoomCreateComponent;
  @ViewChild(ReservationCreateComponent) reservationCreate: ReservationCreateComponent;
  @ViewChild(ReservationEditComponent) reservationEdit: ReservationEditComponent;
  @Input() movH: string;
  @Input() movV: string;
  @Input() modificarEstancia: string;
  @Input() borraReserva: string;
  //@HostListener('window:keyup', ['$event'])
  //@HostListener('document:keydown', ['$event'])
  teclaAlt:any;
  otraTecla:any;

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===107 && this.otraTecla===18){
      this.agrupaReservas();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }

  events: any[] = [];
  expanded: boolean = true;

  reservasAgrupagas: any[] = [];

  navigatorConfig: any = {
    showMonths: 4,
    skipMonths: 4,
    selectMode: "month",
  };

  month = {
    from: null,
    to: null
  };

  fechaHoy = new FormControl(new Date());
  fechaAyer = new FormControl(new Date());  

  //ESTA FUNCION DEVUELVE EL NUMERO DE LOS DIAS DEL MES Y PINTA LA CUADRICULA
  getTimeline(date?: DayPilot.Date | string): any[] {
    var date = date || this.navigator.control.selectionDay;
    var start = new DayPilot.Date(date).firstDayOfMonth();
    //var end = new DayPilot.Date(date).lastDayOfMonth();    
    //var days = start.daysInMonth();
    var days = start.daysInMonth()+14;
    
    

    var timeline = [];
    var checkin = 0;
    var checkout = 0;

    //var checkin = 12;
    //var checkout = 12;

    //var comienzo=start;
    var comienzo=start.addDays(-7);   
    
    for (var i = 0; i < days; i++) {
      var day = comienzo.addDays(i);
      timeline.push({start: day.addHours(checkin), end: day.addDays(1).addHours(checkout), days:day.getDayOfWeek() });
    }        

    //console.log(timeline)
    return timeline;

    
  };

 getLocale=DayPilot.Locale.register(
  new DayPilot.Locale('en-us', 
  {
    'dayNames':['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'],
    'dayNamesShort':['D','L','M','X','J','V','S'],
    'monthNames':['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
    'monthNamesShort':['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'],
    'timePattern':'h:mm tt',
    'datePattern':'dd/MM/yyyy',
    'dateTimePattern':'dd/MM/yyyy h:mm tt',
    'timeFormat':'Clock24Hours',
    'weekStarts':0
  }
));
  
  //EDITAR LA HABITACION, PUEDO HACER QUE LLAME AL COMPONENTE DE EDICION DE HABITACIONES (DIALOG)
  menuRoom: DayPilot.Menu = new DayPilot.Menu({
    items: [
      {text: "Editar...", onClick: args => this.roomEdit.show(args.source).subscribe((params:UpdateRoomParams) => {
        if (params) {
          this.ds.updateRoom(params).subscribe(result => {
            let row = args.source;

            // update the client-side resource object
            /*row.data.strHabitacionNombre = result.name;
            row.data.strTipoHabitacionNombre = result.capacity;
            row.data.intEstadoLimpiezaId = result.status;*/

            row.data.name = result.name;
            row.data.capacity = result.capacity;
            //row.data.status = result.status;

            this.scheduler.control.message("Room updated.");
          });
        }
      })
      },
      //AQUI PUEDO HACER QUE DESACTIVE LA HABITACION CUANDO PULSEN ELIMINAR
      /*{text: "Inhabilitar...", onClick: args => {
        let row = args.source;
        this.ds.deleteRoom(row.id).subscribe(result => {
          row.remove();
          this.scheduler.control.message("Room deleted.");
        });
      } }*/
    ]
  });

  //dayConfig: DayPilot.Locale = new DayPilot.Locale();  

  config: any = {
    scale: "Manual",
    //timeline: this.getTimeline(),
    timeline: [],
    timeHeaders: [ { groupBy: "Month", format: " MMMM yyyy" }, { groupBy: "Day", format: "ddd d" } ],
    eventDeleteHandling: "Update",
    allowEventOverlap: false,
    cellWidthSpec: "Auto",    
    eventHeight: 70,
    rowHeaderColumns: [
      {title: "Habitación", width: 80},
      //{title: "Tipo", width: 80},
      //{title: "Limpieza", width: 80}
    ],
    contextMenuResource: this.menuRoom,
    onBeforeRowHeaderRender: args => {
      let limpieza = function(count) {
        //return count + " cama" + (count > 1 ? "s" : "");

        if(count===1){
          return "Sucia"
        }else if(count===2){
          return "En proceso"
        }else{
          return "Limpia"
        }
      };

      //AQUI CAMBIO CAPACITY POR TIPO DE HABITACION (SIMPLE, DOBLE, TRIPLE...)
      //Y CAMBIO STATUS POR LIMPIEZAID DE LA HABITACION (1,2,3)
      //args.row.columns[0].html = args.row.data.capacity;
      //args.row.columns[1].html = limpieza(args.row.data.status);

      var color = "";
      //switch (args.row.data.intEstadoLimpiezaId)
      switch (args.row.data.status) {
        case 3:
          color = "#00b300"
          break;
        case 1:
          color = "red";
          break;
        case 2:
          color = "#ffc14d";
          break;
      }


      // status
      /*args.row.columns[1].areas = [];
      args.row.columns[1].areas.push({
        right: 2,
        top: 2,
        bottom: 2,
        width: 5,
        backColor: color
      });*/

      /*args.row.columns[0].areas = [];
      args.row.columns[0].areas.push({
        right: 2,
        top: 2,
        bottom: 2,
        width: 5,
        backColor: color
      });*/

      // context menu icon

      //COMENTO ESTAS LINEAS DE COFIGO PORQUE LAS EDICIONES DE LAS HABITACIONES SE HARAN DESDE LA PESTAÑA DE MANTENIMIENTO
      /*args.row.areas = [];
      args.row.areas.push({
        top:3,
        right:12,
        visibility: "Hover",
        style: "font-size: 12px; background-color: #f9f9f9; border: 1px solid #ccc; padding: 2px 2px 0px 2px; cursor:pointer",
        icon: "icon-triangle-down",
        backColor:"grey",
        width:10,
        height:10,
        //image:"<img src='https://png.icons8.com/material-sharp/30/000000/menu-2.png'>",
        action: "ContextMenu"
      });*/

      args.row.areas.push({
        right: 2,
        top: 2,
        bottom: 2,
        width: 5,
        backColor: args.row.data.color
      });

      args.row.areas.push({
        left: 3,
        top: 25,
        bottom: 2,
        width: 5,
        html:args.row.data.capacity
      });      
    },
    onBeforeCellRender: args => {  
      //console.log(args)  
      //this.fechaHoy.value.setDate(this.fechaHoy.value.getDate()-1)  
      /*let miFecha= this.fechaHoy.value.toJSON();
      var midate = new Date();
          midate.setDate(midate.getDate() + 1);*/

          let miFecha =  new Date();
          let miFechaConvert=this.datepipe.transform(miFecha, 'yyyy-MM-dd');
    //console.log(this.datepipe.transform(miFecha, 'yyyy-MM-dd'))
      //let miOtraFecha=args.cell.start.toDateLocal().toLocaleDateString()

      let otraFecha=this.datepipe.transform(args.cell.start.value, 'yyyy-MM-dd')

      if (args.cell.start.getDayOfWeek() === 0 || args.cell.start.getDayOfWeek() === 6) {
        //args.cell.backColor = "#E9E9E9";
        args.cell.backColor = "#E2E2E2";
      }
      /*if(args.cell.start.getDayOfWeek() === 5){
        args.cell.backColor = "#D9D9D9";
      }*/

      if(otraFecha<miFechaConvert){
        //args.cell.disabled = true;
        //cambio de color las celdas anteriores a la fecha actual
        //args.cell.backColor = "#ccc";
        args.cell.backColor = "#8FA5B6";

        if (args.cell.start.getDayOfWeek() === 0 || args.cell.start.getDayOfWeek() === 6) {
          //args.cell.backColor = "#E9E9E9";
          args.cell.backColor = "#65839a";
        }
      }

    },
    onEventMoved: args => {
      let params: MoveReservationParams = {
        id: args.e.id(), //id_reserva
        start: args.newStart, //entrada
        end: args.newEnd, //salida
        room: args.newResource, //id_habitacion
        idCliente:args.e.data.intClienteId,
        strClienteNombre:args.e.data.strClienteNombre,
        strClienteApellidos:args.e.data.strClienteApellidos,
        intRegimenId:args.e.data.intRegimenId,
        regimenNombre:args.e.data.regimenNombre,
        strColor:args.e.data.strColor,
        strEstadoReserva:args.e.data.strEstado,
        intEstadoId:args.e.data.intEstadoId
      };
      //console.log(args);
      //console.log(params)
      //Esta funcion se ejecuta cuando se desplaza una reserva de dia o habitacion sin modificar la duracion de la reserva
      //Hace un UPDATE de la reserva pasando el id_reserva, inicio y fin de reserva y el id de la nueva habitacion
      // let miFecha =  new Date();
      // let miFechaConvert=this.datepipe.transform(miFecha, 'yyyy-MM-dd');    
      // let inicioFecha=this.datepipe.transform(params.start, 'yyyy-MM-dd');
      // let finFecha=this.datepipe.transform(params.end, 'yyyy-MM-dd');
      //if(inicioFecha>=miFechaConvert || finFecha>=miFechaConvert){
      //console.log(args.e.cache)
      //console.log(params)

      // if(args.e.cache.start!=args.newStart){
      //   if(args.e.cache.resource!=args.newResource){
      //     if(confirm('Si continuas vas a cambiar las fechas y habitación de la reserva...')){this.cambioReserva(params)}
      //   }else{
      //     if(confirm('¿Estas seguro que quieres cambiar las fechas de la reserva?')){this.cambioReserva(params)}
      //   }                  
      // }else{
      //   if(confirm('¿Quieres cambiar de habitación esta reserva?')){this.cambioReserva(params)}
      // }


      if(this.movH && this.movV){
        
        //no muestro nada porque no puede mover reservas

      }else if(this.movV){
       
        //esta habilitado el movimiento horizontal

        if(args.e.cache.start!=args.newStart){
          
          if(confirm('¿Quieres cambiar las fechas de la reserva?')){this.cambioReserva(params)}else{window.location.reload()}
                           
        }

      }else if(this.movH){

        //esta habilitado el movimiento vertical
        if(args.e.cache.resource!=args.newResource){
          if(confirm('¿Quieres cambiar de habitación esta reserva?')){this.cambioReserva(params)}else{window.location.reload()}
        }

      }else{
        //muestro todos los mensajes porque puede mover totalmente la reserva
        if(args.e.cache.start!=args.newStart){
          if(args.e.cache.resource!=args.newResource){
            if(confirm('Si continuas vas a cambiar las fechas y habitación de la reserva...')){this.cambioReserva(params)}else{window.location.reload()}
          }else{
            if(confirm('¿Quieres cambiar las fechas de la reserva?')){this.cambioReserva(params)}else{window.location.reload()}
          }                  
        }else{
          if(confirm('¿Quieres cambiar de habitación esta reserva?')){this.cambioReserva(params)}else{window.location.reload()}
        }
  
      }      
    },
    onEventResized: args => {
      let params: MoveReservationParams = {
        id: args.e.id(),
        start: args.newStart,
        end: args.newEnd,
        room: args.e.resource(),
        idCliente:args.e.data.intClienteId,
        strClienteNombre:args.e.data.strClienteNombre,
        strClienteApellidos:args.e.data.strClienteApellidos,
        intRegimenId:args.e.data.intRegimenId,
        regimenNombre:args.e.data.regimenNombre,
        strColor:args.e.data.strColor,
        strEstadoReserva:args.e.data.strEstado,
        intEstadoId:args.e.data.intEstadoId
      };
      //console.log(args);
      //Esta funcion se ejecuta cuando se MODIFICA la DURACION de la reserva
      //Hace un UPDATE de la reserva pasando el id_reserva, inicio y fin de reserva y el id de la nueva habitacion
      //if(params.start>=this.fechaHoy.value.toJSON() || params.end>=this.fechaHoy.value.toJSON()){
        this.ds.moveReservation(params).subscribe(result =>{
          //console.log(result)
          if(result.length!=0){
            this.scheduler.control.message("Cambio de reserva con éxito",{delay:3000,cssClass: "operacion_correcta"});

            // let myObject:any;
            // myObject={id:result.miListHabitacionLimpieza[0].intHabitacionId.toString(), name:result.miListHabitacionLimpieza[0].strHabitacionNombre, 
            //           capacity:result.miListHabitacionLimpieza[0].strTipoHabitacionNombre, 
            //           intTipoHabitacion:result.miListHabitacionLimpieza[0].intTipoHabitacionId, status:result.miListHabitacionLimpieza[0].intEstadoLimpiezaId}
            // let miTicket=result.miListHabitacionLimpieza[0].intHabitacionId.toString();        
            // var index = this.config.resources.map(x => {
            //   return x.id;
            // }).indexOf(miTicket);   
            this.config.resources.splice(0, this.config.resources.length);
            let lista:any[]=[];
            result.miListHabitacionLimpieza.forEach(element => {
              lista.push({id:element.intHabitacionId.toString(), name:element.strHabitacionNombre, capacity:element.strTipoHabitacionNombre, 
                          intTipoHabitacion:element.intTipoHabitacionId, status:element.intEstadoLimpiezaId, color:element.strColorEstadoLimpieza});
              //console.log(element)
            })
            this.config.resources=lista;  
          
            //this.config.resources.splice(index, 1, myObject);

          }else{
            this.scheduler.control.message("No se ha podido cambiar la reserva",{delay:3000,cssClass: "operacion_incorrecta"})
          }  
        });
      /*}/*else{
        //console.log(args.e.id())
        /*let data:any={
          id:args.e.id()
        }
        this.miservicio.getReservaById(data).pipe(first()).subscribe(datos=>{
          //this.miReserva=datos;
          //console.log(datos);

          if(datos.length!=0){
            var index = this.events.map(x => {
              return x.id;
            }).indexOf(data.id);      
            this.events.splice(index, 1);    
            //console.log(this.events)    
            datos.forEach(element => {
              this.events.push({id:element.intId, start:element.datEntrada, end:element.datSalida, intClienteId:element.intClienteId, strClienteNombre:element.strClienteNombre, strClienteApellidos:element.strClienteApellidos, resource:element.intHabitacionId.toString(), strEstadoReserva:element.strEstado, intRegimenId:element.intRegimenId, regimenNombre:element.strRegimenNombre});
            });   
            //console.log(this.events)  
          } 

        })*/
       /* setTimeout(() => {
          //this.openWindow(params.id);
        }, 100);
      }*/
      
    },
    onEventDeleted: args => {
      //Esta funcion se ejecuta cuando BORRA la reserva
      //Hace un delete pasando el id de reserva
      //console.log(args.e.id())
      if (window.confirm("¿Quieres borrar esta reserva?")) { 
        //window.open("exit.html", "Thanks for Visiting!");
        this.ds.deleteReservation(args.e.id()).subscribe(result => this.scheduler.control.message("Reservation deleted."));
      }else{
        window.location.reload()
      }      
    },
    onTimeRangeSelected: args => {
      //console.log(args)
      //Esta funcion se ejecuta cuando se CREA una reserva
      //Pasa el id de habitacion, inicio y fin de reserva y id de cliente
      //Por defecto el estado de la reserva es nueva y esta pagada al 0%
      //if(args.start.value>=this.fechaHoy.value.toJSON() || args.end.value>=this.fechaHoy.value.toJSON()){

        let dialogRef = this.dialog.open(NuevaReservaComponent, {
          width: '600px',
          data:{
            data:args,
            start:args.start.value,
            end:args.end.value,
          }
        });
        dialogRef.afterClosed().subscribe((data:any)=> {
          //console.log(data)

          if(data!=undefined){                 
            this.events.push({id:data.miListReserva[0].intId, intGrupoId:data.miListReserva[0].intGrupoId, start:data.miListReserva[0].datEntrada, end:data.miListReserva[0].datSalida, intClienteId:data.miListReserva[0].intClienteId, 
                              strClienteNombre:data.miListReserva[0].strClienteNombre, strClienteApellidos:data.miListReserva[0].strClienteApellidos, resource:data.miListReserva[0].intHabitacionId.toString(), 
                              strEstadoReserva:data.miListReserva[0].strEstado, intRegimenId:data.miListReserva[0].intRegimenId, regimenNombre:data.miListReserva[0].strRegimenNombre});

            let myObject:any;
            myObject={id:data.miListHabitacionLimpieza[0].intHabitacionId.toString(), name:data.miListHabitacionLimpieza[0].strHabitacionNombre, 
                      capacity:data.miListHabitacionLimpieza[0].strTipoHabitacionNombre, intTipoHabitacion:data.miListHabitacionLimpieza[0].intTipoHabitacionId, 
                      status:data.miListHabitacionLimpieza[0].intEstadoLimpiezaId, color:data.miListHabitacionLimpieza[0].strColorEstadoLimpieza}
            let miTicket=data.miListHabitacionLimpieza[0].intHabitacionId.toString();        
             var index = this.config.resources.map(x => {
               return x.id;
             }).indexOf(miTicket);   
            
            this.config.resources.splice(index, 1, myObject);
                              
            this.scheduler.control.message("Reserva insertada",{delay:3000,cssClass: "operacion_correcta"});              
          }else{
            this.scheduler.control.message("No se ha podido insertar la reserva",{delay:3000,cssClass: "operacion_incorrecta"})
          }

        });
        /*this.reservationCreate.show(args).subscribe(params => {
          
          this.scheduler.control.clearSelection();
          if (params) {
            this.ds.createReservation(params).subscribe((data:any) => {
              if(data.length!=0){                 
                this.events.push({id:data.miListReserva[0].intId, start:data.miListReserva[0].datEntrada, end:data.miListReserva[0].datSalida, intClienteId:data.miListReserva[0].intClienteId, 
                                  strClienteNombre:data.miListReserva[0].strClienteNombre, strClienteApellidos:data.miListReserva[0].strClienteApellidos, resource:data.miListReserva[0].intHabitacionId.toString(), 
                                  strEstadoReserva:data.miListReserva[0].strEstado, intRegimenId:data.miListReserva[0].intRegimenId, regimenNombre:data.miListReserva[0].strRegimenNombre});

                let myObject:any;
                myObject={id:data.miListHabitacionLimpieza[0].intHabitacionId.toString(), name:data.miListHabitacionLimpieza[0].strHabitacionNombre, 
                          capacity:data.miListHabitacionLimpieza[0].strTipoHabitacionNombre, intTipoHabitacion:data.miListHabitacionLimpieza[0].intTipoHabitacionId, 
                          status:data.miListHabitacionLimpieza[0].intEstadoLimpiezaId, color:data.miListHabitacionLimpieza[0].strColorEstadoLimpieza}
                let miTicket=data.miListHabitacionLimpieza[0].intHabitacionId.toString();        
                 var index = this.config.resources.map(x => {
                   return x.id;
                 }).indexOf(miTicket);   
                
                this.config.resources.splice(index, 1, myObject);
                                  
                this.scheduler.control.message("Reserva insertada",{delay:3000,cssClass: "operacion_correcta"});              
              }else{
                this.scheduler.control.message("No se ha podido insertar la reserva",{delay:3000,cssClass: "operacion_incorrecta"})
              } 
            });
          }
        });*/
    },
    onEventClick: args => {
      //alert();
      //args.e.rendered=true
      //console.log(args.e.data)
      //console.log(args.e.rendered)
      //console.log(this.events)

      if(args.e.rendered===null){
        args.e.rendered=true
      }

      let dialogRef = this.dialog.open(ModifyReservationEditComponent, {
        width: '600px',
        data:args.e.data,
      });
      dialogRef.afterClosed().subscribe((data:any)=> {
        //console.log(data)

        if(data!=undefined){
          if(data.length!=0){
            args.e.data.intClienteId=data.miListReserva[0].intClienteId;
            args.e.data.strClienteNombre = data.miListReserva[0].strClienteNombre;
            args.e.data.strClienteApellidos = data.miListReserva[0].strClienteApellidos;
            args.e.data.start = data.miListReserva[0].datEntrada;
            args.e.data.end = data.miListReserva[0].datSalida;
            //args.newResource=data.miListReserva[0].intHabitacionId.toString();
            args.e.data.resource=data.miListReserva[0].intHabitacionId.toString();
            //args.e.data.room=data.miListReserva[0].intHabitacionId.toString();
            args.e.data.strEstadoReserva=data.miListReserva[0].strEstado;
            args.e.data.intRegimenId=data.miListReserva[0].intRegimenId;
            args.e.data.regimenNombre=data.miListReserva[0].strRegimenNombre;
            args.e.data.strColor=data.miListReserva[0].strColor;
            //args.e.data.
  
            // let myObject:any;
            // //myObject=data.miListHabitacionLimpieza[0];    
            // myObject={id:data.miListHabitacionLimpieza[0].intHabitacionId.toString(), name:data.miListHabitacionLimpieza[0].strHabitacionNombre, 
            //           capacity:data.miListHabitacionLimpieza[0].strTipoHabitacionNombre, 
            //           intTipoHabitacion:data.miListHabitacionLimpieza[0].intTipoHabitacionId, status:data.miListHabitacionLimpieza[0].intEstadoLimpiezaId}
            // let miTicket=data.miListHabitacionLimpieza[0].intHabitacionId.toString();        
            //   var index = this.config.resources.map(x => {
            //     return x.id;
            //   }).indexOf(miTicket);   
            
            // this.config.resources.splice(index, 1, myObject);
  
            this.config.resources.splice(0, this.config.resources.length);
            let lista:any[]=[];
            data.miListHabitacionLimpieza.forEach(element => {
              lista.push({id:element.intHabitacionId.toString(), name:element.strHabitacionNombre, capacity:element.strTipoHabitacionNombre, 
                          intTipoHabitacion:element.intTipoHabitacionId, status:element.intEstadoLimpiezaId, color:element.strColorEstadoLimpieza});
            })
            this.config.resources=lista;
  
            this.scheduler.control.message("Cambio de reserva con éxito",{delay:3000,cssClass: "operacion_correcta"})
          }else{
            this.scheduler.control.message("No se ha podido cambiar la reserva",{delay:3000,cssClass: "operacion_incorrecta"})
          } 
        }
        
      })
/*
      this.reservationEdit.show(args.e).subscribe((params:UpdateReservationParams) => {
        //console.log(args)
        if (params) {
          this.ds.updateReservation(params).subscribe( (data: any) => {
            
            //args.e.data.status = data.status;
            //args.e.data.paid = data.paid;
            //console.log(data)
            if(data.length!=0){
              args.e.data.intClienteId=data.miListReserva[0].intClienteId;
              args.e.data.strClienteNombre = data.miListReserva[0].strClienteNombre;
              args.e.data.strClienteApellidos = data.miListReserva[0].strClienteApellidos;
              args.e.data.start = data.miListReserva[0].datEntrada;
              args.e.data.end = data.miListReserva[0].datSalida;
              //args.newResource=data.miListReserva[0].intHabitacionId.toString();
              args.e.data.resource=data.miListReserva[0].intHabitacionId.toString();
              //args.e.data.room=data.miListReserva[0].intHabitacionId.toString();
              args.e.data.strEstadoReserva=data.miListReserva[0].strEstado;
              args.e.data.intRegimenId=data.miListReserva[0].intRegimenId;
              args.e.data.regimenNombre=data.miListReserva[0].strRegimenNombre;
              //args.e.data.

              // let myObject:any;
              // //myObject=data.miListHabitacionLimpieza[0];    
              // myObject={id:data.miListHabitacionLimpieza[0].intHabitacionId.toString(), name:data.miListHabitacionLimpieza[0].strHabitacionNombre, 
              //           capacity:data.miListHabitacionLimpieza[0].strTipoHabitacionNombre, 
              //           intTipoHabitacion:data.miListHabitacionLimpieza[0].intTipoHabitacionId, status:data.miListHabitacionLimpieza[0].intEstadoLimpiezaId}
              // let miTicket=data.miListHabitacionLimpieza[0].intHabitacionId.toString();        
              //   var index = this.config.resources.map(x => {
              //     return x.id;
              //   }).indexOf(miTicket);   
              
              // this.config.resources.splice(index, 1, myObject);

              this.config.resources.splice(0, this.config.resources.length);
              let lista:any[]=[];
              data.miListHabitacionLimpieza.forEach(element => {
                lista.push({id:element.intHabitacionId.toString(), name:element.strHabitacionNombre, capacity:element.strTipoHabitacionNombre, 
                            intTipoHabitacion:element.intTipoHabitacionId, status:element.intEstadoLimpiezaId, color:element.strColorEstadoLimpieza});
              })
              this.config.resources=lista;

              this.scheduler.control.message("Cambio de reserva con éxito",{delay:3000,cssClass: "operacion_correcta"})
            }else{
              this.scheduler.control.message("No se ha podido cambiar la reserva",{delay:3000,cssClass: "operacion_incorrecta"})
            } 
            
            //this.scheduler.control.message("Reservation updated.");
          });
        }

      });*/
    },
    onEventDoubleClick: args => {
      
      this.reservasAgrupagas.push(args.e.data.id)
      //args.e.cache.borderColor="blue";
      //console.log(args.e)
      //this.scheduler.control.update(args.e);

      // let e = args.e;
      // e.cache.borderColor = "red";
      // this.scheduler.control.update(e);
    },
    onEventRightClick: args =>{
      //console.log(args)
      //alert('Quieres agrupar esta reserva')
    },    
    onBeforeEventRender: args => {      
      //para quitar la 'X' de la esquina superior dcha
      //de este modo se borraran las reservas unicamente al pinchar y en la ventana de editar seleccionen eliminar
      args.data.deleteDisabled = this.borraReserva;    
      args.data.doubleClickDisabled=false;  
      //args.data.backColor="white";  
      //this.micolor="green";
      //args.data.borderColor=this.micolor;

      
      //obtengo los datos de la configuraciones
      //para evitar que las reservas se desplacen horizontalmente
      //console.log(this.movV)
      args.data.moveHDisabled=this.movH;
      //para evitar que las reservas se desplacen verticalmente
      args.data.moveVDisabled=this.movV
      args.data.rightClickDisabled=false;

      //opcion de modificar la estancia
      //console.log(this.modificarEstancia)
      args.data.resizeDisabled=this.modificarEstancia;

      let start = new DayPilot.Date(args.data.start);
      let end = new DayPilot.Date(args.data.end);

      //console.log(start+" "+end)
      let now = new DayPilot.Date();
      let today = DayPilot.Date.today();
      //let today = new DayPilot.Date("2017-02-05");
      let strEstadoReserva = args.data.strEstadoReserva;     
      let regimen=args.data.regimenNombre

      // customize the reservation bar color and tooltip depending on status
      //console.log(args.data)
      // switch (args.e.strEstadoReserva) {
        // case "O":
        //   let in2days = today.addDays(1);

        //   if (start < in2days) {
        //     args.data.barColor = 'red';
        //     //args.data.moveDisabled=true;
        //     //args.data.resizeDisabled=true;
        //     status = 'Expired (not confirmed in time)';
        //   }
        //   else {
        //     args.data.barColor = '#0066cc';
        //     status = 'New';
        //   }
        //   break;
        /*case "Confirmed":
          let arrivalDeadline = today.addHours(18);

          if (start < today || (start === today && now > arrivalDeadline)) { // must arrive before 6 pm
            args.data.barColor = "#f41616";  // red
            status = 'Late arrival';
          }
          else {
            args.data.barColor = "green";
            status = "Confirmed";
          }
          break;
        case 'Arrived': // arrived
          let checkoutDeadline = today.addHours(10);

          if (end < today || (end === today && now > checkoutDeadline)) { // must checkout before 10 am
            args.data.barColor = "#f41616";  // red
            status = "Late checkout";
          }
          else
          {
            args.data.barColor = "#1691f4";  // blue
            status = "Arrived";
          }
          break;
        case 'CheckedOut': // checked out
          args.data.barColor = "gray";
          status = "Checked out";
          break;
        default:
          status = "Unexpected state";
          break;*/
      // }

      // customize the reservation HTML: text, start and end dates
      args.data.html = args.data.strClienteNombre + " " + args.data.strClienteApellidos + "<br> (" + start.toString("dd/MM") + " - " + end.toString("dd/MM") + ")" + "<br /><span style='color:gray'>" + strEstadoReserva + "</span><span style='float:right'>" + regimen + "</span>";

      // reservation tooltip that appears on hover - displays the status text
      args.e.toolTip = strEstadoReserva;
      args.data.barColor = args.data.strColor;

      // add a bar highlighting how much has been paid already (using an "active area")
      let paid = args.e.paid;
      let paidColor = "#aaaaaa";
      args.data.areas = [
        //{ bottom: 10, right: 4, html: "<div style='color:" + paidColor + "; font-size: 8pt;'>Pagada: " + paid + "%</div>", v: "Visible"},
        //{ left: 4, bottom: 8, right: 4, height: 2, html: "<div style='background-color:" + paidColor + "; height: 100%; width:" + paid + "%'></div>" }
      ];
      //console.log(args.e)
    },
    heightSpec: "Max100Pct",
    hideBorderFor100PctHeight: true
  
  };
  
  

  //puntoPartida:any;
  constructor(private ds: DataService, private router: Router,public dialog: MatDialog,private route: ActivatedRoute,
  private miservicio:ReservasService,public datepipe: DatePipe,private ServMant:MantenimientosService) {
    /*this.route.queryParams.subscribe(params => {
      this.puntoPartida=params["puntoPartida"];
    })*/   
    
  }

  // keyEvent(event: KeyboardEvent) {
  //   console.log(event);    
  // }

  cambioReserva(params){
    this.ds.moveReservation(params).subscribe(result =>{ 
      //console.log(result)
      if(result.length!=0){
        this.scheduler.control.message("Cambio de reserva con éxito",{delay:3000,cssClass: "operacion_correcta"});

        // let myObject:any;
        // //myObject=data.miListHabitacionLimpieza[0];    
        // myObject={id:result.miListHabitacionLimpieza[0].intHabitacionId.toString(), name:result.miListHabitacionLimpieza[0].strHabitacionNombre, 
        //           capacity:result.miListHabitacionLimpieza[0].strTipoHabitacionNombre, 
        //           intTipoHabitacion:result.miListHabitacionLimpieza[0].intTipoHabitacionId, status:result.miListHabitacionLimpieza[0].intEstadoLimpiezaId}
        // let miTicket=result.miListHabitacionLimpieza[0].intHabitacionId.toString();        
        //   var index = this.config.resources.map(x => {
        //     return x.id;
        //   }).indexOf(miTicket);   
        
        // this.config.resources.splice(index, 1, myObject);

        this.config.resources.splice(0, this.config.resources.length);
          let lista:any[]=[];
          result.miListHabitacionLimpieza.forEach(element => {
            lista.push({id:element.intHabitacionId.toString(), name:element.strHabitacionNombre, capacity:element.strTipoHabitacionNombre, 
                        intTipoHabitacion:element.intTipoHabitacionId, status:element.intEstadoLimpiezaId, color:element.strColorEstadoLimpieza});
            //console.log(element)
          })
          this.config.resources=lista;

      }else{
        this.scheduler.control.message("No se ha podido cambiar la reserva",{delay:3000,cssClass: "operacion_incorrecta"})
      }        
    });
  }  
  
  openWindow(){
    let dialogRef = this.dialog.open(VentanaErrorComponent, {
      width: '600px',
      // data:"Parece que ha ocurrido un error con las fechas seleccionadas..."
      data:{
        texto:"Parece que ha ocurrido un error con las fechas seleccionadas...",
        boton:false
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      /*if(result.length!=0){
        var index = this.events.map(x => {
          return x.id;
        }).indexOf(id);      
        this.events.splice(index, 1);    
        result.forEach(element => {
          this.events.push({id:element.intId, start:element.datEntrada, end:element.datSalida, intClienteId:element.intClienteId, strClienteNombre:element.strClienteNombre, strClienteApellidos:element.strClienteApellidos, resource:element.intHabitacionId.toString(), strEstadoReserva:element.strEstado, intRegimenId:element.intRegimenId, regimenNombre:element.strRegimenNombre});
        });   
      }    */   
    });
  }

  ngAfterViewInit(): void {      
    //console.log(this.config.timeHeaders)
    this.dateChange();
    this.ds.getRooms().subscribe((result:any) => {
      let lista:any[]=[];
       //this.config.resources = result  
       //console.log(result)        
       result.forEach(element => {
        lista.push({id:element.intHabitacionId.toString(), name:element.strHabitacionNombre, capacity:element.strTipoHabitacionNombre, 
                    intTipoHabitacion:element.intTipoHabitacionId, status:element.intEstadoLimpiezaId, color:element.strColorEstadoLimpieza});
        //console.log(element)
      })
      this.config.resources=lista;   
      // console.log(this.config.resources);
      // console.log(result[0].intHabitacionId.toString())

      //var index=this.config.resources.indexOf(result[0].intHabitacionId.toString());
      //  var index = this.config.resources.map(x => {
      //            // console.log(x)
      //              return x.id;
      //            }).indexOf(result[0].intHabitacionId.toString());  
      // console.log(index)
    });
    //console.log(this.config);    
    //this.ds.listar();
  }

  viewChange(args) {
    // quit if the date range hasn't changed
    if (!args.visibleRangeChanged) {
      return;
    }

    //let miListReserva:ReservationData[]=[];
    //let miReserva:any[]=[];

    //from es el dia 1 del mes
    //to es el dia 1 del mes siguiente
    let from = this.scheduler.control.visibleStart().addMonths(-1);
    let to = this.scheduler.control.visibleEnd().addMonths(1);
    this.ds.getReservations(from, to).subscribe(result => {
      //this.events = result;
      //console.log(result)
      if(result.length!=0){
        if(this.events.length!=0){ 
          this.events.splice(0, this.events.length)         
        }        
        result.forEach(element => {
          this.events.push({
            id:element.intId, 
            start:element.datEntrada, 
            end:element.datSalida, 
            intClienteId:element.intClienteId, 
            strClienteNombre:element.strClienteNombre, 
            strClienteApellidos:element.strClienteApellidos, 
            resource:element.intHabitacionId.toString(), 
            strEstadoReserva:element.strEstado, 
            intRegimenId:element.intRegimenId, 
            regimenNombre:element.strRegimenNombre,
            intGrupoId:element.intGrupoId,
            strTipo:element.strTipo,
            strColor:element.strColor,
            intEstadoId:element.intEstadoId
          });
          //this.events.push({id:element.intId, start:this.datepipe.transform(element.datEntrada, 'yyyy-MM-dd'), end:this.datepipe.transform(element.datSalida, 'yyyy-MM-dd'), intClienteId:element.intClienteId, strClienteNombre:element.strClienteNombre, strClienteApellidos:element.strClienteApellidos, resource:element.intHabitacionId.toString(), strEstadoReserva:element.strEstado, intRegimenId:element.intRegimenId, regimenNombre:element.strRegimenNombre});
          //console.log(element)
        });
      }      
      //console.log(this.events);
      //return this.events;
    });    
    /*this.ds.getReservations().subscribe(result => {
      this.events = result;
    });*/
  }

  dateChange() {
    this.config.timeline = this.getTimeline(this.navigator.control.selectionStart);
  }

  //AQUI LLAMARA AL COMPONENTE DE INSERCION DE HABITACION
  createRoom() {

    this.roomCreate.show().subscribe((params: CreateRoomParams) => {
      if (params) {
        this.ds.createRoom(params).subscribe(room => {
          this.config.resources.push(room);
          this.scheduler.control.message("Room created.");
        });
      }
    });

  }

  agrupaReservas(){
    //console.log('ok')
    //console.log(this.reservasAgrupagas)
    //console.log(this.scheduler.control.visibleStart().addMonths(-1))

    let dialogRef = this.dialog.open(AgruparReservasComponent, {
      width: '600px',
      data:{
        inicio:this.scheduler.control.visibleStart().addMonths(-1),
        fin: this.scheduler.control.visibleEnd().addMonths(1),
      }      
    });
    dialogRef.afterClosed().subscribe((data:any)=> {
      //console.log(data)
    })
  }

  ngOnInit() {
    //this.cargaReservas();
  }

  ngOnChanges(){
    //this.cargaReservas();
  }

  cargaReservas(){
    let from = this.scheduler.control.visibleStart().addMonths(-1);
    let to = this.scheduler.control.visibleEnd().addMonths(1);

    this.ds.getReservations(from, to).subscribe(result => {
      //console.log(result)
      if(result.length!=0){
        if(this.events.length!=0){ 
          this.events.splice(0, this.events.length)         
        }        
        result.forEach(element => {
          //console.log(element)
          this.events.push({id:element.intId, start:element.datEntrada, end:element.datSalida, intClienteId:element.intClienteId, strClienteNombre:element.strClienteNombre, 
                            strClienteApellidos:element.strClienteApellidos, resource:element.intHabitacionId.toString(), strEstadoReserva:element.strEstado, 
                            intRegimenId:element.intRegimenId, regimenNombre:element.strRegimenNombre, strColor:element.strColor});
        });
      }      
    });  
  }

}
