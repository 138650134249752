import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { MantenimientosService } from 'src/app/core/services/mantenimientos.service';

@Component({
  selector: 'app-detalle-tarifa',
  templateUrl: './detalle-tarifa.component.html',
  styleUrls: ['./detalle-tarifa.component.scss']
})
export class DetalleTarifaComponent implements OnInit {

  miDetalleTarifa:DetalleTarifa=new DetalleTarifa();

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: DetalleTarifa,private miservicio:MantenimientosService) { 
    this.miDetalleTarifa=this.data;
  }

  ngOnInit() {
    //console.log(this.data);
    //console.log(this.miDetalleTarifa)
  }

  cancelar(){
    this.thisDialogRef.close();
  }

  salvar(){
    //console.log(this.miDetalleTarifa);
    this.miservicio.actualizaTarifaHabitacion(this.miDetalleTarifa).subscribe(datos=>{
      //console.log(datos);
      if(datos.length>0){
        this.thisDialogRef.close(datos);
      }
    })
  }

}

export class DetalleTarifa{

  public datFecha:Date;
  public decPrecio:number;
  public intTipoHabitacionId:number;
  public intTipoRegimenId:number;
  public strTipoHabitacionNombre:string;
  public strTipoRegimenNombre:string;

}
