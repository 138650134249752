import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, NavigationExtras } from '@angular/router';
import { DataService } from '../hotel/service/data.service';
import { ReservasService } from '../core/services/reservas.service';
import { first } from 'rxjs/operators';
import { Reserva } from '../core/models/reserva';

@Component({
  selector: 'app-ventana-error',
  templateUrl: './ventana-error.component.html',
  styleUrls: ['./ventana-error.component.scss']
})
export class VentanaErrorComponent implements OnInit {

  puntoPartida:any;
  miReserva:Reserva[]=[];
  dosAcciones:boolean=false;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>,private ds: ReservasService,@Inject(MAT_DIALOG_DATA) public data: miEstructura) { }

  ngOnInit() {
    //console.log(this.data);
    //this.recuperaReserva();
    this.dosAcciones=this.data.boton;
  }

  pulsa(){
    this.thisDialogRef.close('delete');
  }  

  pulsa2(){
    this.thisDialogRef.close();
  } 
}

export interface miEstructura {
  texto:any;
  boton:any;
}
