import { Component, OnInit, Inject } from '@angular/core';
import { Articulo } from 'src/app/core/models/articulo';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { MantenimientosService } from 'src/app/core/services/mantenimientos.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Iva } from 'src/app/core/models/iva';

@Component({
  selector: 'app-edita-articulo',
  templateUrl: './edita-articulo.component.html',
  styleUrls: ['./edita-articulo.component.scss']
})
export class EditaArticuloComponent implements OnInit {

  nuevoArticulo:Articulo=new Articulo();
  formNew: FormGroup;
  submitted:boolean=false;
  isChecked:boolean=false;
  Estado:string;
  existError:boolean=false;
  mensajeError:string;
  listIva:Iva[]=[];

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: Articulo,private _formBuilder: FormBuilder,
  private miServicio:MantenimientosService) { }


  ngOnInit() {
    this.formNew = this._formBuilder.group({
      nombre: ['', Validators.required],
      descripcion: [''],
      pv: [''],
      iva: [''],
      pvp: ['', Validators.required],
      dto: [''],
      activo: ['']
    })

    this.formNew.get('pv').disable();
    this.listarDatos();
    this.listarIva();
  }

  listarDatos(){
    this.miServicio.getArticulos(this.data.intId).subscribe(datos=>{
      //console.log(datos)

      if(datos[0].strActivo==='S'){
        this.isChecked=true;
        this.Estado='Activo'
      }else{
        this.isChecked=false;
        this.Estado='Inactivo'
      }

      this.nuevoArticulo=datos[0];
      //console.log(this.nuevoArticulo)
    })
  }

  listarIva(){
    this.miServicio.getIva(0).subscribe(datos=>{
      //console.log(datos)
      this.listIva=datos.filter(e=>e.strActivo==="S");
      //console.log(this.listIva)
    })
  }

  pulsa(){
    if(this.isChecked){
      this.Estado='Inactivo';
    }else{
      this.Estado="Activo";
    }
  }

  salvar(){
    if(this.isChecked===true){
      this.nuevoArticulo.strActivo='S';
    }else{
      this.nuevoArticulo.strActivo="N";
    } 
    //console.log(this.nuevoArticulo)

    this.miServicio.actualizaArticulo(this.nuevoArticulo).subscribe((datos:any)=>{
      //console.log(datos)
      if(datos.miRespuesta.booOk){
        this.thisDialogRef.close(datos.miListArticulo)
      }else{
        this.existError=true;
        this.mensajeError=datos.miRespuesta.strMensaje;
      }
    })
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
