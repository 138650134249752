import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MantenimientosService } from 'src/app/core/services/mantenimientos.service';
import { Iva } from 'src/app/core/models/iva';

@Component({
  selector: 'app-nuevo-iva',
  templateUrl: './nuevo-iva.component.html',
  styleUrls: ['./nuevo-iva.component.scss']
})
export class NuevoIvaComponent implements OnInit {

  miIva:Iva=new Iva();
  formNew: FormGroup;
  submitted:boolean=false;
  existError:boolean=false;
  mensajeError:string;
  isChecked:boolean;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, private _formBuilder: FormBuilder,private miServicio:MantenimientosService) { }

  ngOnInit() {
    this.formNew = this._formBuilder.group({
      nombre: ['', Validators.required],
      /*valor: new FormControl('', Validators.compose([
        Validators.pattern('^[0-9]{1,6}[.,]?[0-9]{1,6}$'),
      ])),   */  
      valor: ['', Validators.required], 
      estado: ['']
    })
  }

  cancelar(){
    this.thisDialogRef.close();
  }

  salvar(){
    this.submitted = true; 
    if(this.isChecked){
      this.miIva.strActivo="S"
    }else{
      this.miIva.strActivo="N"
    }
    
    if(this.formNew.valid){
      //console.log(this.miIva)

      this.miServicio.insertaIva(this.miIva).subscribe((datos:any)=>{
        //console.log(datos)
        if(datos.miRespuesta.booOk){
          this.thisDialogRef.close(datos.miListIva);
        }else{
          this.mensajeError=datos.miRespuesta.strMensaje;
          this.existError=true;
        }
      })

      /*this.miServicio.postCliente(this.miIva).subscribe((datos:any)=>{       
        //console.log(datos)
        if(datos.miRespuesta.booOk){
          this.thisDialogRef.close(datos.miListCliente);
        }else{
          this.mensajeError=datos.miRespuesta.strMensaje;
          this.existError=true;
        }
      }) */  

    }
  }

}
