export class Empleado {

    public intId?:number;
    public strNombre?:string;
    public strApellidos?:string;
    public strDni?:string;
    public strEmail?:string;
    public strTelefono1?:string;
    public strTelefono2?:string;
    public strDireccion?:string;
    public strCP?:string;
    public strProvincia?:string;
    public strPoblacion?:string;
    public strPais?:string;
    public datFechaRegistro?:Date;
    public datFechaActualizacion?:Date;
    public strActivo?:string;
    public strUserId?:string;
    public strUserName?:string;
    public strPassword?:string;

}
