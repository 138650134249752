import { Component } from "@angular/core";

@Component({
  selector: 'sidebar-main',
  styles: [`  `],
  template: `
  <ng-content></ng-content>
`,
})
export class SidebarMainComponent {
}

