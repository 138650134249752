import { Component, OnInit } from '@angular/core';

import { StorageService } from '../core/services/storage.service';
import { User } from '../core/models/user.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public loginUserData: User;
  showSpinner: boolean = false;

  constructor(
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.loginUserData = this.storageService.getCurrentUser();
    /** Me dice si es administrador o trabajador **/
    //console.log(this.loginUserData.strRolName);
  }

  public logout(): void{
    this.showSpinner = true;

          setTimeout(() => {
            this.showSpinner = false;
            this.storageService.logout();
          }, 2000);  
  }

}
