import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSlideToggleChange} from '@angular/material';
import { Cliente } from '../../../core/models/cliente';
import { ReservasService } from '../../../core/services/reservas.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-busca-cliente',
  templateUrl: './busca-cliente.component.html',
  styleUrls: ['./busca-cliente.component.scss']
})
export class BuscaClienteComponent implements OnInit {

  listaClientes:Cliente[]=[];
  selectCliente:any;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: Cliente,
  private miservicioReservas:ReservasService) { }

  ngOnInit() {
    this.listarClientes();
  }

  listarClientes(){
    this.miservicioReservas.getClientes().pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.listaClientes=datos;
      //console.log(this.listaClientes)
    })
  }

  selecciona(){
    //console.log(this.selectCliente)
    this.miservicioReservas.getUnicoCliente(this.selectCliente).pipe(first()).subscribe(datos=>{
      //console.log(datos)
      this.thisDialogRef.close(datos)
    })
    
  }

}
