import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthorizatedGuard } from './core/guards/authorizated.guard';
import { ListadoComponent } from './listado/listado.component';
import { LoginRecuperacionComponent } from './login-recuperacion/login-recuperacion.component';
import { EditarComponent } from './editar/editar.component';
import { InformesComponent } from './informes/informes.component';
import { HabitacionAsignadaComponent } from './habitacion-asignada/habitacion-asignada.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { HabitacionesComponent } from './habitaciones/habitaciones.component';
import { UsuariosComponent } from './usuarios/usuarios.component';

import { EdicionHabitacionesComponent } from './mantenimientos/editar/edicion-habitaciones/edicion-habitaciones.component';
import { EdicionUsuariosComponent } from './mantenimientos/editar/edicion-usuarios/edicion-usuarios.component';
import { InsertarHabitacionesComponent } from './mantenimientos/insertar/insertar-habitaciones/insertar-habitaciones.component';
import { InsertarUsuariosComponent } from './mantenimientos/insertar/insertar-usuarios/insertar-usuarios.component';
import { AsignacionesComponent } from './asignaciones/asignaciones.component';
import { EmpleadoComponent } from './asignaciones/empleado/empleado.component';
import { AuthAdminGuard } from './core/guards/auth-admin.guard';
import { AuthTrabajadorGuard } from './core/guards/auth-trabajador.guard';
import { ReservasComponent } from './reservas/reservas.component';
import { ClientesComponent } from './clientes/clientes.component';
import { EdicionClientesComponent } from './clientes/edicion-clientes/edicion-clientes.component';
import { LayoutComponent } from './layout/layout.component';
import { InicioComponent } from './inicio/inicio.component';
import { InsertaReservaComponent } from './reservas/inserta-reserva/inserta-reserva.component';
import { InsertaClientesComponent } from './clientes/inserta-clientes/inserta-clientes.component';
import { ChangePasswordComponent } from './usuarios/change-password/change-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HotelComponent } from './hotel/hotel.component';
import { MisReservasComponent } from './hotel/mis-reservas/mis-reservas.component';
import { ConfiguracionesComponent } from './configuraciones/configuraciones.component';
import { BuscaClienteComponent } from './hotel/componentes/busca-cliente/busca-cliente.component';
import { VentanaErrorComponent } from './ventana-error/ventana-error.component';
import { MantenimientoEstadosLimpiezaComponent } from './mantenimiento-estados-limpieza/mantenimiento-estados-limpieza.component';
import { MantenimientoTiposHabitacionComponent } from './mantenimiento-tipos-habitacion/mantenimiento-tipos-habitacion.component';
import { MantenimientoRegimenComponent } from './mantenimiento-regimen/mantenimiento-regimen.component';
import { InsertarTipoHabitacionComponent } from './mantenimientos/insertar/insertar-tipo-habitacion/insertar-tipo-habitacion.component';
import { InsertarRegimenComponent } from './mantenimientos/insertar/insertar-regimen/insertar-regimen.component';
import { EdicionTipoHabitacionComponent } from './mantenimientos/editar/edicion-tipo-habitacion/edicion-tipo-habitacion.component';
import { EdicionRegimenComponent } from './mantenimientos/editar/edicion-regimen/edicion-regimen.component';
import { TarifasComponent } from './tarifas/tarifas.component';
import { DetalleTarifaComponent } from './tarifas/detalle-tarifa/detalle-tarifa.component';
import { APIResolver } from './core/models/resolve/apiresolver';
import { ModifyReservationEditComponent } from './hotel/componentes/modify-reservation-edit/modify-reservation-edit.component';
import { AgregaHuespedComponent } from './hotel/componentes-auxiliares/agrega-huesped/agrega-huesped.component';
import { Demo4Component } from './demo4/demo4.component';
import { FacturasComponent } from './facturas/facturas.component';
import { MantenimientoArticulosComponent } from './mantenimiento-articulos/mantenimiento-articulos.component';
import { MantenimientoIvaComponent } from './mantenimiento-iva/mantenimiento-iva.component';
import { MantenimientoFormaPagoComponent } from './mantenimiento-forma-pago/mantenimiento-forma-pago.component';
import { NuevoArticuloComponent } from './mantenimiento-articulos/nuevo-articulo/nuevo-articulo.component';
import { EditaArticuloComponent } from './mantenimiento-articulos/edita-articulo/edita-articulo.component';
import { EditaIvaComponent } from './mantenimiento-iva/edita-iva/edita-iva.component';
import { NuevoIvaComponent } from './mantenimiento-iva/nuevo-iva/nuevo-iva.component';
import { EditaFormapagoComponent } from './mantenimiento-forma-pago/edita-formapago/edita-formapago.component';
import { NuevoFormapagoComponent } from './mantenimiento-forma-pago/nuevo-formapago/nuevo-formapago.component';
import { InsertaTarifaComponent } from './tarifas/inserta-tarifa/inserta-tarifa.component';
import { NuevaReservaComponent } from './hotel/componentes/nueva-reserva/nueva-reserva.component';
import { AgregaHuespedNuevaReservaComponent } from './hotel/componentes-auxiliares/agrega-huesped-nueva-reserva/agrega-huesped-nueva-reserva.component';
import { AgruparReservasComponent } from './hotel/componentes-auxiliares/agrupar-reservas/agrupar-reservas.component';
import { NuevoGastoComponent } from './hotel/componentes-auxiliares/nuevo-gasto/nuevo-gasto.component';
import { ListaArticuloComponent } from './hotel/componentes-auxiliares/lista-articulo/lista-articulo.component';
import { HabitacionesReservaComponent } from './hotel/componentes-auxiliares/habitaciones-reserva/habitaciones-reserva.component';
import { EditaGastoComponent } from './hotel/componentes-auxiliares/edita-gasto/edita-gasto.component';
import { ReservaPagoComponent } from './hotel/componentes-auxiliares/reserva-pago/reserva-pago.component';
import { ListaFormaPagoComponent } from './hotel/componentes-auxiliares/lista-forma-pago/lista-forma-pago.component';
import { EditaHuespedComponent } from './hotel/componentes-auxiliares/edita-huesped/edita-huesped.component';
import { TitularFacturaComponent } from './hotel/componentes-auxiliares/titular-factura/titular-factura.component';
import { EditaHabitacionReservaComponent } from './hotel/componentes-auxiliares/edita-habitacion-reserva/edita-habitacion-reserva.component';
//import { EditaClientesComponent } from './clientes/edita-clientes/edita-clientes.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [ AuthorizatedGuard ] },

  /****************************************** Estos son los componentes comunes para admin y trabajador ****************************************/

  { path: 'listado', component: ListadoComponent, canActivate: [ AuthorizatedGuard ], },
  { path: 'editar', component: EditarComponent, canActivate: [ AuthorizatedGuard ] },
  { path: 'informes', component: InformesComponent, canActivate: [ AuthorizatedGuard ] },

  /****************************************** Estos son los componentes del admin ****************************************/

  { path: 'habitaciones', component: HabitacionesComponent, canActivate: [ AuthAdminGuard ] },  
  { path: 'mantenimientos/editar/edicion-habitaciones', component: EdicionHabitacionesComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'mantenimientos/editar/edicion-usuarios', component: EdicionUsuariosComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'mantenimientos/editar/edicion-tipo-habitacion', component: EdicionTipoHabitacionComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'mantenimientos/editar/edicion-regimen', component: EdicionRegimenComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'mantenimientos/insertar/insertar-habitaciones', component: InsertarHabitacionesComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'mantenimientos/insertar/insertar-usuarios', component: InsertarUsuariosComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'mantenimientos/insertar/insertar-tipo-habitacion', component: InsertarTipoHabitacionComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'mantenimientos/insertar/insertar-regimen', component: InsertarRegimenComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'edicion-clientes', component: EdicionClientesComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'asignaciones', component: AsignacionesComponent, canActivate: [ AuthAdminGuard ] },
  //{ path: 'asignaciones/:dato', component: AsignacionesComponent, resolve: { items: APIResolver }  },
  { path: 'asignaciones/empleado', component: EmpleadoComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'usuarios', component: UsuariosComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'reservas', component: ReservasComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'inserta-reserva', component: InsertaReservaComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'clientes', component: ClientesComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'inserta-clientes', component: InsertaClientesComponent, canActivate: [ AuthAdminGuard ] },
  //{ path: 'edita-clientes', component: EditaClientesComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'hotel', component: HotelComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'mis-reservas', component: MisReservasComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'configuraciones', component: ConfiguracionesComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'busca-cliente', component: BuscaClienteComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'estadoslimpieza', component: MantenimientoEstadosLimpiezaComponent, canActivate: [ AuthAdminGuard ] },

  { path: 'tipohabitacion', component: MantenimientoTiposHabitacionComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'tiporegimen', component: MantenimientoRegimenComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'tarifas', component: TarifasComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'detalle-tarifa', component: DetalleTarifaComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'editar-reserva', component: ModifyReservationEditComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'nuevo-reserva', component: NuevaReservaComponent, canActivate: [ AuthAdminGuard ] },

  { path: 'facturas', component: FacturasComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'articulos', component: MantenimientoArticulosComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'iva', component: MantenimientoIvaComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'formas-pago', component: MantenimientoFormaPagoComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'nuevo-articulo', component: NuevoArticuloComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'edita-articulo', component: EditaArticuloComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'nuevo-iva', component: NuevoIvaComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'edita-iva', component: EditaIvaComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'nuevo-formaPago', component: NuevoFormapagoComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'edita-formaPago', component: EditaFormapagoComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'nueva-tarifa', component: InsertaTarifaComponent, canActivate: [ AuthAdminGuard ] },

  //COMPONENTES AUXILIARES RESERVA
  { path: 'nuevo-huesped', component: AgregaHuespedComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'nuevo-huesped-reserva', component: AgregaHuespedNuevaReservaComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'edita-huesped-reserva', component: EditaHuespedComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'agrupa-reserva', component: AgruparReservasComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'agrupa-gasto-reserva', component: NuevoGastoComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'agrupa-articulo-gasto', component: ListaArticuloComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'habitaciones-reserva', component: HabitacionesReservaComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'edita-gasto', component: EditaGastoComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'reserva-pago', component: ReservaPagoComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'lista-forma-pago', component: ListaFormaPagoComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'titular-factura', component: TitularFacturaComponent, canActivate: [ AuthAdminGuard ] },
  { path: 'edita-habitacion-reserva', component: EditaHabitacionReservaComponent, canActivate: [ AuthAdminGuard ] },

  /****************************************** Estos son los componentes del empleado ****************************************/

  { path: 'demo4', component: Demo4Component },
  
  { path: 'habitacion-asignada', component: HabitacionAsignadaComponent, canActivate: [ AuthTrabajadorGuard ] },  
  
  { path: 'login', component: LoginComponent },
  { path: 'inicio', component: InicioComponent },
  { path: 'ventana-error', component: VentanaErrorComponent },
  //{ path: 'toolbar', component: ToolbarComponent },
  { path: 'sidemenu', component: SidemenuComponent },
  { path: 'layout', component: LayoutComponent },
  //{ path: 'header', component: HeaderComponent },
  { path: 'login-recuperacion', component: LoginRecuperacionComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: '', redirectTo: '/listado', pathMatch: 'full' },
  { path: '**', redirectTo: '/listado'},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
