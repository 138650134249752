import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {DayPilot} from 'daypilot-pro-angular';
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map, first} from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { StorageService } from '../../core/services/storage.service';
import { Habitacion } from '../../core/models/habitacion';
import { Cliente } from '../../core/models/cliente';
import { HabitacionLimpieza } from '../../core/models/habitacion-limpieza';
import { HabitacionService } from '../../core/services/habitacion.service';
import { FormControl } from '@angular/forms';
import { ReservasService } from '../../core/services/reservas.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  user:Cliente=new Cliente();

  listHabitacionLimpieza:any[]=[];
  listHabitacionLimpieza2: Observable<HabitacionLimpieza[]>;
  date = new FormControl(new Date());
/*
  RoomData:any[]=[
  {id:1, name:"101", capacity:"Doble", status:1},
  {id:2, name:"102", capacity:"simple", status:3},  
  {id:3, name:"103", capacity:"Triple", status:1},
  {id:4, name:"104", capacity:"Doble", status:2},
  {id:5, name:"105", capacity:"Simple", status:3},
  {id:6, name:"106", capacity:"Doble", status:1}
  ]
*/
 
  //RoomData:RoomData[]=[];

  /*User:any[]=[
    {id:1, name:"carol", password:"Infitec1*"},
    {id:2, name:"lucas", password:"Infitec1*"},
    {id:3, name:"fernando", password:"Infitec1*"},
    {id:4, name:"juan", password:"Infitec1*"},
    {id:5, name:"lidia", password:"Infitec1*"},
    {id:6, name:"gema", password:"Infitec1*"}
  ]*/
/*
  ReservationData:any[]=[
    {id:471, start:"2018-09-20T00:00:00", end:"2018-09-22T00:00:00", strClienteNombre:"Juan carlos", strClienteApellidos:"rojas perez", resource:"2", strEstadoReserva:"O"},
    {id:470, start:"2018-09-25T00:00:00", end:"2018-09-28T00:00:00", strClienteNombre:"Juan carlos", strClienteApellidos:"rojas perez", resource:"2", strEstadoReserva:"O"},
    //{id:2, start:"2018-09-03", end:"2018-09-05", strClienteNombre:"Gema", strClienteApellidos:"Sanchez",  resource:"3", strEstadoReserva:"confirmada", paid:100},
    //{id:3, start:"2018-09-06", end:"2018-09-10", strClienteNombre:"Lidia", strClienteApellidos:"Lopez", resource:"4", strEstadoReserva:"cancelada", paid:20},
    //{id:4, start:"2018-09-15", end:"2018-09-17", strClienteNombre:"Juan", strClienteApellidos:"Ruiz",  resource:"6", strEstadoReserva:"cancelada", paid:50},
    //{id:5, start:"2018-09-22", end:"2018-09-27", strClienteNombre:"Lucas", strClienteApellidos:"Sainz", resource:"5", strEstadoReserva:"confirmada", paid:100},
    //{id:6, start:"2018-09-22", end:"2018-10-05", strClienteNombre:"Lucas", strClienteApellidos:"Sainz", resource:"2", strEstadoReserva:"confirmada", paid:100}

  ]
*/
  misReservas:any[]=[];


  constructor(private http : HttpClient, private router: Router,private storageService: StorageService,
    private miservicio:HabitacionService,private miservicio2:ReservasService){
  }

  public url: string = environment.baseUrl;

  private token=this.storageService.getCurrentToken();
  private cookie=this.storageService.getCurrentSession().strCookie;
  //private userId=this.storageService.getCurrentUser().strUserId;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + this.token  })
  };

  getUser(): Observable<any> {
    let dato='strCookie='+this.cookie;
    let urlCompleta=this.url+'/api/cliente?'+dato;
 
    return this.http.get<any>(urlCompleta,this.httpOptions);

   /* return new Observable(observer => {
      setTimeout(() => {
        observer.next(this.user);
      }, 200);
    });*/
  }

  /*doLogin(user:{username: string, password: string}): Observable<any> {
    return this.http.post("/api/backend_login.php", user);
  }

  isLoggedIn() {
    return !!localStorage.getItem("user");
  }

  logout() {
    localStorage.removeItem("user");
    this.router.navigate(["/login"]);
  }*/

  getReservations(from: DayPilot.Date, to: DayPilot.Date): Observable<any[]> {

    //return new Observable(obs=>obs.next(this.ReservationData));

    return new Observable(observer => {
      this.miservicio2.getReserva(from,to,"").pipe(first()).subscribe(datos=>{
        this.misReservas=datos;
        //console.log(this.misReservas)
        observer.next(this.misReservas);
      })
    });

    //return this.http.post("/api/backend_reservations.php", {start: from.toString(), end: to.toString()}) as Observable<any>;
   
  }

  createReservation(data: CreateReservationParams): Observable<ReservationData> {
    /*return this.http.post("/api/backend_reservation_create.php", data).pipe(map((response:any) => {
      return {
        id: response.id,
        start: data.start,
        end: data.end,
        resource: data.room,
        strClienteNombre: data.strClienteNombre,
        strClienteApellidos: data.strClienteApellidos,
        strEstadoReserva: "New",
        //paid: "0",
        intRegimenId:data.intRegimenId
      };
    }));*/
    return this.miservicio2.postReserva(data).pipe(first());
  }

  deleteReservation(id: string): Observable<any> {
    return this.http.post("/api/backend_reservation_delete.php", {id: id});
  }

  updateReservation(params: any): Observable<any> {
    /*return this.http.post("/api/backend_reservation_update.php", params).pipe(map((response:any) => {
      return {
        id: params.id,
        start: params.start,
        end: params.end,
        strClienteNombre: params.strClienteNombre,
        strClienteApellidos: params.strClienteApellidos,
        resource: params.room,
        strEstadoReserva: params.strEstadoReserva,
        //paid: params.paid,
        intRegimenId:params.intRegimenId,
        regimenNombre:params.regimenNombre
      };
    }));*/
    return this.miservicio2.putReserva(params).pipe(first());
  }

  moveReservation(params: MoveReservationParams): Observable<any> {
    //return this.http.post("/api/backend_reservation_move.php", params);
    return this.miservicio2.moveReserva(params).pipe(first());
  }

  listar(){
    this.miservicio.getHabLimpiezas(this.date.value.toLocaleDateString(),'','').pipe(first()).subscribe(datos=>{
      this.listHabitacionLimpieza=datos;
      //console.log(this.listHabitacionLimpieza)
    })
  }

  getRooms(): Observable<any[]> {
    //return this.http.post("/api/backend_rooms.php", {capacity: 0}) as Observable<any>;

    return new Observable(observer => {
      this.miservicio.getHabLimpiezas(this.date.value.toLocaleDateString(),'','').pipe(first()).subscribe(datos=>{
        this.listHabitacionLimpieza=datos;
        //console.log(this.listHabitacionLimpieza)
        observer.next(this.listHabitacionLimpieza);
      })
    });

    /*return new Observable(observer => {
        observer.next(this.RoomData);
    });*/
  }

  createRoom(params: CreateRoomParams): Observable<RoomData> {
    return this.http.post("/api/backend_room_create.php", params).pipe(map((response:any) => {
      return {
        name: params.name,
        capacity: params.capacity,
        intTipoHabitacion:params.intTipoHabitacion,
        status: 3,
        id: response.id,
        color:response.color
      };
    }));
  }

  /*updateRoom(params: UpdateRoomParams): Observable<RoomData> {
    return this.http.post("/api/backend_room_update.php", params).pipe(map((response:any) => {
      return {
        id: params.id,
        name: params.name,
        capacity: params.capacity,
        status: params.status
      };
    }));
  }*/

  updateRoom(params: UpdateRoomParams): Observable<UpdateRoomParams> {
    return this.http.post("/api/backend_room_update.php", params).pipe(map((response:any) => {
      return {
        id: params.id,
        name: params.name,
        capacity: params.capacity,
        intTipoHabitacion:params.intTipoHabitacion
      };
    }));
  }

  deleteRoom(id: string): Observable<any> {
    return this.http.post("/api/backend_room_delete.php", {id: id});
  }  

}

export interface CreateReservationParams {
  start: string;
  end: string;
  strClienteNombre: string;
  strClienteApellidos: string;
  intClienteId:number | string;
  room: string | number;
  intRegimenId:any;
  strColor:any;
  strEstadoReserva: string;
}

export interface UpdateReservationParams {
  id: string;
  start: DayPilot.Date;
  end: DayPilot.Date;
  room: string;
  intClienteId:any;
  strClienteNombre: string;
  strClienteApellidos: string;
  intEstadoId:number;
  strEstadoReserva: string;
  intRegimenId:number;
  regimenNombre:string;
  strColor:any;
  //paid: number | string;
}

export interface MoveReservationParams {
  id: string;
  start: DayPilot.Date;
  end: DayPilot.Date;
  room: string;
  idCliente:any;
  strClienteNombre: string;
  strClienteApellidos: string;
  intRegimenId:number;
  regimenNombre:string;
  intEstadoId:number;
  strEstadoReserva: string;
  strColor:any;
}

export interface CreateRoomParams {
  name: string;
  capacity: string;
  intTipoHabitacion:any;
}

export interface UpdateRoomParams {
  id: string;
  name: string;
  capacity: any;
  intTipoHabitacion:any;
  //status: number;
}

export interface ReservationData {
  id: string | number;
  start: string | DayPilot.Date;
  end: string | DayPilot.Date;
  intClienteId:any;
  strClienteNombre: string;
  strClienteApellidos: string;
  resource: string | number;
  intEstadoId:number;
  strEstadoReserva: string;
  intRegimenId:number;
  regimenNombre:string;
  intGrupoId:any;
  strTipo:any;
  strColor:any;
  //paid: number | string;
}

export interface RoomData {
  id: string;
  name: string;
  capacity: string;
  intTipoHabitacion:any;
  status: number;
  color:any;
}

