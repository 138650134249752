import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from '../../core/services/storage.service';
import { HabitacionService } from '../../core/services/habitacion.service';
import { Habitacion } from '../../core/models/habitacion';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Empleado } from '../../core/models/empleado';
import { HabitacionLimpieza } from '../../core/models/habitacion-limpieza';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-empleado',
  templateUrl: './empleado.component.html',
  styleUrls: ['./empleado.component.scss']
})
export class EmpleadoComponent implements OnInit {

  empleado:Empleado=new Empleado();
  listEmpleados:Empleado[]=[];
  selectObjc:string;
  strNota:string;

  constructor(private http: HttpClient,private storageService: StorageService,
    private route: ActivatedRoute,private router: Router,private miservicio:HabitacionService,
    public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data:HabitacionLimpieza) { }


  ngOnInit() {
    this.getEmpleados();
  }

  cancelar(){
    this.thisDialogRef.close(this.data);
  }

  public getEmpleados(){
    let intId=0;
    this.miservicio.getEmpleados(intId).pipe(first()).subscribe(datos=>{
      this.listEmpleados=datos;
      //console.log(this.listEmpleados)
    })
  }

  addEmpleado(){
    //console.log(this.strNota)
    this.miservicio.addAsignacion(this.selectObjc,this.data,this.strNota).pipe(first()).subscribe(datos=>{    
      //console.log(datos);
      //if(datos.miRespuesta.booOk===true){
        this.thisDialogRef.close(datos);
      //}      
    });
  }


  cambia(event:any){
    this.selectObjc=event;
  }

}
