import {Component, ViewChild, OnInit} from "@angular/core";
import {DayPilot, DayPilotModalComponent} from "daypilot-pro-angular";
import {Validators, FormBuilder, FormGroup} from "@angular/forms";
import {Observable, AsyncSubject} from "rxjs";
import { UpdateRoomParams, DataService } from "../service/data.service";
import { TipoHabitacion } from "../../core/models/tipo-habitacion";
import { MantenimientosService } from "../../core/services/mantenimientos.service";
import { first } from "rxjs/operators";
import { ReservasService } from "src/app/core/services/reservas.service";

@Component({
  selector: 'room-edit-dialog',
  template: `
    <daypilot-modal>
    <div class="center">
      <h1>Editar habitación</h1>
      <br>
      <form [formGroup]="form">
        <div class="form-item">
          <input formControlName="name" type="text" placeholder="Número habitación"> <span *ngIf="!form.controls.name.valid"> *Campo necesario</span>
        </div>
        <div class="form-item">Tipo:
          <select formControlName="capacity" [(ngModel)]="selectObj" >
            <option *ngFor="let it of listaTiposHabitacion" [ngValue]="it.intId">{{it.strNombre}}</option>
          </select>
        </div>
        <!--<div class="form-item">
          Limpieza:
          <select formControlName="status">
            <option *ngFor="let it of statuses" [ngValue]="it.value">{{it.name}}</option>
          </select>
        </div>-->
        <div class="form-item">
          <button (click)="submit()" [disabled]="!form.valid">Guardar</button>
          <button (click)="cancel()">Cancelar</button>
        </div>
    </form>
    </div>
    </daypilot-modal>
  `,
  styles: [`
  .center {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top:80px;
  }
  .form-item {
    margin: 4px 0px;
  }
  `]
})
export class RoomEditComponent implements OnInit {
  @ViewChild(DayPilotModalComponent) modal : DayPilotModalComponent;

  form: FormGroup;
  row: DayPilot.Row;
  subject: AsyncSubject<UpdateRoomParams>;
  listaTiposHabitacion:TipoHabitacion[]=[];
  selectObj:any;

  capacities: any[] = [
    { name: "1 bed", value: "1"},
    { name: "2 beds", value: "2"},
    { name: "3 beds", value: "3"},
    { name: "4 beds", value: "4"}
  ];

  /*statuses: any[] = [
    { name: "Ready", value: "Ready"},
    { name: "Cleanup", value: "Cleanup"},
    { name: "Dirty", value: "Dirty"}
  ];*/

  constructor(private fb: FormBuilder, private ds: DataService,private miservicio:ReservasService) {
    this.form = this.fb.group({
      name: ["", Validators.required],
      capacity: ["1", Validators.required],
      //status: ["Ready", Validators.required]
    });
    
  }

  ngOnInit(){
    this.listarTiposHab();
  }

  listarTiposHab(){
    this.miservicio.getTiposHab().pipe(first()).subscribe(datos=>{
      this.listaTiposHabitacion=datos;
      //console.log(this.listaTiposHabitacion);
    })
  }

  show(row: DayPilot.Row): Observable<UpdateRoomParams>   {    
    this.row = row;
    this.form.setValue({
      name: row.name,
      capacity: row.data.capacity,
      //status: row.data.status
    });
    this.modal.show();

    this.subject = new AsyncSubject();
    return this.subject.asObservable();

  }

  submit() {
    //console.log(this.selectObj);

    let data = this.form.getRawValue();

    let params: UpdateRoomParams = {
      id: this.row.id,
      name: data.name,
      capacity: this.selectObj,
      intTipoHabitacion:data.intTipoHabitacion
      //status: data.status
    };

    this.modal.hide();

    this.subject.next(params);
    this.subject.complete();
  }

  cancel() {
    this.modal.hide();
  }

}

