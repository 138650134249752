export class HabitacionLimpieza {

    public intHabitacionId?:number;
	public strHabitacionNombre?:string;
	public strHabitacionDescripcion?:string;
	public intTipoHabitacionId?:number;
	public strTipoHabitacionNombre?:string;
	public intEstadoLimpiezaId?:number;
	public strEstadoLimpiezaNombre?:string;
	public strUserNameAsignado?:string;
	public strUserNameRealizado?:string;
	public strUserNameMixto?:string;
	public datFecha?:Date;
	public intLimpiezaId?:number;
	public strUltimaNotaLimpieza?:string;
	public strNotas?:string;
	public strEstHabitacion?:string;
	public strColorEstadoLimpieza?:string;

}
