import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../../core/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Empleado } from '../../../core/models/empleado';
import { HabitacionService } from '../../../core/services/habitacion.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-edicion-usuarios',
  templateUrl: './edicion-usuarios.component.html',
  styleUrls: ['./edicion-usuarios.component.scss']
})
export class EdicionUsuariosComponent implements OnInit {

  //edicionEmpleado:Empleado=new Empleado();
  formTrabajador: FormGroup;
  passwordError:boolean=false;

  mostrarPassword:boolean=true;
  ocultarPassword:boolean=false;
  mostrarPassword2:boolean=true;
  ocultarPassword2:boolean=false;

  miEmpleado:Empleado[]=[];
  isChecked:boolean=false;
  Estado:string;
  submitted:boolean=false;

  constructor(private http: HttpClient,private storageService: StorageService,private _formBuilder: FormBuilder,
    private route: ActivatedRoute,private router: Router,private miservicio:HabitacionService,
    public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: Empleado) { }

  ngOnInit() {
    this.formTrabajador = this._formBuilder.group({
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      dni: [''],
      telefono1: [''],
      telefono2: [''],
      email: [''],      
      /*telefono: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$'),
      ])),*/
      direccion: [''],
      poblacion: [''],
      provincia: [''],
      //cp: [''],
      cp: new FormControl('', Validators.compose([
        Validators.pattern('^[0-9]{5}$'),
      ])),
      pais: [''],
      // usuario: new FormControl('', Validators.compose([       
      //   Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      // ])),
      activo:['']
     /* password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{7,}'),
      ])),
      confirmPassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{7,}'),
      ]))*/      
    })

    //console.log(this.data.intId)

    this.listar();
  }

  listar(){
    let intId=this.data.intId;
    this.miservicio.getEmpleados(intId).pipe(first()).subscribe(datos=>{
      this.miEmpleado=datos;
      //console.log(datos)

      if(this.miEmpleado[0].strActivo==='S'){
        this.isChecked=true;
        this.Estado='Activo'
      }else{
        this.isChecked=false;
        this.Estado='Inactivo'
      }
    })
  }

  pulsa(){
    if(this.isChecked===true){
      this.Estado='Inactivo';
    }else{
      this.Estado="Activo";
    }
  }

  salvar(trabajador:Empleado){
    this.submitted=true;
    let miEstado:string;

    if(this.isChecked===true){
      miEstado='S';
    }else{
      miEstado="N";
    } 

    if(this.formTrabajador.valid){
      //console.log(trabajador, miEstado);
      this.miservicio.putEmpleado(trabajador,miEstado).pipe(first()).subscribe(datos=>{
        //console.log(datos)
        if(datos.miRespuesta.booOk===true){
          this.thisDialogRef.close(datos);
        }
      })
    }    
  }

  cancelar(){
    this.thisDialogRef.close(this.data);
  }

}
