import { Component, OnInit } from '@angular/core';
import { User } from '../core/models/user.model';
import { StorageService } from '../core/services/storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CRespuestaService } from '../login/shared/CRespuesta.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MantenimientosService } from '../core/services/mantenimientos.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login-recuperacion',
  templateUrl: './login-recuperacion.component.html',
  styleUrls: ['./login-recuperacion.component.scss']
})
export class LoginRecuperacionComponent implements OnInit {

  public loginUserData:User=new User();
  loginForm: FormGroup;
  submitted: Boolean = false;
  cookie:string;
  showSpinner:boolean=false;
  respuesta:string[]=[];
  mensaje:string;
  muestraError:boolean=false;
  
  constructor(private storageService: StorageService,private route: ActivatedRoute,
    private router: Router,private _formBuilder: FormBuilder,
    private miservicio:CRespuestaService) {
      this.route.queryParams.subscribe(params => {
        this.cookie=params["coo"];
      });
     }

  ngOnInit() {

    this.loginForm = this._formBuilder.group({
      username: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ]))
    })
  }

  public submitLogin() {
    this.submitted = true; 
    this.muestraError=false;
    if(this.loginForm.valid){
      //console.log(this.loginUserData)
      this.showSpinner = true;
        setTimeout(() => {
          this.miservicio.envialEmailRecuperacionContrasena(this.loginUserData,this.cookie).pipe(first()).subscribe(datos=>{
            //console.log(datos);
            this.showSpinner = false; 
            //this.mensaje=datos.strMensaje;
            this.respuesta=datos;
          },(err : HttpErrorResponse)=>{    
            this.showSpinner = false;        
            this.muestraError=true; 
            this.mensaje="El usuario no existe";
          })           
        }, 2000);             
    }
  }

  getColor2(booOk) { 
    switch (booOk) {
      case false:
        return '#ff3333';
      case true:
        return '#006600';
    }
  }
}
