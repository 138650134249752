
import { Injectable } from '@angular/core';
import { Router, CanActivate, Route, CanLoad, ActivatedRoute } from '@angular/router';
import {StorageService} from "../services/storage.service";

@Injectable()
export class AuthorizatedGuard implements CanActivate {

  constructor(private router: Router,
              private storageService: StorageService) { }

              /*canLoad(route: Route) {
                let url: string = route.path;
                console.log('Url:'+ url);
                return true;
              }*/

              

  canActivate() {

    // let miComponente=window.location.pathname;
    //   //console.log(window)
    //   //console.log(window.location.pathname)
    //   //console.log(this.router)
    //   let loginUserComponent = this.storageService.getCurrentSession();
    //   let strAcceso:boolean;

    //   strAcceso=loginUserComponent.miListComponente.some(e => e.strPath === miComponente);
      //console.log(strAcceso)


    if (this.storageService.isAuthenticated() ) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page
    this.router.navigate(['/login']);
    return false;
  }
}
