import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ReservasService } from 'src/app/core/services/reservas.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cliente } from 'src/app/core/models/cliente';

@Component({
  selector: 'app-titular-factura',
  templateUrl: './titular-factura.component.html',
  styleUrls: ['./titular-factura.component.scss']
})
export class TitularFacturaComponent implements OnInit {

  listClientes:Cliente[]=[];  
  cliente:Cliente=new Cliente();
  formNewCliente: FormGroup;
  dniValido:boolean=false;
  submitted:boolean=false;
  avanza:boolean=false;
  dniError:boolean=false;


  filtro:string;
  dataTodo:Cliente[]=[];
  checktodos:any;
  dataTodoObsoleto:Cliente[]=[];

  selectCliente:any;
  listAux:any[]=[];

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, private miservicioReservas:ReservasService,private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.listarClientes();

    this.formNewCliente = this._formBuilder.group({
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      // nacimiento: ['', Validators.required],
      // sexo: ['', Validators.required],
      dni: ['', Validators.required],
      // expedido: ['', Validators.required],
      pais: ['', Validators.required],
      email: [''],
      telefono1: [''],
      telefono2: [''],
      direccion: ['', Validators.required],
      cp: ['', Validators.required],
      poblacion: ['', Validators.required],
      provincia: ['', Validators.required]
    })
  }

  listarClientes(){
    this.miservicioReservas.getClientes().subscribe(datos=>{
      this.dataTodoObsoleto=datos;
      this.dataTodo=datos.filter(e=>e.strActivo==="S");
      this.listClientes=datos.filter(e=>e.strActivo==="S");
    })
  }

  onChangeNacimiento(event){
    if(event){
      this.cliente.datFechaNacimiento=event;
    }
  }

  onChangeExpedido(event){
    if(event){
      this.cliente.datDniExpedido=event;
    }
  }

  todos(event){
    if(event.checked){
      this.dataTodo.splice(0,this.dataTodo.length)
      this.dataTodo=this.dataTodoObsoleto;
      if(this.filtro===undefined){
        this.filtro='';
      }   
      this.funcionBusqueda(this.filtro.toLocaleLowerCase(),this.dataTodo);                    
    }else{
      this.dataTodo=this.dataTodoObsoleto.filter(e=>e.strActivo==="S");
      if(this.filtro===undefined){
        this.filtro='';
      }
      this.funcionBusqueda(this.filtro.toLocaleLowerCase(),this.dataTodo);
    }
  }

  buscar(event: any){
    this.filtro = event.target.value;
  
    this.funcionBusqueda(this.filtro,this.dataTodo);
  }

  funcionBusqueda(filtro,dataFilter){
    if(filtro===''){
      this.listClientes=dataFilter
    }else{
      this.listClientes=dataFilter.filter(e=>e.strNombre.toLowerCase().startsWith(filtro.toLowerCase()) || 
      e.strApellidos.toLowerCase().startsWith(filtro.toLowerCase()) || e.strDni.toLowerCase().startsWith(filtro.toLowerCase()));
    }
  }

  seleccionaCliente(e){
    this.selectCliente=e.intId;
    this.listAux.push(e.intId)
    let ultimo=this.listAux[this.listAux.length - 1];

    this.listAux.forEach(o => {
      if(o===ultimo){
        document.getElementById(o).style.borderStyle="solid";
        document.getElementById(o).style.borderWidth="2px";
        document.getElementById(o).style.borderColor="#ffe066";        
      }else{
        document.getElementById(o).style.borderStyle="none";
      }
    });
  }

  agregarCliente(){
    this.dniError=false;
    this.submitted = true;

    if(this.nif()===true){
      this.avanza=true;
    }else{
      this.dniError=true;
      this.avanza=false;
    }

    if(this.formNewCliente.valid && this.dniValido===true){      
      this.cliente.strActivo="S";

      ///===============================================================
      //PROCESO DE AÑADIR AL CLIENTE 
      ///===============================================================

      //console.log(this.cliente)
      this.miservicioReservas.postCliente(this.cliente).subscribe((datos:any)=>{
         if(datos.miRespuesta.booOk){
          this.cliente.intId=parseInt(datos.miRespuesta.strValor);
          this.thisDialogRef.close(this.cliente);
         }
      })
    }
  }

  guardar(){
  this.thisDialogRef.close(this.dataTodoObsoleto.filter(e=>e.intId===this.selectCliente)[0]);
  }

  public  nif() {
    var numero
    var letr
    var letra
    var expresion_regular_dni
   
    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;
   
    if(expresion_regular_dni.test (this.formNewCliente.controls.dni.value) == true){
       numero = this.formNewCliente.controls.dni.value.substr(0,this.formNewCliente.controls.dni.value.length-1);
       letr = this.formNewCliente.controls.dni.value.substr(this.formNewCliente.controls.dni.value.length-1,1);
       numero = numero % 23;
       letra='TRWAGMYFPDXBNJZSQVHLCKET';
       letra=letra.substring(numero,numero+1);
      if (letra!=letr.toUpperCase()) {
        return this.dniValido=false;
       }else{
         return this.dniValido=true;
       }
    }else if(this.formNewCliente.controls.dni.value===undefined){
      return this.dniValido=true;
    }else{
      return this.dniValido=false;
     }
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
