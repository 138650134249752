import { Component, OnInit, Inject } from '@angular/core';
import { Cliente } from 'src/app/core/models/cliente';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ReservasService } from 'src/app/core/services/reservas.service';

@Component({
  selector: 'app-agrupar-reservas',
  templateUrl: './agrupar-reservas.component.html',
  styleUrls: ['./agrupar-reservas.component.scss']
})
export class AgruparReservasComponent implements OnInit {

  listClientes:Cliente[]=[];  
  cliente:Cliente=new Cliente();

  filtro:string;
  dataTodo:Cliente[]=[];
  checktodos:any;
  dataTodoObsoleto:Cliente[]=[];

  //colorCliente:any;
  selectCliente:any;
  listAux:any[]=[];

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, private miservicioReservas:ReservasService,private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    //console.log(this.data)
    this.listarClientes();
    this.listarReservas();
  }

  listarReservas(){
    this.miservicioReservas.getReserva(this.data.inicio.value,this.data.fin.value,"").subscribe(datos=>{
      console.log(datos)
      //this.misReservas=datos;
      //console.log(this.misReservas)
    })
  }

  listarClientes(){
    this.miservicioReservas.getClientes().subscribe(datos=>{
      //this.data=datos;
      //console.log(datos);
      this.dataTodoObsoleto=datos;
      this.dataTodo=datos.filter(e=>e.strActivo==="S");
      this.listClientes=datos.filter(e=>e.strActivo==="S");
      //console.log(this.listClientes)
    })
  }

  todos(event){
    //console.log(event.checked)
    if(event.checked){
      this.dataTodo.splice(0,this.dataTodo.length)
      this.dataTodo=this.dataTodoObsoleto;
      if(this.filtro===undefined){
        this.filtro='';
      }   
      this.funcionBusqueda(this.filtro.toLocaleLowerCase(),this.dataTodo);                    
    }else{
      //this.dataTodo.splice(0,this.dataTodo.length)
      this.dataTodo=this.dataTodoObsoleto.filter(e=>e.strActivo==="S");
      if(this.filtro===undefined){
        this.filtro='';
      }
      this.funcionBusqueda(this.filtro.toLocaleLowerCase(),this.dataTodo);
    }
    //console.log(this.dataTodo)
  }

  buscar(event: any){
    this.filtro = event.target.value;
  
    this.funcionBusqueda(this.filtro,this.dataTodo);
  }

  funcionBusqueda(filtro,dataFilter){
    if(filtro===''){
      this.listClientes=dataFilter
    }else{
      this.listClientes=dataFilter.filter(e=>e.strNombre.toLowerCase().startsWith(filtro.toLowerCase()) || 
      e.strApellidos.toLowerCase().startsWith(filtro.toLowerCase()) || e.strDni.toLowerCase().startsWith(filtro.toLowerCase()));
    }
  }

  seleccionaCliente(e){
    this.selectCliente=e.intId;
    this.listAux.push(e.intId)
    let ultimo=this.listAux[this.listAux.length - 1];

    this.listAux.forEach(o => {
      if(o===ultimo){
        document.getElementById(o).style.borderStyle="solid";
        document.getElementById(o).style.borderWidth="2px";
        document.getElementById(o).style.borderColor="#ffe066";        
      }else{
        document.getElementById(o).style.borderStyle="none";
      }
    });
  }

  guardar(){
    //console.log(this.selectCliente)
    //console.log(this.dataTodoObsoleto)
    //console.log(this.dataTodoObsoleto.filter(e=>e.intId===this.selectCliente))

    this.thisDialogRef.close(this.dataTodoObsoleto.filter(e=>e.intId===this.selectCliente)[0]);
    /*this.miservicioReservas.postHuespedReserva(this.data.intGrupoId,this.data.id,this.selectCliente).subscribe((datos:any)=>{
      if(datos.miRespuesta.booOk){
        this.thisDialogRef.close(datos.miListHuesped);
      }
    })*/
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
