import { Component, OnInit, ViewChild, ViewContainerRef, HostListener } from '@angular/core';
import { Habitacion } from '../core/models/habitacion';
import { StorageService } from '../core/services/storage.service';
import { User } from '../core/models/user.model';
import { HabitacionService } from '../core/services/habitacion.service';
import {MatTableDataSource, MatSort, MatPaginator} from '@angular/material';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { Estados } from '../core/models/estados';
import { ColorPickerService, Cmyk } from 'ngx-color-picker';
import {FormControl} from '@angular/forms';
import { EmpleadoComponent } from './empleado/empleado.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import { map, first } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { HabitacionLimpieza } from '../core/models/habitacion-limpieza';
import { HttpClient } from '@angular/common/http';
import { Empleado } from '../core/models/empleado';
import { DatePipe} from '@angular/common';

@Component({
  selector: 'app-asignaciones',
  templateUrl: './asignaciones.component.html',
  styleUrls: ['./asignaciones.component.scss']
})
export class AsignacionesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataTodo:HabitacionLimpieza[]=[];
  displayedColumns = ['strHabitacionNombre','strTipoHabitacionNombre','strUserNameAsignado','strUserNameRealizado'];
  dataSource;

  public loginUserData: User;
  data:HabitacionLimpieza[]=[];
  today = Date();
  date = new FormControl(new Date());
  habitacion:Habitacion;

  listEmpleados:Empleado[]=[];
  miHabitacionSeleccionada:number;
  noHayAsignado:boolean=false;
  HayAsignado:boolean=false;
  dato:string;
  Actual = new FormControl(new Date());
  trabajadorAsignado:string;
  dataExcel:datosExcelAsignaciones[]=[];
  filtro:string;
  listFiltrada:HabitacionLimpieza[]=[];
  teclaAlt:any;
  otraTecla:any;
  
  constructor(
    private storageService: StorageService,private http: HttpClient,
    private miservicio:HabitacionService,public datepipe: DatePipe,
    private router: Router,private route: ActivatedRoute,public dialog: MatDialog
  ) { }

  ngOnInit() {
    //console.log(window.location.pathname);
    this.paginator._intl.itemsPerPageLabel = 'Elementos por pagina';
    this.loginUserData = this.storageService.getCurrentUser();

    this.listar();
    this.getEmpleados();
    /*this.miservicio.getAsignacionTrabajo().subscribe(datos=>{
      console.log(datos)
      var event = new Date(datos[0].dateFechaRegistro);
    var jsonDate = event.toLocaleDateString();
    //console.log(jsonDate)
    if(this.Actual.value.toLocaleDateString()===jsonDate){
      //console.log('vale')
    }else{
      //console.log('no vale')
    }
    })*/
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===88 && this.otraTecla===18){
      this.exportAsXLSX();
    }else if(this.teclaAlt===66 && this.otraTecla===18){
      var myEl = document.getElementById('miFiltro');
      myEl.focus();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }  

  public listar(){
    this.dataSource = new MyTableDataSource(this.paginator, this.sort);
    this.miservicio.getHabLimpiezas(this.date.value.toLocaleDateString(),'','').pipe(first()).subscribe(datos=>{   
      //console.log(datos)
      this.data=datos;      
      this.listFiltrada=datos;  
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;    
    })
  }

  public getEmpleados(){
    let intId=0;
    this.miservicio.getEmpleados(intId).pipe(first()).subscribe(datos=>{
      this.listEmpleados=datos;
      //console.log(this.listEmpleados)
    })
  }

  cambia(event:any){

    //console.log(event);
    //console.log(this.miHabitacionSeleccionada)
    
    this.miservicio.addAsignacion(this.miHabitacionSeleccionada,event,'').pipe(first()).subscribe(datos=>{      
      //console.log(datos)
    });


  }

  seleccionar(habitacion:HabitacionLimpieza){
    let dialogRef = this.dialog.open(EmpleadoComponent, {
      width: '600px',
      data: {
        'intHabitacionId':habitacion.intHabitacionId,
      }
    });
    dialogRef.afterClosed().subscribe((datos:any) => {
      //this.listar();      
      if(datos!=undefined && datos.miRespuesta!=undefined){

        let myObject:any;
        myObject=datos.miListHabitacionLimpieza[0];
        //console.log(myObject);

        let miTicket=datos.miListHabitacionLimpieza[0].intHabitacionId;
        //console.log(miTicket)

        var index = this.data.map(x => {
          return x.intHabitacionId;
        }).indexOf(miTicket);

        if(datos.length!=0){      
          this.data.splice(index, 1, myObject);
          //console.log(index);
          //console.log(this.data);
          this.dataSource=new MatTableDataSource(this.data);
        }
        this.dataSource=new MatTableDataSource(this.data);
        /*if(datos.length===0){
          this.data.splice(index, 1);
        }*/

      }      
    });
  }

  getThis(h:HabitacionLimpieza){
      //console.log(h.intHabitacionId, h.strHabitacionNombre)
    this.miHabitacionSeleccionada=h.intHabitacionId;
  }

  buscar(event: any){
    this.filtro = event.target.value;
  
    this.dataTodo=this.data;
    if(this.filtro===''){
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
      this.listFiltrada=this.data;
    }else{
      this.data=this.data.filter(e=>e.strHabitacionNombre.toLowerCase().startsWith(this.filtro.toLowerCase()) || 
      e.strUserNameAsignado.toLowerCase().startsWith(this.filtro.toLowerCase()) || e.strUserNameRealizado.toLowerCase().startsWith(this.filtro.toLowerCase()));
    //console.log(this.data);
      this.listFiltrada=this.data;
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    }
    this.data=this.dataTodo;
  }
  
  exportAsXLSX():void {
    this.listFiltrada.forEach(e => {
      // this.dataExcel.push({num_reserva:element.intId, num_habitacion:element.strHabitacionNombre, entrada:this.datepipe.transform(element.datEntrada, 'dd/MM/yyyy'), salida:this.datepipe.transform(element.datSalida, 'dd/MM/yyyy'), cliente:element.strClienteNombre+" "+element.strClienteApellidos, precio:0});
      this.dataExcel.push({
        Fecha:this.datepipe.transform(e.datFecha, 'dd/MM/yyyy'), 
        Habitacion:e.strHabitacionNombre, 
        Tipo:e.strTipoHabitacionNombre, 
        Estado_reserva:e.strEstHabitacion, 
        Estado_limpieza:e.strEstadoLimpiezaNombre, 
        Asignado:e.strUserNameAsignado, 
        Realizado:e.strUserNameRealizado,
        Nota:e.strUltimaNotaLimpieza
      })
    });
    this.miservicio.exportAsExcelFile(this.dataExcel, 'Listado asignaciones limpiezas');

    this.dataExcel.splice(0, this.dataExcel.length)
  }

  /*addEmpleado(){
    let dialogRef = this.dialog.open(EmpleadoComponent, {
      width: '600px',
      
    });
    dialogRef.afterClosed().subscribe(result => {
      this.habitacion=result;
    });
  }
*/
}

export interface datosExcelAsignaciones {
  Fecha:any;
  Habitacion:any;
  Tipo:any;
  Estado_reserva:any;
  Estado_limpieza:any;
  Asignado:any;
  Realizado:any;
  Nota:any;
}


export class MyTableDataSource extends DataSource<HabitacionLimpieza> {
  data:HabitacionLimpieza[]=[];
 
   constructor(private paginator: MatPaginator, private sort: MatSort) {
     super();
   
   }
 
   connect(): Observable<HabitacionLimpieza[]> {
     const dataMutations = [
       observableOf(this.data),
       this.paginator.page,
       this.sort.sortChange
     ];
 
     this.paginator.length = this.data.length;
 
     return merge(...dataMutations).pipe(map(() => {
       return this.getPagedData(this.getSortedData([...this.data]));
     }));
   }
 
   disconnect() {}
 
   private getPagedData(data: HabitacionLimpieza[]) {
     const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
     return data.splice(startIndex, this.paginator.pageSize);
   }
 
 
   private getSortedData(data: HabitacionLimpieza[]) {
     if (!this.sort.active || this.sort.direction === '') {
       return data;
     }
 
     return data.sort((a, b) => {
       const isAsc = this.sort.direction === 'asc';
       switch (this.sort.active) {
         case 'strTipoHabitacionNombre': return compare(a.strTipoHabitacionNombre, b.strTipoHabitacionNombre, isAsc);
         case 'strUserNameAsignado': return compare(+a.strUserNameAsignado, +b.strUserNameAsignado, isAsc);
         default: return 0;
       }
     });
   }
 }
 
 function compare(a, b, isAsc) {
   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
 }