import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { MantenimientosService } from '../core/services/mantenimientos.service';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Articulo } from '../core/models/articulo';
import { Observable, of as observableOf, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { NuevoArticuloComponent } from './nuevo-articulo/nuevo-articulo.component';
import { EditaArticuloComponent } from './edita-articulo/edita-articulo.component';

@Component({
  selector: 'app-mantenimiento-articulos',
  templateUrl: './mantenimiento-articulos.component.html',
  styleUrls: ['./mantenimiento-articulos.component.scss']
})
export class MantenimientoArticulosComponent implements OnInit {

  data:Articulo[]=[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['strNombre','strDescripcion','decPvp'];
  dataSource ;
  dataExcel:datosExcel[]=[];
  filtro:string;
  dataTodo:Articulo[]=[];
  dataFiltrada:Articulo[]=[];
  teclaAlt:any;
  otraTecla:any;

  constructor(private miServicio:MantenimientosService,public dialog: MatDialog) { }

  ngOnInit() {
    this.listarArticulos();
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===78 && this.otraTecla===18){
      this.newArticulo();
    }else if(this.teclaAlt===88 && this.otraTecla===18){
      this.exportAsXLSX();
    }/*else if(this.teclaAlt===107 && this.otraTecla===18){
      alert('agrupa')
    }*/else if(this.teclaAlt===66 && this.otraTecla===18){
      var myEl = document.getElementById('miFiltro');
      myEl.focus();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }

  listarArticulos(){   

    this.dataSource = new MyTableDataSource(this.paginator, this.sort);

    this.miServicio.getArticulos(0).subscribe(datos=>{
      this.data=datos;
      //console.log(datos);
      //console.log(this.data);
      this.dataFiltrada=datos;
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    })
  }

  getColor(strActivo) { 
    switch (strActivo) {
      case 'N':
        return '#ff3333';
      case 'S':
        return '#00b300';
    }
  }

  buscar(event: any){
    this.filtro = event.target.value;
  
    this.dataTodo=this.data;
    if(this.filtro===''){
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
      this.dataFiltrada=this.data;
    }else{
      this.data=this.data.filter(e=>e.strNombre.toLowerCase().startsWith(this.filtro.toLowerCase()) || 
      e.strDescripcion.toLowerCase().startsWith(this.filtro.toLowerCase()) || e.decPvp.toString().startsWith(this.filtro));
    //console.log(this.data);
      this.dataFiltrada=this.data;
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    }
    //console.log(this.data);
    this.data=this.dataTodo;
    
  }

  exportAsXLSX():void {
    //console.log(this.dataFiltrada);
    this.dataFiltrada.forEach(e => {
      this.dataExcel.push({        
        Nombre:e.strNombre,
        Descripcion:e.strDescripcion,
        Pv:e.decPv,
        IVA:e.decIva,
        PVP:e.decPvp,
        Dto:e.decDto
      })
    });
    this.miServicio.exportAsExcelFile(this.dataExcel, 'Listado Articulos');

    this.dataExcel.splice(0, this.dataExcel.length)
  }

  newArticulo(){
    /*this.muestraReserva=true;
    this.datosInicio=false;*/

    let dialogRef = this.dialog.open(NuevoArticuloComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result)
      if(result!=undefined){
        this.data.splice(0, this.data.length);
        this.data=result;  
        this.dataSource=new MatTableDataSource(result); 
      }
      //this.listarClientes();
    });
  }

  editArticulo(e){
    let dialogRef = this.dialog.open(EditaArticuloComponent, {
      width: '600px',
      data: {
        'intId':e.intId,
      }      
    });
    dialogRef.afterClosed().subscribe((datos) => {
      //console.log(datos)
      if(datos!=undefined){        
        //this.data.splice(0, this.data.length);
        //this.data=datos;  
        //this.dataSource=new MatTableDataSource(this.data);         
        //console.log(datos)

        let myObject:any;
          myObject=datos[0];
          //console.log(myObject);
    
          let miTicket=datos[0].intId;
    
          var index = this.data.map(x => {
            return x.intId;
          }).indexOf(miTicket);
    
          if(datos.length!=0){      
            this.data.splice(index, 1, myObject);
            //console.log(index);
            //console.log(this.data);
            this.dataSource=new MatTableDataSource(this.data);
          }
          this.getColor(myObject.strActivo);
          if(datos.length===0){
            this.data.splice(index, 1);
          }
      }  
    });
  }

}

export interface datosExcel{  
  Nombre:any;
  Descripcion:any;
  Pv:any;
  IVA:any;
  PVP:any;
  Dto:any;  
}

export class MyTableDataSource extends DataSource<Articulo> {
  data:Articulo[]=[];
 
   constructor(private paginator: MatPaginator, private sort: MatSort) {
     super();
   }
 
   connect(): Observable<Articulo[]> {
     const dataMutations = [
       observableOf(this.data),
       this.paginator.page,
       this.sort.sortChange
     ];
 
     this.paginator.length = this.data.length;
 
     return merge(...dataMutations).pipe(map(() => {
       return this.getPagedData(this.getSortedData([...this.data]));
     }));
   }
 
   disconnect() {}
 
   private getPagedData(data: Articulo[]) {
     const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
     return data.splice(startIndex, this.paginator.pageSize);
   }
 
 
   private getSortedData(data: Articulo[]) {
     if (!this.sort.active || this.sort.direction === '') {
       return data;
     }
 
     return data.sort((a, b) => {
       const isAsc = this.sort.direction === 'asc';
       switch (this.sort.active) {
         case 'strNombre': return compare(a.strNombre, b.strNombre, isAsc);
         case 'strDescripcion': return compare(+a.strDescripcion, +b.strDescripcion, isAsc);
         default: return 0;
       }
     });
   }
 }
 
 function compare(a, b, isAsc) {
   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
 }

