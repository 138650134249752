import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { MantenimientosService } from '../core/services/mantenimientos.service';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Iva } from '../core/models/iva';
import { Observable, of as observableOf, merge } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import { EditaIvaComponent } from './edita-iva/edita-iva.component';
import { NuevoIvaComponent } from './nuevo-iva/nuevo-iva.component';

@Component({
  selector: 'app-mantenimiento-iva',
  templateUrl: './mantenimiento-iva.component.html',
  styleUrls: ['./mantenimiento-iva.component.scss']
})
export class MantenimientoIvaComponent implements OnInit {

  // listaIva:Iva[]=[];
  strDefecto:string;

  data:Iva[]=[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['strNombre','decValor','strDefecto'];
  dataSource ;
  dataExcel:datosExcel[]=[];
  filtro:string;
  dataTodo:Iva[]=[];
  dataFiltrada:Iva[]=[];
  teclaAlt:any;
  otraTecla:any;

  constructor(private miServicio:MantenimientosService,public dialog: MatDialog) { }

  ngOnInit() {
    this.listarDatos();
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===78 && this.otraTecla===18){
      this.newIva();
    }else if(this.teclaAlt===88 && this.otraTecla===18){
      this.exportAsXLSX();
    }else if(this.teclaAlt===66 && this.otraTecla===18){
      var myEl = document.getElementById('miFiltro');
      myEl.focus();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }

  listarDatos(){
    this.dataSource = new MyTableDataSource(this.paginator, this.sort);
    
    this.miServicio.getIva(0).subscribe(datos=>{
      //console.log(datos);
      
      datos.forEach(e => {
        if(e.strDefecto==='S' ){
          return e.strChecked=true;
        }else{
          return e.strChecked=false;
        }
      });

      this.data=datos;
      this.dataFiltrada=datos;
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;

      
    })
  }

  getColor(strActivo) { 
    switch (strActivo) {
      case 'N':
        return '#ff3333';
      case 'S':
        return '#00b300';
    }
  }

  pulsa(e){
    //console.log(e)
    
    this.data.forEach(i => {
      i.strChecked=false;
      i.strDefecto="N"
    });

    let myObject:any;
    myObject=e;
    myObject.strChecked=true;
    myObject.strDefecto="S";

    var index = this.data.map(x => {
      return x.intId;
    }).indexOf(e.intId);
    
    this.data.splice(index, 1, myObject);
    //console.log(this.data)
      
    this.miServicio.actualizaIva(this.data).subscribe(datos=>{
      //console.log(datos)
    })
  }

  editIva(e){
    let dialogRef = this.dialog.open(EditaIvaComponent, {
      width: '600px',
      data: {
        'intId':e.intId,
      }      
    });
    dialogRef.afterClosed().subscribe((datos) => {
      //console.log(datos)
      if(datos!=undefined){
        datos.forEach(e => {
          if(e.strDefecto==='S' ){
            return e.strChecked=true;
          }else{
            return e.strChecked=false;
          }
        });
        this.data.splice(0, this.data.length);
        this.data=datos;  
        this.dataSource=new MatTableDataSource(this.data);  
      }  
    });
  }

  newIva(){
    let dialogRef = this.dialog.open(NuevoIvaComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result!=undefined){
        result.forEach(e => {
          if(e.strDefecto==='S' ){
            return e.strChecked=true;
          }else{
            return e.strChecked=false;
          }
        });
        this.data.splice(0, this.data.length);
        this.data=result;  
        this.dataSource=new MatTableDataSource(result); 
      }
    });
  }

  buscar(event: any){
    this.filtro = event.target.value;
  
    this.dataTodo=this.data;
    if(this.filtro===''){
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
      this.dataFiltrada=this.data;
    }else{
      this.data=this.data.filter(e=>e.strNombre.toLowerCase().startsWith(this.filtro.toLowerCase()) || e.decValor.toString().startsWith(this.filtro));
    //console.log(this.data);
      this.dataFiltrada=this.data;
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    }
    this.data=this.dataTodo;
  }

  exportAsXLSX():void {
    this.dataFiltrada.forEach(e => {
      this.dataExcel.push({        
        Nombre:e.strNombre,
        Valor:e.decValor,
        Defecto:e.strDefecto,
        Activo:e.strActivo
      })
    });
    this.miServicio.exportAsExcelFile(this.dataExcel, 'Listado Iva');

    this.dataExcel.splice(0, this.dataExcel.length)
  }

}

export interface datosExcel{  
  Nombre:any;
  Valor:any;
  Defecto:any;
  Activo:any;
}

export class MyTableDataSource extends DataSource<Iva> {
  data:Iva[]=[];
 
   constructor(private paginator: MatPaginator, private sort: MatSort) {
     super();
   }
 
   connect(): Observable<Iva[]> {
     const dataMutations = [
       observableOf(this.data),
       this.paginator.page,
       this.sort.sortChange
     ];
 
     this.paginator.length = this.data.length;
 
     return merge(...dataMutations).pipe(map(() => {
       return this.getPagedData(this.getSortedData([...this.data]));
     }));
   }
 
   disconnect() {}
 
   private getPagedData(data: Iva[]) {
     const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
     return data.splice(startIndex, this.paginator.pageSize);
   }
 
 
   private getSortedData(data: Iva[]) {
     if (!this.sort.active || this.sort.direction === '') {
       return data;
     }
 
     return data.sort((a, b) => {
       const isAsc = this.sort.direction === 'asc';
       switch (this.sort.active) {
         case 'strNombre': return compare(a.strNombre, b.strNombre, isAsc);
         case 'strDefecto': return compare(+a.strDefecto, +b.strDefecto, isAsc);
         default: return 0;
       }
     });
   }
 }
 
 function compare(a, b, isAsc) {
   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
 }