import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Empleado } from '../../core/models/empleado';
import { MantenimientosService } from '../../core/services/mantenimientos.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  
  strPassword:string;
  strConfirmPassword:string;
  formNewPassword: FormGroup;
  mostrarPassword:boolean=true;
  ocultarPassword:boolean=false;
  mostrarPassword2:boolean=true;
  ocultarPassword2:boolean=false;
  submitted2: Boolean = false;
  passwordError:boolean=false;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>,private _formBuilder:FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Empleado,private miservicio:MantenimientosService) { }

  ngOnInit() {
    this.formNewPassword = this._formBuilder.group({
      
      newpassword: new FormControl('', Validators.compose([
        Validators.required,
        //Validators.minLength(8),
        //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{7,}'),
      ])),
      newconfirmPassword: new FormControl('', Validators.compose([
        Validators.required,
        //Validators.minLength(8),
        //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{7,}'),
      ]))      
    })
  }

  showPassword(){
    this.mostrarPassword=false;
    this.ocultarPassword=true;
  }

  hiddenPassword(){
    this.mostrarPassword=true;
    this.ocultarPassword=false;
  }

  showPassword2(){
    this.mostrarPassword2=false;
    this.ocultarPassword2=true;
  }

  hiddenPassword2(){
    this.mostrarPassword2=true;
    this.ocultarPassword2=false;
  }

  cancelar(){
    this.thisDialogRef.close();
  }

  salvar(){
    
    this.submitted2 = true;    
    this.passwordError=false;
    if(this.formNewPassword.valid){
      if(this.strPassword===this.strConfirmPassword){
        this.miservicio.changePasswordTrabajador(this.data.strUserId,this.strPassword,this.strConfirmPassword).pipe(first()).subscribe(datos=>{
          //console.log(datos);
          if(datos.booOk===true){
            this.thisDialogRef.close();
          }
        })
      }else{
        this.passwordError=true;
      }
    }
  }

}
