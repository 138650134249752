export class Cliente {
    
    public intId?:number;
    public strNombre?:string;
    public strApellidos?:string;
    public strDni?:string;
    public strEmail?:string;
    public strTelefono1?:string;
    public strTelefono2?:string;
    public strDireccion?:string;
    public strCP?:string;
    public strPoblacion?:string;
    public strProvincia?:string;
    public strPais?:string;
    public datFechaRegistro?:Date;
    public datFechaActualizacion?:Date;
    public strActivo?:string;
    public datFechaNacimiento?:Date;
    public datDniExpedido?:Date;
    public strClienteSexo?:string;

}
