export class Facturas {

    public intId?:number;
    public intClienteId?:number;
    public intGrupoId?:number;
    public strCliente?:string;
    public intEjercicio?:number;
    public intNumFactura?:number;
    public datFecha?:Date;
    public strDescripcion?:string;
    public decDto?:number;
    public decBase?:number;
    public decCuotaDto?:number;
    public decCuotaIva?:number;
    public decTotal?:number;

}
