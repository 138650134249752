import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
//
//import { DataService, MoveReservationParams, ReservationData, UpdateReservationParams, CreateRoomParams, UpdateRoomParams } from './service/data.service';
import { Router } from '@angular/router';
//import { DayPilotSchedulerComponent, DayPilotNavigatorComponent, DayPilot } from 'daypilot-pro-angular';
import { RoomEditComponent } from './componentes/room-edit.component';
import { RoomCreateComponent } from './componentes/room-create.component';
import { ReservationCreateComponent } from './componentes/reservation-create.component';
import { ReservationEditComponent } from './componentes/reservation-edit.component';
import { MantenimientosService } from '../core/services/mantenimientos.service';

@Component({
  selector: 'app-hotel',
  template: `
  <app-layout></app-layout>
  <div class="fullscreen">
  <!--<div class="toolbar">
  </div>-->
  <div class="contentReservas" *ngIf="muestraPlanning">
  <mis-reservas [movH]="movH" [movV]="movV" [modificarEstancia]="modificarEstancia" [borraReserva]="borraReserva"></mis-reservas>  
  </div>
  </div>
  <nav class="miSpinner" *ngIf="showSpinner">
  <mat-spinner></mat-spinner>
</nav>
  
   
`,
styles: [`
.fullscreen {
  position: absolute; top:40px; left: 126px; right: 0px; bottom: 0px;
}
/*.toolbar {
  position: absolute; top: 0px; left: 0px; right: 0px; height: 25px; background-color: #f0f0f0;
}*/
.contentReservas {
  position: absolute; top: 25px; left: 126px; right: 0px; bottom: 50px !important;
}
.user {
  float: right;
  margin-top: 5px;
  margin-right: 10px;
}

@media screen and (max-width: 1100px) {
  .fullscreen {
    position: absolute; top:40px; left: 0px; right: 0px; bottom: 0px;
  }  
  .contentReservas {
    position: absolute; top: 25px; left: 0px; right: 0px; bottom: 50px !important;
  }
}


.miSpinner{
  width: 100%;
  height: 100%;
  z-index: 101;
  background-color: rgba(150, 150, 150, 0.48);
  position: fixed;
  top:0;
  
}

.miSpinner mat-spinner{
  //margin: 0 auto;
  top:50%;
  left: 50%;
}
`]
})
export class HotelComponent implements OnInit, AfterViewInit {

  parentMessage = "message from parent"

  ngAfterViewInit(): void {
  }

  movH:boolean;
  movV:boolean;
  modificarEstancia:boolean;
  borraReserva:boolean;
  muestraPlanning:boolean=false;
  showSpinner:boolean=false;

  constructor(private ServMant:MantenimientosService){
    this.ServMant.getConfiguraciones().subscribe((datos:any)=>{   
      //console.log(datos)
      if(datos[0].strMovHorizontal==="S"){
        this.movH=false;
      }else{
        this.movH=true;
      }

      //console.log(this.movH)
  
      if(datos[0].strMovVertical==="S"){
        this.movV=false;
      }else{
        this.movV=true;
      }
      
      if(datos[0].strModificarEstancia==="S"){
        this.modificarEstancia=false;
      }else{
        this.modificarEstancia=true;
      }

      if(datos[0].strBorraReserva==="S"){
        this.borraReserva=false;
      }else{
        this.borraReserva=true;
      }
      //this.movV=datos.strMovVertical;
    }) 
  }

  ngOnInit(){  
    this.showSpinner = true;

    setTimeout(() => {
      this.showSpinner = false;
      this.muestraPlanning=true
    }, 1000); 
  }


}
