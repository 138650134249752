import {Injectable} from "@angular/core";
//import {Http, Response} from "@angular/http";
import {Observable} from "rxjs";
import {LoginObject} from "./login-object.model";
import {Session} from "../../core/models/session.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
//import { CRespuesta } from "./crespuesta";
import { User } from "../../core/models/user.model";
import { environment } from "../../../environments/environment";
import { StorageService } from "../../core/services/storage.service";

@Injectable()
export class CRespuestaService {

  constructor(private http: HttpClient,private storageService: StorageService) {}

  public url: string = environment.baseUrl;

  /*findCRespuesta(strUserName:string,strPassword:string): Observable<any>{    
  let dato = '{"strUsuario": "'+ strUserName +'", "strContrasena":"'+ strPassword +'"}'; 
  let headers = new HttpHeaders().set('Content-Type','application/json');
     
    return this.http.post(this.url + 'sesion', dato, {headers: headers});
  }*/

findCRespuesta2(cookie): Observable<any>{    
  let dato = '{"strId":"'+ cookie +'"}';
  let headers = new HttpHeaders().set('Content-Type','application/json');
   
  return this.http.post(this.url + '/api/usuario', dato, {headers: headers});
}

findCRespuesta3(strUsuario:string,strPassword:string,miCookie): Observable<any>{    
  let dato = '{"strUsuario": "'+ strUsuario +'", "strContrasena":"'+ strPassword +'", "strCookie":"'+ miCookie +'"}';
  let headers = new HttpHeaders().set('Content-Type','application/json');   
  return this.http.post(this.url + '/api/sesion', dato, {headers: headers});
}

findCRespuesta4(mistrUsuario:string,mistrPassword:string): Observable<any>{    
  var data = "username=" + mistrUsuario + "&password=" + mistrPassword + "&grant_type=password";
  var reqHeader =new HttpHeaders().set('Content-Type','application/json');
  return this.http.post<any>(this.url + '/token' , data, { headers: reqHeader });
}

envialEmailRecuperacionContrasena(loginUserData:User,cookie):Observable<any>{
  var reqHeader =new HttpHeaders().set('Content-Type','application/json');
  let urlCompleta=this.url+'/api/establecimiento/forgotPassword?'+"strCookie="+cookie;
  let dato = '{"Email": "'+ loginUserData.strUserName +'"}'; 

  return this.http.post<any>(urlCompleta , dato, { headers: reqHeader });
}

changePasswordAdmin(strUserName,strPassword,ConfirmPassword,cookie,id):Observable<any>{
  var reqHeader =new HttpHeaders().set('Content-Type','application/json');
  let urlCompleta=this.url+'/api/establecimiento/SetPassword?'+"strCookie="+cookie+"&strId="+id;
  let dato = '{"strUserName": "'+ strUserName +'", "strPassword":"' + strPassword + '", "ConfirmPassword":"' + ConfirmPassword + '"}'; 

  return this.http.post<any>(urlCompleta , dato, { headers: reqHeader });
}

postPrueba(): Observable<any>{    
  //let dato = '{"strId":"'+ cookie +'"}';
  let xml='xml=<?xml version="1.0" encoding="UTF-8"?><GetBookings><Auth><ApiKey>d8d926d79ec8a3d665b6348c85c1f303</ApiKey><PropertyId>9920524687</PropertyId></Auth><CreationStartDate>2018-10-01</CreationStartDate><CreationEndDate>2018-10-02</CreationEndDate></GetBookings>';
  let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
  
  /*{
    headers: new HttpHeaders()
    .set('Content-Type', 'text/xml') 
    .append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS') 
    .append('Access-Control-Allow-Origin', '*')
    .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
, responseType:'text'}*/
   
  return this.http.post('https://www.octorate.com/api/live/callApi.php?method=GetBookings', xml, {headers: headers});
}

}
