import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ReservasService } from 'src/app/core/services/reservas.service';
import { Articulo } from 'src/app/core/models/articulo';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MantenimientosService } from 'src/app/core/services/mantenimientos.service';

@Component({
  selector: 'app-lista-articulo',
  templateUrl: './lista-articulo.component.html',
  styleUrls: ['./lista-articulo.component.scss']
})
export class ListaArticuloComponent implements OnInit {

  listArticulos:Articulo[]=[];  
  articulo:Articulo=new Articulo();
  formNewArticulo: FormGroup;
  submitted:boolean=false;

  filtro:string;
  dataTodo:Articulo[]=[];
  checktodos:any;
  dataTodoObsoleto:Articulo[]=[];

  selectArticulo:any;
  listAux:any[]=[];

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, private miservicioReservas:MantenimientosService,private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.listarArticulos();

    this.formNewArticulo = this._formBuilder.group({
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      nacimiento: ['', Validators.required],
      sexo: ['', Validators.required],
      dni: ['', Validators.required],
      expedido: ['', Validators.required],
      pais: ['', Validators.required]
    })
  }

  listarArticulos(){
    this.miservicioReservas.getArticulos(0).subscribe(datos=>{
      //console.log(datos);
      this.dataTodoObsoleto=datos;
      this.dataTodo=datos.filter(e=>e.strActivo==="S");
      this.listArticulos=datos.filter(e=>e.strActivo==="S");
    })
  }

  todos(event){
    if(event.checked){
      this.dataTodo.splice(0,this.dataTodo.length)
      this.dataTodo=this.dataTodoObsoleto;
      if(this.filtro===undefined){
        this.filtro='';
      }   
      this.funcionBusqueda(this.filtro.toLocaleLowerCase(),this.dataTodo);                    
    }else{
      this.dataTodo=this.dataTodoObsoleto.filter(e=>e.strActivo==="S");
      if(this.filtro===undefined){
        this.filtro='';
      }
      this.funcionBusqueda(this.filtro.toLocaleLowerCase(),this.dataTodo);
    }
  }

  buscar(event: any){
    this.filtro = event.target.value;
    this.funcionBusqueda(this.filtro,this.dataTodo);
  }

  funcionBusqueda(filtro,dataFilter){
    if(filtro===''){
      this.listArticulos=dataFilter
    }else{
      this.listArticulos=dataFilter.filter(e=>e.strNombre.toLowerCase().startsWith(filtro.toLowerCase()) || 
      e.strDescripcion.toLowerCase().startsWith(filtro.toLowerCase()) );
    }
  }

  seleccionaArticulo(e){
    this.selectArticulo=e.intId;
    this.listAux.push(e.intId)
    let ultimo=this.listAux[this.listAux.length - 1];

    this.listAux.forEach(o => {
      if(o===ultimo){
        document.getElementById(o).style.borderStyle="solid";
        document.getElementById(o).style.borderWidth="2px";
        document.getElementById(o).style.borderColor="#ffe066";        
      }else{
        document.getElementById(o).style.borderStyle="none";
      }
    });
  }

  agregarHuesped(){
    this.submitted = true;

    if(this.formNewArticulo.valid){      
      this.articulo.strActivo="S";

      this.thisDialogRef.close(this.articulo);

      ///===============================================================
      //FALTA EL PROCESO DE AÑADIR AL ARTICULO (SI SE HACE)
      ///===============================================================

      /*this.miservicioReservas.postCliente(this.data.intGrupoId,this.data.id,this.cliente).subscribe((datos:any)=>{
        if(datos.miRespuesta.booOk){
          this.thisDialogRef.close(datos.miListHuesped);
        }
      })*/
    }
  }

  guardar(){
    this.thisDialogRef.close(this.dataTodoObsoleto.filter(e=>e.intId===this.selectArticulo)[0]);
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
