import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDatepickerInputEvent } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Cliente } from '../../core/models/cliente';
import { ReservasService } from '../../core/services/reservas.service';
import { first } from 'rxjs/operators';
import { TipoHabitacion } from '../../core/models/tipo-habitacion';
import { MantenimientosService } from '../../core/services/mantenimientos.service';
import { Habitacion } from '../../core/models/habitacion';
import { Reserva } from '../../core/models/reserva';
import { HabitacionService } from '../../core/services/habitacion.service';

@Component({
  selector: 'app-inserta-reserva',
  templateUrl: './inserta-reserva.component.html',
  styleUrls: ['./inserta-reserva.component.scss']
})
export class InsertaReservaComponent implements OnInit {
  
  formNewReserva: FormGroup;
  //NuevaRerserva:NuevoTrabajador=new NuevoTrabajador();
  date = new FormControl(new Date());
  date2 = new FormControl(new Date());

  listaClientes:Cliente[]=[];
  listaTiposHabitacion:TipoHabitacion[]=[];
  listaHabitaciones:Habitacion[]=[];
  decImporteTotal:any;
  habitacion:Habitacion=new Habitacion();
  cliente:Cliente=new Cliente();

  today = new FormControl(new Date());
  fechaInicio:any;
  fechaFin:any;
  fechaInicioValida:boolean=false;
  fechaFinValida:boolean=false;
  fechasError:boolean=false;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>,private _formBuilder: FormBuilder,private miservicio:MantenimientosService,
    @Inject(MAT_DIALOG_DATA) public data: {NuevaReserva:Reserva},private miservicioReservas:ReservasService,
    private miservicio2:HabitacionService) { }

  ngOnInit() {
    this.formNewReserva = this._formBuilder.group({
      inicio: ['', Validators.required],
      fin: ['', Validators.required],
      //importe: [''],
      habitacion: [''],
      cliente: [''],      
    })
    this.listarClientes();
    this.listarTiposHab();
    this.listarHabitaciones();
  }

  listarClientes(){
    this.miservicioReservas.getClientes().pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.listaClientes=datos;
      //console.log(this.listaClientes)
    })
  }

  listarTiposHab(){
    this.miservicio.getTiposHab(0).pipe(first()).subscribe(datos=>{
      this.listaTiposHabitacion=datos;
      //console.log(this.listaTiposHabitacion);
    })
  }

  public listarHabitaciones(){
    this.miservicio2.getHabitaciones().pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.listaHabitaciones=datos;
    })
  }

  addEvent( event: MatDatepickerInputEvent<Date>) {
    this.fechaInicio=event.value.toJSON();

    if(this.today.value.toJSON()<=this.fechaInicio){
      this.fechaInicioValida=true;
      this.fechaInicio=event.value.toJSON();
    }else{
      alert('No puede seleccionar una fecha inferior a la actual');
    }
    
  }

  addEvent2( event: MatDatepickerInputEvent<Date>) {
    this.fechaFin=event.value.toJSON();

    if(this.today.value.toJSON()>this.fechaFin){
      alert('No puede seleccionar una fecha inferior a la actual');
    }else{
      this.fechaFinValida=true;
      this.fechaFin=event.value.toJSON();
    }
  }

  salvar(){
    
    //console.log(this.habitacion);
    if(this.fechaInicio===undefined){
      this.fechaInicioValida=true;
      this.fechaInicio=this.today.value.toJSON();
    }

    if(this.fechaFin===undefined){
      this.fechaFinValida=false;
      this.fechaFin=this.today.value.toJSON();
    }else if(this.fechaFin===this.fechaInicio){
      this.fechaFinValida=false;
      alert('La fecha de salida no puede ser la misma que la de entrada')
    }else{
      this.fechaFinValida=true;
    }

    //console.log("Inicio"+this.fechaInicio);
    //console.log("fin"+this.fechaFin);
    if(this.fechaInicioValida===true && this.fechaFinValida===true){
      //console.log('ok')
      this.fechasError=false;
      /*this.miservicioReservas.postReserva(this.cliente,this.habitacion,this.fechaInicio,this.fechaFin,this.decImporteTotal).subscribe((datos:any)=>{
        //console.log(datos)
        if(datos.booOk===true){
          this.thisDialogRef.close();
        }
        /*if(datos.booOk===false){
          this.faltanDatos=true;
        }
      })*/
  }else{
      this.fechasError=true;
    }
  }

  cancelar(){
    this.thisDialogRef.close(this.data);
  }

}
