import {Component} from "@angular/core";

@Component({
  selector: 'sidebar-expanded',
  styles: [`  `],
  template: `
  <ng-content></ng-content>
`,
})
export class SidebarExpandedComponent {
}

