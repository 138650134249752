import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TipoHabitacion } from 'src/app/core/models/tipo-habitacion';
import { MantenimientosService } from 'src/app/core/services/mantenimientos.service';
import { Regimenes } from 'src/app/core/models/regimenes';
import { Configuracion } from 'src/app/core/models/configuracion';
import { HttpErrorResponse, HttpResponse, HttpHandler, HttpRequest } from '@angular/common/http';

const listDias=['L','M','X','J','V','S','D'];

@Component({
  selector: 'app-inserta-tarifa',
  templateUrl: './inserta-tarifa.component.html',
  styleUrls: ['./inserta-tarifa.component.scss']
})
export class InsertaTarifaComponent implements OnInit {

  formNew: FormGroup;
  submitted:boolean=false;
  miobjetoTarifa:objetoTarifa=new objetoTarifa();
  listTiposHabitacion:TipoHabitacion[]=[];
  listRegimenes:Regimenes[]=[];
  dateDesde = new FormControl(new Date());
  dateHasta = new FormControl(new Date());
  listFiltoDia:diasSemana[]=[];
  listDiaSeleccion:diasSemana[]=[];
  miConfiguracion:Configuracion=new Configuracion();
  //miConfiguracion:Configuracion[]=[];
  showSpinner: boolean = false;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>,@Inject(MAT_DIALOG_DATA) public data: any,private _formBuilder: FormBuilder,private miservicio:MantenimientosService) { }

  ngOnInit() {
    for(var i=0; i<listDias.length; i++){
      let mivalornum;
      mivalornum=i+1;
      if(i===6){
       mivalornum=0;
      }
      this.listFiltoDia.push({num:mivalornum,dia:listDias[i],check:true})
    }
    //console.log(this.data)
    // this.formNew = this._formBuilder.group({
    //   habitacion: ['', Validators.required],
    //   regimen: ['', Validators.required],
    //   pv: [''],
    //   iva: [''],
    //   pvp: ['', Validators.required],
    //   dto: [''],
    //   activo: ['']
    // })

    // this.formNew.get('pv').disable();
    this.listarTiposHabitacion();
    this.listarRegimenes();
    this.listarDatosIva();
  }

  listarTiposHabitacion(){
    this.miservicio.getTiposHab(0).subscribe(datos=>{
      this.listTiposHabitacion=datos.filter(e=>e.strActivo==="S");
    })
  }

  listarRegimenes(){
    this.miservicio.getRegimenes(0).subscribe(datos=>{
      this.listRegimenes=datos.filter(e=>e.strActivo==="S");
    })
  }

  public listarDatosIva(){
    this.miservicio.getConfiguraciones().subscribe(datos=>{
      //console.log(datos)  
      this.miConfiguracion=datos[0];
    })
  }

  marcarItem2(event: any,d)
  {
     this.listDiaSeleccion.splice(0, this.listDiaSeleccion.length)

     this.listFiltoDia.forEach((e:any) => {
       if(e.check===true){
        this.listDiaSeleccion.push(e.num)
       }
     });
  }

  cancelar(){
    this.thisDialogRef.close();
  }

  salvar(){    
    this.showSpinner=true;
    //let firstDay = new FormControl(new Date(this.date.value.getFullYear(), this.date.value.getMonth(), 1));

    this.listDiaSeleccion.splice(0, this.listDiaSeleccion.length)

     this.listFiltoDia.forEach((e:any) => {
      if(e.check===true){
        this.listDiaSeleccion.push(e.num)
      }
     });

    this.miservicio.insertaTarifaHabitacion(this.miobjetoTarifa,this.listDiaSeleccion,this.data.inicio,this.data.fin).subscribe(datos=>{
      console.log(datos)
      if(datos.length>0){
        this.showSpinner=false;
        this.thisDialogRef.close(datos);
        //this.listDatosTarifas.splice(0, this.listDatosTarifas.length);
        //this.listarDatos(firstDay.value.toLocaleDateString(),this.lastDay.value.toLocaleDateString());      
      }
    })

    //console.log(this.miobjetoTarifa)
    //console.log(this.listDiaSeleccion)

    
    /*this.miobjetoTarifa=new objetoTarifa();

    this.listFiltoDia.splice(0, this.listFiltoDia.length);
    for(var i=0; i<listDias.length; i++){
      let mivalornum;
      mivalornum=i+1;
      if(i===6){
       mivalornum=0;
      }
      this.listFiltoDia.push({num:mivalornum,dia:listDias[i],check:true})
    }*/
    
  }

}


export class objetoTarifa{
  intTipoHabitacion:number;
  intTipoRegimen:number;
  datFechaDesde:any;
  datFechaHasta:any;
  decPvp:number;
}

export interface diasSemana {
  num: number;
  dia: string;
  check:boolean;
}