export class User {
    
    public strUserName?:string;
    public strRolName?:string;
    public strUserId?:string;
    public strRolId?:string;
    public strEntidadId?:string;
    public strEntidadName?:string;
    public strActivo?:string;
}

