import { Component, OnInit, ViewChild } from '@angular/core';
import { Habitacion } from '../core/models/habitacion';
import { StorageService } from '../core/services/storage.service';
import { HabitacionService } from '../core/services/habitacion.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import {MatTableDataSource, MatSort, MatPaginator, MatDialog} from '@angular/material';
import { User } from '../core/models/user.model';
import { FormControl } from '@angular/forms';
import { HabitacionLimpieza } from '../core/models/habitacion-limpieza';
import { DataSource } from '@angular/cdk/collections';
import { map, first } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { EditarComponent } from '../editar/editar.component';

@Component({
  selector: 'app-habitacion-asignada',
  templateUrl: './habitacion-asignada.component.html',
  styleUrls: ['./habitacion-asignada.component.scss']
})
export class HabitacionAsignadaComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  showSpinner: boolean = false;
  //listaHabitaciones:HabitacionLimpieza[]=[];
  
  //public loginUserData: User;
  date = new FormControl(new Date());

  data:HabitacionLimpieza[]=[];
  dataTodo:HabitacionLimpieza[]=[];
  //displayedColumns = ['strHabitacionNombre','strTipoHabitacionNombre','strHabitacionDescripcion','intEstadoLimpiezaId',];
  displayedColumns = ['strHabitacionNombre','strEstHabitacion','strUltimaNotaLimpieza'];
  dataSource ;
  puntoPartida:string="miTrabajo";

  constructor(
    private storageService: StorageService,public dialog: MatDialog,
    private miservicio:HabitacionService,
    private router: Router,private route: ActivatedRoute,
  ) { }

  ngOnInit() {
     this.listar();
  }

public listar(){
  this.dataSource = new MyTableDataSource(this.paginator, this.sort);

  let fecha=this.date.value.toLocaleDateString();
  let usuario=this.storageService.getCurrentUser().strUserName;

  this.miservicio.getHabLimpiezas(fecha,usuario,'').pipe(first()).subscribe(datos=>{
    //console.log(datos);
    this.data=datos;
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
  })
}

  getColor(intEstadoLimpiezaId) { 
    switch (intEstadoLimpiezaId) {
      case 1:
        return '#ff3333';
      case 2:
        return '#ffc14d';
      case 3:
        return '#00b300';
    }
  }

  /*cambia(h:HabitacionLimpieza){
    let extra: NavigationExtras = {
      queryParams: {
        'intHabitacionId':h.intHabitacionId,
        'datFecha':h.datFecha,
        'intLimpiezaId':h.intLimpiezaId,
        'puntoPartida':this.puntoPartida
      }
  }
    this.router.navigate(["editar"],extra);  
  }*/

  cambia(h:HabitacionLimpieza){
    let dialogRef = this.dialog.open(EditarComponent, {
      width: '600px',
      data: {
        'strHabitacionNombre':h.strHabitacionNombre,
          'intHabitacionId':h.intHabitacionId,
          'datFechaRegistro':h.datFecha,
          'intLimpiezaId':h.intLimpiezaId,
          'intEstadoLimpiezaId':h.intEstadoLimpiezaId,
          'strUltimaNotaLimpieza':h.strUltimaNotaLimpieza,
          'puntoPartida':this.puntoPartida
      }
    });
    dialogRef.afterClosed().subscribe((datos) => {
      //console.log(datos)
      //this.listar();
      if(datos!=undefined){
        let myObject:any;
        myObject=datos.miListHabitacionLimpieza[0];

        let miTicket=datos.miListHabitacionLimpieza[0].intHabitacionId;

        var index = this.data.map(x => {
          return x.intHabitacionId;
        }).indexOf(miTicket);

        if(datos.length!=0){      
          this.data.splice(index, 1, myObject);
          this.dataSource=new MatTableDataSource(this.data);
        }
        this.dataSource=new MatTableDataSource(this.data);
        if(datos.length===0){
          this.data.splice(index, 1);
        }
      }
    });
  }

}

export class MyTableDataSource extends DataSource<HabitacionLimpieza> {
  data:HabitacionLimpieza[]=[];
 
   constructor(private paginator: MatPaginator, private sort: MatSort) {
     super();
   }
 
   connect(): Observable<HabitacionLimpieza[]> {
     const dataMutations = [
       observableOf(this.data),
       this.paginator.page,
       this.sort.sortChange
     ];
 
     this.paginator.length = this.data.length;
 
     return merge(...dataMutations).pipe(map(() => {
       return this.getPagedData(this.getSortedData([...this.data]));
     }));
   }
 
   disconnect() {}
 
   private getPagedData(data: HabitacionLimpieza[]) {
     const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
     return data.splice(startIndex, this.paginator.pageSize);
   }
 
 
   private getSortedData(data: HabitacionLimpieza[]) {
     if (!this.sort.active || this.sort.direction === '') {
       return data;
     }
 
     return data.sort((a, b) => {
       const isAsc = this.sort.direction === 'asc';
       switch (this.sort.active) {
         case 'strTipoHabitacionNombre': return compare(a.strTipoHabitacionNombre, b.strTipoHabitacionNombre, isAsc);
         case 'strHabitacionDescripcion': return compare(+a.strHabitacionDescripcion, +b.strHabitacionDescripcion, isAsc);
         default: return 0;
       }
     });
   }
 }
 
 function compare(a, b, isAsc) {
   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
 }
