export class Reserva {

    public intId?:number;
    public intGrupoId?:number;
    public datFechaRegistro?:Date;
    public datFechaActualizacion?:Date;
    public intHabitacionId?:number;
    public strHabitacionNombre?:string;
    public datEntrada?:Date;
    public datSalida?:Date;
    public intClienteId?:number;
    public strClienteNombre?:string;
    public strClienteApellidos?:string;
    public decImporteTotal?:number; 
    public intEstadoId?:number;
    public strEstado?:string;
    public strCerrada?:string;
    public intRegimenId?:number;
    public strRegimenNombre?:string;
    public intTipoHabitacionId?:number;
    public strTipoHabitacionNombre?:string;
    public strOrigen?:string;
    public strClienteTelefono?:string;
    public strClienteEmail?:string;
    public intEstancia?:number;
    public strTipo?:string;

}
