export class Iva {

    public intId?:number;
    public strNombre?:string;
    public decValor?:number;
    public strDefecto?:string;
    public strActivo?:string;
    public strChecked?:boolean;

}
