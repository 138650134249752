import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {StorageService} from './storage.service';
import { environment } from '../../../environments/environment';
import { Limpieza } from '../models/limpieza';
import { Observable, interval } from 'rxjs';
import { Habitacion } from '../models/habitacion';
import { HabitacionLimpieza } from '../models/habitacion-limpieza';
import { Estados } from '../models/estados';
import { Empleado } from '../models/empleado';
import { NuevoTrabajador } from '../models/nuevo-trabajador';
import { flatMap } from 'rxjs/operators';
import { EstadosLimpieza } from '../models/estados-limpieza';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class HabitacionService {

  constructor(private http: HttpClient, private storageService: StorageService ) {}

  public url: string = environment.baseUrl;

  private token=this.storageService.getCurrentToken();
  private cookie=this.storageService.getCurrentSession().strCookie;
  //private userId=this.storageService.getCurrentUser().strUserId;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + this.token  })
  };

  getHabitaciones():Observable<Habitacion[]>{
    let dato='strCookie='+this.cookie;
    let urlCompleta=this.url+'/api/habitacion?'+dato;
 
    return this.http.get<Habitacion[]>(urlCompleta,this.httpOptions);
  }

  getHabLimpiezas(strFecha:string, strUsuarioNombre:string, strEstadoLimpiezaId:string):Observable<any[]>{
    let dato='strCookie='+this.cookie+'&strFecha='+strFecha+'&strUsuarioNombre='+strUsuarioNombre+'&strEstadoLimpiezaId='+strEstadoLimpiezaId;
    let urlCompleta=this.url+'/api/habitacionlimpieza?'+dato;
 
    return this.http.get<any[]>(urlCompleta,this.httpOptions);
  }

  public getClients(strFecha:string, strUsuarioNombre:string, strEstadoLimpiezaId:string) {
    let dato='strCookie='+this.cookie+'&strFecha='+strFecha+'&strUsuarioNombre='+strUsuarioNombre+'&strEstadoLimpiezaId='+strEstadoLimpiezaId;
    let urlCompleta=this.url+'/api/habitacionlimpieza?'+dato;

    return interval(6000).pipe(flatMap( () => {return this.http.get<any[]>(urlCompleta,this.httpOptions)}));
  }

  getHabLimpiezas2(strFecha:string):Observable<any[]>{
    let dato='strCookie='+this.cookie+'&strFecha='+strFecha;
    let urlCompleta=this.url+'/api/habitacionlimpieza?'+dato;
 
    return this.http.get<any[]>(urlCompleta,this.httpOptions);
  }

  getEstadosLimpieza():Observable<EstadosLimpieza[]>{
    let token=this.storageService.getCurrentToken();
    let dato='strCookie='+this.cookie;
    return this.http.get<EstadosLimpieza[]>(this.url + '/api/estadolimpieza?' +dato, this.httpOptions);
  } 

  putEstadosLimpieza(miEstadoNuevo):Observable<any>{
    let urlCompleta=this.url+'/api/estadolimpieza?'+"strCookie="+this.cookie ;
    var jsonString = JSON.stringify(miEstadoNuevo);
    return this.http.put<any>(urlCompleta, jsonString, this.httpOptions);
  }

  postEstadosLimpieza(miEstadoNuevo):Observable<EstadosLimpieza>{
    let urlCompleta=this.url+'/api/estadolimpieza?'+"strCookie="+this.cookie ;
    var jsonString = JSON.stringify(miEstadoNuevo);
    return this.http.post<any>(urlCompleta, jsonString, this.httpOptions);
  }

  getEstadosHabitacion():Observable<Estados[]>{
    let dato='strCookie='+this.cookie;
    return this.http.get<Estados[]>(this.url + '/api/EstadoHabitacion?' +dato, this.httpOptions);
  }

  /*habAsignada(fecha){
    let token=this.storageService.getCurrentToken();
    let usuario=this.storageService.getCurrentUser().strUserName;
    let dato='strToken='+token+'&strFecha='+ fecha +'&strUsuarioNombre='+usuario+'&strEstadoLimpiezaId=';
    let urlCompleta=this.url+'habitacionlimpieza?'+dato;
 
    return this.http.get(urlCompleta);
  }*/

  getLimpieza(data,strUserId:string):Observable<Limpieza[]>{
    let dato='strCookie='+this.cookie+'&intLimpiezaId='+data.intLimpiezaId+'&intHabitacionId='+data.intHabitacionId+'&strUserId='+strUserId+'&strFecha='+data.datFechaRegistro;
    //let dato='strCookie='+this.cookie+'&intLimpiezaId='+data.intId+'&intHabitacionId='+data.intHabitacionId+'&strUserId='+strUserId+'&strFecha='+data.datFechaRegistro;

    let urlCompleta=this.url+'/api/limpieza?'+dato;
 
    return this.http.get<Limpieza[]>(urlCompleta,this.httpOptions);
  }

  putLimpieza(limpieza:Limpieza):Observable<Limpieza>{
    let urlCompleta=this.url+'/api/limpieza?'+"strCookie="+this.cookie ;
    //let dato = '{"intId":"'+ limpieza.intId +'", "intEstadoLimpiezaId":'+ limpieza.intEstadoLimpiezaId +', "strNotas":"'+ limpieza.strNotas +'", "strHoraInicio":"'+ limpieza.strHoraInicio +'", "strHoraFin":"'+ limpieza.strHoraFin +'"}'; 

    let dato:any = {};
    //dato.strUserId = limpieza.strUserId;
    dato.intHabitacionId=limpieza.intHabitacionId;
    dato.intId=limpieza.intId;
    dato.intEstadoLimpiezaId=limpieza.intEstadoLimpiezaId;
    dato.strNotas=limpieza.strNotas;
    dato.strHoraInicio=limpieza.strHoraInicio;
    dato.strHoraFin=limpieza.strHoraFin;

    var jsonString = JSON.stringify(dato);


    return this.http.put<Limpieza>(urlCompleta, jsonString, this.httpOptions);
  }

  putLimpieza2(limpiezaHoy:Limpieza):Observable<Limpieza>{
    let urlCompleta=this.url+'/api/limpieza?'+"strCookie="+this.cookie ;
    //let dato = '{"intId":"'+ limpieza.intId +'", "intEstadoLimpiezaId":'+ limpieza.intEstadoLimpiezaId +', "strNotas":"'+ limpieza.strNotas +'", "strHoraInicio":"'+ limpieza.strHoraInicio +'", "strHoraFin":"'+ limpieza.strHoraFin +'"}'; 
    //let dato = '{"strUserId":"'+ this.userId +'","intHabitacionId":'+ limpiezaHoy.intHabitacionId +',"intId":"'+ limpiezaHoy.intId +'", "intEstadoLimpiezaId":'+ limpiezaHoy.intEstadoLimpiezaId +', "strNotas":"'+ limpiezaHoy.strNotas +'", "strHoraInicio":"'+ limpiezaHoy.strHoraInicio +'", "strHoraFin":"'+ limpiezaHoy.strHoraFin +'"}'; 

    let dato:any = {};
    dato.strUserId = limpiezaHoy.strUserId;
    dato.intHabitacionId=limpiezaHoy.intHabitacionId;
    dato.intId=limpiezaHoy.intId;
    dato.intEstadoLimpiezaId=limpiezaHoy.intEstadoLimpiezaId;
    dato.strNotas=limpiezaHoy.strNotas;
    dato.strHoraInicio=limpiezaHoy.strHoraInicio;
    dato.strHoraFin=limpiezaHoy.strHoraFin;

    var jsonString = JSON.stringify(dato);

    return this.http.put<Limpieza>(urlCompleta, jsonString, this.httpOptions);
  }

  getEmpleados(intId):Observable<Empleado[]>{

    let dato='strCookie='+this.cookie+'&intId='+intId;

    return this.http.get<Empleado[]>(this.url+'/api/trabajador?' +dato,this.httpOptions)
  }

  addEmpleado(NuevoEmpleado):Observable<NuevoTrabajador>{    
    let urlCompleta=this.url+'/api/trabajador?'+"strCookie="+this.cookie ;

    let dato:any = {};
    dato.strNombre = NuevoEmpleado.strNombre;
    dato.strApellidos=NuevoEmpleado.strApellidos;
    dato.strDni=NuevoEmpleado.strDni;
    dato.strEmail=NuevoEmpleado.strEmail;
    dato.strTelefono1=NuevoEmpleado.strTelefono1;
    dato.strTelefono2=NuevoEmpleado.strTelefono2;
    dato.strDireccion=NuevoEmpleado.strDireccion;
    dato.strCP=NuevoEmpleado.strCP;
    dato.strProvincia=NuevoEmpleado.strProvincia;
    dato.strPoblacion=NuevoEmpleado.strPoblacion;
    dato.strPais=NuevoEmpleado.strPais;
    dato.strActivo="S";
    dato.strUserName=NuevoEmpleado.strUserName;
    dato.strPassword=NuevoEmpleado.strPassword;

    var jsonString = JSON.stringify(dato);
    //console.log(jsonString);

    /*let dato = '{"strNombre": "'+ NuevoEmpleado.strNombre +'", "strApellidos":"'+ NuevoEmpleado.strApellidos +'", ';
    dato += '"strDni":"'+ NuevoEmpleado.strDni+'", "strEmail":"'+ NuevoEmpleado.strEmail+'", ';
    dato +='"strTelefono1":"'+ NuevoEmpleado.strTelefono1+'", "strTelefono2":"'+ NuevoEmpleado.strTelefono2+'", ';
    dato +='"strDireccion":"'+ NuevoEmpleado.strDireccion+'", "strCP":"'+ NuevoEmpleado.strCP+'", ';
    dato +='"strProvincia":"'+ NuevoEmpleado.strProvincia+'", "strPoblacion":"'+ NuevoEmpleado.strPoblacion+'", ';
    dato +='"strPais":"'+ NuevoEmpleado.strPais+'", "strActivo":"S", ';
    dato +='"strUserName":"'+ NuevoEmpleado.strUserName+'", "strPassword":"'+ NuevoEmpleado.strPassword+'"}';*/

    //let dato = '{"strNombre": "'+ NuevoEmpleado.strNombre +'", "strApellidos":"'+ NuevoEmpleado.strApellidos +'", "strDni":"'+ NuevoEmpleado.strDni+'", "strEmail":"'+ NuevoEmpleado.strEmail+'", "strTelefono1":"'+ NuevoEmpleado.strTelefono1+'", "strTelefono2":"'+ NuevoEmpleado.strTelefono2+'", "strDireccion":"'+ NuevoEmpleado.strDireccion+'", "strCP":"'+ NuevoEmpleado.strCP+'", "strProvincia":"'+ NuevoEmpleado.strProvincia+'", "strPoblacion":"'+ NuevoEmpleado.strPoblacion+'", "strPais":"'+ NuevoEmpleado.strPais+'", "strActivo":"S", "strUserName":"'+ NuevoEmpleado.strUserName+'", "strPassword":"'+ NuevoEmpleado.strPassword+'"}';
    return this.http.post<NuevoTrabajador>(urlCompleta,jsonString,this.httpOptions);
  }

  putEmpleado(trabajador,miEstado):Observable<any>{
    let urlCompleta=this.url+'/api/trabajador?'+"strCookie="+this.cookie ;

    let dato:any = {};
    dato.intId=trabajador.intId;
    dato.strNombre = trabajador.strNombre;
    dato.strApellidos=trabajador.strApellidos;
    dato.strDni=trabajador.strDni;
    dato.strEmail=trabajador.strEmail;
    dato.strTelefono1=trabajador.strTelefono1;
    dato.strTelefono2=trabajador.strTelefono2;
    dato.strDireccion=trabajador.strDireccion;
    dato.strCP=trabajador.strCP;
    dato.strProvincia=trabajador.strProvincia;
    dato.strPoblacion=trabajador.strPoblacion;
    dato.strPais=trabajador.strPais;
    dato.strActivo=miEstado;
    dato.strUserName=trabajador.strUserName;
    //dato.strPassword=trabajador.strPassword;

    var jsonString = JSON.stringify(dato);
    return this.http.put<any>(urlCompleta,jsonString,this.httpOptions);
  }

  addAsignacion(selectObjc,data,strNota):Observable<any>{
    let urlCompleta=this.url+'/api/HabitacionASPNETUser?'+"strCookie="+this.cookie ;
    let dato = '{"intIdHabitacion": "'+ data.intHabitacionId +'", "strUserId":"'+ selectObjc +'", "strNota":"'+ strNota +'"}';
    return this.http.post<any>(urlCompleta, dato, this.httpOptions);             
  }

  getAsignacionTrabajo(){
    let dato='strCookie='+this.cookie;

    return this.http.get(this.url+'/api/HabitacionASPNETUser?' +dato, this.httpOptions)
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    //console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
