import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {FormBuilder, FormGroup, FormControl} from '@angular/forms';
import { User } from '../core/models/user.model';
import { StorageService } from '../core/services/storage.service';
import { MatDialog } from '@angular/material';
import { InicioComponent } from '../inicio/inicio.component';
import { MantenimientosService } from '../core/services/mantenimientos.service';
import { first } from 'rxjs/operators';
import { Configuracion } from '../core/models/configuracion';
import { Menu } from '../core/models/permisos/menu';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

  public loginUserData: User;
  showSpinner: boolean = false;
  miTrabajo:boolean=false;
  soyAdmin:boolean=false; 

  abierto:boolean=true;
  cerrado:boolean=false;
  hide = true;
  miConfiguracion:Configuracion[]=[];

  menuGobernantaAdmin:boolean=false;
  menuGobernantaTrabajador:boolean=false;

  constructor(public dialog: MatDialog,private miservicio:MantenimientosService, private router:Router,
    private storageService: StorageService) { }

  ngOnInit() {
    //console.log(this.storageService.getCurrentSession());
    //this.miMenu=this.storageService.getCurrentSession().miListMenuResult; 

    var event = new Date(this.storageService.getCurrentSession().expires);
    var jsonDate = event.toJSON();
    var today = new FormControl(new Date()).value.toJSON();
    if(jsonDate>today){
    }else if(jsonDate<today){
      setTimeout(() => {
        this.openWindow();
      }, 100); 
    }    
    this.loginUserData = this.storageService.getCurrentUser();
    //this.definirUser();
    this.definirMenu();
    this.cargarDatosGenerales();
  }

  agregarItemHijo(){}

  openWindow(){
    let dialogRef = this.dialog.open(InicioComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public logout(): void{
    this.showSpinner = true;

          setTimeout(() => {
            this.showSpinner = false;
            this.storageService.logout();
          }, 500);  
  }


  definirMenu(){
  //console.log(this.storageService.getCurrentSession().intMenuId);
  //1. --> Gobernanta-Admin
  //2. --> Gobernanta-Trabajador
  //3. --> DNI-Admin
  //4. --> Facturacion-Admin
  //5. --> Enterprise-Admin
  
  switch(this.storageService.getCurrentSession().intMenuId){
    case 1:
      return this.menuGobernantaAdmin=true;
    
    case 2:
      return this.menuGobernantaTrabajador=true;
  }

  //console.log(this.menuGobernantaAdmin)
  //console.log(this.menuGobernantaTrabajador)

  }

  verHotel(){    
    
    this.showSpinner = true;

          setTimeout(() => {
            this.showSpinner = false;
            this.router.navigate(['/hotel'])
          }, 1000);  
  }

  /*public definirUser(){
  this.loginUserData = this.storageService.getCurrentUser();
    if(this.loginUserData.strRolName.includes("Administrador")){
      return this.soyAdmin=true;
    }else{
      this.hide = false;
      return this.miTrabajo=true;
    }
  }*/

  cargarDatosGenerales(){
    this.miservicio.getConfiguraciones().pipe(first()).subscribe(datos=>{   
      this.miConfiguracion=datos;
    })
  }

 openNav() {
  document.getElementById("miSidenav").style.width="250px";

  this.abierto=true;
  this.cerrado=false;
}
 closeNav() {
   //console.log('oo')
  document.getElementById("miSidenav").style.width="0";
  
  this.abierto=false;
  this.cerrado=true;
  
}

}
