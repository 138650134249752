import {Component, ViewChild, OnInit} from "@angular/core";
import {DayPilot, DayPilotModalComponent} from "daypilot-pro-angular";
import {Validators, FormBuilder, FormGroup, FormControl} from "@angular/forms";
import {Observable, AsyncSubject} from "rxjs";
import { UpdateReservationParams, DataService } from "../service/data.service";
import { Cliente } from "../../core/models/cliente";
import { ReservasService } from "../../core/services/reservas.service";
import { first } from "rxjs/operators";
import { Regimenes } from "../../core/models/regimenes";
import { MatDialog } from "@angular/material";
import { InsertaReservaComponent } from "../../reservas/inserta-reserva/inserta-reserva.component";
import { BuscaClienteComponent } from "./busca-cliente/busca-cliente.component";
import { DatePipe } from "@angular/common";
import { ModifyReservationEditComponent } from "./modify-reservation-edit/modify-reservation-edit.component";

@Component({
  selector: 'reservation-edit-dialog',
  template: `
    <daypilot-modal>
    <div class="center">      
      
      <h1>Editar reserva</h1>
      <br>
      <div class="myheaders">
        <div class="form-item">
        <!--<label for="nuevo">Nuevo cliente </label>
        <input type="radio" id="nuevo" name="cliente" value="nuevo" (click)="addClienteNuevo()" />
        &nbsp;&nbsp;
        <label for="buscar">Buscar cliente </label>
        <input type="radio" id="buscar" name="cliente" value="buscar" (click)="buscarCliente()" />-->            
        </div>
        <div class="form-item" *ngIf="miCliente">
        *Cliente
          <select [(ngModel)]="selectCliente">          
            <option *ngFor="let r of listaClientes" [ngValue]="r.intId">{{r.strNombre}} {{r.strApellidos}}</option>
          </select>
        </div>
        </div>
        <form [formGroup]="form">
        <div class="form-item">
        *Nº cliente: <input type="text" class="mini" formControlName="name" name="intId"  [(ngModel)]="intId" disabled/>
        &nbsp;&nbsp;
        <button (click)="findCliente()">&nbsp; * &nbsp;</button>
        </div>
        <div class="form-item">
        *Nombre: <input type="text" formControlName="formNombre" name="nombre"  [(ngModel)]="nombre"/>        
        </div>
        <div class="form-item">
        *Apellidos: <input type="text" formControlName="formApellidos" name="apellidos"  [(ngModel)]="apellidos"/>         
        </div>                
        
        <div class="form-item">
          *Habitación
          <select formControlName="resource">
            <option *ngFor="let it of resources" [ngValue]="it.id">{{it.name}}</option>
          </select>
        </div>
        <div class="form-item">
        *Régimen 
          <select formControlName="midato1" [(ngModel)]="selectRegimen">
            <option *ngFor="let r of listRegimenes" [ngValue]="r.intId">{{r.strNombre}}</option>
          </select>
        </div>
        <div class="form-item">
        *Entrada <input formControlName="start" type="text" placeholder="Start"> <span *ngIf="!form.controls.start.valid"> *Fecha no válida</span>
        </div>
        <div class="form-item">
        *Salida <input formControlName="end" type="text" placeholder="End"> <span *ngIf="!form.controls.end.valid"> *Fecha no válida</span>
        </div>
        <!--<div class="form-item">
          Estado reserva:
          <select formControlName="strEstadoReserva">
            <option *ngFor="let it of statuses" [ngValue]="it.value">{{it.name}}</option>
          </select>
        </div>-->
        <div class="form-item">
        <button (click)="eliminaReserva()">Anular reserva</button>        
        </div>
        <!--<div class="form-item">
          Pagada:
          <select formControlName="paid">
            <option *ngFor="let it of paid" [ngValue]="it.value">{{it.name}}</option>
          </select>
        </div>-->
        <br>
        <div class="form-item">
        <ul>
          <li>
            <button (click)="submit()" [disabled]="!form.valid">Guardar</button>
          </li>
          <li>
            <button (click)="cancel()">Cancelar</button>
          </li>
        </ul>
        </div>
    </form>
    </div>
    </daypilot-modal>
  `,
  styles: [`
  .center {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top:80px;
  }
  .center form{
    margin:0 auto;
    max-width:400px;
  }
  .form-item {
    margin: 4px 0px;
  }

  .form-item .mini{
    max-width:50px;
  }
  .myheaders, h1{
    margin:0 auto;
    max-width:400px;
  }

  .form-item ul{
    width:100%;
    list-style:none;
  }

  li{
    width:50%;
    display: inline-block;
  }
  li button{
    width:40%;
    margin:0 auto;
  }
  `]
})
export class ReservationEditComponent implements OnInit{
  @ViewChild(DayPilotModalComponent) modal : DayPilotModalComponent;

  form: FormGroup;
  dateFormat = "dd/MM/yyyy";

  resources: any[];
  listaClientes:Cliente[]=[];
  listRegimenes:Regimenes[]=[];
  selectRegimen:any;
  selectCliente:any;

  seleccionMetodo:boolean=true;
  nuevoCliente:boolean=false;
  miCliente:boolean=false;
  
  nombre:any;
  apellidos:any;

  event: DayPilot.Event;
  isChecked:boolean=false;
  Estado:string;
  intId:any;

  subject: AsyncSubject<UpdateReservationParams>;
  fechaHoy = new FormControl(new Date());

  statuses: any[] = [
    { name: "New", value: "New"},
    { name: "Confirmed", value: "Confirmed"},
    { name: "Arrived", value: "Arrived"},
    { name: "Checked Out", value: "CheckedOut"},
  ];

   paid: any[] = [
     { name: "0%", value: "0"},
     { name: "50%", value: "50"},
     { name: "100%", value: "100"},
   ];

  constructor(private fb: FormBuilder, private ds: DataService,private miservicioReservas:ReservasService,public dialog: MatDialog
    ,public datepipe: DatePipe) {
    this.form = this.fb.group({
      name: [""],
      start: ["", this.dateTimeValidator(this.dateFormat)],
      end: ["", [Validators.required, this.dateTimeValidator(this.dateFormat)]],
      resource: ["", Validators.required],
      strEstadoReserva: ["", Validators.required],
      midato1: ["", Validators.required],
      formNombre:[""],
      formApellidos:[""]      
    });


  }

  ngOnInit(){
    this.listarClientes();
    this.listarRegimenes();
  }

  eliminaReserva(){
    
  }

  findCliente(){
    let dialogRef = this.dialog.open(BuscaClienteComponent, {
      width: '280px',
    });
    dialogRef.afterClosed().subscribe(result => {
      //this.listarRservas();
      //console.log(result)
      this.form.controls.name.setValue(result[0].intId)
      this.form.controls.formNombre.setValue(result[0].strNombre)
      this.form.controls.formApellidos.setValue(result[0].strApellidos)
      //console.log(this.form.value.formApellidos.setValue(result[0].strApellidos))
      /*this.form.setValue({
        //start: ev.start().toString(this.dateFormat),
        //end: ev.end().toString(this.dateFormat),
        name: result[0].intId,
        //resource: ev.resource(),
        //strEstadoReserva: result.strEstadoReserva,
        //midato1:result.intRegimenId,
        formNombre:result[0].strNombre,
        formApellidos:result[0].strApellidos
      });*/
      
    });
  }

  buscarCliente(){
    this.seleccionMetodo=false;
    this.nuevoCliente=false;
    this.miCliente=true;
  }

  addClienteNuevo(){
    this.seleccionMetodo=false;
    this.nuevoCliente=true;
    this.miCliente=false;
  }

  listarClientes(){
    this.miservicioReservas.getClientes().pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.listaClientes=datos;
      //console.log(this.listaClientes)
    })
  }

  listarRegimenes(){
    
    this.miservicioReservas.getRegimenes().pipe(first()).subscribe(datos=>{
      //console.log(datos);
      //this.listRegimenes=datos;
      this.listRegimenes=datos.filter(e=>e.strActivo==="S");
    })
  }

  show(ev: DayPilot.Event): Observable<UpdateReservationParams>  {

    /*let dialogRef = this.dialog.open(ModifyReservationEditComponent, {
      width: '280px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.form.controls.name.setValue(result[0].intId)
      this.form.controls.formNombre.setValue(result[0].strNombre)
      this.form.controls.formApellidos.setValue(result[0].strApellidos)      
    });*/


    this.ds.getRooms().subscribe(result =>{ 
      //this.resources = result; 
      let lista:any[]=[];
       result.forEach(element => {
        lista.push({id:element.intHabitacionId.toString(), name:element.strHabitacionNombre, capacity:element.strTipoHabitacionNombre, intTipoHabitacion:element.intTipoHabitacionId, status:element.intEstadoLimpiezaId});
      })
      this.resources=lista;
    });
    

    this.event = ev;
    /*if(ev.data.intClienteId===0 || ev.data.intClienteId==="" ){
      this.nuevoCliente=true;
      this.miCliente=false;
      //console.log(ev.data)
      

    }else{
      this.nuevoCliente=false;
      this.miCliente=true;

      this.form.setValue({
        start: ev.start().toString(this.dateFormat),
        end: ev.end().toString(this.dateFormat),
        name: ev.data.intClienteId,
        resource: ev.resource(),
        strEstadoReserva: ev.data.strEstadoReserva,
        midato1:ev.data.intRegimenId,
        formNombre:"",
        formApellidos:""
      });
    }*/
    
    if(ev.data.intClienteId===0){
      ev.data.intClienteId="";
    }
    this.form.setValue({
      start: ev.start().toString(this.dateFormat),
      end: ev.end().toString(this.dateFormat),
      name: ev.data.intClienteId,
      resource: ev.resource(),
      strEstadoReserva: ev.data.strEstadoReserva,
      midato1:ev.data.intRegimenId,
      formNombre:ev.data.strClienteNombre,
      formApellidos:ev.data.strClienteApellidos
    });   

    let miFecha =  new Date();
    let miFechaConvert=this.datepipe.transform(miFecha, 'yyyy-MM-dd');    
    let inicioFecha=this.datepipe.transform(ev.data.start, 'yyyy-MM-dd');
    let finFecha=this.datepipe.transform(ev.data.end, 'yyyy-MM-dd');

    if(inicioFecha>=miFechaConvert || finFecha>miFechaConvert){
      this.modal.show();
      //console.log(ev.data.inClienteId);
      this.subject = new AsyncSubject();
      return this.subject.asObservable();
    }else{

    }     
    
    
   
  }

  submit() {
    let data = this.form.getRawValue();

    //let miClienteNombre=this.listaClientes.find(c=>c.intId=)
    /*if(this.selectCliente===undefined){
      this.selectCliente=this.form.controls.name.value;
    }*/

    // let params: UpdateReservationParams = {
    //   id: this.event.id(),
    //   start: DayPilot.Date.parse(data.start, this.dateFormat),
    //   end: DayPilot.Date.parse(data.end, this.dateFormat),
    //   room: data.resource,
    //   intClienteId:this.form.controls.name.value,
    //   strClienteNombre: this.nombre,
    //   strClienteApellidos: this.apellidos,
    //   strEstadoReserva: data.strEstadoReserva,
    //   //paid: data.paid,
    //   intRegimenId:this.selectRegimen,      
    //   regimenNombre:data.regimenNombre
    // };

    this.modal.hide();

    // this.subject.next(params);
    this.subject.complete();
    this.nuevoCliente=false;
    this.miCliente=false;

  }

  cancel() {
    this.modal.hide();

    this.subject.next(null);
    this.subject.complete();
    this.nuevoCliente=false;
    this.miCliente=false;
  }

  dateTimeValidator(format: string) {
    return function(c:FormControl) {
      let valid = !!DayPilot.Date.parse(c.value, format);
      return valid ? null : {badDateTimeFormat: true};
    };
  }
}

