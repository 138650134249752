import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { ReservasService } from 'src/app/core/services/reservas.service';
import { first,map } from 'rxjs/operators';
import { Regimenes } from 'src/app/core/models/regimenes';
import { Cliente } from 'src/app/core/models/cliente';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BuscaClienteComponent } from '../busca-cliente/busca-cliente.component';
import { DataService, UpdateReservationParams } from '../../service/data.service';
import { DayPilot } from 'daypilot-pro-angular';
import { Reserva } from 'src/app/core/models/reserva';
import { EdicionClientesComponent } from 'src/app/clientes/edicion-clientes/edicion-clientes.component';
import { HabitacionService } from 'src/app/core/services/habitacion.service';
import { Habitacion } from 'src/app/core/models/habitacion';
import { AgregaHuespedComponent } from '../../componentes-auxiliares/agrega-huesped/agrega-huesped.component';
import { Huesped } from 'src/app/core/models/huesped';
import { VentanaErrorComponent } from 'src/app/ventana-error/ventana-error.component';
import { NuevoGastoComponent } from '../../componentes-auxiliares/nuevo-gasto/nuevo-gasto.component';
import { AgregaHuespedNuevaReservaComponent } from '../../componentes-auxiliares/agrega-huesped-nueva-reserva/agrega-huesped-nueva-reserva.component';
import { ReservaGasto } from 'src/app/core/models/reserva-gasto';
import { EditaGastoComponent } from '../../componentes-auxiliares/edita-gasto/edita-gasto.component';
import { ReservaPago } from 'src/app/core/models/reserva-pago';
import { ReservaPagoComponent } from '../../componentes-auxiliares/reserva-pago/reserva-pago.component';
import { EditaHuespedComponent } from '../../componentes-auxiliares/edita-huesped/edita-huesped.component';
import { Facturado } from 'src/app/core/models/facturado';
import { TitularFacturaComponent } from '../../componentes-auxiliares/titular-factura/titular-factura.component';
import { Facturas } from 'src/app/core/models/facturas';
import { EditaHabitacionReservaComponent } from '../../componentes-auxiliares/edita-habitacion-reserva/edita-habitacion-reserva.component';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { EstadoReserva } from 'src/app/core/models/estado-reserva';

@Component({
  selector: 'app-modify-reservation-edit',
  templateUrl: './modify-reservation-edit.component.html',
  styleUrls: ['./modify-reservation-edit.component.scss']
})
export class ModifyReservationEditComponent implements OnInit {

  //RESERVA
  //=================================
  miReserva:Reserva=new Reserva();
  listaClientes:Cliente[]=[];
  listHabitaciones:Habitacion[]=[];  
  listRegimenes:Regimenes[]=[];
  listEstadosReservas:EstadoReserva[]=[];

  //hABITACIONES
  //==============================
  listHabitacionesHuespedes:Reserva[]=[];

  //HUESPEDES
  //=================================
  listHuespedes:Huesped[]=[];

  //GASTOS
  //==================================  
  listReservaGastos:ReservaGasto[]=[];

  //PAGOS
  //==================================  
  listReservaPagos:ReservaPago[]=[];

  //FACTURACION
  //==================================  
  listFacturadosTodos:Facturado[]=[];
  listFacturados:Facturado[]=[];
  miFactura:Facturas=new Facturas();
  listFacturas:Facturas[]=[];
  //titularFactura:Cliente=new Cliente();
  muestraFormularioFacturas:boolean=false;
  btnActualizarFacturas:boolean=false;
  btnNuevaFacturas:boolean=false;

  constructor(private miservicioReservas:ReservasService,public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: reservaEdit,
  public datepipe: DatePipe,public dialog: MatDialog,private ds: DataService, private miservicioHabitacion:HabitacionService) {
    // var columns = ["ID", "Name", "Country"];
    // var rows = [
    //     [1, "Shaw", "Tanzania"],
    //     [2, "Nelson", "Kazakhstan"],
    //     [3, "Garcia", "Madagascar"],
    // ];

    // var doc = new jsPDF('p', 'pt');
    // //doc.autoTable(columns, rows);
    // //doc.save('table.pdf');
    // this.descargarFactura();
  }

  ngOnInit() {

    

    //console.log(this.data)
    //RESERVA
    //=================================
    this.listarHabitaciones();
    this.listarClientes();
    this.listarRegimenes();
    this.datosReserva();
    this.listarEstadosReserva();

    //hABITACIONES
    //=================================
    this.recuperaDatosHabitaciones();

    //HUESPEDES
    //=================================
    this.listarHuespedes();

    //GASTOS
    //==================================  
    this.listarGastos();

    //PAGOS
    //==================================  
    this.listarPagos();

    //FACTURACION
    //==================================  
    // this.datosFacturacion();

    if(this.miFactura.datFecha===undefined){
      this.miFactura.datFecha=this.data.start
    }

    this. listarFacturasCreadas();
  }

  datosReserva(){
    this.miservicioReservas.getReservaById(this.data.id,0).subscribe(datos=>{
      this.miReserva=datos[0];
      //console.log(this.miReserva)
    })
  }

  listarClientes(){
    this.miservicioReservas.getClientes().pipe(first()).subscribe(datos=>{
      this.listaClientes=datos;
      //console.log(this.listaClientes);
    })
  }


  listarRegimenes(){
    this.miservicioReservas.getRegimenes().pipe(first()).subscribe(datos=>{
      this.listRegimenes=datos.filter(e=>e.strActivo==="S");
    })
  }

  listarEstadosReserva(){
    this.miservicioReservas.getEstadosReserva(0).subscribe(datos=>{
      //console.log(datos)
      this.listEstadosReservas=datos.filter(o=>o.strActivo==="S")
    })
  }

  listarHabitaciones(){
    this.miservicioHabitacion.getHabitaciones().subscribe(datos=>{
      //console.log(datos)
      this.listHabitaciones=datos.filter(e=>e.strActivo==="S");
    })
  }

  onChangeEntrada(event){
    this.miReserva.datEntrada=event;
  }

  onChangeSalida(event){
    this.miReserva.datSalida=event;
  }

  guardarCambiosReserva(){
    //console.log(this.miReserva)
    this.miservicioReservas.editaReserva(this.miReserva).subscribe(datos=>{
      this.thisDialogRef.close(datos);
    })
  }

  cancelar(){
    this.thisDialogRef.close();
  }

  eliminaReserva(){
    
  }  


  openClientes(){
    let dialogRef = this.dialog.open(AgregaHuespedNuevaReservaComponent, {
      width: '600px',
      data:this.data
    });
    dialogRef.afterClosed().subscribe((datos:any)=> {
      //console.log(datos)
      if(datos!=undefined){
        // this.cliente=data
        this.miReserva.intClienteId=datos.intId;
        this.miReserva.strClienteNombre=datos.strNombre;
        this.miReserva.strClienteApellidos=datos.strApellidos;
      }      
      //console.log(this.cliente)
      //this.form.controls.nombre.setValue(data.strNombre)
    });
    //console.log(this.miReserva)
  }

  //HABITACIONES
  //==================================

  recuperaDatosHabitaciones(){
    this.miservicioReservas.getReservaById(0,this.data.intGrupoId).subscribe(datos=>{
      //console.log(datos)
      this.listHabitacionesHuespedes=datos;
      //console.log(this.listHabitacionesHuespedes);      

      //calculo diferencia entre fechas
      //  var diff = Math.abs(new Date(this.listHabitacionesHuespedes[0].datEntrada).getTime() - new Date(this.listHabitacionesHuespedes[0].datSalida).getTime());
      //  var diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 
      //  console.log(diffDays)
    })
  }

  editarHabitacion(e){
    //console.log(e)
    let dialogRef = this.dialog.open(EditaHabitacionReservaComponent, {
      width: '600px',
      data:e
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result!=undefined){
        //console.log(result)
        // this.listReservaPagos=result;
         this.listHabitacionesHuespedes.forEach(o => {
           if(o.intId===result.intId){
             o.decImporteTotal=result.decImporteTotal;
           }
         });
      }     
    });
  }

  //HUESPEDES
  //==================================

  listarHuespedes(){
    this.miservicioReservas.getHuespedReserva(this.data.intGrupoId).subscribe(datos=>{
      //console.log(datos)
      this.listHuespedes=datos;
      //console.log(this.listHuespedes)
    })
  }

  agregaHuesped(){
    let dialogRef = this.dialog.open(AgregaHuespedComponent, {
      width: '600px', 
      data: this.data
    });
    dialogRef.afterClosed().subscribe((datos:any) => {
      //console.log(datos)
      if(datos!=undefined){
        this.listHuespedes.splice(0, this.listHuespedes.length);  
        this.listHuespedes=datos;
      }        
    });
  }

  editarHuesped(huesped){
    // console.log(huesped)
    // console.log(huesped.strClienteDni)

    let dialogRef = this.dialog.open(EditaHuespedComponent, {
      width: '600px',
      data:huesped
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result) 
      if(result!=undefined){
        this.listHuespedes=result;   
      }     
    });
  }

  eliminarHuesped(huesped){
    let dialogRef = this.dialog.open(VentanaErrorComponent, {
      width: '600px',
      data:{
        texto:"¿Quieres eliminar este huesped?",
        boton:true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result) 
      if(result==='delete'){
        //console.log(e)
        this.miservicioReservas.deleteHuespedReserva(huesped.intOcupacionId,huesped.intReservaGrupo).subscribe(datos=>{
          //console.log(datos)
          if(datos.miRespuesta.booOk){
            this.listHuespedes=datos.miListHuesped;
          }          
        })
      }      
    });    
  }


  //GASTOS
  //==================================  

  eliminarGasto(e){
    let dialogRef = this.dialog.open(VentanaErrorComponent, {
      width: '600px',
      data:{
        texto:"¿Quieres eliminar esta línea?",
        boton:true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result) 
      if(result==='delete'){
        //console.log(e)
        this.miservicioReservas.deleteGastoReserva(e.intId,e.intReservaId).subscribe(datos=>{
          //console.log(datos)
          if(datos.miRespuesta.booOk){
            this.listReservaGastos=datos.miListReservaGastos;
          }          
        })
      }      
    });    
  }

  listarGastos(){
    this.miservicioReservas.getGastoReserva(0,0,0,'','',this.data.intGrupoId,0).subscribe(datos=>{
      //console.log(datos)
      this.listReservaGastos=datos;
      //console.log(this.miReserva)
    })
  }

  insertaGasto(){
    let dialogRef = this.dialog.open(NuevoGastoComponent, {
      width: '600px',
      data:{
        id:this.data.id,
        grupoId:this.data.intGrupoId,
        habitacion:this.miReserva.strHabitacionNombre,
        fecha:this.data.start
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //devuelvo el listado de los gastos que tiene la reserva    
      //console.log(result) 
      if(result!=undefined){
        this.listReservaGastos=result;   
      }     
    });
  }

  editarGasto(e){
    //console.log(e)

    let dialogRef = this.dialog.open(EditaGastoComponent, {
      width: '600px',
      data:{
        data:e,
        grupoId:this.data.intGrupoId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result) 
      if(result!=undefined){
        this.listReservaGastos=result;   
      }     
    });
  }

  //PAGOS
  //==================================  
  listarPagos(){
    this.miservicioReservas.getPagoReserva(0,'','',this.data.intGrupoId,0,0).subscribe(datos=>{
      //console.log(datos)
      this.listReservaPagos=datos;
      //console.log(this.miReserva)
    })
  }

  insertaPago(){
    let dialogRef = this.dialog.open(ReservaPagoComponent, {
      width: '600px',
      data:{
        // id:this.data.id,
        intReservaGrupoId:this.data.intGrupoId,
        intReservaId:this.data.id,
        datFecha:this.data.start
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //devuelvo el listado de los gastos que tiene la reserva    
      //console.log(result) 
      if(result!=undefined){
        this.listReservaPagos=result;   
      }     
    });
  }

  editarPago(e){
    //console.log(e)
    let dialogRef = this.dialog.open(ReservaPagoComponent, {
      width: '600px',
      data:e
    });
    dialogRef.afterClosed().subscribe(result => {
      //devuelvo el listado de los gastos que tiene la reserva    
      //console.log(result) 
      if(result!=undefined){
        this.listReservaPagos=result;   
      }     
    });
  }

  eliminarPago(e){
    let dialogRef = this.dialog.open(VentanaErrorComponent, {
      width: '600px',
      data:{
        texto:"¿Quieres eliminar esta línea?",
        boton:true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result) 
      if(result==='delete'){
        //console.log(e)
        this.miservicioReservas.deletePagoReserva(e.intId,e.intReservaGrupoId).subscribe(datos=>{
          //console.log(datos)
          if(datos.miRespuesta.booOk){
            this.listReservaPagos=datos.miListReservaPagos;
          }          
        })
      }      
    });    
  }


  //FACTURACION
  //==================================  

  yourFn($event){
    if($event.tab.textLabel==="Facturación"){
      this.datosFacturacion();
    }
  }

  datosFacturacion(){
    this.miservicioReservas.getFacturado(0,'','',this.data.intGrupoId,this.data.id,0,'').subscribe(datos=>{
      //console.log(datos)
      this.listFacturadosTodos=datos;
      this.listFacturados=datos.filter(o=>o.intFacturaId===0);
      //console.log(this.miReserva)
      this.listFacturados.forEach(e => {
        //console.log(e)
        if(e.intFacturaId!=0){
          //e.strMarcado=true
          e.strHabilitado=true;
        }else{
          e.strMarcado=false;
        }
      });
    })
  }

  listarFacturasCreadas(){
    this.miservicioReservas.getCabeceraFactura(0,this.data.intGrupoId,'','',0,0).subscribe(datos=>{
      //console.log(datos)
      this.listFacturas=datos;
    })
  }

  marcarItem(event: any,e)
  {
    //console.log(e)
  }

  onChangeFechaFactura(event){
    this.miFactura.datFecha=event;
  }

  openClientesFacturas(){
    let dialogRef = this.dialog.open(TitularFacturaComponent, {
      width: '600px',
      data:this.data
    });
    dialogRef.afterClosed().subscribe((datos:any)=> {
      //console.log(datos)
      if(datos!=undefined){
        this.miFactura.intClienteId=datos.intId;
        this.miFactura.strCliente=datos.strNombre + " " + datos.strApellidos;
        //CREO LA CABECERA DE LA FACTURA
        // this.miservicioReservas.postInsertaCabeceraFactura(this.miFactura).subscribe(datos=>{
        //   console.log(datos)
        // })
      }      
    });
  }

  nuevaFactura(){
    this.muestraFormularioFacturas=true;
    this.btnNuevaFacturas=true;
    this.btnActualizarFacturas=false;
    this.miFactura=new Facturas();
    if(this.miFactura.datFecha===undefined){
      this.miFactura.datFecha=this.data.start
    }
  }

  creaFactura(){    
    this.miFactura.intGrupoId=this.data.intGrupoId;
    
    //console.log(this.miFactura)
    
    this.miservicioReservas.postInsertaCabeceraFactura(this.miFactura).subscribe((datos:any)=>{
      //console.log(datos)
      if(datos.miRespuesta.booOk){
        //this.miFactura=datos.miListFacturas[0];
        this.listFacturas=datos.miListFacturas
        this.muestraFormularioFacturas=false;
        this.btnNuevaFacturas=true;
      }
    })
  }

  verPendientes(){
    // let listAux:Facturado[]=[];
    // listAux=this.listFacturadosTodos;
    // this.listFacturados=listAux.filter(o=>o.intFacturaId===0);

    this.miservicioReservas.getFacturado(0,'','',this.data.intGrupoId,this.data.id,0,'').subscribe(datos=>{
      //console.log(datos)
      this.listFacturadosTodos=datos;
      this.listFacturados=datos.filter(o=>o.intFacturaId===0);
      //console.log(this.miReserva)
      this.listFacturados.forEach(e => {
        //console.log(e)
        // if(e.intFacturaId!=0){
        //   //e.strMarcado=true
        //   e.strHabilitado=true;
        // }else{
        //   e.strMarcado=false;
        // }
      });
    })
  }

  seleccionarFactura(e){
    //console.log(this.listFacturadosTodos)
    //let listAux:Facturado[]=[];
    //listAux=this.listFacturadosTodos
    this.muestraFormularioFacturas=true;
    this.btnActualizarFacturas=true;
    this.btnNuevaFacturas=false;
    this.miFactura=e;
    //this.listFacturados=listAux.filter(o=>o.intFacturaId===e.intId)

    this.miservicioReservas.getFacturado(0,'','',0,0,e.intId,'').subscribe(datos=>{
      //console.log(datos)
      //this.listFacturadosTodos=datos;
      this.listFacturados=datos;
      //console.log(this.miReserva)
      this.listFacturados.forEach(e => {
        //console.log(e)
        // if(e.intFacturaId!=0){
        //   //e.strMarcado=true
        //   e.strHabilitado=true;
        // }else{
        //   e.strMarcado=false;
        // }
      });
    })
  }

  actualizarFactura(){
    //console.log(this.miFactura)
    this.miservicioReservas.putCambiaCabeceraFactura(this.miFactura).subscribe(datos=>{
      //console.log(datos)
      if(datos.miRespuesta.booOk){
        this.listFacturas=datos.miListFacturas;
        this.muestraFormularioFacturas=false;
        this.btnActualizarFacturas=true;
      }
    })
  }

  eliminarFactura(e){
    //console.log(e)
    this.miservicioReservas.putEliminaCabeceraFactura(e.intId,e.intGrupoId,this.data.id).subscribe(datos=>{
      //console.log(datos)
      // if(datos.miRespuesta.booOk){
        this.listFacturadosTodos=datos;
        this.listFacturas=datos.miListFacturas.filter(o=>o.intFacturaId===0);
        this.listFacturados=datos.miListFacturado;
        this.listFacturados.forEach(e => {
          //console.log(e)
          if(e.intFacturaId!=0){
            //e.strMarcado=true
            e.strHabilitado=true;
          }else{
            e.strMarcado=false;
          }
        });
      // }
    })
  }

  facturar(){ 
    let listAux:Facturado[]=[];  
    this.listFacturados.forEach(e => {
      if(e.strMarcado){
        e.intFacturaId=this.miFactura.intId;
        e.intReservaId=this.data.id;
        e.intGrupoId=this.data.intGrupoId;
        listAux.push(e)
      }
    });

    //console.log(listAux)
    this.miservicioReservas.postInsertaLineasFactura(listAux).subscribe(datos=>{
      //console.log(datos)
      if(datos.miRespuesta.booOk){
        this.listFacturadosTodos=datos;
        this.listFacturados=datos.miListFacturados.filter(o=>o.intFacturaId===0);
        this.listFacturados.forEach(e => {
          //console.log(e)
          if(e.intFacturaId!=0){
            //e.strMarcado=true
            e.strHabilitado=true;
          }else{
            e.strMarcado=false;
          }
        });
      }
    })
  }

  eliminarLineas(){
    let listAux:Facturado[]=[];  
    this.listFacturados.forEach(e => {
      if(e.strMarcado){
        e.intFacturaId=0;
        e.intReservaId=this.data.id;
        e.intGrupoId=this.data.intGrupoId;
        listAux.push(e)
      }
    });

    //console.log(listAux)
    this.miservicioReservas.postInsertaLineasFactura(listAux).subscribe(datos=>{
      //console.log(datos)
      if(datos.miRespuesta.booOk){
        this.listFacturadosTodos=datos;
        this.listFacturados=datos.miListFacturados.filter(o=>o.intFacturaId===0);
        this.listFacturados.forEach(e => {
          //console.log(e)
          if(e.intFacturaId!=0){
            //e.strMarcado=true
            e.strHabilitado=true;
          }else{
            e.strMarcado=false;
          }
        });
      }
    })
  }

  descargarFactura(){
    // var doc = new jsPDF()
    // doc.text('Hello world!', 10, 10)
    // doc.save('a4.pdf')

    //===================================================================================================

    // let doc = new jsPDF()
    // doc.autoTable(['Test'], [['test']])
    // doc.save('table.pdf')

    //===================================================================================================

    // var imgData = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD//gA7Q1JFQVRPUjogZ2QtanBlZyB2MS4wICh1c2luZyBJSkcgSlBFRyB2NjIpLCBxdWFsaXR5ID0gNzUK/9sAQwAIBgYHBgUIBwcHCQkICgwUDQwLCwwZEhMPFB0aHx4dGhwcICQuJyAiLCMcHCg3KSwwMTQ0NB8nOT04MjwuMzQy/9sAQwEJCQkMCwwYDQ0YMiEcITIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIy/8AAEQgAMgAyAwEiAAIRAQMRAf/EABsAAAIDAQEBAAAAAAAAAAAAAAAEAwUGAQcC/8QAMxAAAQMDAgQEAwcFAAAAAAAAAQIDEQAEIQUSBjFBURNhcYEikbEUFSNCUpKhNDVUc8H/xAAZAQADAQEBAAAAAAAAAAAAAAACBAUDAAH/xAAgEQACAgIBBQEAAAAAAAAAAAAAAQIDERIxBBMhIlFx/9oADAMBAAIRAxEAPwCgCSYg+1Spgcz86iBSOc0xZW7+o3bdrbJLjzmEoTAOBPM+VRG8CqWeA3DoAfeKbsNPudUeU1bpRuSncresJx6nr5UveWF7YPIaura4ZcWnclK08098c/8Alcsr+5sHvGtFhDm0p3EBUT5ERPnQyy168hRSUltwXp4N1QupS05arClEBXixAiQTjE8sTmqC4actbhbD6FNuoMFJkH+a0dzxl+I79jtSklKNjrpBIUIkwMDtis7dXb95cLuHnlLWo/mJUQOgE5xWVLtb90b3xpWO2yDPn8qK7vV3/g0UxkWF8gjc5zOTEgedeu6Doum6Zp7T1uhly5SgzdwNygc5gnEfSvHw8BMEHtt51ptJ4sc07THkXF1cuvKcBbbBEbQOqjmPKlepjOUfQZ6acIyzM31rrtpfX67ZlalPoSUhXhEiDggK9uXpWR444csLLT2Lm0Q7bbiEeGhslHeVK7+vOom+PnRdeILRtu2KC2ppK9zgVE7wo9cgQapdR4g1LVWG2ru5KgkbVKGAsSCAoDBIPWhqpsrknwv03vvqsi1FFTbMhgStXPmJwfOpSQDEmDykcqhH9RnCQmPWpDuid4Ce3eql2mqxyTY5yG091fOio5T+hf7hRS+AhcLCRiSfLNdKi4I2zPUUo04E5lwk8hOKkS+rcCd0DMGtIrVps5rJ9t27bays5BOJORTG6CZMpHXrSR3pKVk/CFqJ6xgUKuIIG8T2ArW6UWkogQTz5MtqOoakrWLm3t7m42+KUobQTynAArjiuIbZlTrir9toc1KCgB0yaa0zXzoPEt1doYbe3LKTvHIT07GnuKeOHuILZu3bZSw1H4gBkuHoT0n0om7VOMYwWv0r1VUOraT8/DN/fGpf5z/76KS96Kb0j8FtUb3t6io3FH4cnnRRU6QqSc2Ez+s/QUqfy+9FFeHIyd//AHG4/wBh+tL0UVTXA3HgKKKK9OP/2Q=='
    // var doc = new jsPDF()

    // doc.setFontSize(40)
    // doc.text(35, 25, 'Paranyan loves jsPDF')
    // doc.addImage(imgData, 'JPEG', 15, 40, 50, 50)
    // doc.save("ImageSample.pdf")

    //===================================================================================================


    // let chartImage : string;
    // this.pdfservice.convertSVGElementToDataURLviaCanvas(document.getElementsByTagName("svg")[0],"image/png")
    //     .mergeMap((res) => {
    //       chartImage = res;          
    //       return this.pdfservice.convertImgToDataURLviaCanvas(
    //         "companylogo.png","image/png");
    //     })
    //     .mergeMap((companyLogoDataURI) => {              
    //           let docDefinition = {
    //             pageSize: 'A4',
    //             pageOrientation: 'landscape',                
    //             header: {
                    
    //             },
    //             footer: (currentPage : number, pageCount: number) => {
    //                 return {
    //                     text: "Page"+                            
    //                     currentPage.toString() + ' / ' + pageCount,
    //                     alignment: "right",
    //                     margin: [40, 10] 
    //               };
    //             },                                    
    //             content: 
    //               [
    //                 {
    //                   table: {
    //                       widths: [300, '*'],
    //                       body: [
    //                           [{
    //                               // if you specify both width and height - image will be stretched
    //                               image: companyLogoDataURI,
    //                               width: 150                             
    //                           },
    //                               [{ 
    //                                   paddingTop: 10,
    //                                   text: "Report",                                
    //                                   fontSize: 20
    //                               },
    //                               {
    //                                   text: this.datepipe.transform(this.linechart.horizNavLeft,'MMM yyyy')+" - "+
    //                                     this.datepipe.transform(this.linechart.horizNavRight,'MMM yyyy')
    //                               }
    //                               ]
    //                           ]
    //                       ]
    //                   }, 
    //                   layout: 'noBorders'		
    //               },
    //               {
    //                 image: chartImage,                    
    //                 width: 750               
    //               }
    //             ]
    //       };          

    //       return this.pdfservice.createPDF(docDefinition,"report.pdf");            
    //   }).subscribe();

    
  }

 

}

export class reservaEdit{

  public end: any;
  public id: number;
  public intClienteId: number;
  public intRegimenId: number;
  public regimenNombre: string;
  public resource: any;
  public start: any;
  public strClienteApellidos: string;
  public strClienteNombre: string;
  public strEstadoReserva: string;
  public intGrupoId:any;

}
