import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Cliente } from '../../core/models/cliente';
import { ReservasService } from '../../core/services/reservas.service';

@Component({
  selector: 'app-inserta-clientes',
  templateUrl: './inserta-clientes.component.html',
  styleUrls: ['./inserta-clientes.component.scss']
})
export class InsertaClientesComponent implements OnInit {

  formNewCliente: FormGroup;
  dniValido:boolean=false;
  nuevoCliente:Cliente=new Cliente();
  submitted:boolean=false;
  dniError:boolean=false;
  muestraReserva:boolean=false;
  datosInicio:boolean=true;
  avanza:boolean=false;
  @ViewChild("falloDni") dniField:ElementRef;
  existError:boolean=false;
  mensajeError:string;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>,private _formBuilder: FormBuilder,
    private miservicioReservas:ReservasService) { }

  ngOnInit() {
    this.formNewCliente = this._formBuilder.group({
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      nacimiento: ['', Validators.required],
      sexo: ['', Validators.required],
      dni: ['', Validators.required],
      expedido: ['', Validators.required],
      email: new FormControl('', Validators.compose([
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),
      telefono: new FormControl('', Validators.compose([
        Validators.pattern('^[\+]?[(]?[0-9]{0,2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3}$'),
      ])),
      direccion: [''],
      cp: new FormControl('', Validators.compose([
        Validators.pattern('^[0-9]{5}$'),
      ])),
      localidad: [''],
      poblacion: [''],
      pais: ['',Validators.required],
    })
  }

  onChangeExpedido(event){
    //console.log(event)
  }

  onChangeNacimiento(event){
    //console.log(event)
  }

  salvar(){
    //console.log(this.nuevoCliente)
    this.submitted = true;
    //console.log(this.nif());
 
      if(this.nif()===true){
        this.avanza=true;
      }else{
        this.dniField.nativeElement.focus();
        this.dniError=true;
      }
    
    if(this.formNewCliente.valid && this.dniValido===true){
      //console.log('llega')
      this.miservicioReservas.postCliente(this.nuevoCliente).subscribe((datos:any)=>{       
        //console.log(datos)
        //this.thisDialogRef.close();
        if(datos.miRespuesta.booOk){
          this.thisDialogRef.close(datos.miListCliente);
        }else{
          this.mensajeError=datos.miRespuesta.strMensaje;
          this.existError=true;
        }
      })      
    }
  }

  public  nif() {
    var numero
    var letr
    var letra
    var expresion_regular_dni
   
    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;
   
    if(expresion_regular_dni.test (this.formNewCliente.controls.dni.value) == true){
       numero = this.formNewCliente.controls.dni.value.substr(0,this.formNewCliente.controls.dni.value.length-1);
       letr = this.formNewCliente.controls.dni.value.substr(this.formNewCliente.controls.dni.value.length-1,1);
       numero = numero % 23;
       letra='TRWAGMYFPDXBNJZSQVHLCKET';
       letra=letra.substring(numero,numero+1);
      if (letra!=letr.toUpperCase()) {
        return this.dniValido=false;
         //alert('Dni erroneo, la letra del NIF no se corresponde');
       }else{
         return this.dniValido=true;
       }
    }else if(this.formNewCliente.controls.dni.value===undefined){
      return this.dniValido=true;
    }else{
      return this.dniValido=false;
       //alert('Dni erroneo, formato no válido');
     }
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
