import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ReservasService } from 'src/app/core/services/reservas.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cliente } from 'src/app/core/models/cliente';

@Component({
  selector: 'app-agrega-huesped-nueva-reserva',
  templateUrl: './agrega-huesped-nueva-reserva.component.html',
  styleUrls: ['./agrega-huesped-nueva-reserva.component.scss']
})
export class AgregaHuespedNuevaReservaComponent implements OnInit {

  listClientes:Cliente[]=[];  
  cliente:Cliente=new Cliente();
  formNewCliente: FormGroup;
  dniValido:boolean=false;
  submitted:boolean=false;
  avanza:boolean=false;
  dniError:boolean=false;


  filtro:string;
  dataTodo:Cliente[]=[];
  checktodos:any;
  dataTodoObsoleto:Cliente[]=[];

  //colorCliente:any;
  selectCliente:any;
  listAux:any[]=[];

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, private miservicioReservas:ReservasService,private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.listarClientes();

    this.formNewCliente = this._formBuilder.group({
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      nacimiento: ['', Validators.required],
      sexo: ['', Validators.required],
      dni: ['', Validators.required],
      expedido: ['', Validators.required],
      pais: ['', Validators.required],
      email: [''],
      telefono1: [''],
      telefono2: [''],
      direccion: [''],
      cp: [''],
      poblacion: [''],
      provincia: ['']
    })
  }

  listarClientes(){
    this.miservicioReservas.getClientes().subscribe(datos=>{
      //this.data=datos;
      //console.log(datos);
      this.dataTodoObsoleto=datos;
      this.dataTodo=datos.filter(e=>e.strActivo==="S");
      this.listClientes=datos.filter(e=>e.strActivo==="S");
      //console.log(this.listClientes)
    })
  }

  onChangeNacimiento(event){
    //console.log(event)
    if(event){
      this.cliente.datFechaNacimiento=event;
    }
  }

  onChangeExpedido(event){
    //console.log(event)
    if(event){
      this.cliente.datDniExpedido=event;
    }
  }

  todos(event){
    //console.log(event.checked)
    if(event.checked){
      this.dataTodo.splice(0,this.dataTodo.length)
      this.dataTodo=this.dataTodoObsoleto;
      if(this.filtro===undefined){
        this.filtro='';
      }   
      this.funcionBusqueda(this.filtro.toLocaleLowerCase(),this.dataTodo);                    
    }else{
      //this.dataTodo.splice(0,this.dataTodo.length)
      this.dataTodo=this.dataTodoObsoleto.filter(e=>e.strActivo==="S");
      if(this.filtro===undefined){
        this.filtro='';
      }
      this.funcionBusqueda(this.filtro.toLocaleLowerCase(),this.dataTodo);
    }
    //console.log(this.dataTodo)
  }

  buscar(event: any){
    this.filtro = event.target.value;
  
    // if(this.filtro===''){
    //   this.listClientes=this.dataTodo
    // }else{
    //   this.listClientes=this.dataTodo.filter(e=>e.strNombre.toLowerCase().startsWith(this.filtro.toLowerCase()) || 
    //   e.strApellidos.toLowerCase().startsWith(this.filtro.toLowerCase()) || e.strDni.toLowerCase().startsWith(this.filtro.toLowerCase()));
    // }
    this.funcionBusqueda(this.filtro,this.dataTodo);
  }

  funcionBusqueda(filtro,dataFilter){
    if(filtro===''){
      this.listClientes=dataFilter
    }else{
      this.listClientes=dataFilter.filter(e=>e.strNombre.toLowerCase().startsWith(filtro.toLowerCase()) || 
      e.strApellidos.toLowerCase().startsWith(filtro.toLowerCase()) || e.strDni.toLowerCase().startsWith(filtro.toLowerCase()));
    }
  }

  seleccionaCliente(e){
    this.selectCliente=e.intId;
    this.listAux.push(e.intId)
    let ultimo=this.listAux[this.listAux.length - 1];

    this.listAux.forEach(o => {
      if(o===ultimo){
        document.getElementById(o).style.borderStyle="solid";
        document.getElementById(o).style.borderWidth="2px";
        document.getElementById(o).style.borderColor="#ffe066";        
      }else{
        document.getElementById(o).style.borderStyle="none";
      }
    });
  }

  agregarHuesped(){
    //this.thisDialogRef.close();
    this.dniError=false;
    this.submitted = true;

    if(this.nif()===true){
      this.avanza=true;
    }else{
      this.dniError=true;
      this.avanza=false;
    }

    if(this.formNewCliente.valid && this.dniValido===true){      
      this.cliente.strActivo="S";

      //console.log(this.cliente)
      // this.thisDialogRef.close(this.cliente);

      ///===============================================================
      //PROCESO DE AÑADIR AL CLIENTE 
      ///===============================================================

      this.miservicioReservas.postCliente(this.cliente).subscribe((datos:any)=>{
        //console.log(datos)
         if(datos.miRespuesta.booOk){
          this.cliente.intId=parseInt(datos.miRespuesta.strValor);
          //console.log(this.cliente)
          this.thisDialogRef.close(this.cliente);
         }
      })
    }
  }

  guardar(){
    //console.log(this.selectCliente)
    //console.log(this.dataTodoObsoleto)
    //console.log(this.dataTodoObsoleto.filter(e=>e.intId===this.selectCliente))

    this.thisDialogRef.close(this.dataTodoObsoleto.filter(e=>e.intId===this.selectCliente)[0]);
    /*this.miservicioReservas.postHuespedReserva(this.data.intGrupoId,this.data.id,this.selectCliente).subscribe((datos:any)=>{
      if(datos.miRespuesta.booOk){
        this.thisDialogRef.close(datos.miListHuesped);
      }
    })*/
  }

  public  nif() {
    var numero
    var letr
    var letra
    var expresion_regular_dni
   
    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;
   
    if(expresion_regular_dni.test (this.formNewCliente.controls.dni.value) == true){
       numero = this.formNewCliente.controls.dni.value.substr(0,this.formNewCliente.controls.dni.value.length-1);
       letr = this.formNewCliente.controls.dni.value.substr(this.formNewCliente.controls.dni.value.length-1,1);
       numero = numero % 23;
       letra='TRWAGMYFPDXBNJZSQVHLCKET';
       letra=letra.substring(numero,numero+1);
      if (letra!=letr.toUpperCase()) {
        return this.dniValido=false;
         //alert('Dni erroneo, la letra del NIF no se corresponde');
       }else{
         return this.dniValido=true;
       }
    }else if(this.formNewCliente.controls.dni.value===undefined){
      return this.dniValido=true;
    }else{
      return this.dniValido=false;
       //alert('Dni erroneo, formato no válido');
     }
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
