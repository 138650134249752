import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ReservasService } from '../core/services/reservas.service';
import { first, map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { Observable, of as observableOf, merge } from 'rxjs';
import { DateAdapter, MAT_DATE_LOCALE, MatDatepickerInputEvent, MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Cliente } from '../core/models/cliente';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { InsertaClientesComponent } from './inserta-clientes/inserta-clientes.component';
import { EdicionClientesComponent } from './edicion-clientes/edicion-clientes.component';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {

  listaClientes:Cliente[]=[];
  data:Cliente[]=[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['strNombre','strDni','strApellidos','strTelefono1'];
  dataSource ;

  formNewCliente: FormGroup;
  nuevoCliente:Cliente=new Cliente();
  submitted:boolean=false;
  dniError:boolean=false;
  dniValido:boolean=false;
  muestraReserva:boolean=false;
  datosInicio:boolean=true;

  avanza:boolean=false;
  dataExcel:datosExcel[]=[];
  filtro:string;
  listFiltrada:Cliente[]=[];
  dataTodo:Cliente[]=[];
  teclaAlt:any;
  otraTecla:any;

  constructor(private miservicioReservas:ReservasService,private _formBuilder: FormBuilder,public dialog: MatDialog,
    private router: Router) { }

  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = 'Elementos por pagina';

    this.formNewCliente = this._formBuilder.group({
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      dni: ['', Validators.required],
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),
      telefono: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$'),
      ])),
      direccion: ['', Validators.required],
      cp: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{5}$'),
      ])),
      localidad: ['', Validators.required],
      poblacion: ['', Validators.required],
      pais: ['', Validators.required],
    })

    this.listarClientes();
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===78 && this.otraTecla===18){
      this.newCliente();
    }else if(this.teclaAlt===88 && this.otraTecla===18){
      this.exportAsXLSX();
    }else if(this.teclaAlt===66 && this.otraTecla===18){
      var myEl = document.getElementById('miFiltro');
      myEl.focus();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }

  listarClientes(){
    this.dataSource = new MyTableDataSource(this.paginator, this.sort);

    this.miservicioReservas.getClientes().pipe(first()).subscribe(datos=>{
      this.data=datos;
      //console.log(datos);
      //console.log(this.data);
      this.listFiltrada=datos;
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    })
  }

  getColor(strActivo) { 
    switch (strActivo) {
      case 'N':
        return '#ff3333';
      case 'S':
        return '#00b300';
    }
  }

  public  nif() {
    var numero
    var letr
    var letra
    var expresion_regular_dni
   
    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;
   
    if(expresion_regular_dni.test (this.formNewCliente.controls.dni.value) == true){
       numero = this.formNewCliente.controls.dni.value.substr(0,this.formNewCliente.controls.dni.value.length-1);
       letr = this.formNewCliente.controls.dni.value.substr(this.formNewCliente.controls.dni.value.length-1,1);
       numero = numero % 23;
       letra='TRWAGMYFPDXBNJZSQVHLCKET';
       letra=letra.substring(numero,numero+1);
      if (letra!=letr.toUpperCase()) {
        return this.dniValido=false;
         //alert('Dni erroneo, la letra del NIF no se corresponde');
       }else{
         return this.dniValido=true;
       }
    }else{
      return this.dniValido=false;
       //alert('Dni erroneo, formato no válido');
     }
  }

  deleteMuestraReserva(){
    this.muestraReserva=false;
    this.datosInicio=true;
  }

  newCliente(){
    /*this.muestraReserva=true;
    this.datosInicio=false;*/

    let dialogRef = this.dialog.open(InsertaClientesComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result)
      if(result!=undefined){
        this.data.splice(0, this.data.length);
        this.data=result;  
        this.dataSource=new MatTableDataSource(result); 
      }
      //this.listarClientes();
    });
  }

  editCliente(cliente:Cliente){
    let dialogRef = this.dialog.open(EdicionClientesComponent, {
      width: '600px',
      data: {
        'intId':cliente.intId,
      }
    });
    dialogRef.afterClosed().subscribe((datos) => {
      if(datos!=undefined){
        let myObject:any;
        myObject=datos.miListCliente[0];
        //console.log(myObject);
  
        let miTicket=datos.miListCliente[0].intId;
  
        var index = this.data.map(x => {
          return x.intId;
        }).indexOf(miTicket);
        //console.log(miTicket)
  
        if(datos.length!=0){      
          this.data.splice(index, 1, myObject);
          //console.log(index);
          //console.log(this.data);
          this.dataSource=new MatTableDataSource(this.data);
        }
        this.getColor(myObject.strActivo);
        if(datos.length===0){
          this.data.splice(index, 1);
        } 
      }      
    });

   /* let extra: NavigationExtras = {
      queryParams: {
        'intId':cliente.intId
      }
  }
    this.router.navigate(["edicion-clientes"],extra);*/
  }

  addCliente(){
    this.submitted = true;
 
    if(this.formNewCliente.controls.dni.value===undefined){
      this.dniError=false;
    }else{
      if(this.nif()===true){
        this.avanza=true;
      }else{
        this.dniError=true;
      }
    }

    if(this.formNewCliente.valid && this.avanza===true){
      this.miservicioReservas.postCliente(this.nuevoCliente).subscribe((datos:any)=>{
        //console.log(datos)
        if(datos.booOk===true){
          this.muestraReserva=false;
          this.datosInicio=true;
          this.listarClientes();
        }
      })      
    }
  }

  buscar(event: any){
    this.filtro = event.target.value;
  
    this.dataTodo=this.data;
    if(this.filtro===''){
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
      this.listFiltrada=this.data;
    }else{
      this.data=this.data.filter(e=>e.strNombre.toLowerCase().startsWith(this.filtro.toLowerCase()) || 
      e.strApellidos.toLowerCase().startsWith(this.filtro.toLowerCase()) || e.strDni.toLowerCase().startsWith(this.filtro.toLowerCase()) || 
      e.strTelefono1.toLowerCase().startsWith(this.filtro.toLowerCase()) || e.strTelefono2.toLowerCase().startsWith(this.filtro.toLowerCase()));
    //console.log(this.data);
      this.listFiltrada=this.data;
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    }
    this.data=this.dataTodo;
  }

  exportAsXLSX():void {
    this.listFiltrada.forEach(e => {
      //console.log(e)
      // this.dataExcel.push({num_reserva:element.intId, num_habitacion:element.strHabitacionNombre, entrada:this.datepipe.transform(element.datEntrada, 'dd/MM/yyyy'), salida:this.datepipe.transform(element.datSalida, 'dd/MM/yyyy'), cliente:element.strClienteNombre+" "+element.strClienteApellidos, precio:0});
      this.dataExcel.push({        
        Nombre:e.strNombre,
        Apellidos:e.strApellidos,
        Dni:e.strDni,
        Direccion:e.strDireccion,
        CP:e.strCP,
        Poblacion:e.strPoblacion,
        Provincia:e.strProvincia,
        Pais:e.strPais,
        Telefono1:e.strTelefono1,
        Telefono2:e.strTelefono2,
        Email:e.strEmail,
        Activo:e.strActivo
      })
    });
    this.miservicioReservas.exportAsExcelFile(this.dataExcel, 'Listado Clientes');

    this.dataExcel.splice(0, this.dataExcel.length)
  }

}

export interface datosExcel{  
  Nombre:any;
  Apellidos:any;
  Dni:any;
  Direccion:any;
  CP:any;
  Poblacion:any;
  Provincia:any;
  Pais:any;
  Telefono1:any;
  Telefono2:any;
  Email:any;
  Activo:any;
}


export class MyTableDataSource extends DataSource<Cliente> {
  data:Cliente[]=[];
 
   constructor(private paginator: MatPaginator, private sort: MatSort) {
     super();
   }
 
   connect(): Observable<Cliente[]> {
     const dataMutations = [
       observableOf(this.data),
       this.paginator.page,
       this.sort.sortChange
     ];
 
     this.paginator.length = this.data.length;
 
     return merge(...dataMutations).pipe(map(() => {
       return this.getPagedData(this.getSortedData([...this.data]));
     }));
   }
 
   disconnect() {}
 
   private getPagedData(data: Cliente[]) {
     const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
     return data.splice(startIndex, this.paginator.pageSize);
   }
 
 
   private getSortedData(data: Cliente[]) {
     if (!this.sort.active || this.sort.direction === '') {
       return data;
     }
 
     return data.sort((a, b) => {
       const isAsc = this.sort.direction === 'asc';
       switch (this.sort.active) {
         case 'strNombre': return compare(a.strNombre, b.strNombre, isAsc);
         case 'strDni': return compare(+a.strDni, +b.strDni, isAsc);
         default: return 0;
       }
     });
   }
 }
 
 function compare(a, b, isAsc) {
   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
 }
