import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { MantenimientosService } from '../core/services/mantenimientos.service';
import { Observable, of as observableOf, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { FormaPago } from '../core/models/forma-pago';
import { NuevoFormapagoComponent } from './nuevo-formapago/nuevo-formapago.component';
import { EditaFormapagoComponent } from './edita-formapago/edita-formapago.component';

@Component({
  selector: 'app-mantenimiento-forma-pago',
  templateUrl: './mantenimiento-forma-pago.component.html',
  styleUrls: ['./mantenimiento-forma-pago.component.scss']
})
export class MantenimientoFormaPagoComponent implements OnInit {

  data:FormaPago[]=[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['strNombre'];
  dataSource ;
  dataExcel:datosExcel[]=[];
  filtro:string;
  dataTodo:FormaPago[]=[];
  dataFiltrada:FormaPago[]=[];
  teclaAlt:any;
  otraTecla:any;

  constructor(private miServicio:MantenimientosService,public dialog: MatDialog) { }

  ngOnInit() {
    this.listarFormasPago();
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===78 && this.otraTecla===18){
      this.newForma();
    }else if(this.teclaAlt===88 && this.otraTecla===18){
      this.exportAsXLSX();
    }else if(this.teclaAlt===66 && this.otraTecla===18){
      var myEl = document.getElementById('miFiltro');
      myEl.focus();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }

  listarFormasPago(){   

    this.dataSource = new MyTableDataSource(this.paginator, this.sort);

    this.miServicio.getFormasPago(0).subscribe(datos=>{
      this.data=datos;
      this.dataFiltrada=datos;
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    })
  }

  getColor(strActivo) { 
    switch (strActivo) {
      case 'N':
        return '#ff3333';
      case 'S':
        return '#00b300';
    }
  }

  buscar(event: any){
    this.filtro = event.target.value;
  
    this.dataTodo=this.data;
    if(this.filtro===''){
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
      this.dataFiltrada=this.data;
    }else{
      this.data=this.data.filter(e=>e.strNombre.toLowerCase().startsWith(this.filtro.toLowerCase()));
    //console.log(this.data);
      this.dataFiltrada=this.data;
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    }
    //console.log(this.data);
    this.data=this.dataTodo;
    
  }

  exportAsXLSX():void {
    //console.log(this.dataFiltrada);
    this.dataFiltrada.forEach(e => {
      this.dataExcel.push({        
        Nombre:e.strNombre,
        Activo:e.strActivo
      })
    });
    this.miServicio.exportAsExcelFile(this.dataExcel, 'Listado Formas_Pago');

    this.dataExcel.splice(0, this.dataExcel.length)
  }

  newForma(){
    let dialogRef = this.dialog.open(NuevoFormapagoComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result)
      if(result!=undefined){
        this.data.splice(0, this.data.length);
        this.data=result;  
        this.dataSource=new MatTableDataSource(result); 
      }
      //this.listarClientes();
    });
  }

  editFormaPago(e){
    let dialogRef = this.dialog.open(EditaFormapagoComponent, {
      width: '600px',
      data: {
        'intId':e.intId,
      }      
    });
    dialogRef.afterClosed().subscribe((datos) => {
      if(datos!=undefined){        

        let myObject:any;
        myObject=datos[0];
    
        let miTicket=datos[0].intId;
    
        var index = this.data.map(x => {
          return x.intId;
        }).indexOf(miTicket);
    
        if(datos.length!=0){      
          this.data.splice(index, 1, myObject);
          this.dataSource=new MatTableDataSource(this.data);
        }
        this.getColor(myObject.strActivo);
        if(datos.length===0){
          this.data.splice(index, 1);
        }
      }  
    });
  }


}

export interface datosExcel{  
  Nombre:any;
  Activo:any;  
}

export class MyTableDataSource extends DataSource<FormaPago> {
  data:FormaPago[]=[];
 
   constructor(private paginator: MatPaginator, private sort: MatSort) {
     super();
   }
 
   connect(): Observable<FormaPago[]> {
     const dataMutations = [
       observableOf(this.data),
       this.paginator.page,
       this.sort.sortChange
     ];
 
     this.paginator.length = this.data.length;
 
     return merge(...dataMutations).pipe(map(() => {
       return this.getPagedData(this.getSortedData([...this.data]));
     }));
   }
 
   disconnect() {}
 
   private getPagedData(data: FormaPago[]) {
     const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
     return data.splice(startIndex, this.paginator.pageSize);
   }
 
 
   private getSortedData(data: FormaPago[]) {
     if (!this.sort.active || this.sort.direction === '') {
       return data;
     }
 
     return data.sort((a, b) => {
       const isAsc = this.sort.direction === 'asc';
       switch (this.sort.active) {
         case 'strNombre': return compare(a.strNombre, b.strNombre, isAsc);
         //case 'strDescripcion': return compare(+a.strDescripcion, +b.strDescripcion, isAsc);
         default: return 0;
       }
     });
   }
 }
 
 function compare(a, b, isAsc) {
   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
 }


