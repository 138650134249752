import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { MantenimientosService } from 'src/app/core/services/mantenimientos.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormaPago } from 'src/app/core/models/forma-pago';

@Component({
  selector: 'app-edita-formapago',
  templateUrl: './edita-formapago.component.html',
  styleUrls: ['./edita-formapago.component.scss']
})
export class EditaFormapagoComponent implements OnInit {

  nuevo:FormaPago=new FormaPago();
  formNew: FormGroup;
  submitted:boolean=false;
  isChecked:boolean;
  Estado:string;
  existError:boolean=false;
  mensajeError:string;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: FormaPago,private _formBuilder: FormBuilder,
  private miServicio:MantenimientosService) { }

  ngOnInit() {
    this.formNew = this._formBuilder.group({
      nombre: ['', Validators.required],
      activo: ['']
    })
    this.listarDatos();
  }

  listarDatos(){
    this.miServicio.getFormasPago(this.data.intId).subscribe(datos=>{
      //console.log(datos)

      if(datos[0].strActivo==='S'){
        this.isChecked=true;
        this.Estado='Activo'
      }else{
        this.isChecked=false;
        this.Estado='Inactivo'
      }

      this.nuevo=datos[0];
      //console.log(this.nuevoArticulo)
    })
  }

  pulsa(){
    if(this.isChecked){
      this.Estado='Inactivo';
    }else{
      this.Estado="Activo";
    }
  }

  salvar(){
    if(this.isChecked===true){
      this.nuevo.strActivo='S';
    }else{
      this.nuevo.strActivo="N";
    } 
    //console.log(this.nuevoArticulo)

    this.miServicio.actualizaFormaPago(this.nuevo).subscribe((datos:any)=>{
      //console.log(datos)
      if(datos.miRespuesta.booOk){
        this.thisDialogRef.close(datos.miListForma_Pago)
      }else{
        this.existError=true;
        this.mensajeError=datos.miRespuesta.strMensaje;
      }
    })
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
