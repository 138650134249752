import { Component, OnInit, Inject } from '@angular/core';
import { MantenimientosService } from 'src/app/core/services/mantenimientos.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TipoHabitacion } from 'src/app/core/models/tipo-habitacion';

@Component({
  selector: 'app-edicion-tipo-habitacion',
  templateUrl: './edicion-tipo-habitacion.component.html',
  styleUrls: ['./edicion-tipo-habitacion.component.scss']
})
export class EdicionTipoHabitacionComponent implements OnInit {

  listaHabitaciones:TipoHabitacion[]=[];
  isChecked:boolean=false;
  Estado:string;
  existError:boolean=false;
  mensajeError:string;

  constructor(private miservicio:MantenimientosService,
    public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data:TipoHabitacion) { }

  ngOnInit() {
    this.listarTiposHab();
    //console.log(this.data.intId);
  }

  listarTiposHab(){
    this.miservicio.getTiposHab(this.data.intId).subscribe(datos=>{
      this.listaHabitaciones=datos;
      //console.log(this.listaHabitaciones);
      if(this.listaHabitaciones[0].strActivo==='S'){
        this.isChecked=true;
        this.Estado='Activa'
      }else{
        this.isChecked=false;
        this.Estado='Inactiva'
      }
    })
  }

  pulsa(){
    if(this.isChecked===true){
      this.Estado='Inactiva';
    }else{
      this.Estado="Activa";
    }
  }

  salvar(e){
    let miEstado:string;

    if(this.isChecked===true){
      miEstado='S';
    }else{
      miEstado="N";
    }

    // console.log(e);
    // console.log(miEstado)

    this.miservicio.putTipoHab(e,miEstado).subscribe((datos:any)=>{
      //console.log(datos);
      if(datos.miRespuesta.booOk){
        this.thisDialogRef.close(datos.miListTipoHabitacion);
      }else{
        this.mensajeError=datos.miRespuesta.strMensaje;
        this.existError=true;
      }
    })
  }

  cancelar(){
    this.thisDialogRef.close();
  }


}
