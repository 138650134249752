import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { Habitacion } from '../../../core/models/habitacion';
import { User } from '../../../core/models/user.model';
import { StorageService } from '../../../core/services/storage.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { HabitacionService } from '../../../core/services/habitacion.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSlideToggleChange} from '@angular/material';
import { MantenimientosService } from '../../../core/services/mantenimientos.service';
import { TipoHabitacion } from '../../../core/models/tipo-habitacion';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-edicion-habitaciones',
  templateUrl: './edicion-habitaciones.component.html',
  styleUrls: ['./edicion-habitaciones.component.scss']
})
export class EdicionHabitacionesComponent implements OnInit {

  listaHabitaciones:Habitacion[]=[];
  habitacion:Habitacion= new Habitacion();
  public loginUserData: User;
  public mihabitacion: Habitacion;
  public listaTiposHabitacion:TipoHabitacion[]=[];

  //private  data.strActivo:boolean;
  isChecked:boolean=false;
  Estado:string;
  existError:boolean=false;
  mensajeError:string;

  constructor(private http: HttpClient,private storageService: StorageService,private miservicio2:HabitacionService,
    private route: ActivatedRoute,private router: Router,private miservicio:MantenimientosService,
    public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: Habitacion) {
    this.route.queryParams.subscribe(params => {
      //this.habitacion.strNombre = params['strNombre'];
      //this.habitacion.strTipoHabitacionNombre = params["strTipoHabitacionNombre"];
     // this.habitacion.strDescripcion = params["strDescripcion"];
      //this.habitacion.strUserName = params["strUserName"];
      //this.habitacion.intEstadoLimpiezaId = params["intEstadoLimpiezaId"];
    });
   }

 

  ngOnInit() {
    //console.log(this.data.intId);

    this.listar();

    this.listarTiposHab();
  


    //console.log(this.mihabitacion = this.data.mihabitacion);
    //this.setActivo(this.data);
    
  }

 /* setActivo(strActivo) { 
    switch (strActivo) {
      case 'S':
        return true;
      case 'N':
        return false;
    }

  }*/

  listar(){
    this.miservicio.getMiHabEditar(this.data.intId).pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.listaHabitaciones=datos;
      //console.log(this.listaHabitaciones);
      //console.log(this.listaHabitaciones[0].strActivo);

      if(this.listaHabitaciones[0].strActivo==='S'){
        this.isChecked=true;
        this.Estado='Activa'
      }else{
        this.isChecked=false;
        this.Estado='Inactiva'
      }
    })
  }


  listarTiposHab(){
    this.miservicio.getTiposHab(0).pipe(first()).subscribe(datos=>{
      this.listaTiposHabitacion=datos.filter(e=>e.strActivo==="S");;
      //console.log(this.listaTiposHabitacion);
    })
  }
 

  /*setActivo(change: EventEmitter<MatSlideToggleChange>) { 
    switch (change.) {
      case true:
        return true;
      case 'N':
        return false;
    }
    console.log
}*/

  salvar(habitacion:Habitacion){
    /* Aqui obtengo los datos del formulario */
    //console.log(this.data);
    /* Hago el put y despues ejecuto la funcion close del DialogRef */

    let miEstado:string;

    if(this.isChecked===true){
      miEstado='S';
    }else{
      miEstado="N";
    }

    this.miservicio.putHabitaciones(habitacion,miEstado).pipe(first()).subscribe((datos:any)=>{
      //console.log(datos)
      if(datos.miRespuesta.booOk===true){
        this.thisDialogRef.close(datos);
      }else{
        this.mensajeError=datos.miRespuesta.strMensaje;
        this.existError=true;
      }
    })
    
  // this.thisDialogRef.close(this.data);

   //this.thisDialogRef.close();
  }

  cancelar(){
    this.thisDialogRef.close();
  }

  pulsa(){
    if(this.isChecked===true){
      this.Estado='Inactiva';
    }else{
      this.Estado="Activa";
    }
  }

}
