import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../../core/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HabitacionService } from '../../../core/services/habitacion.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { Habitacion } from '../../../core/models/habitacion';
import { Empleado } from '../../../core/models/empleado';
import { NuevoTrabajador } from '../../../core/models/nuevo-trabajador';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-insertar-usuarios',
  templateUrl: './insertar-usuarios.component.html',
  styleUrls: ['./insertar-usuarios.component.scss']
})
export class InsertarUsuariosComponent implements OnInit {

  NuevoEmpleado:NuevoTrabajador=new NuevoTrabajador();
  //NuevoEmpleado:NuevoTrabajador[]=[];

  formNewTrabajador: FormGroup;
  submitted: Boolean = false;
  //public error: {code: number, message: string} = null;
  passwordError:boolean=false;

  mostrarPassword:boolean=true;
  ocultarPassword:boolean=false;
  mostrarPassword2:boolean=true;
  ocultarPassword2:boolean=false;

  existError:boolean=false;
  mensajeError:string;

  constructor(private http: HttpClient,private storageService: StorageService,private _formBuilder: FormBuilder,
    private route: ActivatedRoute,private router: Router,private miservicio:HabitacionService,
    public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: {NuevoEmpleado:NuevoTrabajador}) { }

  ngOnInit() {
    this.formNewTrabajador = this._formBuilder.group({
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      dni: [''],
      telefono1: [''],
      telefono2: [''],
      email: [''],      
      /*telefono: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$'),
      ])),*/
      direccion: [''],
      poblacion: [''],
      provincia: [''],
      //cp: [''],
      cp: new FormControl('', Validators.compose([
        Validators.pattern('^[0-9]{5}$'),
      ])),
      pais: [''],
      usuario: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{7,}'),
      ])),
      confirmPassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{7,}'),
      ])),
      /*cp: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{5}$'),
      ])),*/
    })
  }

  showPassword(){
    this.mostrarPassword=false;
    this.ocultarPassword=true;
  }

  hiddenPassword(){
    this.mostrarPassword=true;
    this.ocultarPassword=false;
  }

  showPassword2(){
    this.mostrarPassword2=false;
    this.ocultarPassword2=true;
  }

  hiddenPassword2(){
    this.mostrarPassword2=true;
    this.ocultarPassword2=false;
  }

  cancelar(){
    this.thisDialogRef.close(this.data);
  }

  salvar(){
    this.submitted = true;    
    this.passwordError=false;

    if(this.formNewTrabajador.valid){
      if(this.NuevoEmpleado.strPassword===this.NuevoEmpleado.strConfirmPassword){
        //console.log(this.NuevoEmpleado);        
        this.miservicio.addEmpleado(this.NuevoEmpleado).pipe(first()).subscribe((datos:any)=>{
          //console.log(datos)
          if(datos.miRespuesta.booOk){
            this.thisDialogRef.close(datos.miListTrabajador);
          }else{
            this.mensajeError=datos.miRespuesta.strMensaje;
            this.existError=true;
          }
        })
      }else{
        this.passwordError=true;
      }
    }
  }

}
