import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { StorageService } from '../core/services/storage.service';


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  cookie:string;

  constructor(private route: ActivatedRoute, private router:Router,public thisDialogRef: MatDialogRef<MatDialog>,
    private storageService: StorageService
    /*@Inject(MAT_DIALOG_DATA) public data: {any:any}*/) {
    /*this.route.queryParams.subscribe(params => {
      this.cookie=params["coo"];
    });*/
   }

  ngOnInit() {
    //console.log(this.cookie);
  }

  public logout(): void{
    this.thisDialogRef.close();
          setTimeout(() => {
            this.storageService.logout();
          }, /*2000*/);  
  }
  

}
