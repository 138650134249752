import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReservasService } from 'src/app/core/services/reservas.service';
import { Cliente } from 'src/app/core/models/cliente';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Reserva } from 'src/app/core/models/reserva';

@Component({
  selector: 'app-agrega-huesped',
  templateUrl: './agrega-huesped.component.html',
  styleUrls: ['./agrega-huesped.component.scss']
})
export class AgregaHuespedComponent implements OnInit {

  listClientes:Cliente[]=[];  
  cliente:Cliente=new Cliente();
  formNewCliente: FormGroup;
  dniValido:boolean=false;
  submitted:boolean=false;
  avanza:boolean=false;
  dniError:boolean=false;


  filtro:string;
  dataTodo:Cliente[]=[];
  checktodos:any;
  dataTodoObsoleto:Cliente[]=[];

  //colorCliente:any;
  selectCliente:any;
  listAux:any[]=[];

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: reservaEdit,private miservicioReservas:ReservasService,
  private _formBuilder: FormBuilder) { }

  ngOnInit() {
    //console.log(this.data)
    this.listarClientes();

    this.formNewCliente = this._formBuilder.group({
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      nacimiento: ['', Validators.required],
      sexo: ['', Validators.required],
      dni: ['', Validators.required],
      expedido: ['', Validators.required],
      pais: ['', Validators.required]
    })
  }

  listarClientes(){
    this.miservicioReservas.getClientes().subscribe(datos=>{
      //this.data=datos;
      //console.log(datos);
      this.dataTodoObsoleto=datos;
      this.dataTodo=datos.filter(e=>e.strActivo==="S");
      this.listClientes=datos.filter(e=>e.strActivo==="S");
      //console.log(this.listClientes)
    })
  }

  // cambia(event:any){
  //   this.cliente=this.listClientes.filter(e=>e.intId===event)[0];
  // }

  onChangeNacimiento(event){
    //console.log(event)
    if(event){
      this.cliente.datFechaNacimiento=event;
    }
  }

  onChangeExpedido(event){
    //console.log(event)
    if(event){
      this.cliente.datDniExpedido=event;
    }
  }

  todos(event){
    //console.log(event.checked)
    if(event.checked){
      this.dataTodo.splice(0,this.dataTodo.length)
      this.dataTodo=this.dataTodoObsoleto;
      if(this.filtro===undefined){
        this.filtro='';
      }   
      this.funcionBusqueda(this.filtro.toLocaleLowerCase(),this.dataTodo);                    
    }else{
      //this.dataTodo.splice(0,this.dataTodo.length)
      this.dataTodo=this.dataTodoObsoleto.filter(e=>e.strActivo==="S");
      if(this.filtro===undefined){
        this.filtro='';
      }
      this.funcionBusqueda(this.filtro.toLocaleLowerCase(),this.dataTodo);
    }
    //console.log(this.dataTodo)
  }

  buscar(event: any){
    this.filtro = event.target.value;
    //console.log(this.filtro)
  
    // if(this.filtro===''){
    //   this.listClientes=this.dataTodo
    // }else{
    //   this.listClientes=this.dataTodo.filter(e=>e.strNombre.toLowerCase().startsWith(this.filtro.toLowerCase()) || 
    //   e.strApellidos.toLowerCase().startsWith(this.filtro.toLowerCase()) || e.strDni.toLowerCase().startsWith(this.filtro.toLowerCase()));
    // }
    this.funcionBusqueda(this.filtro.toLocaleLowerCase(),this.dataTodo);
  }

  funcionBusqueda(filtro,dataFilter){
    //console.log(filtro)
    if(filtro===''){
      this.listClientes=dataFilter
    }else{
      this.listClientes=dataFilter.filter(e=>e.strNombre.toLowerCase().startsWith(filtro.toLowerCase()) || 
      e.strApellidos.toLowerCase().startsWith(filtro.toLowerCase()) || e.strDni.toLowerCase().startsWith(filtro.toLowerCase()));
    }
  }

  seleccionaCliente(e){
    this.selectCliente=e.intId;
    this.listAux.push(e.intId)
    let ultimo=this.listAux[this.listAux.length - 1];

    this.listAux.forEach(o => {
      if(o===ultimo){
        document.getElementById(o).style.borderStyle="solid";
        document.getElementById(o).style.borderWidth="2px";
        document.getElementById(o).style.borderColor="#ffe066";        
      }else{
        document.getElementById(o).style.borderStyle="none";
      }
    });
  }

  agregarHuesped(){
    //this.thisDialogRef.close();
    this.dniError=false;
    this.submitted = true;

    if(this.nif()===true){
      this.avanza=true;
    }else{
      this.dniError=true;
      this.avanza=false;
    }

    if(this.formNewCliente.valid && this.dniValido===true){
      //console.log(this.cliente)
      this.cliente.strActivo="S";

      this.miservicioReservas.postAgregaHuespedReserva(this.data.intGrupoId,this.data.id,this.cliente).subscribe((datos:any)=>{
        //console.log(datos)
        if(datos.miRespuesta.booOk){
          this.thisDialogRef.close(datos.miListHuesped);
        }
      })
    }
  }

  guardar(){
    //console.log(this.selectCliente)
    //let clienteVacio:Cliente=new Cliente();
    //console.log(this.cliente)
    this.miservicioReservas.postHuespedReserva(this.data.intGrupoId,this.data.id,this.selectCliente).subscribe((datos:any)=>{
      //console.log(datos)
      if(datos.miRespuesta.booOk){
        this.thisDialogRef.close(datos.miListHuesped);
      }
    })
  }

  public  nif() {
    var numero
    var letr
    var letra
    var expresion_regular_dni
   
    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;
   
    if(expresion_regular_dni.test (this.formNewCliente.controls.dni.value) == true){
       numero = this.formNewCliente.controls.dni.value.substr(0,this.formNewCliente.controls.dni.value.length-1);
       letr = this.formNewCliente.controls.dni.value.substr(this.formNewCliente.controls.dni.value.length-1,1);
       numero = numero % 23;
       letra='TRWAGMYFPDXBNJZSQVHLCKET';
       letra=letra.substring(numero,numero+1);
      if (letra!=letr.toUpperCase()) {
        return this.dniValido=false;
         //alert('Dni erroneo, la letra del NIF no se corresponde');
       }else{
         return this.dniValido=true;
       }
    }else if(this.formNewCliente.controls.dni.value===undefined){
      return this.dniValido=true;
    }else{
      return this.dniValido=false;
       //alert('Dni erroneo, formato no válido');
     }
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}

// export class ClaveValor{
  
//   public clave?:any;
//   public valor?:any;

// }


export class reservaEdit{

  public end: any;
  public id: number;
  public intClienteId: number;
  public intRegimenId: number;
  public regimenNombre: string;
  public resource: any;
  public start: any;
  public strClienteApellidos: string;
  public strClienteNombre: string;
  public strEstadoReserva: string;
  public intGrupoId:any;

}