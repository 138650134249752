import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { MantenimientosService } from '../core/services/mantenimientos.service';
import { first, map } from 'rxjs/operators';
import { TipoHabitacion } from '../core/models/tipo-habitacion';
import { MatSort, MatPaginator, MatDialog, MatTableDataSource } from '@angular/material';
import { Observable, of as observableOf, merge } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { InsertarTipoHabitacionComponent } from '../mantenimientos/insertar/insertar-tipo-habitacion/insertar-tipo-habitacion.component';
import { EdicionTipoHabitacionComponent } from '../mantenimientos/editar/edicion-tipo-habitacion/edicion-tipo-habitacion.component';


@Component({
  selector: 'app-mantenimiento-tipos-habitacion',
  templateUrl: './mantenimiento-tipos-habitacion.component.html',
  styleUrls: ['./mantenimiento-tipos-habitacion.component.scss']
})
export class MantenimientoTiposHabitacionComponent implements OnInit {

  // listaTiposHabitacion:TipoHabitacion[]=[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  data:TipoHabitacion[]=[];
  dataTodo:TipoHabitacion[]=[];
  displayedColumns = ['strNombre','intCapacidad','strAbreviatura'];
  dataSource;
  filtro:any;
  listFiltrada:TipoHabitacion[]=[];
  dataExcel:datosExcel[]=[];
  teclaAlt:any;
  otraTecla:any;

  constructor(private miservicio:MantenimientosService,public dialog: MatDialog) { }

  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = 'Elementos por pagina';
    this.listarTiposHab();
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===78 && this.otraTecla===18){
      this.nuevo();
    }else if(this.teclaAlt===88 && this.otraTecla===18){
      this.exportAsXLSX();
    }else if(this.teclaAlt===66 && this.otraTecla===18){
      var myEl = document.getElementById('miFiltro');
      myEl.focus();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }

  listarTiposHab(){
    this.dataSource = new MyTableDataSource(this.paginator, this.sort);

    this.miservicio.getTiposHab(0).pipe(first()).subscribe(datos=>{
      this.data=datos;
      this.listFiltrada=datos;
      //console.log(this.data);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    })
  }

  buscar(event: any){
    this.filtro = event.target.value;
    // console.log(this.filtro);
    // console.log(this.data[0].intCapacidad)
  
    this.dataTodo=this.data;
    if(this.filtro===''){
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
      this.listFiltrada=this.data;
    }else{
      this.data=this.data.filter(e=>e.strNombre.toLowerCase().startsWith(this.filtro.toLowerCase()) || 
      e.strAbreviatura.toLowerCase().startsWith(this.filtro.toLowerCase()) || e.intCapacidad.toString() === this.filtro);
    //console.log(this.data);
      this.listFiltrada=this.data
      this.dataSource = new MyTableDataSource(this.paginator, this.sort);
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    }
    this.data=this.dataTodo;
  }

  getColor(strActivo) { 
    switch (strActivo) {
      case 'N':
        return '#ff3333';
      case 'S':
        return '#00b300';
    }
  }

  nuevo(){
    let dialogRef = this.dialog.open(InsertarTipoHabitacionComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      //this.data=result;
      //console.log(result)
       if(result!=null){
        this.data.splice(0, this.data.length);
        this.data=result;  
        this.dataSource=new MatTableDataSource(result);                 
       }      
    });
  }

  editHab(habitacion:TipoHabitacion){
    let dialogRef = this.dialog.open(EdicionTipoHabitacionComponent, {
      width: '600px',
      data: {
        'intId':habitacion.intId,
      }
    });
    dialogRef.afterClosed().subscribe((datos) => {
      //console.log(datos)
      //console.log(this.data)
      if(datos!=undefined){
        let myObject:any;
        myObject=datos[0];
  
        let miTicket=datos[0].intId;
  
        var index = this.data.map(x => {
          return x.intId;
        }).indexOf(miTicket);
  
        if(datos.length!=0){      
          this.data.splice(index, 1, myObject);
          this.dataSource=new MatTableDataSource(this.data);
        }
        //this.getColor(myObject.strActivo);
        // if(datos.length===0){
        //   this.data.splice(index, 1);
        // }
      }        
    });
  }

  exportAsXLSX():void {
    this.listFiltrada.forEach(e => {
      // this.dataExcel.push({num_reserva:element.intId, num_habitacion:element.strHabitacionNombre, entrada:this.datepipe.transform(element.datEntrada, 'dd/MM/yyyy'), salida:this.datepipe.transform(element.datSalida, 'dd/MM/yyyy'), cliente:element.strClienteNombre+" "+element.strClienteApellidos, precio:0});
      this.dataExcel.push({
        Nombre:e.strNombre, 
        Capacidad:e.intCapacidad, 
        Abreviatura:e.strAbreviatura,
        Activo:e.strActivo
      })
    });
    this.miservicio.exportAsExcelFile(this.dataExcel, 'Tipos habitación');

    this.dataExcel.splice(0, this.dataExcel.length)
  }

}

export interface datosExcel {
  Nombre:any;
  Capacidad:any;
  Abreviatura:any;
  Activo:any;
}

export class MyTableDataSource extends DataSource<TipoHabitacion> {
  data:TipoHabitacion[]=[];
 
   constructor(private paginator: MatPaginator, private sort: MatSort) {
     super();
   }
 
   connect(): Observable<TipoHabitacion[]> {
     const dataMutations = [
       observableOf(this.data),
       this.paginator.page,
       this.sort.sortChange
     ];
 
     this.paginator.length = this.data.length;
 
     return merge(...dataMutations).pipe(map(() => {
       return this.getPagedData(this.getSortedData([...this.data]));
     }));
   }
 
   disconnect() {}
 
   private getPagedData(data: TipoHabitacion[]) {
     const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
     return data.splice(startIndex, this.paginator.pageSize);
   }
 
 
   private getSortedData(data: TipoHabitacion[]) {
     if (!this.sort.active || this.sort.direction === '') {
       return data;
     }
 
     return data.sort((a, b) => {
       const isAsc = this.sort.direction === 'asc';
       switch (this.sort.active) {
         case 'strNombre': return compare(a.strNombre, b.strNombre, isAsc);
         case 'strAbreviatura': return compare(+a.strAbreviatura, +b.strAbreviatura, isAsc);
         default: return 0;
       }
     });
   }
 }
 
 function compare(a, b, isAsc) {
   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
 }
