import { Component, OnInit, OnDestroy, OnChanges, AfterContentInit } from '@angular/core';
//import { CRespuesta } from './shared/crespuesta';
import { User } from '../core/models/user.model';
import { StorageService } from '../core/services/storage.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CRespuestaService } from './shared/CRespuesta.service';
import { Session } from '../core/models/session.model';
import { DISABLED } from '@angular/forms/src/model';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit  {

  loginForm: FormGroup;
  submitted: Boolean = false;
  isLoginError : boolean = false;
  textoError:string;
  public userAcces:User[]=[];
  strUserName:string;
  strPassword:string;

  answer: string = '';
  answerDisplay: string = '';
  showSpinner: boolean = false;

  mostrarPassword:boolean=true;
  ocultarPassword:boolean=false;

  public href: string;
  cookie:string;

  sesionEnUso:boolean=true;
  sescionCaduca:boolean=false;

  mistrUsuario:string;
  mistrPassword:string;

  constructor(private _formBuilder: FormBuilder,
    private storageService: StorageService,
    private router: Router,private route: ActivatedRoute,
    private CRespuestaService:CRespuestaService) {
      this.route.queryParams.subscribe(params => {
        this.cookie=params["coo"];
      });
     }

  ngOnInit() {
    
    this.loginForm = this._formBuilder.group({
      username: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required        
      ]))
    })
  }

  keyDownFunction(event) {
    if(event.key === "Enter"){
      this.submitLogin();
    }
  }

  public submitLogin() {    
    this.submitted = true; 
    this.isLoginError=false;

    if(this.loginForm.valid){

      //this.showSpinner = true;
      //setTimeout(() => {
        //this.showSpinner = false;
    
    this.CRespuestaService.findCRespuesta2(this.cookie).subscribe(datos=>{  
      //console.log(datos)

        if(datos.strUsuario===null){

            this.answerDisplay = this.answer;
            this.sescionCaduca=true;
            this.sesionEnUso=false;          
        }
        //Obtengo strMensaje, strToken y el objeto user (strUserId, strRolName, strUserName)
        this.textoError=datos.strMensaje;

        let strSecurityStampHash=datos.strContrasena;
        this.DesencriptaPassword(strSecurityStampHash);

        let strUsuarioEncriptado=datos.strUsuario;
        this.DesencriptaUsuario(strUsuarioEncriptado);

        let miCookie=datos.strCookie;

        if(datos.strUsuario!=null){
          //Paso el usuario y contraseña que digita el cliente
          this.CRespuestaService.findCRespuesta3(this.strUserName,this.strPassword,miCookie).subscribe(data=>{
            if(data.strMensaje==="OK"){

              this.showSpinner = true;
            setTimeout(() => {
              //Paso el usuario y contraseña que obtengo de la desincriptacion
            this.CRespuestaService.findCRespuesta4(this.mistrUsuario,this.mistrPassword).subscribe(datos2=>{
              //Este me devuelve el token y el tiempo de expiracion
              data.expires=datos2[".expires"];
              data.strToken=datos2.access_token;
              this.correctLogin(data);                              
            })

              this.showSpinner = false;
               }, 4000);

            }                        
            
          })
        }                
    },(err : HttpErrorResponse)=>{
      if(err.status===401){
      }
    });

  //}, 4000);
  }


  }          

  private correctLogin(data: Session){

    this.storageService.setCurrentSession(data);
    this.router.navigate(['/listado']);
   
  }

  private DesencriptaPassword(strSecurityStampHash:string){
    this.mistrPassword = "";

	// Solo continuo si existe strSecurityStampHash
	if (strSecurityStampHash != null && strSecurityStampHash != "")
	{
    strSecurityStampHash=strSecurityStampHash.split('').reverse().join('');
		strSecurityStampHash = strSecurityStampHash.substr(5, strSecurityStampHash.length - (2 * 5));

    let i:number;
		for (i = 0; i < strSecurityStampHash.length; i++)
		{
			if (i % 2 == 0) { this.mistrPassword += strSecurityStampHash.substr(i, 1); }
		}
  }

  //console.log(this.mistrPassword)
	return this.mistrPassword;
  }

  private DesencriptaUsuario(strUsuarioEncriptado:string){
    this.mistrUsuario = "";    

	if (strUsuarioEncriptado != null && strUsuarioEncriptado != "")
	{
    strUsuarioEncriptado=strUsuarioEncriptado.split('').reverse().join('');
		strUsuarioEncriptado = strUsuarioEncriptado.substr(5, strUsuarioEncriptado.length - (2 * 5));

    let i:number;
		for (i = 0; i < strUsuarioEncriptado.length; i++)
		{
			if (i % 2 == 0) { this.mistrUsuario += strUsuarioEncriptado.substr(i, 1); }
		}
  }

  //console.log(this.mistrUsuario)
	return this.mistrUsuario;
  }

  showPassword(){
    this.mostrarPassword=false;
    this.ocultarPassword=true;
  }

  hiddenPassword(){
    this.mostrarPassword=true;
    this.ocultarPassword=false;
  }

  recuperacionPassword(){
    this.showSpinner = true;
         
      let extra: NavigationExtras = {
        queryParams: {
          'coo':this.cookie,
        }
    }

      setTimeout(() => {
        this.showSpinner = false;
        this.router.navigate(['/login-recuperacion'], extra);
      }, 2000); 
  }

}
