export class EstadosLimpieza {

    public intId?:number;
	public strAbreviatura?:string;
	public strNombre?:string;
    public strActivo?:string;
    public strDefecto?:string;
    public strChecked?:boolean;
    public strColor?:string;
    public intPosicion?:number;

}
