import { Component, OnInit, Inject } from '@angular/core';
import { Habitacion } from '../../../core/models/habitacion';
import { HabitacionService } from '../../../core/services/habitacion.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../../core/services/storage.service';
import { ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { MantenimientosService } from '../../../core/services/mantenimientos.service';
import { TipoHabitacion } from '../../../core/models/tipo-habitacion';
import { Estados } from '../../../core/models/estados';
import { first } from 'rxjs/operators';
import { e } from '@angular/core/src/render3';

@Component({
  selector: 'app-insertar-habitaciones',
  templateUrl: './insertar-habitaciones.component.html',
  styleUrls: ['./insertar-habitaciones.component.scss']
})
export class InsertarHabitacionesComponent implements OnInit {

  habitacion:Habitacion=new Habitacion();
  public listaTiposHabitacion:TipoHabitacion[]=[];
  listaEstados:Estados[]=[];
  isChecked:boolean;
  isActiva:string;
  existError:boolean=false;
  mensajeError:string;

  constructor(private http: HttpClient,private storageService: StorageService,private miservicio2:HabitacionService,
    private route: ActivatedRoute,private router: Router,private miservicio:MantenimientosService,
    public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: {habitacion:Habitacion}) { }

  ngOnInit() {
    this.listarTiposHab();
    this.getMisEstados();
  }

  listarTiposHab(){
    this.miservicio.getTiposHab(0).pipe(first()).subscribe((datos:any)=>{
      //console.log(datos);    
      
      this.listaTiposHabitacion=datos.filter(e=>e.strActivo==="S");
      //console.log(this.listaTiposHabitacion);
    })
  }

  getMisEstados(){
    this.miservicio2.getEstadosLimpieza().pipe(first()).subscribe(datos=>{
      this.listaEstados=datos;
      //console.log(this.listaEstados);
    })
  }

  addHab(){
    if(this.isChecked){
      this.isActiva="S"
    }else{
      this.isActiva="N"
    }
    this.miservicio.addHabitacion(this.habitacion,this.isActiva).pipe(first()).subscribe((datos:any)=>{
      //console.log(datos);
      if(datos.miRespuesta.booOk){
        this.thisDialogRef.close(datos.miListHabitacion);
      }else{
        this.mensajeError=datos.miRespuesta.strMensaje;
        this.existError=true;
      }      
    })
  }

  cancelar(){
    this.thisDialogRef.close(this.data);
  }

  getColor(intId) { 
    switch (intId) {
      case 1:
        return '#ff3333';
      case 2:
        return '#ffc14d';
      case 3:
        return '#00b300';
    }
  }

  salvar(){
    
  }

}
