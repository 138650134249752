import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { TipoHabitacion } from 'src/app/core/models/tipo-habitacion';
import { MantenimientosService } from 'src/app/core/services/mantenimientos.service';

@Component({
  selector: 'app-insertar-tipo-habitacion',
  templateUrl: './insertar-tipo-habitacion.component.html',
  styleUrls: ['./insertar-tipo-habitacion.component.scss']
})
export class InsertarTipoHabitacionComponent implements OnInit {

  habitacion:TipoHabitacion=new TipoHabitacion();
  existError:boolean=false;
  mensajeError:string;
  isChecked:boolean=false;
  isActiva:string;

  constructor(private miservicio:MantenimientosService,
    public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: {habitacion:TipoHabitacion}) { }

  ngOnInit() {
  }

  addHab(){   
    //console.log(this.isChecked);
    if(this.isChecked){
      this.isActiva="S"
    }else{
      this.isActiva="N"
    }

    this.miservicio.postTipoHab(this.habitacion,this.isActiva).subscribe(datos=>{
      //console.log(datos)
      //this.thisDialogRef.close();
      if(datos.miRespuesta.booOk===true){
        this.thisDialogRef.close(datos.miListTipoHabitacion);
      }else{
        this.mensajeError=datos.miRespuesta.strMensaje;
        this.existError=true;
      }
    })
  }

  cancelar(){
    this.thisDialogRef.close(this.data);
  }
}
