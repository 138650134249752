import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Habitacion } from '../models/habitacion';
import { Observable } from 'rxjs';
import { TipoHabitacion } from '../models/tipo-habitacion';
import { User } from '../models/user.model';
import { Configuracion } from '../models/configuracion';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DataSource } from '@angular/cdk/table';
import { Regimenes } from '../models/regimenes';
import { ReultTarifaThR } from 'src/app/tarifas/models/reult-tarifa-th-r';
import { Articulo } from '../models/articulo';
import { Iva } from '../models/iva';
import { FormaPago } from '../models/forma-pago';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class MantenimientosService {

  constructor(private http: HttpClient, private storageService: StorageService,) { }

  public url: string = environment.baseUrl;
  private token=this.storageService.getCurrentToken();
  private cookie=this.storageService.getCurrentSession().strCookie;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + this.token  })
  };

  getMiHabEditar(data):Observable<Habitacion[]>{
    let dato='strCookie='+this.cookie+'&intId='+data;
    let urlCompleta=this.url + '/api/habitacion?' + dato;
 
    return this.http.get<Habitacion[]>(urlCompleta,this.httpOptions);
  }

  getTiposHab(id):Observable<TipoHabitacion[]>{
    let dato='strCookie='+this.cookie+'&intId='+id;
    let urlCompleta=this.url+'/api/tipohabitacion?'+dato;
 
    return this.http.get<TipoHabitacion[]>(urlCompleta,this.httpOptions);
  }

  postTipoHab(tipo,isActiva){
    let urlCompleta=this.url+'/api/TipoHabitacion?'+"strCookie="+this.cookie ;

    let dato:any = {};
    dato.strNombre = tipo.strNombre;    
    dato.strAbreviatura=tipo.strAbreviatura;
    dato.intCapacidad=tipo.intCapacidad;
    dato.strActivo=isActiva;

    var jsonString = JSON.stringify(dato);
    return this.http.post<any>(urlCompleta,jsonString,this.httpOptions);
  }

  putTipoHab(tipo,isActiva){
    let urlCompleta=this.url+'/api/TipoHabitacion?'+"strCookie="+this.cookie ;

    let dato:any = {};
    dato.intId=tipo.intId;
    dato.strNombre = tipo.strNombre;    
    dato.strAbreviatura=tipo.strAbreviatura;
    dato.intCapacidad=tipo.intCapacidad;
    dato.strActivo=isActiva;

    var jsonString = JSON.stringify(dato);
    return this.http.put<any>(urlCompleta,jsonString,this.httpOptions);
  }

  putHabitaciones(habitacion:Habitacion,miEstado):Observable<Habitacion>{
    let urlCompleta=this.url+'/api/habitacion?'+"strCookie="+this.cookie ;
    habitacion.strActivo=miEstado
    let dato = '{"intId":'+ habitacion.intId +', "strNombre":"'+ habitacion.strNombre +'", "strDescripcion":"'+ habitacion.strDescripcion +'", "intTipoHabitacionId":'+ habitacion.intTipoHabitacionId +', "intEstadoLimpiezaId":'+ habitacion.intEstadoLimpiezaId +' , "strActivo":"'+ miEstado +'"}'; 
     
    return this.http.put<Habitacion>(urlCompleta, dato, this.httpOptions);
  }

  getRegimenes(id):Observable<Regimenes[]>{
    let dato='strCookie='+this.cookie+'&intId='+id;
    let urlCompleta=this.url+'/api/regimenes?'+dato;
 
    return this.http.get<Regimenes[]>(urlCompleta,this.httpOptions);
  }

  postRegimen(tipo,isActiva){
    let urlCompleta=this.url+'/api/regimenes?'+"strCookie="+this.cookie ;

    let dato:any = {};
    dato.strNombre = tipo.strNombre;    
    dato.strAbreviatura=tipo.strAbreviatura;
    dato.strActivo=isActiva;

    var jsonString = JSON.stringify(dato);
    return this.http.post<any>(urlCompleta,jsonString,this.httpOptions);
  }

  putRegimen(tipo,isActiva){
    let urlCompleta=this.url+'/api/regimenes?'+"strCookie="+this.cookie ;

    let dato:any = {};
    dato.intId=tipo.intId;
    dato.strNombre = tipo.strNombre;    
    dato.strAbreviatura=tipo.strAbreviatura;
    dato.strActivo=isActiva;

    var jsonString = JSON.stringify(dato);
    return this.http.put<any>(urlCompleta,jsonString,this.httpOptions);
  }

  addHabitacion(habitacion:Habitacion,isActiva):Observable<Habitacion>{
    let urlCompleta=this.url+'/api/habitacion?'+"strCookie="+this.cookie ;
    if(habitacion.strDescripcion===undefined){
      habitacion.strDescripcion="";
    }
    let dato = '{"strNombre": "'+ habitacion.strNombre +'", "strDescripcion":"'+ habitacion.strDescripcion +'", "intTipoHabitacionId":'+ habitacion.intTipoHabitacionId +', "intEstadoLimpiezaId":null, "strActivo":"'+ isActiva +'"}'; 
     
    return this.http.post<Habitacion>(urlCompleta, dato, this.httpOptions);
  }

  changePasswordTrabajador(strUserId,strPassword,strConfirmPassword):Observable<any>{
    let urlCompleta=this.url+'/api/trabajador/SetPassword?'+"strCookie="+this.cookie ;
    //let urlCompleta='http://localhost:40474/api/Trabajador/SetPassword?'+"strCookie="+this.cookie ;
    let dato = '{"strUserId": "'+ strUserId +'", "strPassword":"'+ strPassword +'", "ConfirmPassword":"'+ strConfirmPassword +'"}'; 
    return this.http.post<any>(urlCompleta,dato,this.httpOptions)
  }

  getConfiguraciones():Observable<Configuracion[]>{
    let dato='strCookie='+this.cookie+'&intId=1';
    let urlCompleta=this.url+'/api/establecimiento?'+dato;
 
    return this.http.get<Configuracion[]>(urlCompleta,this.httpOptions);
  }

  getMenu():Observable<any[]>{
    let dato='strCookie='+this.cookie;
    let urlCompleta=this.url+'/api/Menu?'+dato;
 
    return this.http.get<any[]>(urlCompleta,this.httpOptions);
  }

  putCondGenerales(c:Configuracion,tipoDato):Observable<any>{
    let urlCompleta=this.url+'/api/establecimiento?'+"strCookie="+this.cookie ;

    let dato:any = {};
    dato.intId=c.intId;
    dato.strNombre = c.strNombre;
    dato.strDescripcion=c.strDescripcion;
    dato.strNif=c.strNif;
    dato.strEmail=c.strEmail;
    dato.strWeb=c.strWeb;
    dato.strTelefono1=c.strTelefono1;
    dato.strTelefono2=c.strTelefono2;
    dato.strDireccion=c.strDireccion;
    dato.strCp=c.strCp;
    dato.strPoblacion=c.strPoblacion;
    dato.strProvincia=c.strProvincia;    
    dato.strPais=c.strPais;
    dato.strCondicionesGenerales=c.strCondicionesGenerales;
    dato.strMapa=c.strMapa;
    dato.strEslogan=c.strEslogan;
    dato.strUsaSsl=c.strUsaSsl;
    dato.intTipoDato=tipoDato;
    dato.strUserId=c.strUserId;

    var jsonString = JSON.stringify(dato);
    return this.http.put<any>(urlCompleta,jsonString,this.httpOptions);
  }

  putServCorreos(c:Configuracion,tipoDato):Observable<any>{
    let urlCompleta=this.url+'/api/establecimiento?'+"strCookie="+this.cookie ;

    let dato:any = {};
    dato.intId=c.intId;
    dato.strCorreoUsuario = c.strCorreoUsuario;
    dato.strCorreoClave=c.strCorreoClave;
    dato.strCorreoSmtp=c.strCorreoSmtp;
    dato.strCorreoSmtpPort=c.strCorreoSmtpPort;
    dato.strCorreoSmtpSsl=c.strCorreoSmtpSsl;
    dato.intTipoDato=tipoDato;
    dato.strUserId=c.strUserId;

    var jsonString = JSON.stringify(dato);
    return this.http.put<any>(urlCompleta,jsonString,this.httpOptions);
  }

  putReservas(c:Configuracion,tipoDato):Observable<any>{
    let urlCompleta=this.url+'/api/establecimiento?'+"strCookie="+this.cookie ;

    let dato:any = {};
    dato.intId=c.intId;
    dato.strMovHorizontal = c.strMovHorizontal;
    dato.strMovVertical=c.strMovVertical;
    dato.strPoliticaCancelaciones=c.strPoliticaCancelaciones;
    dato.strModificarEstancia=c.strModificarEstancia;
    dato.intIvaReservaDefecto=c.intIvaReservaDefecto;
    dato.intTipoDato=tipoDato;
    dato.strUserId=c.strUserId;
    dato.strBorraReserva=c.strBorraReserva;

    var jsonString = JSON.stringify(dato);
    return this.http.put<any>(urlCompleta,jsonString,this.httpOptions);
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    //console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  getTarifasRegimenHabitacion(inicio,fin):Observable<ReultTarifaThR[]>{
    let dato='strCookie='+this.cookie+'&strFechaDesde='+inicio+'&strFechaHasta='+fin;
    let urlCompleta=this.url+'/api/TarifaHabitacionRegimen?'+dato;
 
    return this.http.get<ReultTarifaThR[]>(urlCompleta,this.httpOptions);
  }

  insertaTarifaHabitacion(miobjetoTarifa,listDiaSeleccion,inicio,fin):Observable<ReultTarifaThR[]>{
    var jsonString = JSON.stringify(listDiaSeleccion);
    let dato='strCookie='+this.cookie+'&strFechaDesde='+miobjetoTarifa.datFechaDesde.toLocaleDateString()+'&strFechaHasta='+miobjetoTarifa.datFechaHasta.toLocaleDateString()+'&intTipoHabitacion='+miobjetoTarifa.intTipoHabitacion;
    //dato+='&intTipoRegimen='+miobjetoTarifa.intTipoRegimen+'&decPrecio='+miobjetoTarifa.decPrecio+'&miListDias='+jsonString;
    dato+='&intTipoRegimen='+miobjetoTarifa.intTipoRegimen+'&decPvp='+miobjetoTarifa.decPvp+'&strFechaInicio='+ inicio.toLocaleDateString() +'&strFechaFin='+ fin.toLocaleDateString();
    let urlCompleta=this.url+'/api/TarifaHabitacionRegimen?'+dato;
 
    return this.http.post<ReultTarifaThR[]>(urlCompleta,jsonString,this.httpOptions);
  }

  actualizaTarifaHabitacion(miobjetoTarifa):Observable<ReultTarifaThR[]>{
    //var jsonString = JSON.stringify(listDiaSeleccion);
    let dato='strCookie='+this.cookie+'&strFecha='+miobjetoTarifa.datFecha+'&intTipoHabitacion='+miobjetoTarifa.intTipoHabitacionId;
    dato+='&intTipoRegimen='+miobjetoTarifa.intTipoRegimenId+'&decPvp='+miobjetoTarifa.decPvp;
    let urlCompleta=this.url+'/api/TarifaHabitacionRegimen?'+dato;
 
    return this.http.put<ReultTarifaThR[]>(urlCompleta,this.httpOptions);
  }

  getArticulos(intId):Observable<Articulo[]>{
    let dato='strCookie='+this.cookie+'&intId='+intId;
    let urlCompleta=this.url+'/api/Articulo?'+dato;
 
    return this.http.get<Articulo[]>(urlCompleta,this.httpOptions);
  }

  insertaArticulo(articulo):Observable<Articulo[]>{    
    let dato='strCookie='+this.cookie;
    let urlCompleta=this.url+'/api/Articulo?'+dato;
    var jsonString = JSON.stringify(articulo);
 
    return this.http.post<Articulo[]>(urlCompleta,jsonString,this.httpOptions);
  }

  actualizaArticulo(articulo):Observable<Articulo[]>{    
    let dato='strCookie='+this.cookie;
    let urlCompleta=this.url+'/api/Articulo?'+dato;
    var jsonString = JSON.stringify(articulo);
 
    return this.http.put<Articulo[]>(urlCompleta,jsonString,this.httpOptions);
  }

  getIva(intId):Observable<Iva[]>{
    let dato='strCookie='+this.cookie+'&intId='+intId;
    let urlCompleta=this.url+'/api/Iva?'+dato;
 
    return this.http.get<Iva[]>(urlCompleta,this.httpOptions);
  }

  actualizaIva(iva):Observable<Iva[]>{    
    let dato='strCookie='+this.cookie;
    let urlCompleta=this.url+'/api/Iva?'+dato;
    var jsonString = JSON.stringify(iva);
 
    return this.http.put<Iva[]>(urlCompleta,jsonString,this.httpOptions);
  }

  insertaIva(iva):Observable<Iva[]>{    
    let dato='strCookie='+this.cookie;
    let urlCompleta=this.url+'/api/Iva?'+dato;
    var jsonString = JSON.stringify(iva);
 
    return this.http.post<Iva[]>(urlCompleta,jsonString,this.httpOptions);
  }

  getFormasPago(intId):Observable<FormaPago[]>{
    let dato='strCookie='+this.cookie+'&intId='+intId;
    let urlCompleta=this.url+'/api/Forma_Pago?'+dato;
 
    return this.http.get<FormaPago[]>(urlCompleta,this.httpOptions);
  }

  insertaFormaPago(fpago):Observable<FormaPago[]>{    
    let dato='strCookie='+this.cookie;
    let urlCompleta=this.url+'/api/Forma_Pago?'+dato;
    var jsonString = JSON.stringify(fpago);
 
    return this.http.post<FormaPago[]>(urlCompleta,jsonString,this.httpOptions);
  }

  actualizaFormaPago(fpago):Observable<FormaPago[]>{    
    let dato='strCookie='+this.cookie;
    let urlCompleta=this.url+'/api/Forma_Pago?'+dato;
    var jsonString = JSON.stringify(fpago);
 
    return this.http.put<FormaPago[]>(urlCompleta,jsonString,this.httpOptions);
  }
  
}
