import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Iva } from 'src/app/core/models/iva';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MantenimientosService } from 'src/app/core/services/mantenimientos.service';

@Component({
  selector: 'app-edita-iva',
  templateUrl: './edita-iva.component.html',
  styleUrls: ['./edita-iva.component.scss']
})
export class EditaIvaComponent implements OnInit {

  formNew: FormGroup;
  submitted:boolean=false;
  isChecked:boolean=false;
  Estado:string;
  existError:boolean=false;
  mensajeError:string;
  miIva:Iva=new Iva();

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: Iva,private _formBuilder: FormBuilder,private miServicio:MantenimientosService) { }

  ngOnInit() {
    //console.log(this.data)
    this.listarDatos();

    this.formNew = this._formBuilder.group({
      nombre: ['', Validators.required],
      valor: new FormControl('', Validators.compose([
        Validators.pattern('^[0-9]{1,6}[.,]?[0-9]{1,6}?$'),
      ])),     
      estado: ['']
    })
  }

  listarDatos(){
    this.miServicio.getIva(this.data.intId).subscribe(datos=>{

      if(datos[0].strActivo==='S'){
        this.isChecked=true;
        this.Estado='Activo'
      }else{
        this.isChecked=false;
        this.Estado='Inactivo'
      }

      this.miIva=datos[0];
    })
  }

  pulsa(){
    if(this.isChecked===true){
      this.Estado='Inactivo';
    }else{
      this.Estado="Activo";
    }
  }

  cancelar(){
    this.thisDialogRef.close();
  }

  salvar(){
    if(this.isChecked===true){
      this.miIva.strActivo='S';
    }else{
      this.miIva.strActivo="N";
    } 
    this.submitted = true;
    let listaIva:Iva[]=[];
    listaIva[0]=this.miIva
       
    if(this.formNew.valid){
      
      this.miServicio.actualizaIva(listaIva).subscribe((datos:any)=>{        
        //console.log(datos);
        if(datos.miRespuesta.booOk===true){
          this.thisDialogRef.close(datos.miListIva);
        }else{
          this.mensajeError=datos.miRespuesta.strMensaje;
          this.existError=true;
        }
      }) 

    }     
   }

  

}
