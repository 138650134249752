import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {
  constructor(private router: Router,
    private storageService: StorageService) { }


    /*canLoad() {
      let url: string = window.location.pathname;
      console.log('Url:'+ url);
      return true;
    }*/

    canActivate() {
      
      //let loginUserData = this.storageService.getCurrentUser();
      let miComponente=window.location.pathname;
      //console.log(window)
      //console.log(window.location.pathname)
      let loginUserComponent = this.storageService.getCurrentSession();
      let strAcceso:boolean;

      strAcceso=loginUserComponent.miListComponente.some(e => e.strPath === miComponente);
      //console.log(strAcceso)
      
      //if (this.storageService.isAuthenticated()  &&  loginUserData.strRolName.includes("Administrador") && strAcceso===true) {
      if (this.storageService.isAuthenticated()  && strAcceso) {
       return true;
      }

      this.storageService.logout();
       return false;
    }
}
