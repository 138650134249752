import { Component, OnInit, HostListener } from '@angular/core';
import { Configuracion } from '../core/models/configuracion';
import { first } from 'rxjs/operators';
import { MantenimientosService } from '../core/services/mantenimientos.service';
import { Title } from '@angular/platform-browser';
import { Iva } from '../core/models/iva';

@Component({
  selector: 'app-configuraciones',
  templateUrl: './configuraciones.component.html',
  styleUrls: ['./configuraciones.component.scss']
})
export class ConfiguracionesComponent implements OnInit {
  
  miConfiguracion:Configuracion[]=[];
  //put condiciones generales=1
  //put servidor correos=2
  tipoDato:any;
  showSpinner:boolean=false;
  title:string;
  //selectIva:Iva=new Iva();
  listIva:Iva[]=[];  

  constructor(private miservicio:MantenimientosService,private titleService: Title) { }

  ngOnInit() {
    this.listar();
    this.listarDatos();    
  }  

  public listar(){
    this.miservicio.getConfiguraciones().pipe(first()).subscribe(datos=>{   
      this.miConfiguracion=datos;
      //console.log(this.miConfiguracion)
    })
  }

  listarDatos(){
    this.miservicio.getIva(0).subscribe(datos=>{
      //console.log(datos)
      this.listIva=datos.filter(e=>e.strActivo==="S");       
    })
  }

  addGenerales(c:Configuracion){
    /*let newTitle:string;
      newTitle=c.strNombre;
      this.titleService.setTitle( newTitle ); */   
    this.tipoDato=1;
    //console.log(c)
    this.miservicio.putCondGenerales(c,this.tipoDato).pipe(first()).subscribe((datos:any)=>{
      //console.log(datos)
      if(datos.booOk===true){
        this.showSpinner = true;
        setTimeout(() => {
          this.showSpinner = false;
        }, 1000);
      }
    })
  }

  addServidor(c:Configuracion){
    //console.log(c)
    this.tipoDato=2;
    this.miservicio.putServCorreos(c,this.tipoDato).pipe(first()).subscribe(datos=>{
      //console.log(datos)
      if(datos.booOk===true){
        this.showSpinner = true;
        setTimeout(() => {
          this.showSpinner = false;
        }, 1000);
      }
    })
  }

  addReservas(c:Configuracion){
    //console.log(c)
    this.tipoDato=3;
    this.miservicio.putReservas(c,this.tipoDato).pipe(first()).subscribe(datos=>{
      //console.log(datos)
      if(datos.booOk===true){
        this.showSpinner = true;
        setTimeout(() => {
          this.showSpinner = false;
        }, 1000);
      }
    })
  }

}
