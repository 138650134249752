import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Habitacion } from '../core/models/habitacion';
import { HabitacionService } from '../core/services/habitacion.service';
import { Estados } from '../core/models/estados';
import { User } from '../core/models/user.model';
import { StorageService } from '../core/services/storage.service';
import { HabitacionLimpieza } from '../core/models/habitacion-limpieza';
import { Limpieza } from '../core/models/limpieza';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-editar',
  templateUrl: './editar.component.html',
  styleUrls: ['./editar.component.scss']
})
export class EditarComponent implements OnInit {

  listaLimpiezas:Limpieza[]=[];
  //miLimpieza:Limpieza= new Limpieza();
  limpiezaHoy:Limpieza=new Limpieza();
  strNotas:string;
  listaEstados:Estados[]=[];
  public loginUserData: User;
  isLoginError : boolean = false;
  miFecha:string;
  horaInicio:string;
  horaFin:string;
  date = new FormControl(new Date());
  guardar:boolean=false;
  error2:boolean=false;
  cambio:string;
  sinDatos:boolean=false;
  exito:boolean=false;
  today = new Date();
  noHayHoraInicio:boolean=false;
  noHayHoraFin:boolean=false;
  siHayHoraInicio:boolean=false;
  siHayHoraFin:boolean=false;
  puntoPartida:string;

  listadoLimpieza:boolean=true;
  hayLimpiezaHoy:boolean=false;
  showSpinner:boolean=false;

  constructor(private http: HttpClient,private storageService: StorageService,
    private route: ActivatedRoute,private router: Router,private miservicio:HabitacionService,
    public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    /*this.route.queryParams.subscribe(params => {
      this.miLimpieza.strHabitacionNombre = params['strHabitacionNombre']; 
      this.miLimpieza.intHabitacionId = params['intHabitacionId'];  
      this.miLimpieza.intId = params["intLimpiezaId"];
      this.miLimpieza.datFechaRegistro = params["datFecha"];
      this.miLimpieza.intEstadoLimpiezaId = params["intEstadoLimpiezaId"];
      this.puntoPartida=params["puntoPartida"];

    });*/
   }

  ngOnInit() {
    //console.log(this.data);
    this.getMisEstados();
    
    this.showSpinner = true;
    setTimeout(() => {
      this.showSpinner = false;
      this.cargarDatos();
    }, 1000); 

  }

  cargarDatos(){
    this.miservicio.getLimpieza(this.data,this.storageService.getCurrentUser().strUserId)
    .pipe(first()).subscribe((datos:any)=>{
      //console.log(datos);
      if(datos.length===0){
        this.sinDatos=true;  
        var hoy = new FormControl(new Date()).value.toLocaleDateString();
        
        if(new Date(this.data.datFechaRegistro).toLocaleDateString()===hoy){
          this.sinDatos=false;
          this.cambio = new Date(this.data.datFechaRegistro).toLocaleDateString();
          this.limpiezaHoy=this.data;
          this.limpiezaHoy.strHoraInicio="";
          this.limpiezaHoy.strHoraFin="";
          this.limpiezaHoy.strNotas=this.data.strUltimaNotaLimpieza;
          this.limpiezaHoy.strUserId=this.storageService.getCurrentUser().strUserId;
          //console.log(this.limpiezaHoy)    
          this.listadoLimpieza=false;
          this.hayLimpiezaHoy=true;      
        }else{
          this.listadoLimpieza=true;
          this.hayLimpiezaHoy=false; 
        }    
      }else{
        //console.log(datos);
        this.listaLimpiezas=datos;
        if(this.listaLimpiezas[0].strHoraInicio===""){
          this.noHayHoraInicio=true;
          this.siHayHoraInicio=false;
        }else{
          this.siHayHoraInicio=true;
          this.noHayHoraInicio=false;
        }if(this.listaLimpiezas[0].strHoraFin===""){
          this.noHayHoraFin=true;
          this.siHayHoraFin=false;
        }else{
          this.siHayHoraFin=true;
          this.noHayHoraFin=false;
        }
        this.cambio = new Date(this.listaLimpiezas[0].datFechaRegistro).toLocaleDateString()   
    }   
    })
  }

  /*getColor(intId) { 
    switch (intId) {
      case 1:
        return '#ff3333';
      case 2:
        return '#ffc14d';
      case 3:
        return '#00b300';
    }
  }*/

  

  getMisEstados(){
    this.miservicio.getEstadosLimpieza().pipe(first()).subscribe(datos=>{
      this.listaEstados=datos;
      //console.log(this.listaEstados);
    })
  }

  cambia(event:any){

    let dateInicio = new FormControl(new Date());
    let dateFin = new FormControl(new Date());

    if(this.listadoLimpieza===true){
      if(event===2 && this.listaLimpiezas[0].strHoraInicio===""){
        this.listaLimpiezas[0].strHoraInicio=dateInicio.value.toLocaleTimeString();
      }else if(event===3 && this.listaLimpiezas[0].strHoraFin===""){
        this.listaLimpiezas[0].strHoraFin=dateFin.value.toLocaleTimeString();
      }
    }else if(this.hayLimpiezaHoy===true){
      if(event===2 && this.limpiezaHoy.strHoraInicio===""){
        this.limpiezaHoy.strHoraInicio=dateInicio.value.toLocaleTimeString();
      }else if(event===3 && this.limpiezaHoy.strHoraFin===""){
        this.limpiezaHoy.strHoraFin=dateFin.value.toLocaleTimeString();
      }
    }    
  }

  //LLAMO ESTA FUNCION CUANDO SE EDITA LA LIMPIEZA DEL DIA
  //SE EDITA EL OBJETO DE LIMPIEZA
  salvar(limpieza){
    //console.log(limpieza)
    this.miFecha=this.date.value.toLocaleDateString();
    if(this.cambio===this.miFecha){
      if(limpieza.intEstadoLimpiezaId===1){
        if(this.storageService.getCurrentUser().strRolName==="Administrador"){
          if(confirm("Estas seguro que quieres cambiar esta habitacion al estado SUCIA")){
            
            this.miservicio.putLimpieza(limpieza).pipe(first()).subscribe((datos:any)=>{
              //if(datos.miRespuesta.booOk===true){
                this.thisDialogRef.close(datos);
              //}                           
            })
          }
        }else{
          this.error2=true;
        }
      }else{        
        this.miservicio.putLimpieza(limpieza).pipe(first()).subscribe((datos:any)=>{
          //if(datos.miRespuesta.booOk===true){
            this.thisDialogRef.close(datos);
          //} 
          
        })
      }            
    }else{
      this.guardar=true;
    }  
}

//LLAMO ESTA FUNCION CUANDO ES UNA NUEVA LIMPIEZA DEL DIA
//SE CREA EL OBJETO DE LIMPIEZA
salvar2(limpiezaHoy:Limpieza){
  this.miFecha=this.date.value.toLocaleDateString();
  if(this.cambio===this.miFecha){
    if(limpiezaHoy.intEstadoLimpiezaId===1){
      if(this.storageService.getCurrentUser().strRolName==="Administrador"){
        if(confirm("Estas seguro que quieres cambiar esta habitacion al estado SUCIA")){
          this.miservicio.putLimpieza2(limpiezaHoy).pipe(first()).subscribe((datos:any)=>{
            //if(datos.miRespuesta.booOk===true){
              this.thisDialogRef.close(datos);
            //}          
          })
        }
      }else{
        this.error2=true;
      }
    }else{
      this.miservicio.putLimpieza2(limpiezaHoy).pipe(first()).subscribe((datos:any)=>{
        //if(datos.miRespuesta.booOk===true){
          this.thisDialogRef.close(datos);
        //}         
      })
    }            
  }else{
    this.guardar=true;
    }
  
}

cancelar(){
  this.thisDialogRef.close();
}



}
