import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CRespuestaService } from '../login/shared/CRespuesta.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  loginForm: FormGroup;
  submitted: Boolean = false;
  strUserName:string;
  strPassword:string;
  strConfirmPassword:string;

  mostrarPassword:boolean=true;
  ocultarPassword:boolean=false;
  mostrarPassword2:boolean=true;
  ocultarPassword2:boolean=false;

  passwordError:boolean=false;
  cookie:string;
  id:string;
  mensaje:string;

  operacionFinal:boolean=false;
  showSpinner:boolean=false;

  constructor(private _formBuilder: FormBuilder,private miservicio:CRespuestaService,private route: ActivatedRoute,
    private router: Router) {
      this.route.queryParams.subscribe(params => {
        this.cookie=params["coo"];
        this.id=params["id"];
      });
     }

  ngOnInit() {
    //console.log(this.cookie);
    this.loginForm = this._formBuilder.group({
      username: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{7,}')        
      ])),
      confirmpassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{7,}')        
      ])),
    })
  }

  showPassword(){
    this.mostrarPassword=false;
    this.ocultarPassword=true;
  }

  hiddenPassword(){
    this.mostrarPassword=true;
    this.ocultarPassword=false;
  }

  showPassword2(){
    this.mostrarPassword2=false;
    this.ocultarPassword2=true;
  }

  hiddenPassword2(){
    this.mostrarPassword2=true;
    this.ocultarPassword2=false;
  }

  submitLogin(){
    this.submitted=true;
    this.passwordError=false;

    if(this.loginForm.valid){
      if(this.strPassword===this.strConfirmPassword){
        //console.log(this.strUserName, this.strPassword, this.strConfirmPassword);
        this.miservicio.changePasswordAdmin(this.strUserName, this.strPassword, this.strConfirmPassword,this.cookie,this.id).pipe(first()).subscribe(datos=>{
          //console.log(datos)
          this.mensaje=datos.strMensaje;
          this.operacionFinal=true;
          if(datos.booOk===true){
          
            let extra: NavigationExtras = {
              queryParams: {
                'coo':this.cookie,
              }
            }

            this.showSpinner = true;
            setTimeout(() => {
                this.showSpinner = false; 
                this.router.navigate(['/login'], extra);          
            }, 2000);

          }else{
            //this.mensaje="Parece que ha ocurrido un error. Por favor, vuelve a intentarlo"
          }               
        },(err : HttpErrorResponse)=>{
          this.operacionFinal=true;
          this.mensaje="Parece que ha ocurrido un error. Por favor, vuelve a intentarlo"
        })
      }else{
        this.passwordError=true;
        this.operacionFinal=false;
      }
    }    
  }

}
