import {NgModule, Optional, SkipSelf} from '@angular/core';
//import {fakeBackendProvider} from "./helper/fake-backend";
import {MockBackend} from "@angular/http/testing";
import {BaseRequestOptions} from "@angular/http";
import {StorageService} from "./services/storage.service";
import {AuthorizatedGuard} from "./guards/authorizated.guard";
import { AuthAdminGuard } from './guards/auth-admin.guard';
import { AuthTrabajadorGuard } from './guards/auth-trabajador.guard';
import { APIResolver } from './models/resolve/apiresolver';
//import { CRespuestaService } from './services/CRespuesta.service';
//import { HttpClientModule , HttpClient} from '@angular/common/http';

@NgModule({
  declarations: [  ],
  imports: [],
  providers: [
    StorageService,
    AuthorizatedGuard,
    MockBackend,
    BaseRequestOptions,
    AuthAdminGuard,
    AuthTrabajadorGuard,
    APIResolver    
  ],
  bootstrap: []
})
export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
