import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router, ActivatedRoute } from '@angular/router';
import { StorageService } from '../../services/storage.service';

@Injectable()
export class APIResolver implements Resolve<any> {
  constructor(private storageService: StorageService,router:ActivatedRoute) {
    var snapshot = router.snapshot;
    //console.log(snapshot);
  }

  resolve(route: ActivatedRouteSnapshot) {
          
      //console.log(route.pathFromRoot["0"].component.name)
      //console.log(window.location)

     
      
  }  
}
