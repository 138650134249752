export class ReservaPago {

    public intId?:number;
    public intReservaId?:number;
    public intReservaGrupoId?:number;
    public intFormaPagoId?:number;
    public intFacturaId?:number;
    public strDescripcion?:string;
    public decImporte?:number;
    public datFecha?:Date;

}
