import {Component, ViewChild} from '@angular/core';
import {DayPilot, DayPilotModalComponent} from "daypilot-pro-angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AsyncSubject, Observable} from "rxjs";
import { CreateRoomParams, DataService } from '../service/data.service';

@Component({
  selector: 'room-create-dialog',
  template: `
    <daypilot-modal>
    <div class="center">            
      <form [formGroup]="form">
      <h1>Nueva habitación</h1>
      <br>
        <div class="form-item">
          <input formControlName="name" type="text" placeholder="Numero habitación"> <span *ngIf="!form.controls.name.valid"> *Campo necesario</span>
        </div>
        <div class="form-item">
          <select formControlName="capacity">
            <option *ngFor="let c of capacities" [ngValue]="c.value">{{c.name}}</option>
          </select>
        </div>
        <br>
        <div class="form-item">
          <ul>
            <li>
            <button (click)="submit()" [disabled]="!form.valid">Guardar</button>
            </li>
            <li>
            <button (click)="cancel()">Cancelar</button>
            </li>
          </ul>          
        </div>
    </form>
    </div>
    </daypilot-modal>
  `,
  styles: [`
  .center {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top:80px;
  }
  .center form{
    margin:0 auto;
    max-width:400px;
  }
  .form-item {
    margin: 4px 0px;
  }

  .form-item ul{
    width:100%;
    list-style:none;
  }

  li{
    width:50%;
    display: inline-block;
  }
  li button{
    width:40%;
    margin:0 auto;
  }
  `]
})
export class RoomCreateComponent {
  @ViewChild(DayPilotModalComponent) modal : DayPilotModalComponent;

  form: FormGroup;
  row: DayPilot.Row;
  subject: AsyncSubject<CreateRoomParams>;

  capacities: any[] = [
    { name: "1 bed", value: 1},
    { name: "2 beds", value: 2},
    { name: "3 beds", value: 3},
    { name: "4 beds", value: 4}
  ];

  constructor(private fb: FormBuilder, private ds: DataService) {
    this.form = this.fb.group({
      name: ["", Validators.required],
      capacity: [1, Validators.required],
    });
  }

  show(): Observable<CreateRoomParams> {
    this.form.setValue({
      name: "",
      capacity: 1
    });
    this.modal.show();

    this.subject = new AsyncSubject();
    return this.subject.asObservable();

  }

  submit() {
    let data = this.form.getRawValue();

    let params: CreateRoomParams = {
      name: data.name,
      capacity: data.capacity,
      intTipoHabitacion:data.intTipoHabitacion
    };

    this.modal.hide();
    this.subject.next(params);
    this.subject.complete();

  }

  cancel() {
    this.modal.hide();
    this.subject.next(null);
    this.subject.complete();
  }


}

